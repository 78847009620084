import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
import moment from "moment";

const slice = createSlice({
  name: "homeBillboardChildsMains",
  initialState: {
    list: [],
    listSingleRow: [],
    listOnNavvar: [],
    loading: false,
    lastFetch: null
  },
  reducers: {
    //
    // Babak's Note: By adding 'homeBillboardChildsMainsRequested' or 'homeBillboardChildsMainsReceived' below, a action is created for us:-
    // actions => action handlers. The 'homeBillboardChildsMains' below represents the 'state':-
    homeBillboardChildsMainsRequested: (homeBillboardChildsMains, action) => {
      // window.alert(
      //   " homeBillboardChildsMainsRequested : ",
      //   homeBillboardChildsMains
      // );
      homeBillboardChildsMains.loading = true;
    },
    homeBillboardChildsMainsReceived: (homeBillboardChildsMains, action) => {
      console.log(
        "homeBillboardChildsMainsReceiveddddddddddddddddddddddddddd : ",
        action.payload
      );
      homeBillboardChildsMains.list = action.payload;
      homeBillboardChildsMains.loading = false;
      homeBillboardChildsMains.lastFetch = Date.now();
    },
    homeBillboardChildsMainsRequestedFailed: (
      homeBillboardChildsMains,
      action
    ) => {
      homeBillboardChildsMains.loading = false;
      //   window.alert(`No HomeBillboardChildsMains found.`);
    },

    homeBillboardChildsMainsOnNavbarRequested: (
      homeBillboardChildsMains,
      action
    ) => {
      console.log(
        "homeBillboardChildsMainsOnNavbarRequested : ",
        action.payload
      );
      homeBillboardChildsMains.loading = true;
    },
    homeBillboardChildsMainsOnNavbarReceived: (
      homeBillboardChildsMains,
      action
    ) => {
      console.log(
        "homeBillboardChildsMainsOnNavbarReceived : ",
        action.payload
      );
      homeBillboardChildsMains.listOnNavvar = action.payload;
      homeBillboardChildsMains.loading = false;
      homeBillboardChildsMains.lastFetch = Date.now();
    },
    homeBillboardChildsMainsOnNavbarRequestedFailed: (
      homeBillboardChildsMains,
      action
    ) => {
      homeBillboardChildsMains.loading = false;
      //   window.alert(`No HomeBillboardChildsMains found.`);
    },

    homeBillboardChildsMainRequested: (homeBillboardChildsMain, action) => {
      console.log("homeBillboardChildsMainRequested : ", action.payload);
      homeBillboardChildsMain.loading = true;
    },
    homeBillboardChildsMainReceived: (homeBillboardChildsMain, action) => {
      console.log("homeBillboardChildsMainReceived : ", action.payload);
      homeBillboardChildsMain.listSingleRow = action.payload;
      homeBillboardChildsMain.loading = false;
      homeBillboardChildsMain.lastFetch = Date.now();
    },
    homeBillboardChildsMainRequestedFailed: (
      homeBillboardChildsMain,
      action
    ) => {
      homeBillboardChildsMain.loading = false;
      //   window.alert(`No HomeBillboardChildsMain found.`);
    },
    homeBillboardChildsMainChangeRequested: (
      homeBillboardChildsMain,
      action
    ) => {
      console.log("homeBillboardChildsMainChangeRequested : ", action.payload);
      homeBillboardChildsMain.loading = true;
    },
    homeBillboardChildsMainChangeFailed: (homeBillboardChildsMain, action) => {
      homeBillboardChildsMain.loading = false;

      // //
      // // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete homeBillboardChildsMain api at the back end.
      // const { ID: homeBillboardChildsMainId, A_Name: homeBillboardChildsMainName } = action.payload;

      // window.alert(`HomeBillboardChildsMain '${homeBillboardChildsMainName}' no longer exists.`);

      // const index = homeBillboardChildsMain.list.findIndex(homeBillboardChildsMain => homeBillboardChildsMain.ID === homeBillboardChildsMainId);
      // //console.log("homeBillboardChildsMainDeleted ha ", index);
      // homeBillboardChildsMain.list.splice(index, 1);
    },
    homeBillboardChildsMainChanged: (homeBillboardChildsMain, action) => {
      homeBillboardChildsMain.loading = false;
    },
    homeBillboardChildsMainDeleteRequested: (
      homeBillboardChildsMain,
      action
    ) => {
      console.log("homeBillboardChildsMainDeleteRequested : ", action.payload);
      homeBillboardChildsMain.loading = true;
    },
    homeBillboardChildsMainDeleteFailed: (homeBillboardChildsMains, action) => {
      homeBillboardChildsMains.loading = false;

      //
      // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete homeBillboardChildsMain api at the back end.
      const {
        ID: homeBillboardChildsMainId,
        A_Name: homeBillboardChildsMainName
      } = action.payload;

      window.alert(
        `An issue has occured with homeBillboardChildsMain '${homeBillboardChildsMainName}'. ${action.payload}`
      );

      const index = homeBillboardChildsMains.list.findIndex(
        homeBillboardChildsMain =>
          homeBillboardChildsMain.ID === homeBillboardChildsMainId
      );
      //console.log("homeBillboardChildsMainDeleted ha ", index);
      homeBillboardChildsMains.list.splice(index, 1);
    },
    homeBillboardChildsMainDeleted: (homeBillboardChildsMains, action) => {
      //
      // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete homeBillboardChildsMain api at the back end.
      const { ID: homeBillboardChildsMainID } = action.payload;
      // console.log("homeBillboardChildsMainDeleted ha ", action.payload, homeBillboardChildsMainID, homeBillboardChildsMains);
      const index = homeBillboardChildsMains.list.findIndex(
        homeBillboardChildsMain =>
          homeBillboardChildsMain.ID === homeBillboardChildsMainID
      );
      //console.log("homeBillboardChildsMainDeleted, ha! ha! ", index);
      homeBillboardChildsMains.list.splice(index, 1);
    },
    homeBillboardChildsMainAddRequested: (homeBillboardChildsMain, action) => {
      console.log("homeBillboardChildsMainAddRequested : ", action.payload);
      homeBillboardChildsMain.loading = true;
    },
    homeBillboardChildsMainAddFailed: (homeBillboardChildsMain, action) => {
      homeBillboardChildsMain.loading = false;
    },
    //
    // Babak's Note: The 'homeBillboardChildsMains' below represents the 'state':-
    homeBillboardChildsMainAdded: (homeBillboardChildsMains, action) => {
      console.log("homeBillboardChildsMainAdded : ", action.payload);
      homeBillboardChildsMains.list.push(action.payload);
    }
    // homeBillboardChildsMainAdded: (homeBillboardChildsMains, action) => {
    //   homeBillboardChildsMains.list.push({
    //     id: ++lastId,
    //     description: action.payload.description,
    //     resolved: false
    //   });
    // },
    // homeBillboardChildsMainDeleted: (homeBillboardChildsMains, action) => {
    //   console.log("homeBillboardChildsMainDeleted ha ", action.payload.ID);
    //   homeBillboardChildsMains.list.filter(homeBillboardChildsMain => homeBillboardChildsMain.ID !== action.payload.ID);
    // },
  }
});
const {
  homeBillboardChildsMainAddRequested,
  homeBillboardChildsMainAdded,
  homeBillboardChildsMainAddFailed,
  homeBillboardChildsMainChangeRequested,
  homeBillboardChildsMainChanged,
  homeBillboardChildsMainChangeFailed,
  homeBillboardChildsMainDeleteRequested,
  homeBillboardChildsMainDeleted,
  homeBillboardChildsMainDeleteFailed,
  homeBillboardChildsMainsReceived,
  homeBillboardChildsMainsRequested,
  homeBillboardChildsMainsRequestedFailed,
  homeBillboardChildsMainsOnNavbarReceived,
  homeBillboardChildsMainsOnNavbarRequested,
  homeBillboardChildsMainsOnNavbarRequestedFailed,
  homeBillboardChildsMainReceived,
  homeBillboardChildsMainRequested,
  homeBillboardChildsMainRequestedFailed
} = slice.actions;
export default slice.reducer;

//
// Action Creators
const url = "/homeBillboardChildsMains";

export const loadHomeBillboardChildsMains = homeBillboardID => (
  dispatch,
  getState
) => {
  // const { lastFetch } = getState().entities.homeBillboardChildsMains;

  // window.alert(`loadHomeBillboardChildsMains lastFetch : ${homeBillboardID}`);

  // //
  // // Babak's Note: Below we check with 'moment' that catch that if this request was already fetched less than 10 minutes ago then do not fetch again:-
  // const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  // if (diffInMinutes < 10) return;

  dispatch(
    apiCallBegan({
      url: url + "/main" + "/" + homeBillboardID,
      onStart: homeBillboardChildsMainsRequested.type,
      method: "get",
      onSuccess: homeBillboardChildsMainsReceived.type,
      onError: homeBillboardChildsMainsRequestedFailed.type
    })
  );
};

export const loadHomeBillboardChildsMain = homeBillboardChildsMainID => (
  dispatch,
  getState
) => {
  // const { lastFetch } = getState().entities.homeBillboardChildsMains;

  //console.log("loadHomeBillboardChildsMain : ", homeBillboardChildsMainID);

  // //
  // // Babak's Note: Below we check with 'moment' that catch that if this request was already fetched less than 10 minutes ago then do not fetch again:-
  // const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  // if (diffInMinutes < 10) return;

  dispatch(
    apiCallBegan({
      url: url + "/" + homeBillboardChildsMainID,
      onStart: homeBillboardChildsMainRequested.type,
      method: "get",
      onSuccess: homeBillboardChildsMainReceived.type,
      onError: homeBillboardChildsMainRequestedFailed.type
    })
  );
};

//
// Babak's Note: Below function 'apiCallBegan' is returned back by which function (in this case from within 'index.js') calls 'addHomeBillboardChildsMain':-
export const addHomeBillboardChildsMain = (
  homeBillboardChildsMain,
  user,
  currentDate
) =>
  apiCallBegan({
    url,
    method: "post",
    onStart: homeBillboardChildsMainAddRequested.type,
    //data: homeBillboardChildsMain,
    data: {
      N_OrderNo: homeBillboardChildsMain.N_OrderNo,
      A_Reference: homeBillboardChildsMain.A_Reference,
      A_Summary: homeBillboardChildsMain.A_Summary,
      A_HoverSummary01_Link: homeBillboardChildsMain.A_HoverSummary01_Link,
      I_Image: homeBillboardChildsMain.I_Image,
      D_Inserted: currentDate,
      A_Inserted_By: user
    },
    onSuccess: homeBillboardChildsMainAdded.type,
    onError: homeBillboardChildsMainAddFailed.type
  });

export const getHomeBillboardChildsMains = createSelector(
  state => state.entities.homeBillboardChildsMains,
  //
  // Babak's Note: In the below line, by installing above 'reselect' (at the top of this file) if the list of (homeBillboardChildsMains, projects) has not changed then do not requery again:-
  // (homeBillboardChildsMains) => homeBillboardChildsMains.list.filter(homeBillboardChildsMain => !homeBillboardChildsMain.B_Active)
  homeBillboardChildsMains => homeBillboardChildsMains.list
);

export const changeHomeBillboardChildsMain = (
  homeBillboardChildsMain,
  user,
  currentDate
) =>
  apiCallBegan({
    url: url + "/" + homeBillboardChildsMain.ID,
    method: "put",
    onStart: homeBillboardChildsMainChangeRequested.type,
    data: {
      N_OrderNo: homeBillboardChildsMain.N_OrderNo,
      A_Reference: homeBillboardChildsMain.A_Reference,
      A_Summary: homeBillboardChildsMain.A_Summary,
      A_HoverSummary01_Link: homeBillboardChildsMain.A_HoverSummary01_Link,
      I_Image: homeBillboardChildsMain.I_Image,
      D_Updated: currentDate,
      A_Updated_By: user
    },
    onSuccess: homeBillboardChildsMainChanged.type,
    onError: homeBillboardChildsMainChangeFailed.type
  });

export const deleteHomeBillboardChildsMain = homeBillboardChildsMain =>
  apiCallBegan({
    url: url + "/" + homeBillboardChildsMain.ID,
    method: "delete",
    onStart: homeBillboardChildsMainDeleteRequested.type,
    data: {
      ID: homeBillboardChildsMain.ID,
      A_Reference: homeBillboardChildsMain.A_Reference
    },
    onSuccess: homeBillboardChildsMainDeleted.type,
    onError: homeBillboardChildsMainDeleteFailed.type
  });
