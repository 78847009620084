import React, { Component } from "react";
import Radium, { StyleRoot } from "radium";
import RenderHomeBillboardChildsMainVerticalImages from "./renderHomeBillboardChildsMainVerticalImages";
//import slideInLeft from "react-animations/lib/slide-in-left";
import { getHomeBillboardChildsMains } from "../../services/homeBillboardChildsMainVerticalImages";

class HomeBillboardChildsMainVerticalImages extends Component {
  state = {
    homeBillboardChildsMainVerticalImages: []
  };

  async componentDidMount() {
    const homeBillboardsID = this.props.homeBillboardsID;

    // window.alert(`renderBillboardBulletPinterChilds 01 : ${homeBillboardsID}`);

    // console.log(
    //   `HomeBillboardBulletPointChilds, componentDidMount 01 : ${homeBillboardID}`
    // );

    const {
      data: homeBillboardChildsMainVerticalImages
    } = await getHomeBillboardChildsMains(homeBillboardsID);

    // console.log(
    //   "componentDidMount BulletPointChild 02 : ",
    //   homeBillboardChilds
    // );

    this.setState({ homeBillboardChildsMainVerticalImages });
  }

  render() {
    const mainView = this.props.mainView;
    const identifier = this.props.identifier;
    const sectionStyle = this.props.sectionStyle;
    const sectionStyleMD = this.props.sectionStyleMD;
    const sectionStyleSM = this.props.sectionStyleSM;
    const sectionStyleXS = this.props.sectionStyleXS;
    const homeBillboardsID = this.props.homeBillboardsID;
    const textFC = this.props.textFC;
    const reference = this.props.reference;
    const summary = this.props.summary;
    const imageParent = this.props.imageParent;
    const urlLinkParent = this.props.urlLinkParent;

    const { homeBillboardChildsMainVerticalImages } = this.state;
    // const { length: count } = this.state.homeBillboardChildsMainVerticalImages;
    // window.alert(`Number of rows : ${count}`);
    // if (count === 0) return <div className="hourGlass"></div>;

    // window.alert(`Number of rows : ${reference}`);

    return (
      <div>
        <StyleRoot
          style={sectionStyle}
          className="d-none d-lg-block HeroMain homeOverflowHidden"
        >
          <div
            style={{
              marginTop: mainView ? "250px" : "0px",
              marginBottom: mainView ? "100px" : "0px"
            }}
            className="VerticalImagesChildHeroMain-contentTopLG colorlib-about"
          >
            <div className="VerticalImagesChildHeroMain-contentInnerTopLG">
              <div className="VerticalImagesChildHeroMain-subTitleTopLG">
                <div className="row">
                  <div>
                    <div
                      className="heading-meta"
                      style={{ marginBottom: "10px" }}
                    >
                      <span
                        className="borderInner"
                        style={{
                          color: `${textFC}`,
                          border: `1px solid ${textFC}`
                        }}
                      >
                        {reference}
                      </span>
                    </div>

                    <h2
                      style={{
                        color: `${textFC}`,
                        border: `1px solid ${textFC}`
                      }}
                      className="colorlib-heading borderInner"
                    >
                      {summary}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="VerticalImagesChildHeroMain-subTitleMiddleLG">
                <div className="row">
                  <RenderHomeBillboardChildsMainVerticalImages
                    homeBillboardChildsMainVerticalImages={
                      homeBillboardChildsMainVerticalImages
                    }
                    nameOfClassParentColumn={"col-md-12"}
                    nameOfClassChildColumn={"col-6"}
                    mediaAttribute={"MD"}
                    parentImageLink={"VerticalImagesChildLargeImageLG"}
                    parentReferenceLink={
                      "VerticalImagesChildLargeReferenceLG textTitleNoShadowBlack"
                    }
                    parentSummaryLink={
                      "VerticalImagesChildLargeSummaryLG textTitleNoShadowBlack"
                    }
                    childImageLink={"VerticalImagesChildSmallImageLG"}
                    childReferenceLink={
                      "VerticalImagesChildSmallReferenceLG textTitleNoShadowBlack"
                    }
                    childSummaryLink={
                      "VerticalImagesChildSmallSummaryLG textTitleNoShadowBlack"
                    }
                  ></RenderHomeBillboardChildsMainVerticalImages>
                </div>
              </div>
            </div>
          </div>
        </StyleRoot>

        <StyleRoot
          style={sectionStyleMD}
          className="d-none d-md-block d-lg-none HeroMain homeOverflowHidden"
        >
          <div
            style={{
              marginTop: mainView ? "100px" : "0px",
              marginBottom: mainView ? "100px" : "0px"
            }}
            className="VerticalImagesChildHeroMain-contentTopMD colorlib-about"
          >
            <div className="VerticalImagesChildHeroMain-contentInnerTopMD">
              <div className="VerticalImagesChildHeroMain-subTitleTopMD">
                <div className="row">
                  <div>
                    <div
                      className="heading-meta"
                      style={{ marginBottom: "10px" }}
                    >
                      <span
                        className="borderInner"
                        style={{
                          color: `${textFC}`,
                          border: `1px solid ${textFC}`
                        }}
                      >
                        {reference}
                      </span>
                    </div>

                    <h2
                      style={{
                        color: `${textFC}`,
                        border: `1px solid ${textFC}`
                      }}
                      className="colorlib-heading borderInner"
                    >
                      {summary}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="VerticalImagesChildHeroMain-subTitleMiddleMD">
                <div className="row">
                  <RenderHomeBillboardChildsMainVerticalImages
                    homeBillboardChildsMainVerticalImages={
                      homeBillboardChildsMainVerticalImages
                    }
                    nameOfClassParentColumn={"col-md-12"}
                    nameOfClassChildColumn={"col-6"}
                    mediaAttribute={"MD"}
                    parentImageLink={"VerticalImagesChildLargeImageMD"}
                    parentReferenceLink={
                      "VerticalImagesChildLargeReferenceMD textTitleNoShadowBlack"
                    }
                    parentSummaryLink={
                      "VerticalImagesChildLargeSummaryMD textTitleNoShadowBlack"
                    }
                    childImageLink={"VerticalImagesChildSmallImageMD"}
                    childReferenceLink={
                      "VerticalImagesChildSmallReferenceMD textTitleNoShadowBlack"
                    }
                    childSummaryLink={
                      "VerticalImagesChildSmallSummaryMD textTitleNoShadowBlack"
                    }
                  ></RenderHomeBillboardChildsMainVerticalImages>
                  {/* <div className="col-md-9">
                    <div>top row</div>
                  </div>
                  <div className="col-md-9">
                    <div className="col-6" style={{ float: "left" }}>
                      top row2
                    </div>
                    <div className="col-6" style={{ float: "left" }}>
                      bottom row2
                    </div>
                    <div className="col-6" style={{ float: "left" }}>
                      top row3
                    </div>
                    <div className="col-6" style={{ float: "left" }}>
                      bottom row3
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </StyleRoot>

        <StyleRoot
          style={sectionStyleSM}
          className="d-sm-block d-md-none d-none HeroMain homeOverflowHidden"
        >
          <div
            style={{
              marginTop: mainView ? "100px" : "-50px",
              marginBottom: mainView ? "100px" : "0px"
            }}
            className="VerticalImagesChildHeroMain-contentTopSM colorlib-about"
          >
            <div className="VerticalImagesChildHeroMain-contentInnerTopSM">
              <div className="VerticalImagesChildHeroMain-subTitleTopSM">
                <div className="row">
                  <div>
                    <div
                      className="heading-meta"
                      style={{ marginBottom: "10px" }}
                    >
                      <span
                        className="borderInner"
                        style={{
                          color: `${textFC}`,
                          border: `1px solid ${textFC}`
                        }}
                      >
                        {reference}
                      </span>
                    </div>

                    <h2
                      style={{
                        color: `${textFC}`,
                        border: `1px solid ${textFC}`
                      }}
                      className="colorlib-heading borderInner"
                    >
                      {summary}
                    </h2>
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginTop: mainView ? "100px" : "-30px"
                }}
                className="VerticalImagesChildHeroMain-subTitleMiddleSM"
              >
                <div className="row">
                  <RenderHomeBillboardChildsMainVerticalImages
                    homeBillboardChildsMainVerticalImages={
                      homeBillboardChildsMainVerticalImages
                    }
                    nameOfClassParentColumn={"col-md-12"}
                    nameOfClassChildColumn={"col-6"}
                    mediaAttribute={"SM"}
                    parentImageLink={"VerticalImagesChildLargeImageSM"}
                    parentReferenceLink={
                      "VerticalImagesChildLargeReferenceSM textTitleNoShadowBlack"
                    }
                    parentSummaryLink={
                      "VerticalImagesChildLargeSummarySM textTitleNoShadowBlack"
                    }
                    childImageLink={"VerticalImagesChildSmallImageSM"}
                    childReferenceLink={
                      "VerticalImagesChildSmallReferenceSM textTitleNoShadowBlack"
                    }
                    childSummaryLink={
                      "VerticalImagesChildSmallSummarySM textTitleNoShadowBlack"
                    }
                  ></RenderHomeBillboardChildsMainVerticalImages>
                </div>
              </div>
            </div>
          </div>
        </StyleRoot>

        <StyleRoot
          style={sectionStyleXS}
          className="d-xs-block d-sm-none d-md-none HeroMain homeOverflowHidden"
        >
          <div
            style={{
              marginTop: mainView ? "100px" : "-50px",
              marginBottom: mainView ? "100px" : "0px"
            }}
            className="VerticalImagesChildHeroMain-contentTopXS colorlib-about"
          >
            <div className="VerticalImagesChildHeroMain-contentInnerTopXS">
              <div className="VerticalImagesChildHeroMain-subTitleTopXS">
                <div className="row">
                  <div>
                    <div
                      className="heading-meta"
                      style={{ marginBottom: "10px" }}
                    >
                      <span
                        className="borderInner"
                        style={{
                          color: `${textFC}`,
                          border: `1px solid ${textFC}`
                        }}
                      >
                        {reference}
                      </span>
                    </div>

                    <h2
                      style={{
                        color: `${textFC}`,
                        border: `1px solid ${textFC}`
                      }}
                      className="colorlib-heading borderInner"
                    >
                      {summary}
                    </h2>
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginTop: mainView ? "100px" : "-30px"
                }}
                className="VerticalImagesChildHeroMain-subTitleMiddleXS"
              >
                <div className="row">
                  <RenderHomeBillboardChildsMainVerticalImages
                    homeBillboardChildsMainVerticalImages={
                      homeBillboardChildsMainVerticalImages
                    }
                    nameOfClassParentColumn={"col-md-12"}
                    nameOfClassChildColumn={"col-6"}
                    mediaAttribute={"XS"}
                    parentImageLink={"VerticalImagesChildLargeImageXS"}
                    parentReferenceLink={
                      "VerticalImagesChildLargeReferenceXS textTitleNoShadowBlack"
                    }
                    parentSummaryLink={
                      "VerticalImagesChildLargeSummaryXS textTitleNoShadowBlack"
                    }
                    childImageLink={"VerticalImagesChildSmallImageXS"}
                    childReferenceLink={
                      "VerticalImagesChildSmallReferenceXS textTitleNoShadowBlack"
                    }
                    childSummaryLink={
                      "VerticalImagesChildSmallSummaryXS textTitleNoShadowBlack"
                    }
                  ></RenderHomeBillboardChildsMainVerticalImages>
                </div>
              </div>
            </div>
          </div>
        </StyleRoot>
      </div>
    );
  }
}

export default HomeBillboardChildsMainVerticalImages;
