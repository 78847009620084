import React, { Component } from "react";
import { Navbar } from "react-bootstrap";

//
// Babak's Note: Below 'user' is from 'props', we have used destructering so by adding only 'user' reactjs will
//               automnatically take it from 'props':-
class NavBarFooter extends Component {
  constructor(props) {
    super(props);
  }

  FooterNavBar = ({ customizationSingleRow }) => {
    if (!customizationSingleRow.A_BaseNavbarFGC) return "";

    const styles = {
      background: `${customizationSingleRow.A_BaseNavbarBGC}`,
      color: `${customizationSingleRow.A_BaseNavbarFGC}`,
      marginLeft: "auto",
      marginRight: "auto",
      textAlign: "center"
    };

    return (
      <div style={styles}>
        <a>
          &copy; {new Date().getFullYear()} -{" "}
          {customizationSingleRow.A_WebAppTitle}
        </a>
        <br />
        <a>Full Stack Developer : bobbyalimardani@hotmail.com</a>
      </div>
    );
  };

  render() {
    const { customizationSingleRow } = this.props;

    // return (
    //   <Navbar
    //     style={{
    //       background: `${customizationSingleRow.A_BaseNavbarBGC}`,
    //       color: `${customizationSingleRow.A_BaseNavbarFGC}`
    //     }}
    //     fixed="bottom"
    //     expand="lg"
    //     className="navbar"
    //   >
    //     <this.FooterNavBar
    //       customizationSingleRow={this.props.customizationSingleRow}
    //     />
    //   </Navbar>
    // );
    return (
      <div
        className={this.props.nameOfClass}
        style={{
          background: `${customizationSingleRow.A_BaseNavbarBGC}`,
          color: `${customizationSingleRow.A_BaseNavbarFGC}`,
          paddingTop: "10px",
          paddingBottom: "15px"
        }}
        // fixed="bottom"
        expand="lg"
        // className="navbar"
      >
        <this.FooterNavBar
          customizationSingleRow={this.props.customizationSingleRow}
        />
      </div>
    );
  }
}

// export default withRouter(NavBar);
export default NavBarFooter;
