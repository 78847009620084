import React, { Component, useState } from "react";
import auth from "../services/authService";
import { NavLink } from "react-router-dom";
import { getBillboardLargesSearchAutoComplete } from "../services/billboardLargeService";
import { Link, animateScroll as scroll } from "react-scroll";
import {
  Form,
  FormControl,
  Nav,
  Navbar,
  NavDropdown,
  Button
} from "react-bootstrap";
import logo02 from "../images/logo05.png";

// Babak's Note: Below is no longer required even though I have installed it:-
//import styled from "styled-components";

//
// Babak's Note: Below 'user' is from 'props', we have used destructering so by adding only 'user' reactjs will
//               automnatically take it from 'props':-
class NavBar2 extends Component {
  scrollToTop = () => {
    scroll.scrollToTop();
  };

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      isOpen: false,
      navExpanded: false,
      suggestions: [],
      text: "",
      dynamicSearchIcon: ""
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  intervalID;

  HomeNavigate = ({ customizationSingleRow }) => {
    if (!customizationSingleRow.A_TopNavbarFGC) return "";

    const [bgColour, setBgColour] = useState(
      `${customizationSingleRow.A_TopNavbarFGC}`
    );

    const styles = {
      background: `${customizationSingleRow.A_TopNavbarBGC}`,
      color: `${bgColour}`
    };

    //
    // Babak's Notes: VERY GOOD "https://getbootstrap.com/docs/4.0/utilities/spacing/"
    return (
      <NavLink
        // className="navbar-brand text-primary clickable textShadow ml-sm-2"
        //
        // Babak's Note: "mb-1" from bottom up 1. "ml-sm-4" from left right 4. "https://getbootstrap.com/docs/4.0/utilities/spacing/",
        //                                                                     "https://getbootstrap.com/docs/4.0/utilities/display/" :-
        className="navbar-brand clickable textShadow mr-auto ml-sm-4 mb-1"
        style={styles}
        onMouseEnter={() =>
          setBgColour(`${customizationSingleRow.A_TopNavbarHover}`)
        }
        onMouseLeave={() =>
          setBgColour(`${customizationSingleRow.A_TopNavbarFGC}`)
        }
        to="/"
        onClick={this.closeNav}
      >
        Home
      </NavLink>
    );
  };

  MenuNavigate = ({ customizationSingleRow, menu, index, rowLength }) => {
    if (!customizationSingleRow.A_TopNavbarFGC) return "";

    const [bgColour, setBgColour] = useState(
      `${customizationSingleRow.A_TopNavbarFGC}`
    );

    const styles = {
      background: `${customizationSingleRow.A_TopNavbarBGC}`,
      color: `${bgColour}`
    };

    return (
      <NavLink
        // className="navbar-brand text-warning ml-sm-2"
        className="d-lg-none nav-item nav-link textShadow"
        style={styles}
        onMouseEnter={() =>
          setBgColour(`${customizationSingleRow.A_TopNavbarHover}`)
        }
        onMouseLeave={() =>
          setBgColour(`${customizationSingleRow.A_TopNavbarFGC}`)
        }
        to={
          menu.N_BillboardOrigin_ID === 1
            ? `/detailsGroup/${menu.ID}`
            : menu.N_BillboardOrigin_ID === 2
            ? `/detailsMaster/${menu.ID}`
            : menu.N_BillboardOrigin_ID === 3
            ? `/detailsDetail/${menu.ID}`
            : ""
        }
        onClick={this.closeNav2}
      >
        {menu.A_Reference}
      </NavLink>
    );
  };

  MaintenanceNavigate = ({ customizationSingleRow }) => {
    if (!customizationSingleRow.A_TopNavbarFGC) return "";

    const [bgColour, setBgColour] = useState(
      `${customizationSingleRow.A_TopNavbarFGC}`
    );

    const styles = {
      background: `${customizationSingleRow.A_TopNavbarBGC}`,
      color: `${bgColour}`
    };

    return (
      <span
        // className="text-primary my-auto textShadow"
        className="my-auto textShadow"
        style={styles}
        onMouseEnter={() =>
          setBgColour(`${customizationSingleRow.A_TopNavbarHover}`)
        }
        onMouseLeave={() =>
          setBgColour(`${customizationSingleRow.A_TopNavbarFGC}`)
        }
        // data-toggle="dropdown"
        // style={{ color: bgColors.darkCyan }}
      >
        Maintenance
      </span>
    );
  };

  RolesNavigate = ({ customizationSingleRow }) => {
    if (!customizationSingleRow.A_TopNavbarFGC) return "";

    const [bgColour, setBgColour] = useState(
      `${customizationSingleRow.A_TopNavbarFGC}`
    );

    const styles = {
      background: `${customizationSingleRow.A_TopNavbarBGC}`,
      color: `${bgColour}`
    };

    return (
      <NavLink
        // className="nav-item nav-link text-primary textShadow"
        className="nav-item nav-link textShadow"
        style={styles}
        onMouseEnter={() =>
          setBgColour(`${customizationSingleRow.A_TopNavbarHover}`)
        }
        onMouseLeave={() =>
          setBgColour(`${customizationSingleRow.A_TopNavbarFGC}`)
        }
        to="/rolesList"
        onClick={this.closeNav}
      >
        Roles
      </NavLink>
    );
  };

  UsersNavigate = ({ customizationSingleRow }) => {
    if (!customizationSingleRow.A_TopNavbarFGC) return "";

    const [bgColour, setBgColour] = useState(
      `${customizationSingleRow.A_TopNavbarFGC}`
    );

    const styles = {
      background: `${customizationSingleRow.A_TopNavbarBGC}`,
      color: `${bgColour}`
    };

    return (
      <NavLink
        // className="nav-item nav-link text-primary textShadow"
        className="nav-item nav-link textShadow"
        style={styles}
        onMouseEnter={() =>
          setBgColour(`${customizationSingleRow.A_TopNavbarHover}`)
        }
        onMouseLeave={() =>
          setBgColour(`${customizationSingleRow.A_TopNavbarFGC}`)
        }
        to="/usersList"
        onClick={this.closeNav}
      >
        Users
      </NavLink>
    );
  };

  BillboardsNavigate = ({ customizationSingleRow }) => {
    if (!customizationSingleRow.A_TopNavbarFGC) return "";

    const [bgColour, setBgColour] = useState(
      `${customizationSingleRow.A_TopNavbarFGC}`
    );

    const styles = {
      background: `${customizationSingleRow.A_TopNavbarBGC}`,
      color: `${bgColour}`
    };

    return (
      <NavLink
        className="nav-item nav-link textShadow"
        style={styles}
        onMouseEnter={() =>
          setBgColour(`${customizationSingleRow.A_TopNavbarHover}`)
        }
        onMouseLeave={() =>
          setBgColour(`${customizationSingleRow.A_TopNavbarFGC}`)
        }
        to="/menusList"
        onClick={this.closeNav}
      >
        Client Menus
      </NavLink>
    );
  };

  HomeLayoutsNavigate = ({ customizationSingleRow }) => {
    if (!customizationSingleRow.A_TopNavbarFGC) return "";

    const [bgColour, setBgColour] = useState(
      `${customizationSingleRow.A_TopNavbarFGC}`
    );

    const styles = {
      background: `${customizationSingleRow.A_TopNavbarBGC}`,
      color: `${bgColour}`
    };

    return (
      <NavLink
        className="nav-item nav-link textShadow"
        style={styles}
        onMouseEnter={() =>
          setBgColour(`${customizationSingleRow.A_TopNavbarHover}`)
        }
        onMouseLeave={() =>
          setBgColour(`${customizationSingleRow.A_TopNavbarFGC}`)
        }
        to="/homeBillboardsList"
        onClick={this.closeNav}
      >
        Home Layout
      </NavLink>
    );
  };

  CustomizationsNavigate = ({ customizationSingleRow }) => {
    if (!customizationSingleRow.A_TopNavbarFGC) return "";

    const [bgColour, setBgColour] = useState(
      `${customizationSingleRow.A_TopNavbarFGC}`
    );

    const styles = {
      background: `${customizationSingleRow.A_TopNavbarBGC}`,
      color: `${bgColour}`
    };

    return (
      <NavLink
        // className="nav-item nav-link text-warning"
        className="nav-item nav-link textShadow"
        style={styles}
        onMouseEnter={() =>
          setBgColour(`${customizationSingleRow.A_TopNavbarHover}`)
        }
        onMouseLeave={() =>
          setBgColour(`${customizationSingleRow.A_TopNavbarFGC}`)
        }
        to="/customizationsList"
        onClick={this.closeNav}
      >
        Customizations
      </NavLink>
    );
  };

  AboutUsNavigate = ({ customizationSingleRow }) => {
    if (!customizationSingleRow.A_TopNavbarFGC) return "";

    const [bgColour, setBgColour] = useState(
      `${customizationSingleRow.A_TopNavbarFGC}`
    );

    const styles = {
      background: `${customizationSingleRow.A_TopNavbarBGC}`,
      color: `${bgColour}`
    };

    // const bgColors = {
    //   default: "#81b71a",
    //   blue: "#00B1E1",
    //   cyan: "#37BC9B",
    //   green: "#8CC152",
    //   red: "#E9573F",
    //   yellow: "#F6BB42",
    //   white: "#fff",
    //   lightBlue: "#ADD8E6",
    //   darkCyan: "#008B8B",
    //   menuBlue: "#0078d4",
    //   white: "white"
    // };

    if (window.location.pathname === "/homeBillboardsMain") {
      return (
        <Link
          // className="nav-item nav-link text-primary clickable textShadow"
          className="nav-item nav-link clickable textShadow"
          style={styles}
          onMouseEnter={() =>
            setBgColour(`${customizationSingleRow.A_TopNavbarHover}`)
          }
          onMouseLeave={() =>
            setBgColour(`${customizationSingleRow.A_TopNavbarFGC}`)
          }
          activeClass="active"
          to={customizationSingleRow.N_AboutUsNo}
          spy={true}
          smooth={true}
          offset={-70}
          duration={1000}
          onClick={this.closeNav}
        >
          About Us
        </Link>
      );
    } else {
      return (
        <NavLink
          // className="nav-item nav-link text-primary clickable textShadow"
          className="nav-item nav-link clickable textShadow"
          style={styles}
          onMouseEnter={() =>
            setBgColour(`${customizationSingleRow.A_TopNavbarHover}`)
          }
          onMouseLeave={() =>
            setBgColour(`${customizationSingleRow.A_TopNavbarFGC}`)
          }
          to="/aboutUs"
          onClick={this.closeNav}
        >
          About Us
        </NavLink>
      );
    }
  };

  ContactUsNavigate = ({ customizationSingleRow }) => {
    if (!customizationSingleRow.A_TopNavbarFGC) return "";

    const [bgColour, setBgColour] = useState(
      `${customizationSingleRow.A_TopNavbarFGC}`
    );

    const styles = {
      background: `${customizationSingleRow.A_TopNavbarBGC}`,
      color: `${bgColour}`
    };

    // const bgColors = {
    //   default: "#81b71a",
    //   blue: "#00B1E1",
    //   cyan: "#37BC9B",
    //   green: "#8CC152",
    //   red: "#E9573F",
    //   yellow: "#F6BB42",
    //   white: "#fff",
    //   lightBlue: "#ADD8E6",
    //   darkCyan: "#008B8B",
    //   menuBlue: "#0078d4",
    //   white: "white"
    // };
    if (window.location.pathname === "/homeBillboardsMain") {
      return (
        <Link
          // className="nav-item nav-link text-primary clickable textShadow"
          className="nav-item nav-link clickable textShadow"
          style={styles}
          onMouseEnter={() =>
            setBgColour(`${customizationSingleRow.A_TopNavbarHover}`)
          }
          onMouseLeave={() =>
            setBgColour(`${customizationSingleRow.A_TopNavbarFGC}`)
          }
          activeClass="active"
          to={customizationSingleRow.N_ContactUsNo}
          spy={true}
          smooth={true}
          offset={-70}
          duration={1000}
          onClick={this.closeNav}
        >
          Contact Us
        </Link>
      );
    } else {
      return (
        <NavLink
          // className="nav-item nav-link text-primary clickable textShadow"
          className="nav-item nav-link clickable textShadow"
          style={styles}
          onMouseEnter={() =>
            setBgColour(`${customizationSingleRow.A_TopNavbarHover}`)
          }
          onMouseLeave={() =>
            setBgColour(`${customizationSingleRow.A_TopNavbarFGC}`)
          }
          to="/contactUs"
          onClick={this.closeNav}
        >
          Contact Us
        </NavLink>
      );
    }
  };

  HelloNavigate = ({ customizationSingleRow, user }) => {
    if (!customizationSingleRow.A_TopNavbarFGC) return "";

    const [bgColour, setBgColour] = useState(
      `${customizationSingleRow.A_TopNavbarFGC}`
    );

    const styles = {
      background: `${customizationSingleRow.A_TopNavbarBGC}`,
      color: `${bgColour}`
    };

    return (
      <NavLink
        // className="nav-item nav-link text-primary textShadow"
        className="nav-item nav-link textShadow"
        style={styles}
        onMouseEnter={() =>
          setBgColour(`${customizationSingleRow.A_TopNavbarHover}`)
        }
        onMouseLeave={() =>
          setBgColour(`${customizationSingleRow.A_TopNavbarFGC}`)
        }
        to="/profileForm"
        onClick={this.closeNav}
      >
        Hello {user.a_name}
      </NavLink>
    );
  };

  LoginNavigate = ({ customizationSingleRow, user }) => {
    if (!customizationSingleRow.A_TopNavbarFGC) return "";

    const [bgColour, setBgColour] = useState(
      `${customizationSingleRow.A_TopNavbarFGC}`
    );

    const styles = {
      background: `${customizationSingleRow.A_TopNavbarBGC}`,
      color: `${bgColour}`
    };

    return (
      <NavLink
        // className="nav-item nav-link text-primary textShadow"
        className="nav-item nav-link textShadow"
        style={styles}
        onMouseEnter={() =>
          setBgColour(`${customizationSingleRow.A_TopNavbarHover}`)
        }
        onMouseLeave={() =>
          setBgColour(`${customizationSingleRow.A_TopNavbarFGC}`)
        }
        to="/login"
        onClick={this.closeNav}
      >
        Login
      </NavLink>
    );
  };

  LogoutNavigate = ({ customizationSingleRow, user }) => {
    if (!customizationSingleRow.A_TopNavbarFGC) return "";

    const [bgColour, setBgColour] = useState(
      `${customizationSingleRow.A_TopNavbarFGC}`
    );

    const styles = {
      background: `${customizationSingleRow.A_TopNavbarBGC}`,
      color: `${bgColour}`
    };

    return (
      <NavLink
        // className="nav-item nav-link text-primary textShadow"
        className="nav-item nav-link textShadow"
        style={styles}
        onMouseEnter={() =>
          setBgColour(`${customizationSingleRow.A_TopNavbarHover}`)
        }
        onMouseLeave={() =>
          setBgColour(`${customizationSingleRow.A_TopNavbarFGC}`)
        }
        to="/logout"
        // style={{ color: bgColors.darkCyan }}
        onClick={this.closeNav}
      >
        Logout
      </NavLink>
    );
  };

  SearchNavigate = ({ customizationSingleRow }) => {
    if (!customizationSingleRow.A_TopSearchBtnFGC) return "";

    const [bgColour, setBgColour] = useState(
      `${customizationSingleRow.A_TopSearchBtnFGC}`
    );

    const styles = {
      background: `${customizationSingleRow.A_TopSearchBtnBGC}`,
      color: `${bgColour}`
    };

    return (
      <Button
        type="submit"
        className="boxShadowLow ml-sm-2"
        style={styles}
        onMouseEnter={() =>
          setBgColour(`${customizationSingleRow.A_TopSearchBtnHover}`)
        }
        onMouseLeave={() =>
          setBgColour(`${customizationSingleRow.A_TopSearchBtnFGC}`)
        }
        // type="submit"
        //variant="outline-primary"
        //variant="outline-secondary"
        //variant="outline-success"
        //variant="outline-danger"
        //variant="outline-warning"
        //variant="outline-info"
        //variant="outline-light"
        //variant="outline-primary" // Babak's Note: Used before
        //variant="primary"
        //variant="secondary"
        //variant="success"
        //variant="danger"
        //variant="warning"
        //variant="info"
        //variant="light"
        //variant="dark"

        // onClick={e => {
        //   onClickSearch(e);
        // }}
      >
        Search
      </Button>
    );
  };

  handleOpen = () => {
    //window.alert(`Open`);
    this.setState({ isOpen: true });
  };

  handleClose = () => {
    //window.alert(`Leave`);
    this.setState({ isOpen: false });
  };

  setNavExpanded = expanded => {
    this.setState({ navExpanded: expanded });
  };

  closeNav = () => {
    // console.log(
    //   "NavBar 2 : ",
    //   this.state.navExpanded,
    //   window.location.pathname
    // );
    //
    // Babak's Note: Had to add below line so when tabbing (by finger when in tablet or mobile mode) the hamberger menu does not un-expand.
    //               I don't why it works???
    // let origin = e.target.placeholder;
    // window.alert(`Hello : ${this.state.navExpanded}  :   ${e.target.placeholder}`)
    this.setState({ navExpanded: false });
  };

  closeNav2 = () => {
    // console.log(
    //   "NavBar 1 : ",
    //   this.state.navExpanded,
    //   window.location.pathname
    // );
    // if (window.location.pathname != "/homeBillboardsMain") {
    //   window.location = `/appointments`;
    // }

    // e.stopPropagation();
    // console.log(e.target.className); // This gets the className of the target
    window.reload(false);
    // this.setState({ navExpanded: false });
  };

  closeNavToAboutUs = () => {
    // console.log(
    //   "NavBar 2 : ",
    //   this.state.navExpanded,
    //   window.location.pathname
    // );
    if (window.location.pathname != "/homeBillboardsMain") {
      window.location = `/infoUs/38`;
    }

    // e.stopPropagation();
    //console.log(e.target.className); // This gets the className of the target

    this.setState({ navExpanded: false });
  };

  closeNavToContactUs = () => {
    // console.log(
    //   "NavBar 2 : ",
    //   this.state.navExpanded,
    //   window.location.pathname
    // );
    if (window.location.pathname != "/homeBillboardsMain") {
      window.location = `/infoUs/26`;
    }

    // e.stopPropagation();
    //console.log(e.target.className); // This gets the className of the target

    this.setState({ navExpanded: false });
  };

  delaySearchAutoComplete = () => {
    clearInterval(this.intervalID);

    const { suggestions } = this.state;
    if (suggestions.length === 0) {
      this.setState(() => ({
        dynamicSearchIcon: "",
        suggestions: "",
        text: ""
      }));
    }
  };

  onTextChanged = async e => {
    const value = e.target.value;
    let suggestions = [];
    if (value.length > 0) {
      //const regex = new RegExp(`^${value}`, "i");
      //suggestions = this.items.sort().filter(v => regex.test(v));

      // this.intervalID = setInterval(
      //   this.delaySearchAutoComplete.bind(this),
      //   10000
      // );

      this.setState(
        { dynamicSearchIcon: "dynamicSearchIcon", suggestions, text: value },
        async () => {
          await getBillboardLargesSearchAutoComplete(value).then(result =>
            this.setState({
              dynamicSearchIcon: "",
              text: value,
              suggestions: [...result.data]
            })
          );
          // Axios.get('/endpoint')
          //   .then(result => this.setState({
          //     loading: false,
          //     data: [...result.data],
          //   }));
        }
      );

      // const { data: suggestion } = await getBillboardLargesSearchAutoComplete(value);
      // suggestions = suggestion;
    }
    this.setState(() => ({ suggestions, text: value }));
  };

  suggestionSelected(e, value) {
    e.preventDefault();
    window.location = `/searchDynamic/'${value}'`;
    this.setState(() => ({
      suggestions: [],
      text: value,
      dynamicSearchView: true
    }));
  }

  renderSuggestions() {
    const { suggestions, text } = this.state;
    if (suggestions.length === 0) {
      if (text) {
        // return (
        //   <div>
        //     <span className="password__dropup__show  ml-sm-2 form-control"></span>{" "}
        //   </div>
        // );
        return null;
      } else {
        return null;
      }
    }
    return (
      <ul className="dropup-content form-control">
        {suggestions.map(item => (
          <li onClick={e => this.suggestionSelected(e, item.A_Reference)}>
            {item.A_Reference}
          </li>
        ))}
      </ul>
    );
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    clearInterval(this.intervalID);
  }

  handleScroll() {
    // const { scrollPos } = this.state;
    // this.setState({
    //   scrollPos: document.body.getBoundingClientRect().top,
    //   show: document.body.getBoundingClientRect().top < scrollPos
    // });
    if (document.body.getBoundingClientRect().top < -100) {
      // this.closeNav();
      this.setState({ show: true, suggestions: "", text: "", isOpen: false });
    } else {
      this.setState({ show: false });
    }
    // this.setState({
    //   show: document.body.getBoundingClientRect().top < -100 ? true : false
    // });
  }

  render() {
    const { text } = this.state;

    const {
      user,
      customizationSingleRow,
      menuOnNavvar,
      onClickSearch
    } = this.props;

    const rowLength = menuOnNavvar.length;

    const navbarShow = this.state.show;

    const bgColors = {
      default: "#81b71a",
      blue: "#00B1E1",
      cyan: "#37BC9B",
      green: "#8CC152",
      lightGreen: "#58FF33",
      red: "#E9573F",
      yellow: "#F6BB42",
      white: "#fff",
      lightBlue: "#33BBFF",
      darkCyan: "#008B8B",
      menuBlue: "#0078d4"
    };

    const openMenu = this.state.isOpen;

    //window.alert(`Leave ${openMenu}`);
    // console.log(
    //   "document.body.getBoundingClientRect().top : ",
    //   document.body.getBoundingClientRect().top,
    //   ",  this.state.show : ",
    //   this.state.show,
    //   ",  navbarShow : ",
    //   navbarShow
    // );

    return (
      // <nav className="navbar navbar-expand-lg navbar-light bg-light">
      //   <Link className="navbar-brand" to="/">
      //     Varkana
      //   </Link>
      //   <button
      //     className="navbar-toggler"
      //     type="button"
      //     data-toggle="collapse"
      //     data-target="#navbarSupportedContent"
      //     aria-controls="navbarSupportedContent"
      //     aria-expanded="false"
      //     aria-label="Toggle navigation"
      //   >
      //     <span className="navbar-toggler-icon" />
      //   </button>
      //   <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
      //     <div className="navbar-nav">
      // <NavLink className="nav-item nav-link" to="/months">
      //   Months
      // </NavLink>
      // {/* Babak's Note: Below 'user' value comes from above 'const NavBar = ({ user }) => {'*/}
      // {!user && (
      //   <React.Fragment>
      //     <NavLink className="nav-item nav-link" to="/login">
      //       Login
      //     </NavLink>
      //     <NavLink className="nav-item nav-link" to="/register">
      //       Register
      //     </NavLink>
      //   </React.Fragment>
      // )}
      // {user && (
      //   <React.Fragment>
      //     <NavLink className="nav-item nav-link" to="/profile">
      //       {user.name}
      //     </NavLink>
      //     <NavLink className="nav-item nav-link" to="/logout">
      //       Logout
      //     </NavLink>
      //   </React.Fragment>
      // )}
      //     </div>
      //   </div>
      // </nav>

      //
      // Babak's Note: Look at below line "style={{ marginTop: 20 }}"
      // <Navbar fixed="top" bg="light" expand="lg" style={{ marginTop: 20 }}>

      <Navbar
        className={
          navbarShow ? "activeNavbar boxShadowLow" : "hiddenNavbar boxShadowLow"
        }
        fixed="top"
        // bg="light"
        expand="lg"
        onToggle={this.setNavExpanded}
        expanded={this.state.navExpanded}
        // style={{ backgroundColor: bgColors.white }}
        style={{ backgroundColor: customizationSingleRow.A_TopNavbarBGC }}
      >
        <img
          src={logo02}
          className="nav-logo02 boxShadow  mr-auto mr-sm-1"
          alt="Logo"
          onClick={this.scrollToTop}
        />
        {/* Babak's Note: Different colour menu options :      
                          https://bootstrap-vue.js.org/docs/components/button/
                          https://getbootstrap.com/docs/4.0/utilities/colors/
                          https://getbootstrap.com/docs/4.0/utilities/colors/
                          https://www.youtube.com/watch?v=3J-bSecBdj8
      */}

        <this.HomeNavigate
          customizationSingleRow={this.props.customizationSingleRow}
        />
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          style={{
            borderColor: customizationSingleRow.A_TopNavbarFGC
          }}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {/* Babak's Note: Add className="ml-auto" to have menu on the left hand side or className="mr-auto" to have menu on the right hand side, try className="md-auto" */}
            {/* <NavLink className="nav-item nav-link text-warning" to="/months">
              Months
            </NavLink> */}

            {user && auth.getCurrentUser().t_role_a_name === "Admin" ? (
              <NavDropdown
                title={
                  <this.MaintenanceNavigate
                    customizationSingleRow={this.props.customizationSingleRow}
                  />
                }
                id="basic-nav-dropdown"
                onMouseEnter={this.handleOpen}
                onMouseLeave={this.handleClose}
                show={openMenu}
              >
                <div
                  className="dropdownMenuCentreSecond"
                  style={{
                    backgroundColor: customizationSingleRow.A_TopNavbarBGC,
                    textAlign: "left"
                  }}
                >
                  <this.RolesNavigate
                    customizationSingleRow={this.props.customizationSingleRow}
                  />

                  <this.UsersNavigate
                    customizationSingleRow={this.props.customizationSingleRow}
                  />

                  <NavDropdown.Divider />

                  <this.BillboardsNavigate
                    customizationSingleRow={this.props.customizationSingleRow}
                  />

                  <this.HomeLayoutsNavigate
                    customizationSingleRow={this.props.customizationSingleRow}
                  />

                  <NavDropdown.Divider />

                  <this.CustomizationsNavigate
                    customizationSingleRow={this.props.customizationSingleRow}
                  />
                </div>
              </NavDropdown>
            ) : (
              ""
            )}
            {/* {user && auth.getCurrentUser().t_role_a_name === "Client" ? (
              <React.Fragment>
                <NavLink
                  className="nav-item nav-link text-primary textShadow"
                  to="/appointments"
                  style={{ color: bgColors.white }}
                  onClick={this.closeNav}
                >
                  Appointments
                </NavLink>
              </React.Fragment>
            ) : (
              ""
            )} */}
            {/* Babak's Note: Below 'user' value comes from above 'const NavBar = ({ user }) => {'*/}
            {!user && (
              <React.Fragment>
                {/* {menuOnNavvar.map((menu, index) => (
                  <this.MenuNavigate
                    key={menu.ID}
                    customizationSingleRow={this.props.customizationSingleRow}
                    menu={menu}
                    index={index}
                    rowLength={rowLength}
                  />
                ))} */}

                <this.AboutUsNavigate
                  customizationSingleRow={this.props.customizationSingleRow}
                />

                <this.ContactUsNavigate
                  customizationSingleRow={this.props.customizationSingleRow}
                />

                <this.LoginNavigate
                  customizationSingleRow={this.props.customizationSingleRow}
                />

                {/* <NavLink
                  className="nav-item nav-link text-warning"
                  to="/register"
                  style={{ color: bgColors.darkCyan }}
                  onClick={this.closeNav}
                >
                  Register
                </NavLink> */}
              </React.Fragment>
            )}
            {user && (
              <React.Fragment>
                {/* <NavLink className="nav-item nav-link" to="/users">
                  Users
                </NavLink>
                <NavLink className="nav-item nav-link" to="/homeBillboards">
                  Billboards
                </NavLink>
                <NavLink className="nav-item nav-link" to="/roles">
                  Roles
                </NavLink>
                <NavLink className="nav-item nav-link" to="/menus">
                  Menus
                </NavLink> */}

                {/* {menuOnNavvar.map((menu, index) => (
                  <this.MenuNavigate
                    key={menu.ID}
                    customizationSingleRow={this.props.customizationSingleRow}
                    menu={menu}
                    index={index}
                    rowLength={rowLength}
                  />
                ))} */}

                <this.AboutUsNavigate
                  customizationSingleRow={this.props.customizationSingleRow}
                />

                <this.ContactUsNavigate
                  customizationSingleRow={this.props.customizationSingleRow}
                />
                {/* <Link
                  className="nav-item nav-link text-primary textShadow clickable"
                  activeClass="active"
                  to="10"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={5000}
                  onClick={this.closeNavToAboutUs}
                >
                  درباره ما
                </Link>
                <Link
                  className="nav-item nav-link text-primary textShadow clickable"
                  activeClass="active"
                  to="23"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={5000}
                  onClick={this.closeNavToContactUs}
                >
                  تماس با ما
                </Link> */}

                <this.HelloNavigate
                  customizationSingleRow={this.props.customizationSingleRow}
                  user={user}
                />

                <this.LogoutNavigate
                  customizationSingleRow={this.props.customizationSingleRow}
                />
              </React.Fragment>
            )}
          </Nav>
          <Form inline onSubmit={e => onClickSearch(e)}>
            <div className="btn-group">
              <input
                value={text}
                type="text"
                id="searchID"
                autoComplete="off"
                placeholder="Search"
                //
                // Babak's Note: Below mr-sm-2 have space on right hand side between search input element and search button, ml-sm-2 on left side:-
                className={`mr-sm-2 form-control ${this.state.dynamicSearchIcon}`}
                style={{
                  minWidth: "200px",
                  maxWidth: "200px"
                }}
                onChange={this.onTextChanged}
              />
              {this.renderSuggestions()}
            </div>
            <this.SearchNavigate
              customizationSingleRow={this.props.customizationSingleRow}
            />
          </Form>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default NavBar2;
