import React, { Component } from "react";

// columns: array
// sortColumn: object
// onSort: function
class TableHeader extends Component {
  raiseSort = path => {
    const sortColumn = { ...this.props.sortColumn };
    //console.log("TableHeader, raiseSort", sortColumn.path, path);
    if (sortColumn.path === path)
      sortColumn.order = sortColumn.order === "asc" ? "desc" : "asc";
    else {
      sortColumn.path = path;
      sortColumn.order = "asc";
    }
    this.props.onSort(sortColumn);
  };

  renderSortIcon = column => {
    //
    // Babak's Notes; Below we want the property of 'sortColumn' of 'this.props'
    const { sortColumn } = this.props;

    if (column.path !== sortColumn.path) return null;
    if (sortColumn.order === "asc") return <i className="fa fa-sort-asc" />;
    return <i className="fa fa-sort-desc" />;
  };

  render() {
    //console.log("render tableHeader");
    return (
      <thead>
        <tr>
          {this.props.columns
            .filter(e => !e.hidden)
            .map(column => (
              <th
                className="clickable"
                key={column.path || column.key}
                onClick={() => this.raiseSort(column.path)}
              >
                {column.label} {this.renderSortIcon(column)}
              </th>
            ))}
        </tr>
      </thead>
    );
  }
}

export default TableHeader;
