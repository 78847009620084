import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
import moment from "moment";

const slice = createSlice({
  name: "homeBillboardsMainsContactUs",
  initialState: {
    list: [],
    listSingleRow: [],
    listOnNavvar: [],
    loading: false,
    lastFetch: null
  },
  reducers: {
    //
    // Babak's Note: By adding 'homeBillboardsMainsContactUsRequested' or 'homeBillboardsMainsContactUsReceived' below, a action is created for us:-
    // actions => action handlers. The 'homeBillboardsMainsContactUs' below represents the 'state':-
    homeBillboardsMainsContactUsRequested: (
      homeBillboardsMainsContactUs,
      action
    ) => {
      console.log("homeBillboardsMainsContactUsRequested : ", action.payload);
      homeBillboardsMainsContactUs.loading = true;
    },
    homeBillboardsMainsContactUsReceived: (
      homeBillboardsMainsContactUs,
      action
    ) => {
      console.log("homeBillboardsMainsContactUsReceived : ", action.payload);
      homeBillboardsMainsContactUs.list = action.payload;
      homeBillboardsMainsContactUs.loading = false;
      homeBillboardsMainsContactUs.lastFetch = Date.now();
    },
    homeBillboardsMainsContactUsRequestedFailed: (
      homeBillboardsMainsContactUs,
      action
    ) => {
      homeBillboardsMainsContactUs.loading = false;
      //   window.alert(`No HomeBillboardsMainsContactUs found.`);
    },

    homeBillboardsMainsContactUsOnNavbarRequested: (
      homeBillboardsMainsContactUs,
      action
    ) => {
      console.log(
        "homeBillboardsMainsContactUsOnNavbarRequested : ",
        action.payload
      );
      homeBillboardsMainsContactUs.loading = true;
    },
    homeBillboardsMainsContactUsOnNavbarReceived: (
      homeBillboardsMainsContactUs,
      action
    ) => {
      console.log(
        "homeBillboardsMainsContactUsOnNavbarReceived : ",
        action.payload
      );
      homeBillboardsMainsContactUs.listOnNavvar = action.payload;
      homeBillboardsMainsContactUs.loading = false;
      homeBillboardsMainsContactUs.lastFetch = Date.now();
    },
    homeBillboardsMainsContactUsOnNavbarRequestedFailed: (
      homeBillboardsMainsContactUs,
      action
    ) => {
      homeBillboardsMainsContactUs.loading = false;
      //   window.alert(`No HomeBillboardsMainsContactUs found.`);
    },

    homeBillboardsMainRequested: (homeBillboardsMain, action) => {
      console.log("homeBillboardsMainRequested : ", action.payload);
      homeBillboardsMain.loading = true;
    },
    homeBillboardsMainReceived: (homeBillboardsMain, action) => {
      console.log("homeBillboardsMainReceived : ", action.payload);
      homeBillboardsMain.listSingleRow = action.payload;
      homeBillboardsMain.loading = false;
      homeBillboardsMain.lastFetch = Date.now();
    },
    homeBillboardsMainRequestedFailed: (homeBillboardsMain, action) => {
      homeBillboardsMain.loading = false;
      //   window.alert(`No HomeBillboardsMain found.`);
    },
    homeBillboardsMainChangeRequested: (homeBillboardsMain, action) => {
      console.log("homeBillboardsMainChangeRequested : ", action.payload);
      homeBillboardsMain.loading = true;
    },
    homeBillboardsMainChangeFailed: (homeBillboardsMain, action) => {
      homeBillboardsMain.loading = false;

      // //
      // // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete homeBillboardsMain api at the back end.
      // const { ID: homeBillboardsMainId, A_Name: homeBillboardsMainName } = action.payload;

      // window.alert(`HomeBillboardsMain '${homeBillboardsMainName}' no longer exists.`);

      // const index = homeBillboardsMain.list.findIndex(homeBillboardsMain => homeBillboardsMain.ID === homeBillboardsMainId);
      // //console.log("homeBillboardsMainDeleted ha ", index);
      // homeBillboardsMain.list.splice(index, 1);
    },
    homeBillboardsMainChanged: (homeBillboardsMain, action) => {
      homeBillboardsMain.loading = false;
    },
    homeBillboardsMainDeleteRequested: (homeBillboardsMain, action) => {
      console.log("homeBillboardsMainDeleteRequested : ", action.payload);
      homeBillboardsMain.loading = true;
    },
    homeBillboardsMainDeleteFailed: (homeBillboardsMainsContactUs, action) => {
      homeBillboardsMainsContactUs.loading = false;

      //
      // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete homeBillboardsMain api at the back end.
      const {
        ID: homeBillboardsMainId,
        A_Name: homeBillboardsMainName
      } = action.payload;

      window.alert(
        `An issue has occured with homeBillboardsMain '${homeBillboardsMainName}'. ${action.payload}`
      );

      const index = homeBillboardsMainsContactUs.list.findIndex(
        homeBillboardsMain => homeBillboardsMain.ID === homeBillboardsMainId
      );
      //console.log("homeBillboardsMainDeleted ha ", index);
      homeBillboardsMainsContactUs.list.splice(index, 1);
    },
    homeBillboardsMainDeleted: (homeBillboardsMainsContactUs, action) => {
      //
      // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete homeBillboardsMain api at the back end.
      const { ID: homeBillboardsMainID } = action.payload;
      // console.log("homeBillboardsMainDeleted ha ", action.payload, homeBillboardsMainID, homeBillboardsMainsContactUs);
      const index = homeBillboardsMainsContactUs.list.findIndex(
        homeBillboardsMain => homeBillboardsMain.ID === homeBillboardsMainID
      );
      //console.log("homeBillboardsMainDeleted, ha! ha! ", index);
      homeBillboardsMainsContactUs.list.splice(index, 1);
    },
    homeBillboardsMainAddRequested: (homeBillboardsMain, action) => {
      console.log("homeBillboardsMainAddRequested : ", action.payload);
      homeBillboardsMain.loading = true;
    },
    homeBillboardsMainAddFailed: (homeBillboardsMain, action) => {
      homeBillboardsMain.loading = false;
    },
    //
    // Babak's Note: The 'homeBillboardsMainsContactUs' below represents the 'state':-
    homeBillboardsMainAdded: (homeBillboardsMainsContactUs, action) => {
      console.log("homeBillboardsMainAdded : ", action.payload);
      homeBillboardsMainsContactUs.list.push(action.payload);
    }
    // homeBillboardsMainAdded: (homeBillboardsMainsContactUs, action) => {
    //   homeBillboardsMainsContactUs.list.push({
    //     id: ++lastId,
    //     description: action.payload.description,
    //     resolved: false
    //   });
    // },
    // homeBillboardsMainDeleted: (homeBillboardsMainsContactUs, action) => {
    //   console.log("homeBillboardsMainDeleted ha ", action.payload.ID);
    //   homeBillboardsMainsContactUs.list.filter(homeBillboardsMain => homeBillboardsMain.ID !== action.payload.ID);
    // },
  }
});
const {
  homeBillboardsMainAddRequested,
  homeBillboardsMainAdded,
  homeBillboardsMainAddFailed,
  homeBillboardsMainChangeRequested,
  homeBillboardsMainChanged,
  homeBillboardsMainChangeFailed,
  homeBillboardsMainDeleteRequested,
  homeBillboardsMainDeleted,
  homeBillboardsMainDeleteFailed,
  homeBillboardsMainsContactUsReceived,
  homeBillboardsMainsContactUsRequested,
  homeBillboardsMainsContactUsRequestedFailed,
  homeBillboardsMainsContactUsOnNavbarReceived,
  homeBillboardsMainsContactUsOnNavbarRequested,
  homeBillboardsMainsContactUsOnNavbarRequestedFailed,
  homeBillboardsMainReceived,
  homeBillboardsMainRequested,
  homeBillboardsMainRequestedFailed
} = slice.actions;
export default slice.reducer;

//
// Action Creators
const url = "/homeBillboardsMainsContactUs";

export const loadHomeBillboardsMainsContactUs = orderNo => (
  dispatch,
  getState
) => {
  // const { lastFetch } = getState().entities.homeBillboardsMainsContactUs;

  // console.log("loadHomeBillboardsMainsContactUs lastFetch : ", lastFetch);

  // //
  // // Babak's Note: Below we check with 'moment' that catch that if this request was already fetched less than 10 minutes ago then do not fetch again:-
  // const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  // if (diffInMinutes < 10) return;

  dispatch(
    apiCallBegan({
      url: url + "/contactUs/" + orderNo,
      onStart: homeBillboardsMainsContactUsRequested.type,
      method: "get",
      onSuccess: homeBillboardsMainsContactUsReceived.type,
      onError: homeBillboardsMainsContactUsRequestedFailed.type
    })
  );
};

export const loadHomeBillboardsMain = homeBillboardsMainID => (
  dispatch,
  getState
) => {
  // const { lastFetch } = getState().entities.homeBillboardsMainsContactUs;

  //console.log("loadHomeBillboardsMain : ", homeBillboardsMainID);

  // //
  // // Babak's Note: Below we check with 'moment' that catch that if this request was already fetched less than 10 minutes ago then do not fetch again:-
  // const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  // if (diffInMinutes < 10) return;

  dispatch(
    apiCallBegan({
      url: url + "/" + homeBillboardsMainID,
      onStart: homeBillboardsMainRequested.type,
      method: "get",
      onSuccess: homeBillboardsMainReceived.type,
      onError: homeBillboardsMainRequestedFailed.type
    })
  );
};

//
// Babak's Note: Below function 'apiCallBegan' is returned back by which function (in this case from within 'index.js') calls 'addHomeBillboardsMain':-
export const addHomeBillboardsMain = (homeBillboardsMain, user, currentDate) =>
  apiCallBegan({
    url,
    method: "post",
    onStart: homeBillboardsMainAddRequested.type,
    //data: homeBillboardsMain,
    data: {
      N_OrderNo: homeBillboardsMain.N_OrderNo,
      N_RadioGrpDirections_ID: homeBillboardsMain.N_RadioGrpDirections_ID,
      N_HomeBillboardsMains_ID: homeBillboardsMain.N_HomeBillboardsMains_ID,
      // A_BillboardLargeLink: homeBillboardsMain.A_BillboardLargeLink,
      A_Reference: homeBillboardsMain.A_Reference,
      A_Summary: homeBillboardsMain.A_Summary,
      A_Description: homeBillboardsMain.A_Description,
      A_HoverSummary01: homeBillboardsMain.A_HoverSummary01,
      A_HoverSummary01_Link: homeBillboardsMain.A_HoverSummary01_Link,
      A_HoverSummary02: homeBillboardsMain.A_HoverSummary02,
      A_HoverSummary02_Link: homeBillboardsMain.A_HoverSummary02_Link,
      A_HoverSummary03: homeBillboardsMain.A_HoverSummary03,
      A_HoverSummary03_Link: homeBillboardsMain.A_HoverSummary03_Link,
      A_HoverSummary04: homeBillboardsMain.A_HoverSummary04,
      A_HoverSummary04_Link: homeBillboardsMain.A_HoverSummary04_Link,
      A_HoverSummary05: homeBillboardsMain.A_HoverSummary05,
      A_HoverSummary05_Link: homeBillboardsMain.A_HoverSummary05_Link,
      A_HoverSummary06: homeBillboardsMain.A_HoverSummary06,
      A_HoverSummary07: homeBillboardsMain.A_HoverSummary07,
      A_HoverSummary08: homeBillboardsMain.A_HoverSummary08,
      A_HoverSummary08_Link: homeBillboardsMain.A_HoverSummary08_Link,
      I_Image: homeBillboardsMain.I_Image,
      D_Inserted: currentDate,
      A_Inserted_By: user
    },
    onSuccess: homeBillboardsMainAdded.type,
    onError: homeBillboardsMainAddFailed.type
  });

export const getHomeBillboardsMainsContactUs = createSelector(
  state => state.entities.homeBillboardsMainsContactUs,
  //
  // Babak's Note: In the below line, by installing above 'reselect' (at the top of this file) if the list of (homeBillboardsMainsContactUs, projects) has not changed then do not requery again:-
  // (homeBillboardsMainsContactUs) => homeBillboardsMainsContactUs.list.filter(homeBillboardsMain => !homeBillboardsMain.B_Active)
  homeBillboardsMainsContactUs => homeBillboardsMainsContactUs.list
);

export const changeHomeBillboardsMain = (
  homeBillboardsMain,
  user,
  currentDate
) =>
  apiCallBegan({
    url: url + "/" + homeBillboardsMain.ID,
    method: "put",
    onStart: homeBillboardsMainChangeRequested.type,
    data: {
      N_OrderNo: homeBillboardsMain.N_OrderNo,
      N_RadioGrpDirections_ID: homeBillboardsMain.N_RadioGrpDirections_ID,
      N_HomeBillboardsMains_ID: homeBillboardsMain.N_HomeBillboardsMains_ID,
      // A_BillboardLargeLink: homeBillboardsMain.A_BillboardLargeLink,
      A_Reference: homeBillboardsMain.A_Reference,
      A_Summary: homeBillboardsMain.A_Summary,
      A_Description: homeBillboardsMain.A_Description,
      A_HoverSummary01: homeBillboardsMain.A_HoverSummary01,
      A_HoverSummary01_Link: homeBillboardsMain.A_HoverSummary01_Link,
      A_HoverSummary02: homeBillboardsMain.A_HoverSummary02,
      A_HoverSummary02_Link: homeBillboardsMain.A_HoverSummary02_Link,
      A_HoverSummary03: homeBillboardsMain.A_HoverSummary03,
      A_HoverSummary03_Link: homeBillboardsMain.A_HoverSummary03_Link,
      A_HoverSummary04: homeBillboardsMain.A_HoverSummary04,
      A_HoverSummary04_Link: homeBillboardsMain.A_HoverSummary04_Link,
      A_HoverSummary05: homeBillboardsMain.A_HoverSummary05,
      A_HoverSummary05_Link: homeBillboardsMain.A_HoverSummary05_Link,
      A_HoverSummary06: homeBillboardsMain.A_HoverSummary06,
      A_HoverSummary07: homeBillboardsMain.A_HoverSummary07,
      A_HoverSummary08: homeBillboardsMain.A_HoverSummary08,
      A_HoverSummary08_Link: homeBillboardsMain.A_HoverSummary08_Link,
      I_Image: homeBillboardsMain.I_Image,
      D_Updated: currentDate,
      A_Updated_By: user
    },
    onSuccess: homeBillboardsMainChanged.type,
    onError: homeBillboardsMainChangeFailed.type
  });

export const deleteHomeBillboardsMain = homeBillboardsMain =>
  apiCallBegan({
    url: url + "/" + homeBillboardsMain.ID,
    method: "delete",
    onStart: homeBillboardsMainDeleteRequested.type,
    data: {
      ID: homeBillboardsMain.ID,
      A_Reference: homeBillboardsMain.A_Reference
    },
    onSuccess: homeBillboardsMainDeleted.type,
    onError: homeBillboardsMainDeleteFailed.type
  });
