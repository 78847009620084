import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
import moment from "moment";

const slice = createSlice({
  name: "sumOfArticles",
  initialState: {
    list: [],
    listSingleRow: [],
    listOnNavvar: [],
    listParentOriginIDMenus: [],
    loading: false,
    lastFetch: null
  },
  reducers: {
    //
    // Babak's Note: By adding 'sumOfArticlesRequested' or 'sumOfArticlesReceived' below, a action is created for us:-
    // actions => action handlers. The 'sumOfArticles' below represents the 'state':-
    sumOfArticlesRequested: (sumOfArticles, action) => {
      console.log("sumOfArticlesRequested : ", action.payload);
      sumOfArticles.loading = true;
    },
    sumOfArticlesReceived: (sumOfArticles, action) => {
      console.log("sumOfArticlesReceived : ", action.payload);
      sumOfArticles.list = action.payload;
      sumOfArticles.loading = false;
      sumOfArticles.lastFetch = Date.now();
    },
    sumOfArticlesRequestedFailed: (sumOfArticles, action) => {
      sumOfArticles.loading = false;
      //   window.alert(`No SumOfArticles found.`);
    },

    sumOfArticlesOnNavbarRequested: (sumOfArticles, action) => {
      console.log("sumOfArticlesOnNavbarRequested : ", action.payload);
      sumOfArticles.loading = true;
    },
    sumOfArticlesOnNavbarReceived: (sumOfArticles, action) => {
      console.log("sumOfArticlesOnNavbarReceived : ", action.payload);
      sumOfArticles.listOnNavvar = action.payload;
      sumOfArticles.loading = false;
      sumOfArticles.lastFetch = Date.now();
    },
    sumOfArticlesOnNavbarRequestedFailed: (sumOfArticles, action) => {
      sumOfArticles.loading = false;
      //   window.alert(`No SumOfArticles found.`);
    },
    sumOfArticleRequested: (sumOfArticle, action) => {
      console.log("sumOfArticleRequested : ", action.payload);
      sumOfArticle.loading = true;
    },
    sumOfArticleReceived: (sumOfArticle, action) => {
      console.log("sumOfArticleReceived : ", action.payload);
      sumOfArticle.listSingleRow = action.payload;
      sumOfArticle.loading = false;
      sumOfArticle.lastFetch = Date.now();
    },
    sumOfArticleRequestedFailed: (sumOfArticle, action) => {
      sumOfArticle.loading = false;
      //   window.alert(`No SumOfArticle found.`);
    },

    parentOriginIDMenusRequested: (parentOriginIDMenus, action) => {
      console.log("parentOriginIDMenusRequested : ", action.payload);
      parentOriginIDMenus.loading = true;
    },
    parentOriginIDMenusReceived: (parentOriginIDMenus, action) => {
      console.log("parentOriginIDMenusReceived : ", action.payload);
      parentOriginIDMenus.listParentOriginIDMenus = action.payload;
      parentOriginIDMenus.loading = false;
      parentOriginIDMenus.lastFetch = Date.now();
    },
    parentOriginIDMenusRequestedFailed: (parentOriginIDMenus, action) => {
      parentOriginIDMenus.loading = false;
      //   window.alert(`No parentOriginIDMenus found.`);
    },

    sumOfArticleChangeRequested: (sumOfArticle, action) => {
      console.log("sumOfArticleChangeRequested : ", action.payload);
      sumOfArticle.loading = true;
    },
    sumOfArticleChangeFailed: (sumOfArticle, action) => {
      sumOfArticle.loading = false;

      // //
      // // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete sumOfArticle api at the back end.
      // const { ID: sumOfArticleId, A_Name: sumOfArticleName } = action.payload;

      // window.alert(`SumOfArticle '${sumOfArticleName}' no longer exists.`);

      // const index = sumOfArticle.list.findIndex(sumOfArticle => sumOfArticle.ID === sumOfArticleId);
      // //console.log("sumOfArticleDeleted ha ", index);
      // sumOfArticle.list.splice(index, 1);
    },
    sumOfArticleChanged: (sumOfArticle, action) => {
      sumOfArticle.loading = false;
    },
    sumOfArticleDeleteRequested: (sumOfArticle, action) => {
      console.log("sumOfArticleDeleteRequested : ", action.payload);
      sumOfArticle.loading = true;
    },
    sumOfArticleDeleteFailed: (sumOfArticles, action) => {
      sumOfArticles.loading = false;

      //
      // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete sumOfArticle api at the back end.
      const { ID: sumOfArticleId, A_Name: sumOfArticleName } = action.payload;

      window.alert(
        `An issue has occured with sumOfArticle '${sumOfArticleName}'. ${action.payload}`
      );

      const index = sumOfArticles.list.findIndex(
        sumOfArticle => sumOfArticle.ID === sumOfArticleId
      );
      //console.log("sumOfArticleDeleted ha ", index);
      sumOfArticles.list.splice(index, 1);
    },
    sumOfArticleDeleted: (sumOfArticles, action) => {
      //
      // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete sumOfArticle api at the back end.
      const { ID: sumOfArticleID } = action.payload;
      // console.log("sumOfArticleDeleted ha ", action.payload, sumOfArticleID, sumOfArticles);
      const index = sumOfArticles.list.findIndex(
        sumOfArticle => sumOfArticle.ID === sumOfArticleID
      );
      //console.log("sumOfArticleDeleted, ha! ha! ", index);
      sumOfArticles.list.splice(index, 1);
    },
    sumOfArticleAddRequested: (sumOfArticle, action) => {
      console.log("sumOfArticleAddRequested : ", sumOfArticle, action.payload);
      sumOfArticle.loading = true;
    },
    sumOfArticleAddFailed: (sumOfArticle, action) => {
      sumOfArticle.loading = false;
    },
    //
    // Babak's Note: The 'sumOfArticles' below represents the 'state':-
    sumOfArticleAdded: (sumOfArticles, action) => {
      console.log("sumOfArticleAdded : ", action.payload);
      sumOfArticles.list.push(action.payload);
    }
    // sumOfArticleAdded: (sumOfArticles, action) => {
    //   sumOfArticles.list.push({
    //     id: ++lastId,
    //     description: action.payload.description,
    //     resolved: false
    //   });
    // },
    // sumOfArticleDeleted: (sumOfArticles, action) => {
    //   console.log("sumOfArticleDeleted ha ", action.payload.ID);
    //   sumOfArticles.list.filter(sumOfArticle => sumOfArticle.ID !== action.payload.ID);
    // },
  }
});
const {
  sumOfArticleAddRequested,
  sumOfArticleAdded,
  sumOfArticleAddFailed,
  sumOfArticleChangeRequested,
  sumOfArticleChanged,
  sumOfArticleChangeFailed,
  sumOfArticleDeleteRequested,
  sumOfArticleDeleted,
  sumOfArticleDeleteFailed,
  sumOfArticlesReceived,
  sumOfArticlesRequested,
  sumOfArticlesRequestedFailed,
  sumOfArticlesOnNavbarReceived,
  sumOfArticlesOnNavbarRequested,
  sumOfArticlesOnNavbarRequestedFailed,
  sumOfArticleReceived,
  sumOfArticleRequested,
  sumOfArticleRequestedFailed,
  parentOriginIDMenusRequested,
  parentOriginIDMenusReceived,
  parentOriginIDMenusRequestedFailed
} = slice.actions;
export default slice.reducer;

//
// Action Creators
const url = "/sumOfArticles";

export const loadSumOfArticles = billboardLargeID => (dispatch, getState) => {
  // const { lastFetch } = getState().entities.sumOfArticles;

  // console.log("loadSumOfArticles lastFetch : ", lastFetch);

  // //
  // // Babak's Note: Below we check with 'moment' that catch that if this request was already fetched less than 10 minutes ago then do not fetch again:-
  // const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  // if (diffInMinutes < 10) return;

  dispatch(
    apiCallBegan({
      url: url + "/sumOfArticles" + "/" + billboardLargeID,
      onStart: sumOfArticlesRequested.type,
      method: "get",
      onSuccess: sumOfArticlesReceived.type,
      onError: sumOfArticlesRequestedFailed.type
    })
  );
};

export const loadSumOfArticle = sumOfArticleID => (dispatch, getState) => {
  // const { lastFetch } = getState().entities.sumOfArticles;

  //console.log("loadSumOfArticle : ", sumOfArticleID);

  // //
  // // Babak's Note: Below we check with 'moment' that catch that if this request was already fetched less than 10 minutes ago then do not fetch again:-
  // const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  // if (diffInMinutes < 10) return;

  dispatch(
    apiCallBegan({
      url: url + "/" + sumOfArticleID,
      onStart: sumOfArticleRequested.type,
      method: "get",
      onSuccess: sumOfArticleReceived.type,
      onError: sumOfArticleRequestedFailed.type
    })
  );
};

export const loadParentOriginIDMenus = billboardLargeID => (
  dispatch,
  getState
) => {
  // const { lastFetch } = getState().entities.sumOfArticles;

  //console.log("loadParentOriginIDMenus : ", sumOfArticleID);

  // //
  // // Babak's Note: Below we check with 'moment' that catch that if this request was already fetched less than 10 minutes ago then do not fetch again:-
  // const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  // if (diffInMinutes < 10) return;

  dispatch(
    apiCallBegan({
      url: url + "/parentOrigin" + "/" + billboardLargeID,
      onStart: parentOriginIDMenusRequested.type,
      method: "get",
      onSuccess: parentOriginIDMenusReceived.type,
      onError: parentOriginIDMenusRequestedFailed.type
    })
  );
};

//
// Babak's Note: Below function 'apiCallBegan' is returned back by which function (in this case from within 'index.js') calls 'addSumOfArticle':-
export const addSumOfArticle = (sumOfArticle, user, currentDate) =>
  apiCallBegan({
    url,
    method: "post",
    onStart: sumOfArticleAddRequested.type,
    //data: sumOfArticle,
    data: {
      N_BillboardOrigin_ID: sumOfArticle.N_BillboardOrigin_ID,
      N_HomeLocationGroup_ID: sumOfArticle.N_HomeLocationGroup_ID,
      N_HomeLocationSumOfArticle_ID: sumOfArticle.N_HomeLocationSumOfArticle_ID,
      N_Order_No: sumOfArticle.N_Order_No,
      A_Reference: sumOfArticle.A_Reference,
      A_Summary: sumOfArticle.A_Summary,
      N_BillboardLarge_ID: sumOfArticle.N_BillboardLarge_ID,
      N_ParentOrigin_ID: sumOfArticle.N_ParentOrigin_ID,
      I_Image: sumOfArticle.I_Image,
      D_Inserted: currentDate,
      A_Inserted_By: user
    },
    onSuccess: sumOfArticleAdded.type,
    onError: sumOfArticleAddFailed.type
  });

export const getSumOfArticles = createSelector(
  state => state.entities.sumOfArticles,
  //
  // Babak's Note: In the below line, by installing above 'reselect' (at the top of this file) if the list of (sumOfArticles, projects) has not changed then do not requery again:-
  // (sumOfArticles) => sumOfArticles.list.filter(sumOfArticle => !sumOfArticle.B_Active)
  sumOfArticles => sumOfArticles.list
);

export const changeSumOfArticle = (sumOfArticle, user, currentDate) =>
  apiCallBegan({
    url: url + "/" + sumOfArticle.ID,
    method: "put",
    onStart: sumOfArticleChangeRequested.type,
    data: {
      N_BillboardOrigin_ID: sumOfArticle.N_BillboardOrigin_ID,
      N_HomeLocationGroup_ID: sumOfArticle.N_HomeLocationGroup_ID,
      N_HomeLocationSumOfArticle_ID: sumOfArticle.N_HomeLocationSumOfArticle_ID,
      N_Order_No: sumOfArticle.N_Order_No,
      A_Reference: sumOfArticle.A_Reference,
      A_Summary: sumOfArticle.A_Summary,
      N_ParentOrigin_ID: sumOfArticle.N_ParentOrigin_ID,
      I_Image: sumOfArticle.I_Image,
      D_Updated: currentDate,
      A_Updated_By: user
    },
    onSuccess: sumOfArticleChanged.type,
    onError: sumOfArticleChangeFailed.type
  });

export const deleteSumOfArticle = sumOfArticle =>
  apiCallBegan({
    url: url + "/" + sumOfArticle.ID,
    method: "delete",
    onStart: sumOfArticleDeleteRequested.type,
    data: { ID: sumOfArticle.ID, A_Reference: sumOfArticle.A_Reference },
    onSuccess: sumOfArticleDeleted.type,
    onError: sumOfArticleDeleteFailed.type
  });
