import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.css";
import configureStore from "./store/configureStore";
import { Provider } from "react-redux";

// console.log("index 01 : ", process.env);

//
// Babak's Note: Below is our single source of truth, meaning we have one store and it's at this level. All our 'reducers' are set and initialized here:-
const store = configureStore();

ReactDOM.render(
  //
  // Babak's Note: 'store' is sent to all components that are wrapped in '<Provider> eg '<Bugs />':-
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
