import React, { Component } from "react";
// import StoreContext from "../contexts/storeContext";
import { Link } from "react-router-dom";
// import { Redirect } from "react-router";
import Pagination from "../common/pagination";
import { loadRoles, getRoles, deleteRole } from "../../store/roles";
import RolesTable from "./rolesTable";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import SearchBox from "../searchBox";
import { connect } from "react-redux";

// **********
// **********  I USE THIS FORM, I DONT NOT USE 'Roles'  **********
// **********

class RolesList extends Component {
  // static contextType = StoreContext;

  state = {
    // RoleForm: false,
    roleID: 0,
    rolesList: [],
    currentPage: 1,
    pageSize: 2,
    searchQuery: "",
    sortColumn: { path: "A_Name", order: "asc" }
  };

  componentDidMount() {
    document.body.style.backgroundImage = `url()`;
    document.body.style.backgroundColor = "white";
    document.body.style.backgroundPosition = "center center";
    document.body.style.backgroundAttachment = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundSize = "cover";

    this.props.loadRoles();

    console.log("RolesList, componentDidMount : ");
  }

  // componentWillUnmount() {
  //   this.unsubscribe();
  // }

  handleUpdate = async role => {
    //console.log("RolesList, handleUpdate : ", role.ID);

    //
    // Babak's Notes: 'Redirect' does not work for some reason (back to prev screen browser arrow key), no time to investifate for now:-
    // this.setState({ RoleForm: true, roleID: role.ID });

    try {
      window.location = `/rolesList/${role.ID}`;
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...ex.errors };
        this.setState({ errors });
      }
    }
  };

  handlePageChange = page => {
    //console.log(page);
    this.setState({ currentPage: page });
  };

  //
  // Babak's Note: We have put this 'input' field inside of the 'SearchBox' component, this component raises
  //               an event and in the we get an string. So below 'query' will contain the string the user
  //               types in the search field.
  handleSearch = query => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  handleSort = sortColumn => {
    //console.log("handleSort : ", sortColumn);
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;

    console.log("RolesList, getPageData : ", this.props.roles);

    const allRoles = this.props.roles;

    let filtered = allRoles;
    if (searchQuery)
      filtered = allRoles.filter(m =>
        m.A_Name.toLowerCase().includes(searchQuery.toLowerCase())
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const roles = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: roles };
  };

  render() {
    console.log("RolesList, render() : ", this.props.roles);

    // if (this.state.RoleForm === true) {
    //   //console.log("RolesList, render(), Redirect : ", this.state.roleID);
    //   return <Redirect to={`/rolesList/${this.state.roleID}`} />;
    // }

    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;

    //
    //Babak's Note: Below I renamed 'data' to 'roles'
    const { totalCount, data: rolesList } = this.getPagedData();

    return (
      <div className="container">
        <div className="row">
          <div
            className="col"
            style={{ marginTop: "160px", marginBottom: "100px" }}
          >
            <h1>Roles</h1>
            <br />
            <Link
              to="/rolesList/new"
              className="btn btn-primary"
              style={{ marginBottom: 20 }}
            >
              New Role
            </Link>
            <p>Showing {totalCount} roles in the database.</p>
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
            <RolesTable
              rolesList={rolesList}
              sortColumn={sortColumn}
              onDelete={role => this.props.deleteRole(role)}
              onUpdate={this.handleUpdate}
              onSort={this.handleSort}
            />
            <div className="paginationBar">
              <Pagination
                itemsCount={totalCount}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={this.handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

//
// Babak's Notes: goto tutorial directory '11-Integration with React' file '5- Connecting Components Using react-redux'
const mapStateToProps = state => ({
  //   bugs: state.entities.roles.list.filter(bug => !bug.resolved)
  roles: state.entities.roles.list
});

const mapDispatchToProps = dispatch => ({
  loadRoles: () => dispatch(loadRoles()),
  deleteRole: id => dispatch(deleteRole(id))
});

// Container Component
//   Presentation (Bugs)
//
// Babak's Notes: Below I populate 'this.props.bugs' and 'this.props.loadBugs()':-
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RolesList);
