import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
import moment from "moment";

// **********
// **********  I DO NOT USE THIS FORM, INSTEAD I USE 'UsersList'  **********
// **********

const slice = createSlice({
  name: "users",
  initialState: {
    list: [],
    listSingleRow: [],
    loading: false,
    lastFetch: null,
    userSuccess: false,
    userError: ""
  },
  reducers: {
    //
    // Babak's Note: By adding 'usersRequested' or 'usersReceived' below, a action is created for us:-
    // actions => action handlers. The 'users' below represents the 'state':-
    usersRequested: (users, action) => {
      console.log("usersRequested : ", action.payload);
      users.userError = "";
      // users.userSuccess = false;
      users.loading = true;
    },
    usersReceived: (users, action) => {
      console.log("usersReceived : ", action.payload);
      users.list = action.payload;
      users.loading = false;
      users.lastFetch = Date.now();
    },
    usersRequestedFailed: (users, action) => {
      users.loading = false;
      users.userError = action.payload;
      // window.alert(`No users found.`);
    },
    userRequested: (users, action) => {
      console.log("userRequested : ", action.payload);
      // users.userSuccess = false;
      users.loading = true;
    },
    userReceived: (user, action) => {
      console.log("userReceived : ", action.payload);
      user.listSingleRow = action.payload;
      user.loading = false;
      user.lastFetch = Date.now();
    },
    userRequestedFailed: (user, action) => {
      user.loading = false;
      // window.alert(`No user found.`);
    },
    userChangeRequested: (users, action) => {
      console.log("userChangeRequested : ", action.payload);
      // users.userSuccess = false;
      users.loading = true;
    },
    userChangeFailed: (user, action) => {
      user.userError = action.payload;
      user.loading = false;

      // //
      // // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete user api at the back end.
      // const { ID: userId, A_Name: userName } = action.payload;

      // window.alert(`user '${userName}' no longer exists.`);

      // const index = user.list.findIndex(user => user.ID === userId);
      // //console.log("userDeleted ha ", index);
      // user.list.splice(index, 1);
    },
    userChanged: (user, action) => {
      console.log("userChanged : ", action.payload);
      //
      // Babak's Note: Below line must be added here, look at 'componentWillReceiveProps' in 'userForm'
      //               "} else if (nextProps.userSuccess)
      //                { this.props.history.goBack();":-
      user.userSuccess = true;
      user.loading = false;
    },
    userDeleteRequested: (users, action) => {
      console.log("userDeleteRequested : ", action.payload);
      // users.userSuccess = false;
      users.loading = true;
    },
    userDeleteFailed: (users, action) => {
      users.loading = false;

      //
      // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete user api at the back end.
      const { ID: userId, A_Name: userName } = action.payload;

      // window.alert(`user '${userName}' no longer exists.`);

      const index = users.list.findIndex(user => user.ID === userId);
      //console.log("userDeleted ha ", index);
      users.list.splice(index, 1);
    },
    userDeleted: (users, action) => {
      //
      // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete user api at the back end.
      const { ID: userID } = action.payload;
      // console.log("userDeleted ha ", action.payload, userID, users);
      const index = users.list.findIndex(user => user.ID === userID);
      //console.log("userDeleted ha ", index);
      users.list.splice(index, 1);
    },
    userAddRequested: (users, action) => {
      console.log("userAddRequested : ", action.payload);
      users.userError = "";
      users.userSuccess = false;
      users.loading = true;
    },
    userAddFailed: (users, action) => {
      users.userError = action.payload;
      users.loading = false;
      // window.alert(`No Userrrr found : ${action.payload}`);
    },
    //
    // Babak's Note: The 'users' below represents the 'state':-
    userAdded: (users, action) => {
      console.log("userAdded : ", action.payload);
      users.list.push(action.payload);
      users.userSuccess = true;
      users.loading = false;
    },
    // userAdded: (users, action) => {
    //   users.list.push({
    //     id: ++lastId,
    //     description: action.payload.description,
    //     resolved: false
    //   });
    // },
    // userDeleted: (users, action) => {
    //   console.log("userDeleted ha ", action.payload.ID);
    //   users.list.filter(user => user.ID !== action.payload.ID);
    // },
    userActivated: (users, action) => {
      console.log("userActivated : ", action.payload);
      const index = users.list.findIndex(user => user.ID === action.payload.ID);
      users.list[index].B_Active = true;
    }
  }
});
const {
  userAddRequested,
  userAdded,
  userAddFailed,
  userChangeRequested,
  userChanged,
  userChangeFailed,
  userDeleteRequested,
  userDeleted,
  userDeleteFailed,
  userActivated,
  usersReceived,
  usersRequested,
  usersRequestedFailed,
  userReceived,
  userRequested,
  userRequestedFailed
} = slice.actions;
export default slice.reducer;

//
// Action Creators
const url = "/users";

export const loadUsers = () => (dispatch, getState) => {
  // const { lastFetch } = getState().entities.users;

  // console.log("loadUsers lastFetch : ", lastFetch);

  // //
  // // Babak's Note: Below we check with 'moment' that catch that if this request was already fetched less than 10 minutes ago then do not fetch again:-
  // const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  // if (diffInMinutes < 10) return;

  dispatch(
    apiCallBegan({
      url: url,
      onStart: usersRequested.type,
      method: "get",
      onSuccess: usersReceived.type,
      onError: usersRequestedFailed.type
    })
  );
};

export const loadUser = userID => (dispatch, getState) => {
  // const { lastFetch } = getState().entities.users;

  //console.log("loadUser : ", userID);

  // //
  // // Babak's Note: Below we check with 'moment' that catch that if this request was already fetched less than 10 minutes ago then do not fetch again:-
  // const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  // if (diffInMinutes < 10) return;

  dispatch(
    apiCallBegan({
      url: url + "/" + userID,
      onStart: userRequested.type,
      method: "get",
      onSuccess: userReceived.type,
      onError: userRequestedFailed.type
    })
  );
};

//
// Babak's Note: Below function 'apiCallBegan' is returned back by which function (in this case from within 'index.js') calls 'adduser':-
export const addUser = user =>
  apiCallBegan({
    url,
    method: "post",
    onStart: userAddRequested.type,
    data: user,
    // data: {
    //   ID: user.ID,
    //   A_RolesID: user.A_RolesID,
    //   A_Email: user.A_Email,
    //   A_Password: user.A_Password,
    //   A_Name: user.A_Name,
    //   N_Mobile: user.N_Mobile,
    //   B_Active: user.B_Active,
    //   I_Photo01: user.I_Photo01
    // },
    onSuccess: userAdded.type,
    onError: userAddFailed.type
  });

export const getUsers = createSelector(
  state => state.entities.users,
  //
  // Babak's Note: In the below line, by installing above 'reselect' (at the top of this file) if the list of (users, projects) has not changed then do not requery again:-
  // (users) => users.list.filter(user => !user.B_Active)
  users => users.list
);

export const changeUser = user =>
  apiCallBegan({
    url: url + "/" + user.ID,
    method: "put",
    onStart: userChangeRequested.type,
    data: {
      // ID: user.ID,
      A_RolesID: user.A_RolesID,
      A_Email: user.A_Email,
      A_Password: user.A_Password,
      A_Name: user.A_Name,
      N_Mobile: user.N_Mobile,
      B_Active: user.B_Active,
      I_Photo01: user.I_Photo01
    },
    onSuccess: userChanged.type,
    onError: userChangeFailed.type
  });

export const deleteUser = user =>
  apiCallBegan({
    url: url + "/" + user.ID,
    method: "delete",
    onStart: userDeleteRequested.type,
    data: { ID: user.ID, A_Name: user.A_Name },
    onSuccess: userDeleted.type,
    onError: userDeleteFailed.type
  });
