import http from "./httpService";

// import { apiUrl } from "../config.json";
// const apiEndpoint = apiUrl + "/menus";
const apiEndpoint = "/customizations";

function CustomizationUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getCustomizations() {
  //console.log("getMenus : ", apiEndpoint);
  return http.get(apiEndpoint);
}

export function getCustomization(customizationID) {
  // console.log(
  //   "getMenu : ",
  //   CustomizationUrl(menuID)
  // );
  return http.get(CustomizationUrl(customizationID));
}

export function saveCustomization(customization, userID, currentDate) {
  //   let D_Inserted = new Date();
  //   // let D_Inserted =
  //   //   new Date().getMonth() +
  //   //   "/" +
  //   //   new Date().getDate() +
  //   //   "/" +
  //   //   new Date().getFullYear();

  //console.log("saveMenu PUT 1 : ", menu, userID, D_Inserted);

  //   let D_Updated = new Date();
  //   // let D_Updated =
  //   //   new Date().getMonth() +
  //   //   "/" +
  //   //   new Date().getDate() +
  //   //   "/" +
  //   //   new Date().getFullYear();

  //   const A_Inserted_By = userID;
  //   const A_Updated_By = userID;

  if (customization.ID) {
    // console.log(
    //   "saveMenu PUT 2 : ",
    //   // CustomizationUrl(menu.ID),
    //   // body,
    //   menu.ID,
    //   menu,
    //   D_Inserted,
    //   A_Inserted_By,
    //   D_Updated,
    //   A_Updated_By
    // );

    const body = {
      ...customization,
      D_Updated: currentDate,
      A_Updated_By: userID
    };
    delete body.ID;

    return http.put(CustomizationUrl(customization.ID), body);
  }

  //console.log("saveMenu POST : ", apiEndpoint, menu);
  return http.post(apiEndpoint, {
    A_WebAppTitle: customization.A_WebAppTitle,
    A_BaseNavbarBGC: customization.A_BaseNavbarBGC,
    A_BaseNavbarFGC: customization.A_BaseNavbarFGC,
    A_BaseNavbarHover: customization.A_BaseNavbarHover,
    A_BaseSearchBtnBGC: customization.A_BaseSearchBtnBGC,
    A_BaseSearchBtnFGC: customization.A_BaseSearchBtnFGC,
    A_BaseSearchBtnHover: customization.A_BaseSearchBtnHover,
    B_ClientNavbarActive: customization.B_ClientNavbarActive,
    B_TopNavbarActive: customization.B_TopNavbarActive,
    A_TopNavbarBGC: customization.A_TopNavbarBGC,
    A_TopNavbarFGC: customization.A_TopNavbarFGC,
    A_TopNavbarHover: customization.A_TopNavbarHover,
    A_TopSearchBtnBGC: customization.A_TopSearchBtnBGC,
    A_TopSearchBtnFGC: customization.A_TopSearchBtnFGC,
    A_TopSearchBtnHover: customization.A_TopSearchBtnHover,
    I_LoginBGImage: customization.I_LoginBGImage,
    A_LoginTitle: customization.A_LoginTitle,
    N_AboutUsNo: customization.N_AboutUsNo,
    N_ContactUsNo: customization.N_ContactUsNo,
    B_GoogleMap: customization.B_GoogleMap,
    A_GoogleKey: customization.A_GoogleKey,
    F_Lat: customization.F_Lat,
    F_Lng: customization.F_Lng,
    D_Inserted: currentDate,
    A_Inserted_By: userID
  });
}

export function deleteCustomization(customizationId) {
  return http.delete(CustomizationUrl(customizationId));
}
