import React, { Component } from "react";
import Radium, { StyleRoot } from "radium";
import RenderHomeBillboardChildsMainKeypad from "./renderHomeBillboardChildsMainKeypad";
//import slideInLeft from "react-animations/lib/slide-in-left";
import { getHomeBillboardChildsMains } from "../../services/homeBillboardChildsMainKeypad";

class HomeBillboardChildsMainKeypad extends Component {
  state = {
    homeBillboardChildsMainKeypad: []
  };

  async componentDidMount() {
    const homeBillboardsID = this.props.homeBillboardsID;

    // window.alert(`renderBillboardBulletPinterChilds 01 : ${homeBillboardsID}`);

    // console.log(
    //   `HomeBillboardBulletPointChilds, componentDidMount 01 : ${homeBillboardID}`
    // );

    const {
      data: homeBillboardChildsMainKeypad
    } = await getHomeBillboardChildsMains(homeBillboardsID);

    // console.log(
    //   "componentDidMount BulletPointChild 02 : ",
    //   homeBillboardChilds
    // );

    this.setState({ homeBillboardChildsMainKeypad });
  }

  render() {
    const mainView = this.props.mainView;
    const identifier = this.props.identifier;
    const sectionStyle = this.props.sectionStyle;
    const sectionStyleMD = this.props.sectionStyleMD;
    const sectionStyleSM = this.props.sectionStyleSM;
    const sectionStyleXS = this.props.sectionStyleXS;
    const homeBillboardsID = this.props.homeBillboardsID;
    const textFC = this.props.textFC;
    const reference = this.props.reference;
    const summary = this.props.summary;
    const imageParent = this.props.imageParent;
    const urlLinkParent = this.props.urlLinkParent;

    const { homeBillboardChildsMainKeypad } = this.state;
    // const { length: count } = this.state.homeBillboardChildsMainKeypad;
    // window.alert(`Number of rows : ${count}`);
    // if (count === 0) return <div className="hourGlass"></div>;

    // window.alert(`Number of rows : ${reference}`);

    return (
      <div>
        <StyleRoot
          style={sectionStyle}
          className="d-none d-lg-block HeroMain homeOverflowHidden"
        >
          <div
            style={{
              marginTop: mainView ? "250px" : "0px",
              marginBottom: mainView ? "100px" : "0px"
            }}
            className="KeypadChildHeroMain-contentTopLG colorlib-about"
          >
            <div className="KeypadChildHeroMain-contentInnerTopLG">
              <div className="KeypadChildHeroMain-subTitleTopLG">
                <div className="row">
                  <div>
                    <div
                      className="heading-meta"
                      style={{ marginBottom: "10px" }}
                    >
                      <span
                        className="borderInner"
                        style={{
                          color: `${textFC}`,
                          border: `1px solid ${textFC}`
                        }}
                      >
                        {reference}
                      </span>
                    </div>

                    <h2
                      style={{
                        color: `${textFC}`,
                        border: `1px solid ${textFC}`
                      }}
                      className="colorlib-heading borderInner"
                    >
                      {summary}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="KeypadChildHeroMain-subTitleMiddleLG">
                <div className="row">
                  <RenderHomeBillboardChildsMainKeypad
                    homeBillboardChildsMainKeypad={
                      homeBillboardChildsMainKeypad
                    }
                    nameOfClassParentColumn={"col-6"}
                    nameOfClassChildColumn={"col-6"}
                    mediaAttribute={"LG"}
                    parentImageLink={"KeypadChildLargeImageLG"}
                    parentReferenceLink={
                      "KeypadChildLargeReferenceLG textTitleNoShadowBlack"
                    }
                    parentSummaryLink={
                      "KeypadChildLargeSummaryLG textTitleNoShadowBlack"
                    }
                    childImageLink={"KeypadChildSmallImageLG"}
                    childReferenceLink={
                      "KeypadChildSmallReferenceLG textTitleNoShadowBlack"
                    }
                    childSummaryLink={
                      "KeypadChildSmallSummaryLG textTitleNoShadowBlack"
                    }
                  ></RenderHomeBillboardChildsMainKeypad>
                </div>
              </div>
            </div>
          </div>
        </StyleRoot>

        <StyleRoot
          style={sectionStyleMD}
          className="d-none d-md-block d-lg-none HeroMain homeOverflowHidden"
        >
          <div
            style={{
              marginTop: mainView ? "100px" : "0px",
              marginBottom: mainView ? "100px" : "0px"
            }}
            className="KeypadChildHeroMain-contentTopMD colorlib-about"
          >
            <div className="KeypadChildHeroMain-contentInnerTopMD">
              <div className="KeypadChildHeroMain-subTitleTopMD">
                <div className="row">
                  <div>
                    <div
                      className="heading-meta"
                      style={{ marginBottom: "10px" }}
                    >
                      <span
                        className="borderInner"
                        style={{
                          color: `${textFC}`,
                          border: `1px solid ${textFC}`
                        }}
                      >
                        {reference}
                      </span>
                    </div>

                    <h2
                      style={{
                        color: `${textFC}`,
                        border: `1px solid ${textFC}`
                      }}
                      className="colorlib-heading borderInner"
                    >
                      {summary}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="KeypadChildHeroMain-subTitleMiddleMD">
                <div className="row">
                  <RenderHomeBillboardChildsMainKeypad
                    homeBillboardChildsMainKeypad={
                      homeBillboardChildsMainKeypad
                    }
                    nameOfClassParentColumn={"col-md-12"}
                    nameOfClassChildColumn={"col-6"}
                    mediaAttribute={"MD"}
                    parentImageLink={"KeypadChildLargeImageMD"}
                    parentReferenceLink={
                      "KeypadChildLargeReferenceMD textTitleNoShadowBlack"
                    }
                    parentSummaryLink={
                      "KeypadChildLargeSummaryMD textTitleNoShadowBlack"
                    }
                    childImageLink={"KeypadChildSmallImageMD"}
                    childReferenceLink={
                      "KeypadChildSmallReferenceMD textTitleNoShadowBlack"
                    }
                    childSummaryLink={
                      "KeypadChildSmallSummaryMD textTitleNoShadowBlack"
                    }
                  ></RenderHomeBillboardChildsMainKeypad>
                  {/* <div className="col-md-9">
                    <div>top row</div>
                  </div>
                  <div className="col-md-9">
                    <div className="col-6" style={{ float: "left" }}>
                      top row2
                    </div>
                    <div className="col-6" style={{ float: "left" }}>
                      bottom row2
                    </div>
                    <div className="col-6" style={{ float: "left" }}>
                      top row3
                    </div>
                    <div className="col-6" style={{ float: "left" }}>
                      bottom row3
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </StyleRoot>

        <StyleRoot
          style={sectionStyleSM}
          className="d-sm-block d-md-none d-none HeroMain homeOverflowHidden"
        >
          <div
            style={{
              marginTop: mainView ? "50px" : "-50px",
              marginBottom: mainView ? "100px" : "0px"
            }}
            className="KeypadChildHeroMain-contentTopSM colorlib-about"
          >
            <div className="KeypadChildHeroMain-contentInnerTopSM">
              <div className="KeypadChildHeroMain-subTitleTopSM">
                <div className="row">
                  <div>
                    <div
                      className="heading-meta"
                      style={{ marginBottom: "10px" }}
                    >
                      <span
                        className="borderInner"
                        style={{
                          color: `${textFC}`,
                          border: `1px solid ${textFC}`
                        }}
                      >
                        {reference}
                      </span>
                    </div>

                    <h2
                      style={{
                        color: `${textFC}`,
                        border: `1px solid ${textFC}`
                      }}
                      className="colorlib-heading borderInner"
                    >
                      {summary}
                    </h2>
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginTop: mainView ? "-30px" : "-30px"
                }}
                className="KeypadChildHeroMain-subTitleMiddleSM"
              >
                <div className="row">
                  <RenderHomeBillboardChildsMainKeypad
                    homeBillboardChildsMainKeypad={
                      homeBillboardChildsMainKeypad
                    }
                    nameOfClassParentColumn={"col-md-12"}
                    nameOfClassChildColumn={"col-6"}
                    mediaAttribute={"SM"}
                    parentImageLink={"KeypadChildLargeImageSM"}
                    parentReferenceLink={
                      "KeypadChildLargeReferenceSM textTitleNoShadowBlack"
                    }
                    parentSummaryLink={
                      "KeypadChildLargeSummarySM textTitleNoShadowBlack"
                    }
                    childImageLink={"KeypadChildSmallImageSM"}
                    childReferenceLink={
                      "KeypadChildSmallReferenceSM textTitleNoShadowBlack"
                    }
                    childSummaryLink={
                      "KeypadChildSmallSummarySM textTitleNoShadowBlack"
                    }
                  ></RenderHomeBillboardChildsMainKeypad>
                </div>
              </div>
            </div>
          </div>
        </StyleRoot>

        <StyleRoot
          style={sectionStyleXS}
          className="d-xs-block d-sm-none d-md-none HeroMain homeOverflowHidden"
        >
          <div
            style={{
              marginTop: mainView ? "50px" : "-50px",
              marginBottom: mainView ? "100px" : "0px"
            }}
            className="KeypadChildHeroMain-contentTopXS colorlib-about"
          >
            <div className="KeypadChildHeroMain-contentInnerTopXS">
              <div className="KeypadChildHeroMain-subTitleTopXS">
                <div className="row">
                  <div>
                    <div
                      className="heading-meta"
                      style={{ marginBottom: "10px" }}
                    >
                      <span
                        className="borderInner"
                        style={{
                          color: `${textFC}`,
                          border: `1px solid ${textFC}`
                        }}
                      >
                        {reference}
                      </span>
                    </div>

                    <h2
                      style={{
                        color: `${textFC}`,
                        border: `1px solid ${textFC}`
                      }}
                      className="colorlib-heading borderInner"
                    >
                      {summary}
                    </h2>
                  </div>
                </div>
              </div>

              <div
                style={{
                  marginTop: mainView ? "-30px" : "-30px"
                }}
                className="KeypadChildHeroMain-subTitleMiddleXS"
              >
                <div className="row">
                  <RenderHomeBillboardChildsMainKeypad
                    homeBillboardChildsMainKeypad={
                      homeBillboardChildsMainKeypad
                    }
                    nameOfClassParentColumn={"col-md-12"}
                    nameOfClassChildColumn={"col-6"}
                    mediaAttribute={"XS"}
                    parentImageLink={"KeypadChildLargeImageXS"}
                    parentReferenceLink={
                      "KeypadChildLargeReferenceXS textTitleNoShadowBlack"
                    }
                    parentSummaryLink={
                      "KeypadChildLargeSummaryXS textTitleNoShadowBlack"
                    }
                    childImageLink={"KeypadChildSmallImageXS"}
                    childReferenceLink={
                      "KeypadChildSmallReferenceXS textTitleNoShadowBlack"
                    }
                    childSummaryLink={
                      "KeypadChildSmallSummaryXS textTitleNoShadowBlack"
                    }
                  ></RenderHomeBillboardChildsMainKeypad>
                </div>
              </div>
            </div>
          </div>
        </StyleRoot>
      </div>
    );
  }
}

export default HomeBillboardChildsMainKeypad;
