import React, { Component } from "react";
import auth from "../../services/authService";
import Table from "../common/table";

class MenusTable extends Component {
  columns = [];

  async componentDidMount() {
    //console.log("componentDidMount MenusTable 01 : ");
  }

  groupColumn = {
    key: "tree",
    type: "text",
    content: menu =>
      auth.getCurrentUser().t_role_a_name === "Admin" ? (
        <button
          onClick={() => this.props.onGroup(menu)}
          className={
            menu.N_BillboardOrigin_ID === 1
              ? menu.N_BB_Count > 0
                ? "btn btn-warning btn-sm pull-right enabled"
                : "btn btn-secondary btn-sm pull-right enabled"
              : menu.N_BillboardOrigin_ID === 2
              ? menu.N_BB_Count > 0
                ? "btn btn-warning btn-sm pull-right enabled"
                : "btn btn-secondary btn-sm pull-right enabled"
              : menu.N_BillboardOrigin_ID === 3
              ? menu.N_BB_Count > 0
                ? "btn btn-warning btn-sm pull-right enabled"
                : "btn btn-secondary btn-sm pull-right enabled"
              : "btn btn-secondary btn-sm pull-right enabled"
          }
        >
          {menu.N_BillboardOrigin_ID === 1
            ? "Group Level"
            : menu.N_BillboardOrigin_ID === 2
            ? "Sum of Article Level"
            : menu.N_BillboardOrigin_ID === 3
            ? "Article Level"
            : "-"}
        </button>
      ) : (
        <button
          className={
            menu.N_BillboardOrigin_ID === 1
              ? menu.N_BB_Count > 0
                ? "btn btn-warning btn-sm pull-right enabled"
                : "btn btn-secondary btn-sm pull-right enabled"
              : menu.N_BillboardOrigin_ID === 2
              ? menu.N_BB_Count > 0
                ? "btn btn-warning btn-sm pull-right enabled"
                : "btn btn-secondary btn-sm pull-right enabled"
              : menu.N_BillboardOrigin_ID === 3
              ? menu.N_BB_Count > 0
                ? "btn btn-warning btn-sm pull-right enabled"
                : "btn btn-secondary btn-sm pull-right enabled"
              : "btn btn-secondary btn-sm pull-right enabled"
          }
        >
          {menu.N_BillboardOrigin_ID === 1
            ? "Group Level"
            : menu.N_BillboardOrigin_ID === 2
            ? "Sum of Article Level"
            : menu.N_BillboardOrigin_ID === 3
            ? "Article Level"
            : "-"}
        </button>
      )
  };

  updateColumn = {
    key: "update",
    type: "text",
    content: menu =>
      auth.getCurrentUser().t_role_a_name === "Admin" ? (
        <button
          onClick={() => this.props.onUpdate(menu)}
          className="btn btn-info btn-sm pull-right enabled"
        >
          Update
        </button>
      ) : (
        <button className="btn btn-info btn-sm pull-right disabled clickableNon">
          Update
        </button>
      )
  };

  deleteColumn = {
    key: "delete",
    type: "text",
    content: menu =>
      auth.getCurrentUser().t_role_a_name === "Admin" ? (
        <button
          onClick={() => {
            if (menu.N_BB_Count < 1) {
              if (
                window.confirm(
                  `Are you sure you want to delete Menu '${menu.A_Reference}'?`
                )
              ) {
                this.props.onDelete(menu);
              }
            }
          }}
          className={
            menu.N_BB_Count > 0
              ? "btn btn-danger btn-sm disabled clickableNon"
              : "btn btn-danger btn-sm enabled"
          }
        >
          Delete
        </button>
      ) : (
        <button className="btn btn-danger btn-sm disabled clickableNon">
          Delete
        </button>
      )
  };

  constructor() {
    super();
    const menus = [{}];

    menus.map(
      item => (
        this.columns.push({
          ID: 1,
          path: "ID",
          label: "Identifier",
          hidden: true,
          type: "text"
        }),
        this.columns.push({
          ID: 2,
          path: "B_Billboard",
          label: "Billboard",
          hidden: true,
          type: "text"
        }),
        this.columns.push({
          ID: 3,
          path: "N_BillboardOrigin_ID",
          label: "BillboardOriginIdentifier",
          hidden: true,
          type: "text"
        }),
        this.columns.push({
          ID: 4,
          path: "N_HomeLocationGroup_ID",
          label: "HomeLocationGroupIdentifier",
          hidden: true,
          type: "text"
        }),
        this.columns.push({
          ID: 5,
          path: "N_Order_No",
          label: "Order",
          type: "text"
        }),
        this.columns.push({
          ID: 6,
          path: "A_Reference",
          label: "Reference",
          type: "text"
        }),
        this.columns.push({
          ID: 7,
          path: "I_Image",
          label: "Image",
          type: "image"
        }),
        this.columns.push({
          ID: 8,
          path: "N_BillboardLarge_ID",
          label: "BillboardLargeIdentifier",
          hidden: true,
          type: "text"
        }),
        this.columns.push({
          ID: 9,
          path: "D_Inserted",
          label: "Inserted",
          type: "date"
        }),
        this.columns.push({
          ID: 10,
          path: "A_Inserted_By",
          label: "Inserted_By",
          type: "text"
        }),
        this.columns.push({
          ID: 11,
          path: "D_Updated",
          label: "Updated",
          type: "date"
        }),
        this.columns.push({
          ID: 12,
          path: "A_Updated_By",
          label: "Updated_By",
          type: "text"
        })
      )
    );

    this.columns.push(this.groupColumn);
    this.columns.push(this.updateColumn);
    this.columns.push(this.deleteColumn);

    //const user = auth.getCurrentUser();
  }

  render() {
    //console.log("render usersTable");
    const { menusList, onSort, sortColumn } = this.props;
    return (
      <Table
        columns={this.columns}
        data={menusList}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default MenusTable;
