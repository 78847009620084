import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
import moment from "moment";

const slice = createSlice({
  name: "radioGrpDirections",
  initialState: {
    list: [],
    loading: false,
    lastFetch: null
  },
  reducers: {
    //
    // Babak's Note: By adding 'radioGrpDirectionsRequested' or 'radioGrpDirectionsReceived' below, a action is created for us:-
    // actions => action handlers. The 'radioGrpDirections' below represents the 'state':-
    radioGrpDirectionsRequested: (radioGrpDirections, action) => {
      console.log("radioGrpDirectionsRequested : ", action.payload);
      radioGrpDirections.loading = true;
    },
    radioGrpDirectionsReceived: (radioGrpDirections, action) => {
      console.log("radioGrpDirectionsReceived : ", action.payload);
      radioGrpDirections.list = action.payload;
      radioGrpDirections.loading = false;
      radioGrpDirections.lastFetch = Date.now();
    },
    radioGrpDirectionsRequestedFailed: (radioGrpDirections, action) => {
      radioGrpDirections.loading = false;
      // window.alert(`No RadioGrpDirections found.`);
    },
    radioGrpDirectionRequested: (radioGrpDirection, action) => {
      console.log("radioGrpDirectionRequested : ", action.payload);
      radioGrpDirection.loading = true;
    },
    radioGrpDirectionReceived: (radioGrpDirection, action) => {
      console.log("radioGrpDirectionReceived : ", action.payload);
      radioGrpDirection.list = action.payload;
      radioGrpDirection.loading = false;
      radioGrpDirection.lastFetch = Date.now();
    },
    radioGrpDirectionRequestedFailed: (radioGrpDirection, action) => {
      radioGrpDirection.loading = false;
      // window.alert(`No RadioGrpDirection found.`);
    },
    radioGrpDirectionChangeRequested: (radioGrpDirection, action) => {
      console.log("radioGrpDirectionChangeRequested : ", action.payload);
      radioGrpDirection.loading = true;
    },
    radioGrpDirectionChangeFailed: (radioGrpDirection, action) => {
      radioGrpDirection.loading = false;

      // //
      // // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete radioGrpDirection api at the back end.
      // const { ID: radioGrpDirectionId, A_Name: radioGrpDirectionName } = action.payload;

      // window.alert(`RadioGrpDirection '${radioGrpDirectionName}' no longer exists.`);

      // const index = radioGrpDirection.list.findIndex(radioGrpDirection => radioGrpDirection.ID === radioGrpDirectionId);
      // //console.log("radioGrpDirectionDeleted ha ", index);
      // radioGrpDirection.list.splice(index, 1);
    },
    radioGrpDirectionChanged: (radioGrpDirection, action) => {
      radioGrpDirection.loading = false;
    },
    radioGrpDirectionDeleteRequested: (radioGrpDirection, action) => {
      console.log("radioGrpDirectionDeleteRequested : ", action.payload);
      radioGrpDirection.loading = true;
    },
    radioGrpDirectionDeleteFailed: (radioGrpDirections, action) => {
      radioGrpDirections.loading = false;

      //
      // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete radioGrpDirection api at the back end.
      const {
        ID: radioGrpDirectionId,
        A_Name: radioGrpDirectionName
      } = action.payload;

      window.alert(
        `An issue has occured with radioGrpDirection '${radioGrpDirectionName}'. ${action.payload}`
      );

      const index = radioGrpDirections.list.findIndex(
        radioGrpDirection => radioGrpDirection.ID === radioGrpDirectionId
      );
      //console.log("radioGrpDirectionDeleted ha ", index);
      radioGrpDirections.list.splice(index, 1);
    },
    radioGrpDirectionDeleted: (radioGrpDirections, action) => {
      //
      // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete radioGrpDirection api at the back end.
      const { ID: radioGrpDirectionID } = action.payload;
      // console.log("radioGrpDirectionDeleted ha ", action.payload, radioGrpDirectionID, radioGrpDirections);
      const index = radioGrpDirections.list.findIndex(
        radioGrpDirection => radioGrpDirection.ID === radioGrpDirectionID
      );
      //console.log("radioGrpDirectionDeleted, ha! ha! ", index);
      radioGrpDirections.list.splice(index, 1);
    },
    radioGrpDirectionAddRequested: (radioGrpDirection, action) => {
      console.log("radioGrpDirectionAddRequested : ", action.payload);
      radioGrpDirection.loading = true;
    },
    radioGrpDirectionAddFailed: (radioGrpDirection, action) => {
      radioGrpDirection.loading = false;
    },
    //
    // Babak's Note: The 'radioGrpDirections' below represents the 'state':-
    radioGrpDirectionAdded: (radioGrpDirections, action) => {
      console.log("radioGrpDirectionAdded : ", action.payload);
      radioGrpDirections.list.push(action.payload);
    }
    // radioGrpDirectionAdded: (radioGrpDirections, action) => {
    //   radioGrpDirections.list.push({
    //     id: ++lastId,
    //     description: action.payload.description,
    //     resolved: false
    //   });
    // },
    // radioGrpDirectionDeleted: (radioGrpDirections, action) => {
    //   console.log("radioGrpDirectionDeleted ha ", action.payload.ID);
    //   radioGrpDirections.list.filter(radioGrpDirection => radioGrpDirection.ID !== action.payload.ID);
    // },
  }
});
const {
  radioGrpDirectionAddRequested,
  radioGrpDirectionAdded,
  radioGrpDirectionAddFailed,
  radioGrpDirectionChangeRequested,
  radioGrpDirectionChanged,
  radioGrpDirectionChangeFailed,
  radioGrpDirectionDeleteRequested,
  radioGrpDirectionDeleted,
  radioGrpDirectionDeleteFailed,
  radioGrpDirectionsReceived,
  radioGrpDirectionsRequested,
  radioGrpDirectionsRequestedFailed,
  radioGrpDirectionReceived,
  radioGrpDirectionRequested,
  radioGrpDirectionRequestedFailed
} = slice.actions;
export default slice.reducer;

//
// Action Creators
const url = "/radioGrpDirections";

export const loadRadioGrpDirections = () => (dispatch, getState) => {
  const { lastFetch } = getState().entities.radioGrpDirections;

  console.log("loadRadioGrpDirections lastFetch : ", lastFetch);

  // //
  // // Babak's Note: Below we check with 'moment' that catch that if this request was already fetched less than 10 minutes ago then do not fetch again:-
  // const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  // if (diffInMinutes < 10) return;

  dispatch(
    apiCallBegan({
      url: url,
      onStart: radioGrpDirectionsRequested.type,
      method: "get",
      onSuccess: radioGrpDirectionsReceived.type,
      onError: radioGrpDirectionsRequestedFailed.type
    })
  );
};

export const loadRadioGrpDirection = radioGrpDirectionID => (
  dispatch,
  getState
) => {
  // const { lastFetch } = getState().entities.radioGrpDirections;

  //console.log("loadRadioGrpDirection : ", radioGrpDirectionID);

  // //
  // // Babak's Note: Below we check with 'moment' that catch that if this request was already fetched less than 10 minutes ago then do not fetch again:-
  // const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  // if (diffInMinutes < 10) return;

  dispatch(
    apiCallBegan({
      url: url + "/" + radioGrpDirectionID,
      onStart: radioGrpDirectionRequested.type,
      method: "get",
      onSuccess: radioGrpDirectionReceived.type,
      onError: radioGrpDirectionRequestedFailed.type
    })
  );
};

//
// Babak's Note: Below function 'apiCallBegan' is returned back by which function (in this case from within 'index.js') calls 'addRadioGrpDirection':-
export const addRadioGrpDirection = radioGrpDirection =>
  apiCallBegan({
    url,
    method: "post",
    onStart: radioGrpDirectionAddRequested.type,
    data: radioGrpDirection,
    onSuccess: radioGrpDirectionAdded.type,
    onError: radioGrpDirectionAddFailed.type
  });

export const getRadioGrpDirections = createSelector(
  state => state.entities.radioGrpDirections,
  //
  // Babak's Note: In the below line, by installing above 'reselect' (at the top of this file) if the list of (radioGrpDirections, projects) has not changed then do not requery again:-
  // (radioGrpDirections) => radioGrpDirections.list.filter(radioGrpDirection => !radioGrpDirection.B_Active)
  radioGrpDirections => radioGrpDirections.list
);

export const changeRadioGrpDirection = radioGrpDirection =>
  apiCallBegan({
    url: url + "/" + radioGrpDirection.ID,
    method: "put",
    onStart: radioGrpDirectionChangeRequested.type,
    data: {
      A_Group: radioGrpDirection.A_Group
    },
    onSuccess: radioGrpDirectionChanged.type,
    onError: radioGrpDirectionChangeFailed.type
  });

export const deleteRadioGrpDirection = radioGrpDirection =>
  apiCallBegan({
    url: url + "/" + radioGrpDirection.ID,
    method: "delete",
    onStart: radioGrpDirectionDeleteRequested.type,
    data: { ID: radioGrpDirection.ID, A_Group: radioGrpDirection.A_Group },
    onSuccess: radioGrpDirectionDeleted.type,
    onError: radioGrpDirectionDeleteFailed.type
  });
