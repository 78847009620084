import React, { Component } from "react";
import auth from "../../services/authService";
import Table from "../common/table";

class SumOfArticlesTable extends Component {
  columns = [];

  async componentDidMount() {
    //console.log("componentDidMount SumOfArticlesTable 01 : ");
  }

  articleColumn = {
    key: "tree",
    type: "text",
    content: article =>
      auth.getCurrentUser().t_role_a_name === "Admin" ? (
        <button
          onClick={() => this.props.onArticle(article)}
          className={
            article.N_BB_Count > 0
              ? "btn btn-warning btn-sm pull-right enabled"
              : "btn btn-secondary btn-sm pull-right enabled"
          }
        >
          {"Article Level"}
        </button>
      ) : (
        <button
          className={
            article.N_BB_Count > 0
              ? "btn btn-warning btn-sm pull-right enabled"
              : "btn btn-secondary btn-sm pull-right enabled"
          }
        >
          {"Article Level"}
        </button>
      )
  };

  updateColumn = {
    key: "update",
    type: "text",
    content: sumOfArticle =>
      auth.getCurrentUser().t_role_a_name === "Admin" ? (
        <button
          onClick={() => this.props.onUpdate(sumOfArticle)}
          className="btn btn-info btn-sm pull-right enabled"
        >
          Update
        </button>
      ) : (
        <button className="btn btn-info btn-sm pull-right disabled clickableNon">
          Update
        </button>
      )
  };

  deleteColumn = {
    key: "delete",
    type: "text",
    content: sumOfArticle =>
      auth.getCurrentUser().t_role_a_name === "Admin" ? (
        <button
          onClick={() => {
            if (sumOfArticle.N_BB_Count < 1) {
              if (
                window.confirm(
                  `Are you sure you want to delete Sum Of Article '${sumOfArticle.A_Reference}'?`
                )
              ) {
                this.props.onDelete(sumOfArticle);
              }
            }
          }}
          className={
            sumOfArticle.N_BB_Count > 0
              ? "btn btn-danger btn-sm disabled clickableNon"
              : "btn btn-danger btn-sm enabled"
          }
        >
          Delete
        </button>
      ) : (
        <button className="btn btn-danger btn-sm disabled clickableNon">
          Delete
        </button>
      )
  };

  constructor() {
    super();
    const sumOfArticles = [{ ID: 1 }];

    sumOfArticles.map(
      item => (
        this.columns.push({
          path: "ID",
          label: "Identifier",
          hidden: true,
          type: "text"
        }),
        this.columns.push({
          path: "B_Billboard",
          label: "Billboard",
          hidden: true,
          type: "text"
        }),
        this.columns.push({
          path: "N_BillboardOrigin_ID",
          label: "BillboardOriginIdentifier",
          hidden: true,
          type: "text"
        }),
        this.columns.push({
          path: "N_HomeLocationGroup_ID",
          label: "HomeLocationGroupIdentifier",
          hidden: true,
          type: "text"
        }),
        this.columns.push({
          path: "N_Order_No",
          label: "Order",
          type: "text"
        }),
        this.columns.push({
          path: "A_Reference",
          label: "Reference",
          type: "text"
        }),
        // this.columns.push({
        //   path: "A_Summary",
        //   label: "Summary",
        //   type: "text"
        // }),
        // this.columns.push({
        //   path: "A_Description",
        //   label: "Description",
        //   type: "text"
        // }),
        this.columns.push({ path: "I_Image", label: "Image", type: "image" }),
        this.columns.push({
          path: "N_BillboardLarge_ID",
          label: "BillboardLargeIdentifier",
          hidden: true,
          type: "text"
        }),
        this.columns.push({
          path: "D_Inserted",
          label: "Inserted",
          type: "date"
        }),
        this.columns.push({
          path: "A_Inserted_By",
          label: "Inserted_By",
          type: "text"
        }),
        this.columns.push({
          path: "D_Updated",
          label: "Updated",
          type: "date"
        }),
        this.columns.push({
          path: "A_Updated_By",
          label: "Updated_By",
          type: "text"
        })
      )
    );

    this.columns.push(this.articleColumn);
    this.columns.push(this.updateColumn);
    this.columns.push(this.deleteColumn);

    //const user = auth.getCurrentUser();
  }

  render() {
    //console.log("render usersTable");
    const { sumOfArticlesList, onSort, sortColumn } = this.props;
    return (
      <Table
        columns={this.columns}
        data={sumOfArticlesList}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default SumOfArticlesTable;
