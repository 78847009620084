import axios from "axios";
import * as actions from "../api";
import auth from "../../services/authService";

const api = ({ dispatch }) => next => async action => {
  console.log("action.type 1 : ", action.type, actions.apiCallBegan.type);
  if (action.type !== actions.apiCallBegan.type) {
    console.log("action.type 10 : ", action.type);
    return next(action);
  }

  ///console.log("action.type 2 : ", action.type);

  const { url, method, data, onStart, onSuccess, onError } = action.payload;

  //console.log("action.type 3 : ", action.type, action.payload);

  if (onStart) dispatch({ type: onStart });

  //console.log("action.type 4 : ", action.type);

  next(action);

  //console.log("action.type 5 : ", action.type);

  axios.defaults.baseURL = process.env.REACT_APP_API_URL;

  axios.defaults.headers.common["x-auth-token"] = auth.getJwt();

  //console.log("action.type 6 : ", action.type);

  // const timeStamp = `${url}?timestamp=${Math.random()}`;
  const timeStamp = `${url}?timestamp=${new Date().getTime()}`;

  //console.log("action.type 7 : ", action.type);

  // console.log("middleware api 1111111 : ", url, data, timeStamp);

  // if (method === "put") window.alert(`data : ${Object.values(data)} :  ${url}`);

  // var myHeaders = new Headers();
  // myHeaders.set("Cache-Control", "no-store");
  // // myHeaders.set('Pragma', 'no-cache');
  // // myHeaders.set('Expires', '0');

  try {
    console.log("middleware api 2222222 : ", url, data);
    const response = await axios.request({
      // baseURL: "http://localhost:3800/api",
      url: `${timeStamp}`,
      method,
      data
      // headers: myHeaders
      // Pragma: "no-cache",
      // Expires: "0"
    });
    // General Success:-
    console.log("dispatch Success 1 : ", action.type, response.data);
    dispatch(actions.apiCallSuccess(response.data));
    // Specific Success:-

    if (onSuccess) {
      console.log("dispatch Success 2 : ", action.type, response.data);
      dispatch({ type: onSuccess, payload: response.data });
    }
  } catch (error) {
    // window.alert(`No user found.`);
    //
    // Babak's Notes: Below 'error.response.data' contians the actual error message sent from the back end:-
    // General Error:-
    console.log("Error 1 : ", error.response.data, data);
    dispatch(actions.apiCallFailed(error.response.data));

    // Specific Error:-
    //
    if (onError) dispatch({ type: onError, payload: error.response.data });
  }
};

export default api;
