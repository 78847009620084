import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "../../services/authService";

const ProtectedRoute = ({ path, component: Component, render, ...rest }) => {
  return (
    <Route
      //   path="/movies/:id"
      //
      // Babak's Note: Changed above with below line. React will automatically look for 'props' attributed
      //               of the same name and populated the 'path' value:-
      {...rest}
      render={props => {
        //console.log(props);
        // if (!auth.getCurrentUser()) return <Redirect to="/login" />;
        // Babak's Note: We replaced above with below so we redirect the user to the actual component (ie view) after
        //               'login' component (ie view) rather than 'movies' component:-
        if (!auth.getCurrentUser())
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        // Babak's Note: Below either we have passed a 'component' or 'render':-
        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};

export default ProtectedRoute;
