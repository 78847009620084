import React, { Component } from "react";
// import StoreContext from "../contexts/storeContext";
import { Link } from "react-router-dom";
// import { Redirect } from "react-router";
import Pagination from "../common/pagination";
import { loadUsers, getUsers, deleteUser } from "../../store/users";
import UsersTable from "./usersTable";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import SearchBox from "../searchBox";
import { connect } from "react-redux";

// **********
// **********  I USE THIS FORM, I DONT NOT USE 'Users'  **********
// **********

class UsersList extends Component {
  // static contextType = StoreContext;

  constructor(props) {
    super(props);
    this.state = {
      // UserForm: false,
      userID: 0,
      usersList: [],
      currentPage: 1,
      pageSize: 10,
      searchQuery: "",
      sortColumn: { path: "A_Name", order: "asc" }
    };
  }

  async componentDidMount() {
    document.body.style.backgroundImage = `url()`;
    document.body.style.backgroundColor = "white";
    document.body.style.backgroundPosition = "center center";
    document.body.style.backgroundAttachment = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundSize = "cover";

    // window.alert("UsersList, componentDidMount : ");

    await this.props.loadUsers();
  }

  // componentWillUnmount() {
  //   this.unsubscribe();
  // }

  handleUpdate = async user => {
    //console.log("UsersList, handleUpdate : ", user.ID);

    //
    // Babak's Notes: 'Redirect' does not work for some reason (back to prev screen browser arrow key), no time to investifate for now:-
    // this.setState({ UserForm: true, userID: user.ID });

    try {
      window.location = `/usersList/${user.ID}`;
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...ex.errors };
        this.setState({ errors });
      }
    }
  };

  handlePageChange = page => {
    //console.log(page);
    this.setState({ currentPage: page });
  };

  //
  // Babak's Note: We have put this 'input' field inside of the 'SearchBox' component, this component raises
  //               an event and in the we get an string. So below 'query' will contain the string the user
  //               types in the search field.
  handleSearch = query => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  handleSort = sortColumn => {
    //console.log("handleSort : ", sortColumn);
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;

    console.log("UsersList, getPageData : ", this.props.users, searchQuery);

    const allUsers = this.props.users;

    let filtered = allUsers;
    if (searchQuery)
      filtered = allUsers.filter(m =>
        m.A_Name.toLowerCase().includes(searchQuery.toLowerCase())
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const users = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: users };
  };

  render() {
    console.log("UsersList, render() : ", this.props.users);

    // if (this.state.UserForm === true) {
    //   //console.log("UsersList, render(), Redirect : ", this.state.userID);
    //   return <Redirect to={`/usersList/${this.state.userID}`} />;
    // }

    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;

    //
    //Babak's Note: Below I renamed 'data' to 'users'
    const { totalCount, data: usersList } = this.getPagedData();

    const { length: count } = usersList;
    console.log(`Hello00000000000000000000 : ${count}  :  ${usersList}`);
    // if (count === 10) return <div>""</div>;

    return (
      <div className="container">
        <div className="row">
          <div
            className="col"
            style={{ marginTop: "160px", marginBottom: "100px" }}
          >
            <h1>Users</h1>
            <br />
            <Link
              to="/usersList/new"
              className="btn btn-primary"
              style={{ marginBottom: 20 }}
            >
              New User
            </Link>
            <p>Showing {totalCount} users in the database.</p>
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
            <UsersTable
              usersList={usersList}
              sortColumn={sortColumn}
              onDelete={user => this.props.deleteUser(user)}
              onUpdate={this.handleUpdate}
              onSort={this.handleSort}
            />
            <div className="paginationBar">
              <Pagination
                itemsCount={totalCount}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={this.handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

//
// Babak's Notes: goto tutorial directory '11-Integration with React' file '5- Connecting Components Using react-redux'
const mapStateToProps = state => ({
  //   bugs: state.entities.users.list.filter(bug => !bug.resolved)
  users: state.entities.users.list
});

const mapDispatchToProps = dispatch => ({
  loadUsers: () => dispatch(loadUsers()),
  deleteUser: id => dispatch(deleteUser(id))
});

// Container Component
//   Presentation (Bugs)
//
// Babak's Notes: Below I populate 'this.props.bugs' and 'this.props.loadBugs()':-
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersList);
