import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
import moment from "moment";

const slice = createSlice({
  name: "homeBillboardSlides",
  initialState: {
    list: [],
    listSingleRow: [],
    listOnNavvar: [],
    loading: false,
    lastFetch: null
  },
  reducers: {
    //
    // Babak's Note: By adding 'homeBillboardSlidesRequested' or 'homeBillboardSlidesReceived' below, a action is created for us:-
    // actions => action handlers. The 'homeBillboardSlides' below represents the 'state':-
    homeBillboardSlidesRequested: (homeBillboardSlides, action) => {
      console.log("homeBillboardSlidesRequested : ", action.payload);
      homeBillboardSlides.loading = true;
    },
    homeBillboardSlidesReceived: (homeBillboardSlides, action) => {
      console.log("homeBillboardSlidesReceived : ", action.payload);
      homeBillboardSlides.list = action.payload;
      homeBillboardSlides.loading = false;
      homeBillboardSlides.lastFetch = Date.now();
    },
    homeBillboardSlidesRequestedFailed: (homeBillboardSlides, action) => {
      homeBillboardSlides.loading = false;
      //   window.alert(`No HomeBillboardSlides found.`);
    },

    homeBillboardSlidesOnNavbarRequested: (homeBillboardSlides, action) => {
      console.log("homeBillboardSlidesOnNavbarRequested : ", action.payload);
      homeBillboardSlides.loading = true;
    },
    homeBillboardSlidesOnNavbarReceived: (homeBillboardSlides, action) => {
      console.log("homeBillboardSlidesOnNavbarReceived : ", action.payload);
      homeBillboardSlides.listOnNavvar = action.payload;
      homeBillboardSlides.loading = false;
      homeBillboardSlides.lastFetch = Date.now();
    },
    homeBillboardSlidesOnNavbarRequestedFailed: (
      homeBillboardSlides,
      action
    ) => {
      homeBillboardSlides.loading = false;
      //   window.alert(`No HomeBillboardSlides found.`);
    },

    homeBillboardSlideRequested: (homeBillboardSlide, action) => {
      console.log("homeBillboardSlideRequested : ", action.payload);
      homeBillboardSlide.loading = true;
    },
    homeBillboardSlideReceived: (homeBillboardSlide, action) => {
      console.log("homeBillboardSlideReceived : ", action.payload);
      homeBillboardSlide.listSingleRow = action.payload;
      homeBillboardSlide.loading = false;
      homeBillboardSlide.lastFetch = Date.now();
    },
    homeBillboardSlideRequestedFailed: (homeBillboardSlide, action) => {
      homeBillboardSlide.loading = false;
      //   window.alert(`No HomeBillboardSlide found.`);
    },
    homeBillboardSlideChangeRequested: (homeBillboardSlide, action) => {
      console.log("homeBillboardSlideChangeRequested : ", action.payload);
      homeBillboardSlide.loading = true;
    },
    homeBillboardSlideChangeFailed: (homeBillboardSlide, action) => {
      homeBillboardSlide.loading = false;

      // //
      // // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete homeBillboardSlide api at the back end.
      // const { ID: homeBillboardSlideId, A_Name: homeBillboardSlideName } = action.payload;

      // window.alert(`HomeBillboardSlide '${homeBillboardSlideName}' no longer exists.`);

      // const index = homeBillboardSlide.list.findIndex(homeBillboardSlide => homeBillboardSlide.ID === homeBillboardSlideId);
      // //console.log("homeBillboardSlideDeleted ha ", index);
      // homeBillboardSlide.list.splice(index, 1);
    },
    homeBillboardSlideChanged: (homeBillboardSlide, action) => {
      homeBillboardSlide.loading = false;
    },
    homeBillboardSlideDeleteRequested: (homeBillboardSlide, action) => {
      console.log("homeBillboardSlideDeleteRequested : ", action.payload);
      homeBillboardSlide.loading = true;
    },
    homeBillboardSlideDeleteFailed: (homeBillboardSlides, action) => {
      homeBillboardSlides.loading = false;

      //
      // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete homeBillboardSlide api at the back end.
      const {
        ID: homeBillboardSlideId,
        A_Name: homeBillboardSlideName
      } = action.payload;

      window.alert(
        `An issue has occured with homeBillboardSlide '${homeBillboardSlideName}'. ${action.payload}`
      );

      const index = homeBillboardSlides.list.findIndex(
        homeBillboardSlide => homeBillboardSlide.ID === homeBillboardSlideId
      );
      //console.log("homeBillboardSlideDeleted ha ", index);
      homeBillboardSlides.list.splice(index, 1);
    },
    homeBillboardSlideDeleted: (homeBillboardSlides, action) => {
      //
      // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete homeBillboardSlide api at the back end.
      const { ID: homeBillboardSlideID } = action.payload;
      // console.log("homeBillboardSlideDeleted ha ", action.payload, homeBillboardSlideID, homeBillboardSlides);
      const index = homeBillboardSlides.list.findIndex(
        homeBillboardSlide => homeBillboardSlide.ID === homeBillboardSlideID
      );
      //console.log("homeBillboardSlideDeleted, ha! ha! ", index);
      homeBillboardSlides.list.splice(index, 1);
    },
    homeBillboardSlideAddRequested: (homeBillboardSlide, action) => {
      console.log("homeBillboardSlideAddRequested : ", action.payload);
      homeBillboardSlide.loading = true;
    },
    homeBillboardSlideAddFailed: (homeBillboardSlide, action) => {
      homeBillboardSlide.loading = false;
    },
    //
    // Babak's Note: The 'homeBillboardSlides' below represents the 'state':-
    homeBillboardSlideAdded: (homeBillboardSlides, action) => {
      console.log("homeBillboardSlideAdded : ", action.payload);
      homeBillboardSlides.list.push(action.payload);
    }
    // homeBillboardSlideAdded: (homeBillboardSlides, action) => {
    //   homeBillboardSlides.list.push({
    //     id: ++lastId,
    //     description: action.payload.description,
    //     resolved: false
    //   });
    // },
    // homeBillboardSlideDeleted: (homeBillboardSlides, action) => {
    //   console.log("homeBillboardSlideDeleted ha ", action.payload.ID);
    //   homeBillboardSlides.list.filter(homeBillboardSlide => homeBillboardSlide.ID !== action.payload.ID);
    // },
  }
});
const {
  homeBillboardSlideAddRequested,
  homeBillboardSlideAdded,
  homeBillboardSlideAddFailed,
  homeBillboardSlideChangeRequested,
  homeBillboardSlideChanged,
  homeBillboardSlideChangeFailed,
  homeBillboardSlideDeleteRequested,
  homeBillboardSlideDeleted,
  homeBillboardSlideDeleteFailed,
  homeBillboardSlidesReceived,
  homeBillboardSlidesRequested,
  homeBillboardSlidesRequestedFailed,
  homeBillboardSlidesOnNavbarReceived,
  homeBillboardSlidesOnNavbarRequested,
  homeBillboardSlidesOnNavbarRequestedFailed,
  homeBillboardSlideReceived,
  homeBillboardSlideRequested,
  homeBillboardSlideRequestedFailed
} = slice.actions;
export default slice.reducer;

//
// Action Creators
const url = "/billboardLarges";

export const loadHomeBillboardSlides = () => (dispatch, getState) => {
  // const { lastFetch } = getState().entities.homeBillboardSlides;

  // console.log("loadHomeBillboardSlides lastFetch : ", lastFetch);

  // //
  // // Babak's Note: Below we check with 'moment' that catch that if this request was already fetched less than 10 minutes ago then do not fetch again:-
  // const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  // if (diffInMinutes < 10) return;

  dispatch(
    apiCallBegan({
      url: url + "/slides",
      onStart: homeBillboardSlidesRequested.type,
      method: "get",
      onSuccess: homeBillboardSlidesReceived.type,
      onError: homeBillboardSlidesRequestedFailed.type
    })
  );
};

export const loadHomeBillboardSlide = homeBillboardSlideID => (
  dispatch,
  getState
) => {
  // const { lastFetch } = getState().entities.homeBillboardSlides;

  //console.log("loadHomeBillboardSlide : ", homeBillboardSlideID);

  // //
  // // Babak's Note: Below we check with 'moment' that catch that if this request was already fetched less than 10 minutes ago then do not fetch again:-
  // const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  // if (diffInMinutes < 10) return;

  dispatch(
    apiCallBegan({
      url: url + "/" + homeBillboardSlideID,
      onStart: homeBillboardSlideRequested.type,
      method: "get",
      onSuccess: homeBillboardSlideReceived.type,
      onError: homeBillboardSlideRequestedFailed.type
    })
  );
};

//
// Babak's Note: Below function 'apiCallBegan' is returned back by which function (in this case from within 'index.js') calls 'addHomeBillboardSlide':-
export const addHomeBillboardSlide = (homeBillboardSlide, user, currentDate) =>
  apiCallBegan({
    url,
    method: "post",
    onStart: homeBillboardSlideAddRequested.type,
    //data: homeBillboardSlide,
    data: {
      N_OrderNo: homeBillboardSlide.N_OrderNo,
      N_RadioGrpDirections_ID: homeBillboardSlide.N_RadioGrpDirections_ID,
      N_HomeBillboards_ID: homeBillboardSlide.N_HomeBillboards_ID,
      A_Reference: homeBillboardSlide.A_Reference,
      A_Summary: homeBillboardSlide.A_Summary,
      A_Description: homeBillboardSlide.A_Description,
      A_HoverSummary01: homeBillboardSlide.A_HoverSummary01,
      A_HoverSummary01_Link: homeBillboardSlide.A_HoverSummary01_Link,
      A_HoverSummary02: homeBillboardSlide.A_HoverSummary02,
      A_HoverSummary02_Link: homeBillboardSlide.A_HoverSummary02_Link,
      A_HoverSummary03: homeBillboardSlide.A_HoverSummary03,
      A_HoverSummary03_Link: homeBillboardSlide.A_HoverSummary03_Link,
      A_HoverSummary04: homeBillboardSlide.A_HoverSummary04,
      A_HoverSummary04_Link: homeBillboardSlide.A_HoverSummary04_Link,
      A_HoverSummary05: homeBillboardSlide.A_HoverSummary05,
      A_HoverSummary05_Link: homeBillboardSlide.A_HoverSummary05_Link,
      A_HoverSummary06: homeBillboardSlide.A_HoverSummary06,
      A_HoverSummary07: homeBillboardSlide.A_HoverSummary07,
      A_HoverSummary08: homeBillboardSlide.A_HoverSummary08,
      A_HoverSummary08_Link: homeBillboardSlide.A_HoverSummary08_Link,
      I_Image: homeBillboardSlide.I_Image,
      D_Inserted: currentDate,
      A_Inserted_By: user
    },
    onSuccess: homeBillboardSlideAdded.type,
    onError: homeBillboardSlideAddFailed.type
  });

export const getHomeBillboardSlides = createSelector(
  state => state.entities.homeBillboardSlides,
  //
  // Babak's Note: In the below line, by installing above 'reselect' (at the top of this file) if the list of (homeBillboardSlides, projects) has not changed then do not requery again:-
  // (homeBillboardSlides) => homeBillboardSlides.list.filter(homeBillboardSlide => !homeBillboardSlide.B_Active)
  homeBillboardSlides => homeBillboardSlides.list
);

export const changeHomeBillboardSlide = (
  homeBillboardSlide,
  user,
  currentDate
) =>
  apiCallBegan({
    url: url + "/" + homeBillboardSlide.ID,
    method: "put",
    onStart: homeBillboardSlideChangeRequested.type,
    data: {
      N_OrderNo: homeBillboardSlide.N_OrderNo,
      N_RadioGrpDirections_ID: homeBillboardSlide.N_RadioGrpDirections_ID,
      N_HomeBillboards_ID: homeBillboardSlide.N_HomeBillboards_ID,
      A_Reference: homeBillboardSlide.A_Reference,
      A_Summary: homeBillboardSlide.A_Summary,
      A_Description: homeBillboardSlide.A_Description,
      A_HoverSummary01: homeBillboardSlide.A_HoverSummary01,
      A_HoverSummary01_Link: homeBillboardSlide.A_HoverSummary01_Link,
      A_HoverSummary02: homeBillboardSlide.A_HoverSummary02,
      A_HoverSummary02_Link: homeBillboardSlide.A_HoverSummary02_Link,
      A_HoverSummary03: homeBillboardSlide.A_HoverSummary03,
      A_HoverSummary03_Link: homeBillboardSlide.A_HoverSummary03_Link,
      A_HoverSummary04: homeBillboardSlide.A_HoverSummary04,
      A_HoverSummary04_Link: homeBillboardSlide.A_HoverSummary04_Link,
      A_HoverSummary05: homeBillboardSlide.A_HoverSummary05,
      A_HoverSummary05_Link: homeBillboardSlide.A_HoverSummary05_Link,
      A_HoverSummary06: homeBillboardSlide.A_HoverSummary06,
      A_HoverSummary07: homeBillboardSlide.A_HoverSummary07,
      A_HoverSummary08: homeBillboardSlide.A_HoverSummary08,
      A_HoverSummary08_Link: homeBillboardSlide.A_HoverSummary08_Link,
      I_Image: homeBillboardSlide.I_Image,
      D_Updated: currentDate,
      A_Updated_By: user
    },
    onSuccess: homeBillboardSlideChanged.type,
    onError: homeBillboardSlideChangeFailed.type
  });

export const deleteHomeBillboardSlide = homeBillboardSlide =>
  apiCallBegan({
    url: url + "/" + homeBillboardSlide.ID,
    method: "delete",
    onStart: homeBillboardSlideDeleteRequested.type,
    data: {
      ID: homeBillboardSlide.ID,
      A_Reference: homeBillboardSlide.A_Reference
    },
    onSuccess: homeBillboardSlideDeleted.type,
    onError: homeBillboardSlideDeleteFailed.type
  });
