import React, { Component } from "react";
import Joi from "joi-browser";
import Input from "./input";
import InputColourPicker from "./inputColourPicker";
import InputEdit from "./inputEdit";
import InputRTL from "./inputRTL";
import TextArea from "./textArea";
import InputRememberMe from "./inputRememberMe";
import InputPassword from "./inputPassword";
import InputProfileEmail from "./inputProfileEmail";
import Select from "./select";
import RadioGroup from "./radioGroup";
// import { getIfUserExists, getIfMobileIsCut } from "../../services/userService";
import zxcvbn from "zxcvbn";
// import { convertToRaw, RichUtils } from "draft-js";
// import { Editor } from "../../../node_modules/react-draft-wysiwyg";
// import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

class Form extends Component {
  //state = { data: {}, errors: {}, editorState: EditorState.createEmpty() };

  validate = () => {
    // const result = Joi.validate(this.state.data, this.schema, {
    //   abortEarly: false
    // });
    //console.log("validate : ", this.state.data);
    // if (!result.error) return null;
    // Babak's Note: Object destructering from above to below
    const options = { abortEarly: false };
    const indentifier = this.props.match.params.id;
    const { error } = Joi.validate(
      this.state.data,
      indentifier != "new" ? this.schemaEdit : this.schemaCreate,
      options
    );
    console.log("Hell0111111111111111111111 : ", error);
    if (!error) return null;
    //console.log("Hell022222 : ", error);
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    //const obj = { username: value };
    // Babak's Note: Changed above line to the below for 'Computed Properties' :-

    const obj = { [name]: value };
    const indentifier = this.props.match.params.id;
    //console.log("Helloooooooooooo222222 : ", name, indentifier);
    const schema = {
      [name]:
        indentifier != "new" ? this.schemaEdit[name] : this.schemaCreate[name]
    };

    const { error } = Joi.validate(obj, schema);

    // if (error) return null;
    // return error.details[0].message;
    // Babak's Note: Changed above two lines to the below for 'Turner Operater' :-
    return error ? error.details[0].message : null;

    // if (name === "username") {
    //   if (value.trim() === "") return "Username is required";
    //   // other rules...
    // }
    // if (name === "password") {
    //   if (value.trim() === "") return "Password is required";
    //   // other rules...
    // }
  };

  validatePropertyPhoto = (name, value) => {
    //const obj = { username: value };
    // Babak's Note: Changed above line to the below for 'Computed Properties' :-

    const obj = { [name]: value };
    const indentifier = this.props.match.params.id;
    //console.log("Helloooooooooooo111111 : ", name, indentifier);
    const schema = {
      [name]:
        indentifier != "new" ? this.schemaEdit[name] : this.schemaCreate[name]
    };

    const { error } = Joi.validate(obj, schema);

    // if (error) return null;
    // return error.details[0].message;
    // Babak's Note: Changed above two lines to the below for 'Turner Operater' :-
    return error ? error.details[0].message : null;

    // if (name === "username") {
    //   if (value.trim() === "") return "Username is required";
    //   // other rules...
    // }
    // if (name === "password") {
    //   if (value.trim() === "") return "Password is required";
    //   // other rules...
    // }
  };

  validatePropertyPhoto02 = (name, value) => {
    //const obj = { username: value };
    // Babak's Note: Changed above line to the below for 'Computed Properties' :-

    const obj = { [name]: value };
    const indentifier = this.props.match.params.id;
    //console.log("Helloooooooooooo111111 : ", name, indentifier);
    const schema = {
      [name]:
        indentifier != "new" ? this.schemaEdit[name] : this.schemaCreate[name]
    };

    const { error } = Joi.validate(obj, schema);

    // if (error) return null;
    // return error.details[0].message;
    // Babak's Note: Changed above two lines to the below for 'Turner Operater' :-
    return error ? error.details[0].message : null;

    // if (name === "username") {
    //   if (value.trim() === "") return "Username is required";
    //   // other rules...
    // }
    // if (name === "password") {
    //   if (value.trim() === "") return "Password is required";
    //   // other rules...
    // }
  };

  handleSubmit = e => {
    e.preventDefault();

    //console.log("Hell03333 : ");

    const errors = this.validate();
    //console.log("What's the error : ", errors);
    this.setState({ errors: errors || {} });
    if (errors) return;

    //const username = this.username.current.value;

    console.log("handleSubmit, What's the error : ", errors);

    this.doSubmit();
  };

  //handleChange = e => { -- Removed 'e' to work with version 3 below
  handleChange = ({ currentTarget: input }) => {
    //console.log("handleChange1: ", input);
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    //console.log("handleChange2: ", data);
    //
    // Babak's Note: -- 1.Hard code version
    //data.username = e.currentTarget.value;

    //
    // Babak's Note: -- 2.Dynamic version
    //data[e.currentTarget.name] = e.currentTarget.value;

    //
    // Babak's Note: -- 3.Even better version
    data[input.name] = input.value;
    //console.log("handleChange3: ", input.name, data[input.name], input.value);
    this.setState({ data, errors });
  };

  handleChangeGoogleMap = ({ currentTarget: input }) => {
    //console.log("handleChange1: ", input);
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    //console.log("handleChange2: ", data);
    //
    // Babak's Note: -- 1.Hard code version
    //data.username = e.currentTarget.value;

    //
    // Babak's Note: -- 2.Dynamic version
    //data[e.currentTarget.name] = e.currentTarget.value;

    //
    // Babak's Note: -- 3.Even better version
    data[input.name] = input.value;
    //console.log("handleChange3: ", input.name, data[input.name], input.value);
    this.setState({ data, errors });
  };

  // handleKeyCommand(command) {
  //   const { editorState } = this.state;
  //   const newState = RichUtils.handleKeyCommand(editorState, command);
  //   if (newState) {
  //     this.onEditorStateChange(newState);
  //     return true;
  //   }
  //   return false;
  // }

  handleCheckboxChange = ({ currentTarget: input }) => {
    //console.log("handleCheckboxChange1: ", input);
    //const errors = { ...this.state.errors };
    //const errorMessage = this.validateProperty(input);
    //if (errorMessage) errors[input.name] = errorMessage;
    //else delete errors[input.name];

    const data = { ...this.state.data };
    // console.log("handleCheckboxChange: ", data[input.name]);

    if (data[input.name]) data[input.name] = false;
    else data[input.name] = true;

    //console.log("handleCheckboxChange3: ", data[input.name]);
    //data[input.name] = input.value;
    // console.log(
    //   "handleCheckboxChange3: ",
    //   input.name,
    //   data[input.name],
    //   input.value
    // );
    this.setState({ data });
  };

  handleCheckboxChangeHoverSummary01_Link = ({ currentTarget: input }) => {
    //console.log("handleCheckboxChange1: ", input);
    //const errors = { ...this.state.errors };
    //const errorMessage = this.validateProperty(input);
    //if (errorMessage) errors[input.name] = errorMessage;
    //else delete errors[input.name];

    const data = { ...this.state.data };
    let B_HoverSummary01_Linked = false;
    // console.log("handleCheckboxChange: ", data[input.name]);

    if (data[input.name]) {
      data[input.name] = false;
      B_HoverSummary01_Linked = false;
    } else {
      data[input.name] = true;
      B_HoverSummary01_Linked = true;
    }

    //console.log("handleCheckboxChange3: ", data[input.name]);
    //data[input.name] = input.value;
    // console.log(
    //   "handleCheckboxChange3: ",
    //   input.name,
    //   data[input.name],
    //   input.value
    // );
    this.setState({ data, B_HoverSummary01_Linked });
  };

  readUploadedFileAsText = inputFile => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsDataURL(inputFile);
      //temporaryFileReader.readAsText(inputFile);
    });
  };

  handleImageFileChange = async ({ currentTarget: input }) => {
    //
    // Babak's Note: This website will show what 'https://www.youtube.com/watch?v=XeiOnkEI7XI' is for:-
    // console.log("handleImageFileChange1: ", input.files[0]);
    //console.log("handleImageFileChange2: ", input.files);
    //console.log("handleImageFileChange1: ", input.className);
    const errors = { ...this.state.errors };

    const name = "N_PhotoSize01";
    const value = input.files[0].size;
    const errorMessage = this.validatePropertyPhoto(name, value);

    const data = { ...this.state.data };

    //if (!input || !input.files || input.files[0].size > 40000) {
    if (errorMessage) {
      input.value = "";
      input.className = "form-control innerTDButtonAvailable";
      errors[input.name] = "Uploaded file is too large, 40kb maximum limit.";
    } else {
      delete errors[input.name];
      input.className = "form-control";
    }

    // errors[input.name] = "Uploaded file is too large";
    // data[input.name] = "";
    //} else {
    this.setState({ waitingForFileUpload: true });

    const fileList = input.files;

    // Uploads will push to the file input's `.files` array. Get the last uploaded file.
    const latestUploadedFile = fileList.item(fileList.length - 1);

    try {
      // window.alert("Hello ");

      const fileContents = await this.readUploadedFileAsText(
        latestUploadedFile
      );
      //const fileContents = URL.createObjectURL(input.files[0]);
      //delete errors[input.name];

      data[input.name] = fileContents;
      data.N_PhotoSize01 = input.files[0].size;
      this.setState({
        waitingForFileUpload: false
      });
    } catch (e) {
      //console.log(e);
      errors[input.name] = "Uploaded file is tooooooooooooo large : ";
      data[input.name] = "";
      this.setState({
        waitingForFileUpload: false
      });
      // }

      //errors[input.name] = "";

      //data[input.name] = input.files[0];
      //console.log("handleImageFileChange3: ", data[input.name]);

      // let reader = new FileReader();
      // reader.readAsDataURL(input.files[0]);

      // reader.onload = e => {
      //   console.warn("img data : ", e.target.result);
      //   const formData = { file: e.target.result };
      //   console.log("handleImageFileChange4: ", formData);
      // };
    }

    //console.log("handleImageFileChange4: ", data[input.name]);

    //this.setState({ I_Photo01: input.files[0] });
    this.setState({ data, errors });
  };

  handleImageOnImageFileChange = async ({ currentTarget: input }) => {
    //
    // Babak's Note: This website will show what 'https://www.youtube.com/watch?v=XeiOnkEI7XI' is for:-
    // console.log("handleImageFileChange1: ", input.files[0]);
    //console.log("handleImageFileChange2: ", input.files);
    //console.log("handleImageFileChange1: ", input.className);
    const errors = { ...this.state.errors };

    const name = "N_PhotoSize02";
    const value = input.files[0].size;
    const errorMessage = this.validatePropertyPhoto02(name, value);

    const data = { ...this.state.data };

    //if (!input || !input.files || input.files[0].size > 40000) {
    if (errorMessage) {
      input.value = "";
      input.className = "form-control innerTDButtonAvailable";
      errors[input.name] = "Uploaded file is too large, 40kb maximum limit.";
    } else {
      delete errors[input.name];
      input.className = "form-control";
    }

    // errors[input.name] = "Uploaded file is too large";
    // data[input.name] = "";
    //} else {
    this.setState({ waitingForFileUpload02: true });

    const fileList = input.files;

    // Uploads will push to the file input's `.files` array. Get the last uploaded file.
    const latestUploadedFile = fileList.item(fileList.length - 1);

    try {
      // window.alert("Hello ");

      const fileContents = await this.readUploadedFileAsText(
        latestUploadedFile
      );
      //const fileContents = URL.createObjectURL(input.files[0]);
      //delete errors[input.name];

      data[input.name] = fileContents;
      data.N_PhotoSize02 = input.files[0].size;
      this.setState({
        waitingForFileUpload02: false
      });
    } catch (e) {
      //console.log(e);
      errors[input.name] = "Uploaded file is tooooooooooooo large : ";
      data[input.name] = "";
      this.setState({
        waitingForFileUpload02: false
      });
      // }

      //errors[input.name] = "";

      //data[input.name] = input.files[0];
      //console.log("handleImageFileChange3: ", data[input.name]);

      // let reader = new FileReader();
      // reader.readAsDataURL(input.files[0]);

      // reader.onload = e => {
      //   console.warn("img data : ", e.target.result);
      //   const formData = { file: e.target.result };
      //   console.log("handleImageFileChange4: ", formData);
      // };
    }

    //console.log("handleImageFileChange4: ", data[input.name]);

    //this.setState({ I_Photo01: input.files[0] });
    this.setState({ data, errors });
  };

  handleRegisterEmailFocusout = async ({ currentTarget: input }) => {
    //console.log("handleRegisterEmailFocusout1: ", input);
    // try {
    //   if (input.value) {
    //     const userFound = await getIfUserExists(input.value);
    //   }
    //   //console.log("handleRegisterEmailFocusout2: ", userFound);
    // } catch (ex) {
    //   if (ex.response && ex.response.status === 400) {
    //     const errors = { ...this.state.errors };
    //     errors.username = ex.response.data;
    //     this.setState({ errors });
    //     input.focus();
    //   }
    // }
  };

  handleCreateEmailFocusout = async ({ currentTarget: input }) => {
    //console.log("handleCreateEmailFocusout1: ", input);
    // try {
    //   if (input.value) {
    //     const userFound = await getIfUserExists(input.value);
    //   }
    //   //console.log("handleCreateEmailFocusout2: ", userFound);
    // } catch (ex) {
    //   if (ex.response && ex.response.status === 400) {
    //     const errors = { ...this.state.errors };
    //     errors.A_Email = ex.response.data;
    //     this.setState({ errors });
    //     input.focus();
    //   }
    // }
  };

  handleMobileRegisterFocusout = async ({ currentTarget: input }) => {
    //console.log("handleMobileRegisterFocusout1: ", input);
    // try {
    //   if (input.value) {
    //     const mobileURL = `http://api.smartsms.ir/sms/send?userId=57674&password=123456@Aa&message=Hello&recipient=${input.value}&originator=500059326511`;
    //     const userFound = await getIfMobileIsCut(mobileURL);
    //     // fetch(
    //     //   `http://api.smartsms.ir/sms/send?userId=57674&password=123456@Aa&message=Hello&recipient=${
    //     //     input.value
    //     //   }&originator=500059326511`,
    //     //   {
    //     //     mode: "no-cors"
    //     //   }
    //     // ).then(response => {
    //     //   //if (response.ok) {
    //     //   // response.json().then(json => {
    //     //   console.log(response);
    //     //   // });
    //     //   //}
    //     // });
    //   }
    // } catch (ex) {
    //   if (ex.response && ex.response.status === 400) {
    //     const errors = { ...this.state.errors };
    //     errors.mobile = ex.response.data;
    //     this.setState({ errors });
    //   }
    // }
  };

  handleMobileCreateFocusout = async ({ currentTarget: input }) => {
    //console.log("handleMobileCreateFocusout1: ", input);
    // try {
    //   if (input.value) {
    //     const mobileURL = `http://api.smartsms.ir/sms/send?userId=57674&password=123456@Aa&message=Hello&recipient=${input.value}&originator=500059326511`;
    //     const userFound = await getIfMobileIsCut(mobileURL);
    //     // fetch(
    //     //   `http://api.smartsms.ir/sms/send?userId=57674&password=123456@Aa&message=Hello&recipient=${
    //     //     input.value
    //     //   }&originator=500059326511`,
    //     //   {
    //     //     mode: "no-cors"
    //     //   }
    //     // ).then(response => {
    //     //   //if (response.ok) {
    //     //   // response.json().then(json => {
    //     //   console.log(response);
    //     //   // });
    //     //   //}
    //     // });
    //   }
    // } catch (ex) {
    //   if (ex.response && ex.response.status === 400) {
    //     const errors = { ...this.state.errors };
    //     errors.N_Mobile = ex.response.data;
    //     this.setState({ errors });
    //   }
    // }
  };

  renderButton(label) {
    return (
      <button disabled={this.validate()} className="btn btn-primary">
        {label}
      </button>
    );
  }

  renderButtonCancel(label) {
    return (
      <button
        style={{ marginLeft: "20px" }}
        className="btn btn-link"
        onClick={this.cancelForm}
      >
        {label}
      </button>
    );
  }

  renderSelect(name, label, options) {
    const { data, errors } = this.state;
    //console.log("State : ", name, data[name], options, data);

    return (
      <Select
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  handleRadioGroupChange = (name, event) => {
    let data = this.state.data;

    //console.log("handleRadioGroupChange : ", event.target.value);

    data[name] = Number(event.target.value);

    this.setState({
      data,
      [name]: Number(event.target.value)
    });
  };

  renderRadioGroup(name, columnName, label, options, disableRadio) {
    const { data, errors } = this.state;
    // console.log(
    //   "renderRadioGroup : ",
    //   this.state[name],
    //   this.state.data[name],
    //   options,
    //   disableRadio
    // );

    return (
      <RadioGroup
        name={name}
        columnName={columnName}
        value={data[name]}
        label={label}
        options={options}
        disableRadio={disableRadio}
        onChange={this.handleRadioGroupChange.bind(this, name)}
        // error={errors[name]}
      />
    );
  }

  handleRadioGroupDirectionsOnChange = (name, event) => {
    // window.alert(`Hello : ${changeEvent.target.value}`);
    let obj = this.state.data;

    obj[name] = Number(event.target.value);

    obj["A_HoverSummary01"] = "";
    obj["A_HoverSummary02"] = "";
    obj["A_HoverSummary03"] = "";
    obj["A_Reference"] = "";
    obj["A_Summary"] = "";
    obj["A_Description"] = "";

    obj["N_RadioGrpDirections_ID"] = Number(event.target.value);

    this.setState({
      data: obj,
      [name]: Number(event.target.value)
    });
  };

  renderRadioGroupDirections(name, columnName, label, options, disableRadio) {
    const { data, errors } = this.state;
    // console.log(
    //   "renderRadioGroup : ",
    //   this.state[name],
    //   this.state.data[name],
    //   options,
    //   disableRadio
    // );

    return (
      <RadioGroup
        name={name}
        columnName={columnName}
        value={data[name]}
        label={label}
        options={options}
        disableRadio={disableRadio}
        onChange={this.handleRadioGroupDirectionsOnChange.bind(this, name)}
        // error={errors[name]}
      />
    );
  }

  renderInputRegisterEmail(name, label, type = "text") {
    //
    // Babak's Note: Below is called object destructing. This means we can remove where ever there is text
    //               of 'this.state'
    const { data, errors } = this.state;

    //console.log("renderInput : ", this.state.data, data, data[name]);

    return (
      <Input
        autoFocus
        onBlur={this.handleRegisterEmailFocusout}
        type={type}
        name={name}
        value={data[name]}
        label={label}
        placeholder={label}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  renderInputProfileEmail(name, label, type = "text") {
    //
    // Babak's Note: Below is called object destructing. This means we can remove where ever there is text
    //               of 'this.state'
    const { data, errors } = this.state;

    //console.log("renderInput : ", this.state.data, data, data[name]);

    return (
      <InputProfileEmail
        disabled={true}
        type={type}
        name={name}
        value={data[name]}
        label={label}
        placeholder={label}
        error={errors[name]}
      />
    );
  }

  renderPasswordProfileInput(name, label, type = "text") {
    //
    // Babak's Note: Below is called object destructing. This means we can remove where ever there is text
    //               of 'this.state'
    const { data, errors } = this.state;

    //console.log("renderInput : ", this.state.data, data, data[name]);

    return (
      <InputPassword
        autoFocus
        type={this.state.type}
        name={name}
        value={data[name]}
        label={label}
        placeholder={label}
        onChange={this.handleChangePasswordStrength}
        // error={errors[name]}
      />
    );
  }

  renderInputCreateEmail(name, label, type = "text") {
    //
    // Babak's Note: Below is called object destructing. This means we can remove where ever there is text
    //               of 'this.state'
    const { data, errors } = this.state;

    //console.log("renderInput : ", this.state.data, data, data[name]);

    return (
      <Input
        autoFocus
        onBlur={this.handleCreateEmailFocusout}
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  renderInputEditEmail(name, label, type = "text") {
    //
    // Babak's Note: Below is called object destructing. This means we can remove where ever there is text
    //               of 'this.state'
    const { data, errors } = this.state;

    //console.log("renderInput : ", this.state.data, data, data[name]);

    return (
      <InputEdit
        autoFocus
        type={type}
        name={name}
        value={data[name]}
        label={label}
        disabled
        error={errors[name]}
      />
    );
  }

  renderInputRegisterMobile(name, label, type = "text") {
    //
    // Babak's Note: Below is called object destructing. This means we can remove where ever there is text
    //               of 'this.state'
    const { data, errors } = this.state;

    // console.log(
    //   "renderInputRegisterMobile : ",
    //   this.state.data,
    //   data,
    //   data[name]
    // );

    return (
      <Input
        onBlur={this.handleMobileRegisterFocusout}
        type={type}
        name={name}
        value={data[name]}
        label={label}
        placeholder={label}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  renderInputCreateMobile(name, label, type = "text") {
    //
    // Babak's Note: Below is called object destructing. This means we can remove where ever there is text
    //               of 'this.state'
    const { data, errors } = this.state;

    // console.log(
    //   "renderInputCreateMobile : ",
    //   this.state.data,
    //   data,
    //   data[name]
    // );

    return (
      <Input
        onBlur={this.handleMobileCreateFocusout}
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  renderInputLoginFocus(name, label, type = "text") {
    //
    // Babak's Note: Below is called object destructing. This means we can remove where ever there is text
    //               of 'this.state'
    const { data, errors } = this.state;

    console.log(
      "renderInputLoginFocus : ",
      this.state.data,
      data,
      data[name],
      errors[name],
      errors
    );

    return (
      <Input
        autoFocus
        type={type}
        name={name}
        value={data[name]}
        label={label}
        placeholder={label}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  handleRegisterPasswordConfirmFocusout = async ({ currentTarget: input }) => {
    //console.log("handleRegisterPasswordConfirmFocusout1: ", input);

    const { A_Password, passwordConfirm } = this.state.data;

    try {
      if (A_Password !== passwordConfirm) {
        //alert("Passwords don't match");
        const errors = { ...this.state.errors };
        errors.passwordConfirm = "Passwords don't match";
        this.setState({ errors });
        //console.log("handleRegisterPasswordConfirmFocusout2: ", userFound);
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.A_Email = ex.response.data;
        this.setState({ errors });
        input.focus();
      }
    }
  };

  renderInputRegisterPasswordConfirm(name, label, type = "text") {
    //
    // Babak's Note: Below is called object destructing. This means we can remove where ever there is text
    //               of 'this.state'
    const { data, errors } = this.state;

    // console.log(
    //   "renderRegisterInputPasswordConfirm : ",
    //   this.state.data,
    //   data,
    //   data[name]
    // );

    return (
      <Input
        onBlur={this.handleRegisterPasswordConfirmFocusout}
        type={type}
        name={name}
        value={data[name]}
        label={label}
        placeholder={label}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  handleCreatePasswordConfirmFocusout = async ({ currentTarget: input }) => {
    //console.log("handleCreatePasswordConfirmFocusout1: ", input);

    const { A_Password, passwordConfirm } = this.state.data;

    try {
      if (A_Password !== passwordConfirm) {
        //alert("Passwords don't match");
        const errors = { ...this.state.errors };
        errors.passwordConfirm = "Passwords don't match";
        this.setState({ errors });
        //console.log("handleCreatePasswordConfirmFocusout2: ", userFound);
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.A_Email = ex.response.data;
        this.setState({ errors });
        input.focus();
      }
    }
  };

  renderInputCreatePasswordConfirm(name, label, type = "text") {
    //
    // Babak's Note: Below is called object destructing. This means we can remove where ever there is text
    //               of 'this.state'
    const { data, errors } = this.state;

    // console.log(
    //   "renderInputCreatePasswordConfirm : ",
    //   this.state.data,
    //   data,
    //   data[name]
    // );

    return (
      <Input
        onBlur={this.handleCreatePasswordConfirmFocusout}
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  // onEditorStateChange({ currentTarget: input }) {
  //   const data = { ...this.state.data };

  //   data[input.name] = input.value;

  //   this.setState({ data });
  // }

  // _imageUploadCallBackSummary(file) {
  //   // long story short, every time we upload an image, we
  //   // need to save it to the state so we can get it's data
  //   // later when we decide what to do with it.

  //   // Make sure you have a uploadImages: [] as your default state
  //   let uploadedImages = this.state.uploadedImagesSummary;

  //   const imageObject = {
  //     file: file,
  //     localSrc: URL.createObjectURL(file)
  //   };

  //   uploadedImages.push(imageObject);

  //   this.setState({ uploadedImagesSummary: uploadedImages });

  //   // We need to return a promise with the image src
  //   // the img src we will use here will be what's needed
  //   // to preview it in the browser. This will be different than what
  //   // we will see in the index.md file we generate.
  //   return new Promise((resolve, reject) => {
  //     resolve({ data: { link: imageObject.localSrc } });
  //   });
  // }
  //
  // Babak's Note: Above we store only the URL of the image rather than the accual image, while below we store the image it self:-
  // _imageUploadCallBackSummary = file =>
  //   new Promise((resolve, reject) => {
  //     let reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     let img = new Image();
  //     // let url = ''
  //     reader.onload = function(e) {
  //       img.src = this.result;
  //       resolve({
  //         data: {
  //           link: img.src
  //         }
  //       });
  //     };
  //   });
  //
  // Babak's Note: Below is the compressed version of above:-
  _imageUploadCallBackSummary = file =>
    new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      let img = new Image();
      // let url = ''
      reader.onload = function(e) {
        img.src = this.result;
      };
      img.onload = function() {
        // console.log(img.src.length)
        // Zoom the canvas needed for the image (you can also define the canvas tag directly in the DOM, so that the compressed image can be directly displayed without going to base64)
        let canvas = document.createElement("canvas");
        let context = canvas.getContext("2d");

        // image original size
        let originWidth = this.width;
        let originHeight = this.height;

        // Maximum size limit, you can achieve image compression by setting the width and height
        let maxWidth = 950,
          maxHeight = 950;
        // target size
        let targetWidth = originWidth,
          targetHeight = originHeight;
        // Image size exceeds 300x300 limit
        if (originWidth > maxWidth || originHeight > maxHeight) {
          if (originWidth / originHeight > maxWidth / maxHeight) {
            // wider, size limited by width
            targetWidth = maxWidth;
            targetHeight = Math.round(maxWidth * (originHeight / originWidth));
          } else {
            targetHeight = maxHeight;
            targetWidth = Math.round(maxHeight * (originWidth / originHeight));
          }
        }
        // canvas scales the image
        canvas.width = targetWidth;
        canvas.height = targetHeight;
        // clear the canvas
        context.clearRect(0, 0, targetWidth, targetHeight);
        // Image Compression
        context.drawImage(img, 0, 0, targetWidth, targetHeight);
        /* The first parameter is the created img object; the second three parameters are the upper left corner coordinates, and the second two are the canvas area width and height */

        // compressed image to base64 url
        /*canvas.toDataURL(mimeType, qualityArgument), mimeType The default value is 'image/png';
         * qualityArgument indicates the quality of the exported image. This parameter is valid only when exported to jpeg and webp formats. The default value is 0.92*/
        let newUrl = canvas.toDataURL("image/jpeg", 0.92); //base64 format
        // console.log(newUrl.length)

        resolve({
          data: {
            link: newUrl
          }
        });
        // You can also convert the compressed image to blob format for uploading
        // canvas.toBlob((blob)=>{
        //     console.log(blob)
        // // Pass the blob as a parameter to the backend
        // }, 'image/jpeg', 0.92)
      };
    });

  // onEditorStateChangeSummary = editorState => {
  //   //window.alert(`editorState : ${editorState}`);

  //   //console.log("onEditorStateChange : ", editorState);

  //   const raw = convertToRaw(editorState.getCurrentContent());

  //   const data = { ...this.state.data };
  //   data["A_Summary"] = JSON.stringify(raw);

  //   // const raw = convertToRaw(this.state.editorState.getCurrentContent());
  //   // // console.log(
  //   // //   "onEditorStateChange : ",
  //   // //   JSON.stringify(raw.blocks.map(el => el.text))
  //   // // );

  //   // const data = { ...this.state.data };
  //   // // data["A_Description"] = JSON.stringify(raw.blocks.map(el => el.text));
  //   // // data["A_Description"] = JSON.stringify(raw.blocks[0].text);
  //   // data["A_Description"] = JSON.stringify(raw);
  //   // //editorState = JSON.stringify(raw);

  //   this.setState({
  //     data,
  //     A_Summary: editorState
  //   });
  // };

  // renderInputControlledEditorSummary(name, label, type = "text") {
  //   //
  //   // Babak's Note: Below is called object destructing. This means we can remove where ever there is text
  //   //               of 'this.state'
  //   const { data, A_Summary } = this.state;

  //   //const raw = convertToRaw(this.state.editorState.getCurrentContent());

  //   // console.log(
  //   //   "renderInputControlledEditor -> data : ",
  //   //   editorState,
  //   //   data["A_Description"]
  //   // );

  //   //const raw = convertToRaw(this.state.editorState.getCurrentContent());
  //   //console.log("onEditorStateChange : ", JSON.stringify(raw));
  //   //const raw = convertToRaw(this.state.editorState.getCurrentContent());

  //   // window.alert(`handle : innerTDButtonOther : ${date}`);
  //   //console.log("renderInput : ", editorState);

  //   //data["A_Description"] = editorState;

  //   return (
  //     <div>
  //       <label>{label}</label>
  //       <Editor
  //         //
  //         // Babak's Note: Below line did not wotk well, shift return keys work better to stop too much vertical
  //         //               space between lines:-
  //         // editorStyle={{ lineHeight: "75%" }}
  //         editorState={A_Summary}
  //         wrapperClassName="demo-wrapper"
  //         editorClassName="demo-editor"
  //         //onEditorStateChange={this.onEditorStateChange.bind(this)}
  //         onEditorStateChange={this.onEditorStateChangeSummary}
  //         readOnly={this.state.N_RadioGrpDirection === "1" ? true : false}
  //         toolbar={{
  //           inline: { inDropdown: true },
  //           list: { inDropdown: true },
  //           textAlign: { inDropdown: true },
  //           link: { inDropdown: true },
  //           history: { inDropdown: true },
  //           image: {
  //             uploadCallback: this._imageUploadCallBackSummary,
  //             urlEnabled: false,
  //             uploadEnabled: true,
  //             alignmentEnabled: true, // Whether to display the arrange button is equivalent to text-align
  //             previewImage: true,
  //             inputAccept: "image/*",
  //             alt: { present: false, mandatory: false }
  //           },
  //           inputAccept:
  //             "application/pdf,text/plain,application/vnd.openxmlformatsofficedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel"
  //         }}
  //       />
  //     </div>
  //   );
  // }

  // _imageUploadCallBackDescription = file =>
  //   new Promise((resolve, reject) => {
  //     let reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     let img = new Image();
  //     // let url = ''
  //     reader.onload = function(e) {
  //       img.src = this.result;
  //       resolve({
  //         data: {
  //           link: img.src
  //         }
  //       });
  //     };
  //   });
  //
  // Babak's Note: Below is the compressed version of above:-
  _imageUploadCallBackDescription = file =>
    new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      let img = new Image();
      // let url = ''
      reader.onload = function(e) {
        img.src = this.result;
      };
      img.onload = function() {
        // console.log(img.src.length)
        // Zoom the canvas needed for the image (you can also define the canvas tag directly in the DOM, so that the compressed image can be directly displayed without going to base64)
        let canvas = document.createElement("canvas");
        let context = canvas.getContext("2d");

        // image original size
        let originWidth = this.width;
        let originHeight = this.height;

        // Maximum size limit, you can achieve image compression by setting the width and height
        let maxWidth = 950,
          maxHeight = 950;
        // target size
        let targetWidth = originWidth,
          targetHeight = originHeight;
        // Image size exceeds 300x300 limit
        if (originWidth > maxWidth || originHeight > maxHeight) {
          if (originWidth / originHeight > maxWidth / maxHeight) {
            // wider, size limited by width
            targetWidth = maxWidth;
            targetHeight = Math.round(maxWidth * (originHeight / originWidth));
          } else {
            targetHeight = maxHeight;
            targetWidth = Math.round(maxHeight * (originWidth / originHeight));
          }
        }
        // canvas scales the image
        canvas.width = targetWidth;
        canvas.height = targetHeight;
        // clear the canvas
        context.clearRect(0, 0, targetWidth, targetHeight);
        // Image Compression
        context.drawImage(img, 0, 0, targetWidth, targetHeight);
        /* The first parameter is the created img object; the second three parameters are the upper left corner coordinates, and the second two are the canvas area width and height */

        // compressed image to base64 url
        /*canvas.toDataURL(mimeType, qualityArgument), mimeType The default value is 'image/png';
         * qualityArgument indicates the quality of the exported image. This parameter is valid only when exported to jpeg and webp formats. The default value is 0.92*/
        let newUrl = canvas.toDataURL("image/jpeg", 0.92); //base64 format
        // console.log(newUrl.length)

        resolve({
          data: {
            link: newUrl
          }
        });
        // You can also convert the compressed image to blob format for uploading
        // canvas.toBlob((blob)=>{
        //     console.log(blob)
        // // Pass the blob as a parameter to the backend
        // }, 'image/jpeg', 0.92)
      };
    });

  // onEditorStateChangeDescription = editorState => {
  //   //window.alert(`editorState : ${editorState}`);

  //   //console.log("onEditorStateChange : ", editorState);

  //   const raw = convertToRaw(editorState.getCurrentContent());

  //   const data = { ...this.state.data };
  //   data["A_Description"] = JSON.stringify(raw);

  //   // const raw = convertToRaw(this.state.editorState.getCurrentContent());
  //   // // console.log(
  //   // //   "onEditorStateChange : ",
  //   // //   JSON.stringify(raw.blocks.map(el => el.text))
  //   // // );

  //   // const data = { ...this.state.data };
  //   // // data["A_Description"] = JSON.stringify(raw.blocks.map(el => el.text));
  //   // // data["A_Description"] = JSON.stringify(raw.blocks[0].text);
  //   // data["A_Description"] = JSON.stringify(raw);
  //   // //editorState = JSON.stringify(raw);

  //   this.setState({
  //     data,
  //     A_Description: editorState
  //   });
  // };

  // renderInputControlledEditorDescription(name, label, type = "text") {
  //   //
  //   // Babak's Note: Below is called object destructing. This means we can remove where ever there is text
  //   //               of 'this.state'
  //   const { data, A_Description } = this.state;

  //   //const raw = convertToRaw(this.state.editorState.getCurrentContent());

  //   // console.log(
  //   //   "renderInputControlledEditor -> data : ",
  //   //   editorState,
  //   //   data["A_Description"]
  //   // );

  //   //const raw = convertToRaw(this.state.editorState.getCurrentContent());
  //   //console.log("onEditorStateChange : ", JSON.stringify(raw));
  //   //const raw = convertToRaw(this.state.editorState.getCurrentContent());

  //   // window.alert(`handle : innerTDButtonOther : ${date}`);
  //   //console.log("renderInput : ", editorState);

  //   //data["A_Description"] = editorState;

  //   return (
  //     <div>
  //       <label>{label}</label>
  //       <Editor
  //         //
  //         // Babak's Note: Below line did not wotk well, shift return keys work better to stop too much vertical
  //         //               space between lines:-
  //         // editorStyle={{ lineHeight: "75%" }}
  //         editorState={A_Description}
  //         wrapperClassName="demo-wrapper"
  //         editorClassName="demo-editor"
  //         //onEditorStateChange={this.onEditorStateChange.bind(this)}
  //         onEditorStateChange={this.onEditorStateChangeDescription}
  //         readOnly={this.state.N_RadioGrpDirection === "1" ? true : false}
  //         toolbar={{
  //           inline: { inDropdown: true },
  //           list: { inDropdown: true },
  //           textAlign: { inDropdown: true },
  //           link: { inDropdown: true },
  //           history: { inDropdown: true },
  //           image: {
  //             uploadCallback: this._imageUploadCallBackDescription,
  //             urlEnabled: false,
  //             uploadEnabled: true,
  //             alignmentEnabled: true, // Whether to display the arrange button is equivalent to text-align
  //             previewImage: true,
  //             inputAccept: "image/*",
  //             alt: { present: false, mandatory: false }
  //           },
  //           inputAccept:
  //             "application/pdf,text/plain,application/vnd.openxmlformatsofficedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel"
  //         }}
  //       />
  //     </div>
  //   );
  // }

  renderInputBillboardLargeLink(name, label, type = "text") {
    //
    // Babak's Note: Below is called object destructing. This means we can remove where ever there is text
    //               of 'this.state'
    const { data, errors } = this.state;

    //console.log("renderInput : ", this.state.data, data, data[name]);

    return (
      <Input
        type={type}
        name={name}
        value={data[name]}
        label={label}
        placeholder={label}
        onChange={this.handleChange}
        error={errors[name]}
        disabled={
          this.state.N_RadioGrpDirections_ID != "1" &&
          this.state.N_RadioGrpDirections_ID != "2" &&
          this.state.N_RadioGrpDirections_ID != "3"
            ? true
            : false
        }
      />
    );
  }

  renderInputReference(name, label, type = "text") {
    //
    // Babak's Note: Below is called object destructing. This means we can remove where ever there is text
    //               of 'this.state'
    const { data, errors } = this.state;

    //console.log("renderInput : ", this.state.data, data, data[name]);

    return (
      <Input
        type={type}
        name={name}
        value={data[name]}
        label={label}
        placeholder={label}
        onChange={this.handleChange}
        error={errors[name]}
        disabled={
          this.state.N_RadioGrpDirections_ID != "2" &&
          this.state.N_RadioGrpDirections_ID != "3" &&
          this.state.N_RadioGrpDirections_ID != "4" &&
          this.state.N_RadioGrpDirections_ID != "5" &&
          this.state.N_RadioGrpDirections_ID != "6" &&
          this.state.N_RadioGrpDirections_ID != "7" &&
          this.state.N_RadioGrpDirections_ID != "8" &&
          this.state.N_RadioGrpDirections_ID != "9" &&
          this.state.N_RadioGrpDirections_ID != "10"
            ? true
            : false
        }
      />
    );
  }

  renderInputSummary(name, label, type = "text") {
    //
    // Babak's Note: Below is called object destructing. This means we can remove where ever there is text
    //               of 'this.state'
    const { data, errors } = this.state;

    //console.log("renderInput : ", this.state.data, data, data[name]);

    return (
      <Input
        type={type}
        name={name}
        value={data[name]}
        label={label}
        placeholder={label}
        onChange={this.handleChange}
        error={errors[name]}
        disabled={
          this.state.N_RadioGrpDirections_ID != "2" &&
          this.state.N_RadioGrpDirections_ID != "3" &&
          this.state.N_RadioGrpDirections_ID != "4" &&
          this.state.N_RadioGrpDirections_ID != "5" &&
          this.state.N_RadioGrpDirections_ID != "6" &&
          this.state.N_RadioGrpDirections_ID != "7" &&
          this.state.N_RadioGrpDirections_ID != "8" &&
          this.state.N_RadioGrpDirections_ID != "9" &&
          this.state.N_RadioGrpDirections_ID != "10"
            ? true
            : false
        }
      />
    );
  }

  renderInputLinkButton(name, label, type = "text") {
    //
    // Babak's Note: Below is called object destructing. This means we can remove where ever there is text
    //               of 'this.state'
    const { data, errors } = this.state;

    //console.log("renderInput : ", this.state.data, data, data[name]);

    return (
      <Input
        type={type}
        name={name}
        value={data[name]}
        label={label}
        placeholder={label}
        onChange={this.handleChange}
        error={errors[name]}
        disabled={this.state.N_RadioGrpDirections_ID != "10" ? true : false}
      />
    );
  }

  renderInputButton(name, label, type = "text") {
    //
    // Babak's Note: Below is called object destructing. This means we can remove where ever there is text
    //               of 'this.state'
    const { data, errors } = this.state;

    //console.log("renderInput : ", this.state.data, data, data[name]);

    return (
      <Input
        type={type}
        name={name}
        value={data[name]}
        label={label}
        placeholder={label}
        onChange={this.handleChange}
        error={errors[name]}
        disabled={this.state.N_RadioGrpDirections_ID != "10" ? true : false}
      />
    );
  }

  renderInputDescription(name, label, type = "text") {
    //
    // Babak's Note: Below is called object destructing. This means we can remove where ever there is text
    //               of 'this.state'
    const { data, errors } = this.state;

    //console.log("renderInput : ", this.state.data, data, data[name]);

    return (
      <Input
        type={type}
        name={name}
        value={data[name]}
        label={label}
        placeholder={label}
        onChange={this.handleChange}
        error={errors[name]}
        disabled={
          this.state.N_RadioGrpDirections_ID != "2" &&
          this.state.N_RadioGrpDirections_ID != "3" &&
          this.state.N_RadioGrpDirections_ID != "5" &&
          this.state.N_RadioGrpDirections_ID != "6" &&
          this.state.N_RadioGrpDirections_ID != "7" &&
          this.state.N_RadioGrpDirections_ID != "8" &&
          this.state.N_RadioGrpDirections_ID != "9" &&
          this.state.N_RadioGrpDirections_ID != "10"
            ? true
            : false
        }
      />
    );
  }

  renderInputHoverSummary(name, label, type = "text") {
    //
    // Babak's Note: Below is called object destructing. This means we can remove where ever there is text
    //               of 'this.state'
    const { data, errors } = this.state;

    //console.log("renderInput : ", this.state.data, data, data[name]);

    return (
      <Input
        type={type}
        name={name}
        value={data[name]}
        label={label}
        placeholder={label}
        onChange={this.handleChange}
        error={errors[name]}
        disabled={this.state.N_RadioGrpDirections_ID != "1" ? true : false}
      />
    );
  }

  renderInput(name, label, type = "text") {
    //
    // Babak's Note: Below is called object destructing. This means we can remove where ever there is text
    //               of 'this.state'
    const { data, errors } = this.state;

    console.log("renderInput : ", this.state.data, data, data[name]);

    return (
      <Input
        type={type}
        name={name}
        value={data[name]}
        label={label}
        placeholder={label}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  renderInputHoverSummary01_Link(name, label, type = "text") {
    //
    // Babak's Note: Below is called object destructing. This means we can remove where ever there is text
    //               of 'this.state'
    const { data, B_HoverSummary01_Linked, errors } = this.state;

    console.log(
      `renderInputHoverSummary01_Link zzzzzzzzzzzzzzzzzz : 
      ${B_HoverSummary01_Linked}`
    );

    return (
      <Input
        type={type}
        name={name}
        value={data[name]}
        label={label}
        placeholder={label}
        onChange={this.handleChange}
        error={errors[name]}
        disabled={B_HoverSummary01_Linked ? false : true}
      />
    );
  }

  renderInputGoogleMap(name, label, type = "text") {
    //
    // Babak's Note: Below is called object destructing. This means we can remove where ever there is text
    //               of 'this.state'
    const { data, errors } = this.state;

    console.log("renderInput : ", this.state.data, data, data[name]);

    return (
      <Input
        type={type}
        name={name}
        value={data[name]}
        label={label}
        placeholder={label}
        onChange={this.handleChangeGoogleMap}
        error={errors[name]}
        disabled={this.state.data["B_GoogleMap"] ? false : true}
      />
    );
  }

  renderInputColourPickerMenu(name, label, type = "color") {
    //
    // Babak's Note: Below is called object destructing. This means we can remove where ever there is text
    //               of 'this.state'
    const { data, errors } = this.state;

    console.log("renderInput : ", this.state.data, data, data[name]);

    return (
      <InputColourPicker
        type={type}
        name={name}
        value={data[name]}
        label={label}
        placeholder={label}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  renderInputColourPickerCust(name, label, type = "color") {
    //
    // Babak's Note: Below is called object destructing. This means we can remove where ever there is text
    //               of 'this.state'
    const { data, errors } = this.state;

    console.log("renderInput : ", this.state.data, data, data[name]);

    return (
      <InputColourPicker
        type={type}
        name={name}
        value={data[name]}
        label={label}
        placeholder={label}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  renderInputColourPicker(name, label, type = "color") {
    //
    // Babak's Note: Below is called object destructing. This means we can remove where ever there is text
    //               of 'this.state'
    const { data, errors } = this.state;

    console.log("renderInput : ", this.state.data, data, data[name]);

    return (
      <InputColourPicker
        type={type}
        name={name}
        value={data[name]}
        label={label}
        placeholder={label}
        onChange={this.handleChange}
        error={errors[name]}
        disabled={
          this.state.N_RadioGrpDirections_ID != "1" &&
          this.state.N_RadioGrpDirections_ID != "2" &&
          this.state.N_RadioGrpDirections_ID != "3" &&
          this.state.N_RadioGrpDirections_ID != "4" &&
          this.state.N_RadioGrpDirections_ID != "5" &&
          this.state.N_RadioGrpDirections_ID != "6" &&
          this.state.N_RadioGrpDirections_ID != "7" &&
          this.state.N_RadioGrpDirections_ID != "8" &&
          this.state.N_RadioGrpDirections_ID != "9" &&
          this.state.N_RadioGrpDirections_ID != "10"
            ? true
            : false
        }
      />
    );
  }

  renderInputRTL(name, label, type = "text") {
    //
    // Babak's Note: Below is called object destructing. This means we can remove where ever there is text
    //               of 'this.state'
    const { data, errors } = this.state;

    //console.log("renderInput : ", this.state.data, data, data[name]);

    return (
      <div className="rtlTextAlign rtl">
        <InputRTL
          type={type}
          name={name}
          value={data[name]}
          label={label}
          placeholder={label}
          onChange={this.handleChange}
          error={errors[name]}
        />
      </div>
    );
  }

  renderTextArea(name, label, type = "text") {
    //
    // Babak's Note: Below is called object destructing. This means we can remove where ever there is text
    //               of 'this.state'
    const { data, errors } = this.state;

    //console.log("renderInput : ", this.state.data, data, data[name]);

    return (
      <TextArea
        type={type}
        name={name}
        value={data[name]}
        label={label}
        placeholder={label}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  showHide = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      type: this.state.type === "password" ? "input" : "password"
    });
  };

  handleChangePasswordStrength = ({ currentTarget: input }) => {
    //console.log("handleChange1: ", input);

    if (input.value === "") {
      this.setState({
        score: "null"
      });
    } else {
      let pw = zxcvbn(input.value);
      this.setState({
        score: pw.score
      });
    }

    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    //console.log("handleChange2: ", input.name, data[input.name], input.value);
    //
    // Babak's Note: -- 1.Hard code version
    //data.username = e.currentTarget.value;

    //
    // Babak's Note: -- 2.Dynamic version
    //data[e.currentTarget.name] = e.currentTarget.value;

    //
    // Babak's Note: -- 3.Even better version
    data[input.name] = input.value;
    //console.log("handleChange3: ", input.name, data[input.name], input.value);
    this.setState({ data, errors });
  };

  passwordStrength(e) {
    if (e.target.value === "") {
      this.setState({
        score: "null"
      });
    } else {
      let pw = zxcvbn(e.target.value);
      this.setState({
        score: pw.score
      });
    }
  }

  renderPasswordInput(name, label, type = "text") {
    //
    // Babak's Note: Below is called object destructing. This means we can remove where ever there is text
    //               of 'this.state'
    const { data, errors } = this.state;

    //console.log("renderInput : ", this.state.data, data, data[name]);

    return (
      // <div className="password form-group">
      //   <label htmlFor={name}>{label}</label>
      //   <input
      //     id={name}
      //     type={this.state.type}
      //     name={name}
      //     value={data[name]}
      //     label={label}
      //     className="password__input form-control"
      //     onChange={this.handleChangePasswordStrength}
      //     error={errors[name]}
      //   />
      //   <span className="password__show" onClick={this.showHide}>
      //     {this.state.type === "input" ? "Hide" : "Show"}
      //   </span>
      //   <span className="password__strength" data-score={this.state.score} />
      // </div>
      <InputPassword
        type={this.state.type}
        name={name}
        value={data[name]}
        label={label}
        placeholder={label}
        onChange={this.handleChangePasswordStrength}
        // error={errors[name]}
      />
    );
  }

  renderCheckboxRememberMe(name, label, type = "checkbox") {
    //
    // Babak's Note: Below is called object destructing. This means we can remove where ever there is text
    //               of 'this.state'
    const { data, errors } = this.state;
    //console.log("renderCheckbox", data[name]);
    return (
      <div>
        <InputRememberMe
          type={type}
          name={name}
          checked={data[name]}
          label={label}
          onChange={this.handleCheckboxChange}
          error={errors[name]}
        />
      </div>
    );
  }

  renderCheckbox(name, label, type = "checkbox") {
    //
    // Babak's Note: Below is called object destructing. This means we can remove where ever there is text
    //               of 'this.state'
    const { data, errors } = this.state;
    // console.log("renderCheck//box", data[name], name, data);
    return (
      <div>
        <label>
          <Input
            type={type}
            name={name}
            checked={data[name]}
            label={label}
            onChange={this.handleCheckboxChange}
            error={errors[name]}
          />
        </label>
      </div>
    );
  }

  renderCheckboxLinkButton(name, label, type = "checkbox") {
    //
    // Babak's Note: Below is called object destructing. This means we can remove where ever there is text
    //               of 'this.state'
    const { data, errors } = this.state;
    // console.log("renderCheck//box", data[name], name, data);
    return (
      <div>
        <label>
          <Input
            type={type}
            name={name}
            checked={data[name]}
            label={label}
            onChange={this.handleCheckboxChange}
            error={errors[name]}
            disabled={this.state.N_RadioGrpDirections_ID != "10" ? true : false}
          />
        </label>
      </div>
    );
  }

  renderCheckboxHoverSummary01_Link(name, label, type = "checkbox") {
    //
    // Babak's Note: Below is called object destructing. This means we can remove where ever there is text
    //               of 'this.state'
    const { data, errors } = this.state;
    // console.log("renderCheck//box", data[name], name, data);
    return (
      <div>
        <label>
          <Input
            type={type}
            name={name}
            checked={data[name]}
            label={label}
            onChange={this.handleCheckboxChangeHoverSummary01_Link}
            error={errors[name]}
          />
        </label>
      </div>
    );
  }

  renderCheckboxHover(name, label, type = "checkbox") {
    //
    // Babak's Note: Below is called object destructing. This means we can remove where ever there is text
    //               of 'this.state'
    const { data, errors } = this.state;
    // console.log("renderCheck//box", data[name], name, data);
    return (
      <div>
        <label>
          <Input
            type={type}
            name={name}
            checked={data[name]}
            label={label}
            onChange={this.handleCheckboxChange}
            error={errors[name]}
            disabled={this.state.N_RadioGrpDirections_ID != "1" ? true : false}
          />
        </label>
      </div>
    );
  }

  renderCheckboxGoogleMap(name, label, type = "checkbox") {
    //
    // Babak's Note: Below is called object destructing. This means we can remove where ever there is text
    //               of 'this.state'
    const { data, errors } = this.state;
    // console.log("renderCheck//box", data[name], name, data);
    return (
      <div>
        <label>
          <Input
            type={type}
            name={name}
            checked={data[name]}
            label={label}
            onChange={this.handleCheckboxChange}
            error={errors[name]}
            disabled={this.state.N_RadioGrpDirections_ID != "6" ? true : false}
          />
        </label>
      </div>
    );
  }

  // renderHomeBillboardImageFile(name, label, type = "file") {
  //   //
  //   // Babak's Note: Below is called object destructing. This means we can remove where ever there is text
  //   //               of 'this.state'
  //   const { data, errors } = this.state;
  //   //console.log("renderImageFile", data[name]);
  //   return (
  //     <div>
  //       <label>
  //         <Input
  //           type={type}
  //           accept=".jpeg,.jpg,.png,.bmp"
  //           multiple={false}
  //           // onDrop={this.onImageDrop.bind(this)}></Input>
  //           name={name}
  //           checked={data[name]}
  //           label={label}
  //           onChange={this.handleImageFileChange}
  //           error={errors[name]}
  //           disabled={
  //             this.state.N_RadioGrpDirection != "1" &&
  //             this.state.N_RadioGrpDirection != "3" &&
  //             this.state.N_RadioGrpDirection != "5"
  //               ? true
  //               : false
  //           }
  //         />
  //       </label>
  //     </div>
  //   );
  // }

  renderImageFile(name, label, type = "file") {
    //
    // Babak's Note: Below is called object destructing. This means we can remove where ever there is text
    //               of 'this.state'
    const { data, errors } = this.state;
    //console.log("renderImageFile", data[name]);
    return (
      <div>
        <label>
          <Input
            type={type}
            accept=".jpeg,.jpg,.png,.bmp"
            multiple={false}
            // onDrop={this.onImageDrop.bind(this)}></Input>
            name={name}
            checked={data[name]}
            label={label}
            onChange={this.handleImageFileChange}
            error={errors[name]}
          />
        </label>
      </div>
    );
  }

  renderSideImageFile(name, label, type = "file") {
    //
    // Babak's Note: Below is called object destructing. This means we can remove where ever there is text
    //               of 'this.state'
    const { data, errors } = this.state;
    //console.log("renderImageFile", data[name]);
    return (
      <div>
        <label>
          <Input
            type={type}
            accept=".jpeg,.jpg,.png,.bmp"
            multiple={false}
            // onDrop={this.onImageDrop.bind(this)}></Input>
            name={name}
            checked={data[name]}
            label={label}
            onChange={this.handleImageOnImageFileChange}
            error={errors[name]}
            disabled={this.state.N_RadioGrpDirections_ID != "10" ? true : false}
          />
        </label>
      </div>
    );
  }

  renderImageOnImageFile(name, label, type = "file") {
    //
    // Babak's Note: Below is called object destructing. This means we can remove where ever there is text
    //               of 'this.state'
    const { data, errors } = this.state;
    //console.log("renderImageFile", data[name]);
    return (
      <div>
        <label>
          <Input
            type={type}
            accept=".jpeg,.jpg,.png,.bmp"
            multiple={false}
            // onDrop={this.onImageDrop.bind(this)}></Input>
            name={name}
            checked={data[name]}
            label={label}
            onChange={this.handleImageOnImageFileChange}
            error={errors[name]}
          />
        </label>
      </div>
    );
  }
}

export default Form;
