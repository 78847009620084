import React, { Component } from "react";
import Radium, { StyleRoot } from "radium";
import RenderHomeBillboardChildsMainAboutUs from "./renderHomeBillboardChildsMainAboutUs";
//import slideInLeft from "react-animations/lib/slide-in-left";
import { getHomeBillboardChildsMains } from "../../services/homeBillboardChildsMainAboutUs";

class HomeBillboardChildsMainAboutUs extends Component {
  state = {
    homeBillboardChildsMainAboutUs: []
  };

  async componentDidMount() {
    const homeBillboardsID = this.props.homeBillboardsID;

    // window.alert(`renderBillboardBulletPinterChilds 01 : ${homeBillboardsID}`);

    // console.log(
    //   `HomeBillboardBulletPointChilds, componentDidMount 01 : ${homeBillboardID}`
    // );

    const {
      data: homeBillboardChildsMainAboutUs
    } = await getHomeBillboardChildsMains(homeBillboardsID);

    // console.log(
    //   "componentDidMount BulletPointChild 02 : ",
    //   homeBillboardChilds
    // );

    this.setState({ homeBillboardChildsMainAboutUs });
  }

  render() {
    const mainView = this.props.mainView;
    const identifier = this.props.identifier;
    const sectionStyle = this.props.sectionStyle;
    const sectionStyleMD = this.props.sectionStyleMD;
    const sectionStyleSM = this.props.sectionStyleSM;
    const sectionStyleXS = this.props.sectionStyleXS;
    const homeBillboardsID = this.props.homeBillboardsID;
    const textFC = this.props.textFC;
    const reference = this.props.reference;
    const summary = this.props.summary;
    const imageParent = this.props.imageParent;
    const urlLinkParent = this.props.urlLinkParent;

    const { homeBillboardChildsMainAboutUs } = this.state;
    // const { length: count } = this.state.homeBillboardChildsMainAboutUs;
    // window.alert(`Number of rows : ${count}`);
    // if (count === 0) return <div className="hourGlass"></div>;

    return (
      <div>
        <StyleRoot
          style={sectionStyle}
          className="d-none d-lg-block HeroMain homeOverflowHidden"
        >
          <div
            style={{
              marginTop: mainView ? "250px" : "0px",
              marginBottom: mainView ? "100px" : "0px"
            }}
            className="AboutUsChildHeroMain-contentTopLG colorlib-about"
          >
            <div className="AboutUsChildHeroMain-contentInnerTopLG">
              <div className="AboutUsChildHeroMain-subTitleTopLG">
                <div className="row">
                  <div>
                    <div
                      className="heading-meta"
                      style={{ marginBottom: "10px" }}
                    >
                      <span
                        className="borderInner"
                        style={{
                          color: `${textFC}`,
                          border: `1px solid ${textFC}`
                        }}
                      >
                        {reference}
                      </span>
                    </div>

                    <h2
                      style={{
                        color: `${textFC}`,
                        border: `1px solid ${textFC}`
                      }}
                      className="colorlib-heading borderInner"
                    >
                      {summary}
                    </h2>
                  </div>
                </div>
              </div>

              <div className="AboutUsChildHeroMain-subTitleMiddleLG">
                <div className="row">
                  {/* <div className="container"> */}
                  <RenderHomeBillboardChildsMainAboutUs
                    homeBillboardChildsMainAboutUs={
                      homeBillboardChildsMainAboutUs
                    }
                    nameOfClassParentColumn={"col-6"}
                    nameOfClassChildColumn={"col-6"}
                    mediaAttribute={"LG"}
                    parentImageLink={"AboutUsChildLargeImageLG"}
                    parentReferenceLink={
                      "AboutUsChildLargeReferenceLG textTitleNoShadowBlack"
                    }
                    parentSummaryLink={
                      "AboutUsChildLargeSummaryLG textTitleNoShadowBlack"
                    }
                    childImageLink={"AboutUsChildSmallImageLG"}
                    childReferenceLink={
                      "AboutUsChildSmallReferenceLG textTitleNoShadowBlack"
                    }
                    childSummaryLink={
                      "AboutUsChildSmallSummaryLG textTitleNoShadowBlack"
                    }
                  ></RenderHomeBillboardChildsMainAboutUs>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </StyleRoot>

        <StyleRoot
          style={sectionStyleMD}
          className="d-none d-md-block d-lg-none HeroMain homeOverflowHidden"
        >
          <div
            style={{
              marginTop: mainView ? "100px" : "0px",
              marginBottom: mainView ? "100px" : "0px"
            }}
            className="AboutUsChildHeroMain-contentTopMD colorlib-about"
          >
            <div className="AboutUsChildHeroMain-contentInnerTopMD">
              <div className="AboutUsChildHeroMain-subTitleTopMD">
                <div className="row">
                  <div>
                    <div
                      className="heading-meta"
                      style={{ marginBottom: "10px" }}
                    >
                      <span
                        className="borderInner"
                        style={{
                          color: `${textFC}`,
                          border: `1px solid ${textFC}`
                        }}
                      >
                        {reference}
                      </span>
                    </div>

                    <h2
                      style={{
                        color: `${textFC}`,
                        border: `1px solid ${textFC}`
                      }}
                      className="colorlib-heading borderInner"
                    >
                      {summary}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="AboutUsChildHeroMain-subTitleMiddleMD">
                <div className="row">
                  <RenderHomeBillboardChildsMainAboutUs
                    homeBillboardChildsMainAboutUs={
                      homeBillboardChildsMainAboutUs
                    }
                    nameOfClassParentColumn={"col-md-12"}
                    nameOfClassChildColumn={"col-6"}
                    mediaAttribute={"MD"}
                    parentImageLink={"AboutUsChildLargeImageMD"}
                    parentReferenceLink={
                      "AboutUsChildLargeReferenceMD textTitleNoShadowBlack"
                    }
                    parentSummaryLink={
                      "AboutUsChildLargeSummaryMD textTitleNoShadowBlack"
                    }
                    childImageLink={"AboutUsChildSmallImageMD"}
                    childReferenceLink={
                      "AboutUsChildSmallReferenceMD textTitleNoShadowBlack"
                    }
                    childSummaryLink={
                      "AboutUsChildSmallSummaryMD textTitleNoShadowBlack"
                    }
                  ></RenderHomeBillboardChildsMainAboutUs>
                  {/* <div className="col-md-9">
                    <div>top row</div>
                  </div>
                  <div className="col-md-9">
                    <div className="col-6" style={{ float: "left" }}>
                      top row2
                    </div>
                    <div className="col-6" style={{ float: "left" }}>
                      bottom row2
                    </div>
                    <div className="col-6" style={{ float: "left" }}>
                      top row3
                    </div>
                    <div className="col-6" style={{ float: "left" }}>
                      bottom row3
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </StyleRoot>

        <StyleRoot
          style={sectionStyleSM}
          className="d-sm-block d-md-none d-none HeroMain homeOverflowHidden"
        >
          <div
            style={{
              marginTop: mainView ? "50px" : "-50px",
              marginBottom: mainView ? "100px" : "0px"
            }}
            className="AboutUsChildHeroMain-contentTopSM colorlib-about"
          >
            <div className="AboutUsChildHeroMain-contentInnerTopSM">
              <div className="AboutUsChildHeroMain-subTitleTopSM">
                <div className="row">
                  <div>
                    <div
                      className="heading-meta"
                      style={{ marginBottom: "10px" }}
                    >
                      <span
                        className="borderInner"
                        style={{
                          color: `${textFC}`,
                          border: `1px solid ${textFC}`
                        }}
                      >
                        {reference}
                      </span>
                    </div>

                    <h2
                      style={{
                        color: `${textFC}`,
                        border: `1px solid ${textFC}`
                      }}
                      className="colorlib-heading borderInner"
                    >
                      {summary}
                    </h2>
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginTop: mainView ? "-30px" : "-30px"
                }}
                className="AboutUsChildHeroMain-subTitleMiddleSM"
              >
                <div className="row">
                  <RenderHomeBillboardChildsMainAboutUs
                    homeBillboardChildsMainAboutUs={
                      homeBillboardChildsMainAboutUs
                    }
                    nameOfClassParentColumn={"col-md-12"}
                    nameOfClassChildColumn={"col-6"}
                    mediaAttribute={"SM"}
                    parentImageLink={"AboutUsChildLargeImageSM"}
                    parentReferenceLink={
                      "AboutUsChildLargeReferenceSM textTitleNoShadowBlack"
                    }
                    parentSummaryLink={
                      "AboutUsChildLargeSummarySM textTitleNoShadowBlack"
                    }
                    childImageLink={"AboutUsChildSmallImageSM"}
                    childReferenceLink={
                      "AboutUsChildSmallReferenceSM textTitleNoShadowBlack"
                    }
                    childSummaryLink={
                      "AboutUsChildSmallSummarySM textTitleNoShadowBlack"
                    }
                  ></RenderHomeBillboardChildsMainAboutUs>
                </div>
              </div>
            </div>
          </div>
        </StyleRoot>

        <StyleRoot
          style={sectionStyleXS}
          className="d-xs-block d-sm-none d-md-none HeroMain homeOverflowHidden"
        >
          <div
            style={{
              marginTop: mainView ? "50px" : "-50px",
              marginBottom: mainView ? "100px" : "0px"
            }}
            className="AboutUsChildHeroMain-contentTopXS colorlib-about"
          >
            <div className="AboutUsChildHeroMain-contentInnerTopXS">
              <div className="AboutUsChildHeroMain-subTitleTopXS">
                <div className="row">
                  <div>
                    <div
                      className="heading-meta"
                      style={{ marginBottom: "10px" }}
                    >
                      <span
                        className="borderInner"
                        style={{
                          color: `${textFC}`,
                          border: `1px solid ${textFC}`
                        }}
                      >
                        {reference}
                      </span>
                    </div>

                    <h2
                      style={{
                        color: `${textFC}`,
                        border: `1px solid ${textFC}`
                      }}
                      className="colorlib-heading borderInner"
                    >
                      {summary}
                    </h2>
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginTop: mainView ? "-30px" : "-30px"
                }}
                className="AboutUsChildHeroMain-subTitleMiddleXS"
              >
                <div className="row">
                  <RenderHomeBillboardChildsMainAboutUs
                    homeBillboardChildsMainAboutUs={
                      homeBillboardChildsMainAboutUs
                    }
                    nameOfClassParentColumn={"col-md-12"}
                    nameOfClassChildColumn={"col-6"}
                    mediaAttribute={"XS"}
                    parentImageLink={"AboutUsChildLargeImageXS"}
                    parentReferenceLink={
                      "AboutUsChildLargeReferenceXS textTitleNoShadowBlack"
                    }
                    parentSummaryLink={
                      "AboutUsChildLargeSummaryXS textTitleNoShadowBlack"
                    }
                    childImageLink={"AboutUsChildSmallImageXS"}
                    childReferenceLink={
                      "AboutUsChildSmallReferenceXS textTitleNoShadowBlack"
                    }
                    childSummaryLink={
                      "AboutUsChildSmallSummaryXS textTitleNoShadowBlack"
                    }
                  ></RenderHomeBillboardChildsMainAboutUs>
                </div>
              </div>
            </div>
          </div>
        </StyleRoot>
      </div>
    );
  }
}

export default HomeBillboardChildsMainAboutUs;
