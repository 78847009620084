import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
import moment from "moment";

const slice = createSlice({
  name: "billboardMenuBGs",
  initialState: {
    list: [],
    loading: false,
    lastFetch: null
  },
  reducers: {
    //
    // Babak's Note: By adding 'billboardMenuBGsRequested' or 'billboardMenuBGsReceived' below, a action is created for us:-
    // actions => action handlers. The 'billboardMenuBGs' below represents the 'state':-
    billboardMenuBGsRequested: (billboardMenuBGs, action) => {
      console.log("billboardMenuBGsRequested : ", action.payload);
      billboardMenuBGs.loading = true;
    },
    billboardMenuBGsReceived: (billboardMenuBGs, action) => {
      console.log("billboardMenuBGsReceived : ", action.payload);
      billboardMenuBGs.list = action.payload;
      billboardMenuBGs.loading = false;
      billboardMenuBGs.lastFetch = Date.now();
    },
    billboardMenuBGsRequestedFailed: (billboardMenuBGs, action) => {
      billboardMenuBGs.loading = false;
      // window.alert(`No BillboardMenuBGs found.`);
    },
    billboardMenuBGRequested: (billboardMenuBG, action) => {
      console.log("billboardMenuBGRequested : ", action.payload);
      billboardMenuBG.loading = true;
    },
    billboardMenuBGReceived: (billboardMenuBG, action) => {
      console.log("billboardMenuBGReceived : ", action.payload);
      billboardMenuBG.list = action.payload;
      billboardMenuBG.loading = false;
      billboardMenuBG.lastFetch = Date.now();
    },
    billboardMenuBGRequestedFailed: (billboardMenuBG, action) => {
      billboardMenuBG.loading = false;
      // window.alert(`No HomeLocationGroup found.`);
    },
    billboardMenuBGChangeRequested: (billboardMenuBG, action) => {
      console.log("billboardMenuBGChangeRequested : ", action.payload);
      billboardMenuBG.loading = true;
    },
    billboardMenuBGChangeFailed: (billboardMenuBG, action) => {
      billboardMenuBG.loading = false;

      // //
      // // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete billboardMenuBG api at the back end.
      // const { ID: billboardMenuBGId, A_Name: billboardMenuBGName } = action.payload;

      // window.alert(`HomeLocationGroup '${billboardMenuBGName}' no longer exists.`);

      // const index = billboardMenuBG.list.findIndex(billboardMenuBG => billboardMenuBG.ID === billboardMenuBGId);
      // //console.log("billboardMenuBGDeleted ha ", index);
      // billboardMenuBG.list.splice(index, 1);
    },
    billboardMenuBGChanged: (billboardMenuBG, action) => {
      billboardMenuBG.loading = false;
    },
    billboardMenuBGDeleteRequested: (billboardMenuBG, action) => {
      console.log("billboardMenuBGDeleteRequested : ", action.payload);
      billboardMenuBG.loading = true;
    },
    billboardMenuBGDeleteFailed: (billboardMenuBGs, action) => {
      billboardMenuBGs.loading = false;

      //
      // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete billboardMenuBG api at the back end.
      const {
        ID: billboardMenuBGId,
        A_Name: billboardMenuBGName
      } = action.payload;

      window.alert(
        `An issue has occured with billboardMenuBG '${billboardMenuBGName}'. ${action.payload}`
      );

      const index = billboardMenuBGs.list.findIndex(
        billboardMenuBG => billboardMenuBG.ID === billboardMenuBGId
      );
      //console.log("billboardMenuBGDeleted ha ", index);
      billboardMenuBGs.list.splice(index, 1);
    },
    billboardMenuBGDeleted: (billboardMenuBGs, action) => {
      //
      // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete billboardMenuBG api at the back end.
      const { ID: billboardMenuBGID } = action.payload;
      // console.log("billboardMenuBGDeleted ha ", action.payload, billboardMenuBGID, billboardMenuBGs);
      const index = billboardMenuBGs.list.findIndex(
        billboardMenuBG => billboardMenuBG.ID === billboardMenuBGID
      );
      //console.log("billboardMenuBGDeleted, ha! ha! ", index);
      billboardMenuBGs.list.splice(index, 1);
    },
    billboardMenuBGAddRequested: (billboardMenuBG, action) => {
      console.log("billboardMenuBGAddRequested : ", action.payload);
      billboardMenuBG.loading = true;
    },
    billboardMenuBGAddFailed: (billboardMenuBG, action) => {
      billboardMenuBG.loading = false;
    },
    //
    // Babak's Note: The 'billboardMenuBGs' below represents the 'state':-
    billboardMenuBGAdded: (billboardMenuBGs, action) => {
      console.log("billboardMenuBGAdded : ", action.payload);
      billboardMenuBGs.list.push(action.payload);
    }
    // billboardMenuBGAdded: (billboardMenuBGs, action) => {
    //   billboardMenuBGs.list.push({
    //     id: ++lastId,
    //     description: action.payload.description,
    //     resolved: false
    //   });
    // },
    // billboardMenuBGDeleted: (billboardMenuBGs, action) => {
    //   console.log("billboardMenuBGDeleted ha ", action.payload.ID);
    //   billboardMenuBGs.list.filter(billboardMenuBG => billboardMenuBG.ID !== action.payload.ID);
    // },
  }
});
const {
  billboardMenuBGAddRequested,
  billboardMenuBGAdded,
  billboardMenuBGAddFailed,
  billboardMenuBGChangeRequested,
  billboardMenuBGChanged,
  billboardMenuBGChangeFailed,
  billboardMenuBGDeleteRequested,
  billboardMenuBGDeleted,
  billboardMenuBGDeleteFailed,
  billboardMenuBGsReceived,
  billboardMenuBGsRequested,
  billboardMenuBGsRequestedFailed,
  billboardMenuBGReceived,
  billboardMenuBGRequested,
  billboardMenuBGRequestedFailed
} = slice.actions;
export default slice.reducer;

//
// Action Creators
const url = "/billboardMenuBGs";

export const loadBillboardMenuBGs = () => (dispatch, getState) => {
  const { lastFetch } = getState().entities.billboardMenuBGs;

  console.log("loadBillboardMenuBGs lastFetch : ", lastFetch);

  // //
  // // Babak's Note: Below we check with 'moment' that catch that if this request was already fetched less than 10 minutes ago then do not fetch again:-
  // const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  // if (diffInMinutes < 10) return;

  dispatch(
    apiCallBegan({
      url: url,
      onStart: billboardMenuBGsRequested.type,
      method: "get",
      onSuccess: billboardMenuBGsReceived.type,
      onError: billboardMenuBGsRequestedFailed.type
    })
  );
};

export const loadBillboardMenuBG = billboardMenuBGID => (
  dispatch,
  getState
) => {
  // const { lastFetch } = getState().entities.billboardMenuBGs;

  //console.log("loadHomeLocationGroup : ", billboardMenuBGID);

  // //
  // // Babak's Note: Below we check with 'moment' that catch that if this request was already fetched less than 10 minutes ago then do not fetch again:-
  // const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  // if (diffInMinutes < 10) return;

  dispatch(
    apiCallBegan({
      url: url + "/" + billboardMenuBGID,
      onStart: billboardMenuBGRequested.type,
      method: "get",
      onSuccess: billboardMenuBGReceived.type,
      onError: billboardMenuBGRequestedFailed.type
    })
  );
};

//
// Babak's Note: Below function 'apiCallBegan' is returned back by which function (in this case from within 'index.js') calls 'addHomeLocationGroup':-
export const addHomeLocationGroup = billboardMenuBG =>
  apiCallBegan({
    url,
    method: "post",
    onStart: billboardMenuBGAddRequested.type,
    data: billboardMenuBG,
    onSuccess: billboardMenuBGAdded.type,
    onError: billboardMenuBGAddFailed.type
  });

export const getBillboardMenuBGs = createSelector(
  state => state.entities.billboardMenuBGs,
  //
  // Babak's Note: In the below line, by installing above 'reselect' (at the top of this file) if the list of (billboardMenuBGs, projects) has not changed then do not requery again:-
  // (billboardMenuBGs) => billboardMenuBGs.list.filter(billboardMenuBG => !billboardMenuBG.B_Active)
  billboardMenuBGs => billboardMenuBGs.list
);

export const changeHomeLocationGroup = billboardMenuBG =>
  apiCallBegan({
    url: url + "/" + billboardMenuBG.ID,
    method: "put",
    onStart: billboardMenuBGChangeRequested.type,
    data: {
      A_Background: billboardMenuBG.A_Background
    },
    onSuccess: billboardMenuBGChanged.type,
    onError: billboardMenuBGChangeFailed.type
  });

export const deleteHomeLocationGroup = billboardMenuBG =>
  apiCallBegan({
    url: url + "/" + billboardMenuBG.ID,
    method: "delete",
    onStart: billboardMenuBGDeleteRequested.type,
    data: {
      ID: billboardMenuBG.ID,
      A_Background: billboardMenuBG.A_Background
    },
    onSuccess: billboardMenuBGDeleted.type,
    onError: billboardMenuBGDeleteFailed.type
  });
