import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
import moment from "moment";

const slice = createSlice({
  name: "articles",
  initialState: {
    list: [],
    listSingleRow: [],
    listOnNavvar: [],
    listParentOriginIDMenus: [],
    loading: false,
    lastFetch: null
  },
  reducers: {
    //
    // Babak's Note: By adding 'articlesRequested' or 'articlesReceived' below, a action is created for us:-
    // actions => action handlers. The 'articles' below represents the 'state':-
    articlesRequested: (articles, action) => {
      console.log("articlesRequested : ", action.payload);
      articles.loading = true;
    },
    articlesReceived: (articles, action) => {
      console.log("articlesReceived : ", action.payload);
      articles.list = action.payload;
      articles.loading = false;
      articles.lastFetch = Date.now();
    },
    articlesRequestedFailed: (articles, action) => {
      articles.loading = false;
      //   window.alert(`No Articles found.`);
    },

    articlesOnNavbarRequested: (articles, action) => {
      console.log("articlesOnNavbarRequested : ", action.payload);
      articles.loading = true;
    },
    articlesOnNavbarReceived: (articles, action) => {
      console.log("articlesOnNavbarReceived : ", action.payload);
      articles.listOnNavvar = action.payload;
      articles.loading = false;
      articles.lastFetch = Date.now();
    },
    articlesOnNavbarRequestedFailed: (articles, action) => {
      articles.loading = false;
      //   window.alert(`No Articles found.`);
    },
    articleRequested: (article, action) => {
      console.log("articleRequested : ", action.payload);
      article.loading = true;
    },
    articleReceived: (article, action) => {
      console.log("articleReceived : ", action.payload);
      article.listSingleRow = action.payload;
      article.loading = false;
      article.lastFetch = Date.now();
    },
    articleRequestedFailed: (article, action) => {
      article.loading = false;
      //   window.alert(`No Article found.`);
    },

    parentOriginIDMenusRequested: (parentOriginIDMenus, action) => {
      console.log("parentOriginIDMenusRequested : ", action.payload);
      parentOriginIDMenus.loading = true;
    },
    parentOriginIDMenusReceived: (parentOriginIDMenus, action) => {
      console.log("parentOriginIDMenusReceived : ", action.payload);
      parentOriginIDMenus.listParentOriginIDMenus = action.payload;
      parentOriginIDMenus.loading = false;
      parentOriginIDMenus.lastFetch = Date.now();
    },
    parentOriginIDMenusRequestedFailed: (parentOriginIDMenus, action) => {
      parentOriginIDMenus.loading = false;
      //   window.alert(`No parentOriginIDMenus found.`);
    },

    articleChangeRequested: (article, action) => {
      console.log("articleChangeRequested : ", action.payload);
      article.loading = true;
    },
    articleChangeFailed: (article, action) => {
      article.loading = false;

      // //
      // // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete article api at the back end.
      // const { ID: articleId, A_Name: articleName } = action.payload;

      // window.alert(`Article '${articleName}' no longer exists.`);

      // const index = article.list.findIndex(article => article.ID === articleId);
      // //console.log("articleDeleted ha ", index);
      // article.list.splice(index, 1);
    },
    articleChanged: (article, action) => {
      article.loading = false;
    },
    articleDeleteRequested: (article, action) => {
      console.log("articleDeleteRequested : ", action.payload);
      article.loading = true;
    },
    articleDeleteFailed: (articles, action) => {
      articles.loading = false;

      //
      // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete article api at the back end.
      const { ID: articleId, A_Name: articleName } = action.payload;

      window.alert(
        `An issue has occured with article '${articleName}'. ${action.payload}`
      );

      const index = articles.list.findIndex(
        article => article.ID === articleId
      );
      //console.log("articleDeleted ha ", index);
      articles.list.splice(index, 1);
    },
    articleDeleted: (articles, action) => {
      //
      // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete article api at the back end.
      const { ID: articleID } = action.payload;
      // console.log("articleDeleted ha ", action.payload, articleID, articles);
      const index = articles.list.findIndex(
        article => article.ID === articleID
      );
      //console.log("articleDeleted, ha! ha! ", index);
      articles.list.splice(index, 1);
    },
    articleAddRequested: (article, action) => {
      console.log("articleAddRequested : ", article, action.payload);
      article.loading = true;
    },
    articleAddFailed: (article, action) => {
      article.loading = false;
    },
    //
    // Babak's Note: The 'articles' below represents the 'state':-
    articleAdded: (articles, action) => {
      console.log("articleAdded : ", action.payload);
      articles.list.push(action.payload);
    }
    // articleAdded: (articles, action) => {
    //   articles.list.push({
    //     id: ++lastId,
    //     description: action.payload.description,
    //     resolved: false
    //   });
    // },
    // articleDeleted: (articles, action) => {
    //   console.log("articleDeleted ha ", action.payload.ID);
    //   articles.list.filter(article => article.ID !== action.payload.ID);
    // },
  }
});
const {
  articleAddRequested,
  articleAdded,
  articleAddFailed,
  articleChangeRequested,
  articleChanged,
  articleChangeFailed,
  articleDeleteRequested,
  articleDeleted,
  articleDeleteFailed,
  articlesReceived,
  articlesRequested,
  articlesRequestedFailed,
  articlesOnNavbarReceived,
  articlesOnNavbarRequested,
  articlesOnNavbarRequestedFailed,
  articleReceived,
  articleRequested,
  articleRequestedFailed,
  parentOriginIDMenusRequested,
  parentOriginIDMenusReceived,
  parentOriginIDMenusRequestedFailed
} = slice.actions;
export default slice.reducer;

//
// Action Creators
const url = "/articles";

export const loadArticles = billboardLargeID => (dispatch, getState) => {
  // const { lastFetch } = getState().entities.articles;

  // console.log("loadArticles lastFetch : ", lastFetch);

  // //
  // // Babak's Note: Below we check with 'moment' that catch that if this request was already fetched less than 10 minutes ago then do not fetch again:-
  // const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  // if (diffInMinutes < 10) return;

  dispatch(
    apiCallBegan({
      url: url + "/articles" + "/" + billboardLargeID,
      onStart: articlesRequested.type,
      method: "get",
      onSuccess: articlesReceived.type,
      onError: articlesRequestedFailed.type
    })
  );
};

export const loadArticle = articleID => (dispatch, getState) => {
  // const { lastFetch } = getState().entities.articles;

  //console.log("loadArticle : ", articleID);

  // //
  // // Babak's Note: Below we check with 'moment' that catch that if this request was already fetched less than 10 minutes ago then do not fetch again:-
  // const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  // if (diffInMinutes < 10) return;

  dispatch(
    apiCallBegan({
      url: url + "/" + articleID,
      onStart: articleRequested.type,
      method: "get",
      onSuccess: articleReceived.type,
      onError: articleRequestedFailed.type
    })
  );
};

export const loadParentOriginIDMenus = billboardLargeID => (
  dispatch,
  getState
) => {
  // const { lastFetch } = getState().entities.articles;

  //console.log("loadParentOriginIDMenus : ", articleID);

  // //
  // // Babak's Note: Below we check with 'moment' that catch that if this request was already fetched less than 10 minutes ago then do not fetch again:-
  // const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  // if (diffInMinutes < 10) return;

  dispatch(
    apiCallBegan({
      url: url + "/parentOrigin" + "/" + billboardLargeID,
      onStart: parentOriginIDMenusRequested.type,
      method: "get",
      onSuccess: parentOriginIDMenusReceived.type,
      onError: parentOriginIDMenusRequestedFailed.type
    })
  );
};

//
// Babak's Note: Below function 'apiCallBegan' is returned back by which function (in this case from within 'index.js') calls 'addArticle':-
export const addArticle = (article, user, currentDate) =>
  apiCallBegan({
    url,
    method: "post",
    onStart: articleAddRequested.type,
    //data: article,
    data: {
      N_BillboardOrigin_ID: article.N_BillboardOrigin_ID,
      N_HomeLocationGroup_ID: article.N_HomeLocationGroup_ID,
      N_HomeLocationArticle_ID: article.N_HomeLocationArticle_ID,
      N_Order_No: article.N_Order_No,
      A_Reference: article.A_Reference,
      A_Summary: article.A_Summary,
      A_Description: article.A_Description,
      N_BillboardLarge_ID: article.N_BillboardLarge_ID,
      N_ParentOrigin_ID: article.N_ParentOrigin_ID,
      I_Image: article.I_Image,
      D_Inserted: currentDate,
      A_Inserted_By: user
    },
    onSuccess: articleAdded.type,
    onError: articleAddFailed.type
  });

export const getArticles = createSelector(
  state => state.entities.articles,
  //
  // Babak's Note: In the below line, by installing above 'reselect' (at the top of this file) if the list of (articles, projects) has not changed then do not requery again:-
  // (articles) => articles.list.filter(article => !article.B_Active)
  articles => articles.list
);

export const changeArticle = (article, user, currentDate) =>
  apiCallBegan({
    url: url + "/" + article.ID,
    method: "put",
    onStart: articleChangeRequested.type,
    data: {
      N_BillboardOrigin_ID: article.N_BillboardOrigin_ID,
      N_HomeLocationGroup_ID: article.N_HomeLocationGroup_ID,
      N_HomeLocationArticle_ID: article.N_HomeLocationArticle_ID,
      N_Order_No: article.N_Order_No,
      A_Reference: article.A_Reference,
      A_Summary: article.A_Summary,
      A_Description: article.A_Description,
      N_ParentOrigin_ID: article.N_ParentOrigin_ID,
      I_Image: article.I_Image,
      D_Updated: currentDate,
      A_Updated_By: user
    },
    onSuccess: articleChanged.type,
    onError: articleChangeFailed.type
  });

export const deleteArticle = article =>
  apiCallBegan({
    url: url + "/" + article.ID,
    method: "delete",
    onStart: articleDeleteRequested.type,
    data: { ID: article.ID, A_Reference: article.A_Reference },
    onSuccess: articleDeleted.type,
    onError: articleDeleteFailed.type
  });
