import React, { Component, Suspense } from "react";
import { loadMenu } from "../../store/menus";
import { loadGroups } from "../../store/groups";
import { connect } from "react-redux";
import LazyLoad from "react-lazy-load";
import NavBarFooter from "../navBarFooter";
import Radium, { StyleRoot } from "radium";
import ScrollUpButton from "react-scroll-up-button";

class DetailsGroup extends Component {
  state = {
    data: {
      A_Reference: "",
      A_Summary: "",
      I_Image: ""
    },
    billboardLargesGroup: []
  };

  async populateMenuGroups() {
    const menuId = this.props.match.params.id;

    // window.alert(`componentDidMount (DetailsGroup) : ${menuId}`);

    await this.props.loadMenu(menuId);

    await this.props.loadGroups(menuId);
  }

  async componentDidMount() {
    await this.populateMenuGroups();
  }

  async componentWillReceiveProps(nextProps) {
    // window.alert("MenuForm, componentWillReceiveProps", nextProps.menuSuccess);

    let data = { ...this.state.data };

    // if (this.state.data !== nextProps.menu) {

    data["A_Reference"] = nextProps.menu.A_Reference;
    data["A_Summary"] = nextProps.menu.A_Summary;
    data["I_Image"] = nextProps.menu.I_Image;

    this.setState({
      data
    });
    // }
  }

  //   async mapToViewModel(billboardLarge) {
  //     //console.log("mapToViewModel-1", billboardLarge);
  //     return {
  //       A_Reference: billboardLarge.A_Reference,
  //       A_Summary: billboardLarge.A_Summary,
  //       I_Image: billboardLarge.I_Image
  //     };
  //   }

  renderBillboardData = ({ item, row }) => {
    // console.log("renderBillboardData 01 : ", item, rowCount);

    let sectionStyle;

    // document.body.style.backgroundImage = `url(${item.I_Image})`;
    // document.body.style.backgroundRepeat = "no-repeat";
    // document.body.style.backgroundSize = "cover";

    if (row === 0) {
      sectionStyle = {
        height: "100vh",
        backgroundImage: `url(${item.I_Image})`,
        backgroundPosition: "center center",
        backgroundAttachment: "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
    } else {
      sectionStyle = {
        height: "100vh",
        backgroundImage: `url(${item.I_Image})`,
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
    }

    let sectionStyleSmallerLg;
    sectionStyleSmallerLg = {
      height: "50vh",
      backgroundImage: `url(${item.I_Image})`,
      backgroundPosition: "center center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover"
    };
    // } else if (item.N_RadioGrpDirection === "5") {
    //   sectionStyle = {
    //     backgroundImage: `url(${item.I_Image})`,
    //     backgroundPosition: "center center",
    //     backgroundAttachment: "fixed",
    //     backgroundRepeat: "no-repeat",
    //     backgroundSize: "cover"
    //   };
    // } else {
    //   sectionStyle = {
    //     // height: "100vh",
    //     // backgroundAttachment: "fixed",
    //     // backgroundSize: "cover",
    //     // width: width,
    //     //opacity: 0.5,
    //     backgroundColor: "white",
    //     backgroundRepeat: "no-repeat",
    //     backgroundSize: "cover"
    //   };
    // }

    // https://www.npmjs.com/package/react-animations
    // const styles = {
    //   bounce: {
    //     animation: "y 3s",
    //     animationName: Radium.keyframes(bounce, "bounce")
    //   },
    //   fadeIn: {
    //     animation: "y 8s",
    //     animationName: Radium.keyframes(fadeIn, "fadeIn")
    //   },
    //   fadeInUp00: {
    //     animation: "y 1s",
    //     animationName: Radium.keyframes(fadeInUp, "fadeInUp01")
    //   },
    //   fadeInUp01: {
    //     animation: "y 3s",
    //     animationName: Radium.keyframes(fadeInUp, "fadeInUp01")
    //   },
    //   fadeInUp02: {
    //     animation: "y 5s",
    //     animationName: Radium.keyframes(fadeInUp, "fadeInUp02")
    //   },
    //   fadeInUp03: {
    //     animation: "y 8s",
    //     animationName: Radium.keyframes(fadeInUp, "fadeInUp03")
    //   },
    //   flipInX: {
    //     animation: "y 3s",
    //     animationName: Radium.keyframes(flipInX, "flipInX")
    //   },
    //   flipInY: {
    //     animation: "y 3s",
    //     animationName: Radium.keyframes(flipInY, "flipInY")
    //   },
    //   rubberBand: {
    //     animation: "y 3s",
    //     animationName: Radium.keyframes(rubberBand, "rubberBand")
    //   },
    //   shake: {
    //     animation: "y 3s",
    //     animationName: Radium.keyframes(shake, "shake")
    //   },
    //   rotateInUpRight: {
    //     animation: "y 3s",
    //     animationName: Radium.keyframes(rotateInUpRight, "rotateInUpRight")
    //   },
    //   rotateInUpLeft: {
    //     animation: "y 3s",
    //     animationName: Radium.keyframes(rotateInUpLeft, "rotateInUpLeft")
    //   },
    //   slideInUp01: {
    //     animation: "y 3s",
    //     animationName: Radium.keyframes(slideInUp, fadeIn, "slideInUp01")
    //   },
    //   slideInUp02: {
    //     animation: "y 5s",
    //     animationName: Radium.keyframes(slideInUp, "slideInUp03")
    //   },
    //   slideInUp03: {
    //     animation: "y 8s",
    //     animationName: Radium.keyframes(slideInUp, "slideInUp03")
    //   }
    // };

    // Babak's Notes: Commented out on 20200115: START
    return (
      // <div style={sectionStyle} className="section-1 box"></div>
      <div>
        {row === 0 ? (
          <div>
            <StyleRoot
              id={item.N_Order_No}
              style={sectionStyle}
              className="d-none d-lg-block Hero boxShadowOnly"
            >
              <div style={{ height: "100vh" }}>
                <div
                  className="Hero-background Lazy is-lazyLoaded"
                  data-component="lazy"
                ></div>
              </div>

              <div className="Hero-content PageSidePadding mediumPlus-PageSidePadding">
                <div className="Hero-contentInner">
                  <h1 className="Hero-subTitle textTitleShadowGray">
                    {item.A_Reference}
                  </h1>

                  <h2 className="Hero-subHeading textTitleShadowGray">
                    {item.A_Summary}
                  </h2>
                </div>
              </div>
            </StyleRoot>

            <StyleRoot
              id={item.N_Order_No}
              style={sectionStyleSmallerLg}
              className="d-none d-md-block d-lg-none Hero"
            >
              <div className="Hero-content-smallerLG PageSidePadding mediumPlus-PageSidePadding">
                <div className="Hero-contentInnerLG">
                  <h1 className="Hero-subTitleLG textTitleShadowGray">
                    {item.A_Reference}
                  </h1>

                  <h2 className="Hero-subHeading textTitleShadowGray">
                    {item.A_Summary}
                  </h2>
                </div>
              </div>
            </StyleRoot>
          </div>
        ) : (
          <div>
            <StyleRoot
              id={item.N_Order_No}
              className="d-none d-lg-block container"
            >
              <div style={{ marginBottom: "100px" }}>
                <div
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    marginTop: 60,
                    marginLeft: 20,
                    marginRight: 20
                  }}
                >
                  {item.A_Reference}
                </div>
                <div
                  style={{
                    textAlign: "center",
                    marginLeft: 20,
                    marginRight: 20,
                    marginBottom: 20
                  }}
                >
                  {item.A_Summary}
                </div>
                <img src={item.I_Image} className="imageTitle"></img>
              </div>
            </StyleRoot>
            <StyleRoot
              id={item.N_Order_No}
              className="d-none d-md-block d-lg-none container"
            >
              <div style={{ marginBottom: "100px" }}>
                <div
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    marginTop: 60,
                    marginLeft: 20,
                    marginRight: 20
                  }}
                >
                  {item.A_Reference}
                </div>
                <div
                  style={{
                    textAlign: "center",
                    marginLeft: 20,
                    marginRight: 20,
                    marginBottom: 20
                  }}
                >
                  {item.A_Summary}
                </div>
                <img src={item.I_Image} className="imageTitle"></img>
              </div>
            </StyleRoot>
          </div>
        )}

        {row === 0 ? (
          <StyleRoot id={item.N_Order_No} className="d-sm-block d-md-none">
            <div style={{ marginBottom: "100px" }}>
              <img src={item.I_Image} className="imageTitle"></img>
              <div
                style={{
                  textAlign: "center",
                  fontWeight: "600",
                  fontSize: "25px",
                  marginTop: 20,
                  marginLeft: 20,
                  marginRight: 20
                }}
              >
                {item.A_Reference}
              </div>
              <div
                style={{
                  textAlign: "center",
                  fontWeight: "400",
                  fontSize: "20px",
                  marginLeft: 20,
                  marginRight: 20,
                  marginBottom: 20
                }}
              >
                {item.A_Summary}
              </div>
            </div>
          </StyleRoot>
        ) : (
          <StyleRoot
            id={item.N_Order_No}
            className="d-sm-block d-md-none container"
          >
            <div style={{ marginBottom: "100px" }}>
              <div
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  marginTop: 60,
                  marginLeft: 20,
                  marginRight: 20
                }}
              >
                {item.A_Reference}
              </div>
              <div
                style={{
                  textAlign: "center",
                  marginLeft: 20,
                  marginRight: 20,
                  marginBottom: 20
                }}
              >
                {item.A_Summary}
              </div>
              <img src={item.I_Image} className="imageTitle"></img>
            </div>
          </StyleRoot>
        )}
      </div>
    );
  };

  render() {
    const { data } = this.state;
    // const { billboardLargesGroup } = this.props.groups;

    // let sectionStyle;

    // sectionStyle = {
    //   // height: "100vh",
    //   // backgroundAttachment: "fixed",
    //   // backgroundSize: "cover",
    //   // width: width,
    //   //opacity: 0.5,
    //   backgroundImage: `url(${data.I_Image})`,
    //   backgroundRepeat: "no-repeat",
    //   backgroundSize: "cover"
    // };

    // console.log(
    //   "DetailsGroup render, Number of billboardLargesGroup: ",
    //   this.props.match.params.id,
    //   data,
    //   this.props.groups,
    //   this.props.groups.length
    // );

    if (this.props.groups.length === 0)
      return <div className="hourGlass"></div>;

    //
    // Babak's Note: Goto at top of screen:-
    window.scrollTo(0, 0);

    // return (
    //   <div>
    //     {/* <Suspense fallback={<div className="hourGlass"></div>}> */}
    //     <this.renderBillboardData item={data} row={0} />

    //     {this.props.groups.map((item, index) => (
    //       <this.renderBillboardData item={item} row={index + 1} />
    //     ))}
    //     {/* </Suspense> */}
    //     {/* <div>
    //       <ScrollUpButton />
    //     </div> */}
    //   </div>
    // );

    return (
      // <StyleRoot style={sectionStyle}>
      <div>
        <this.renderBillboardData item={data} row={0} />

        <div
          className="container homeOverflowHidden"
          style={{ paddingBottom: "100px" }}
        >
          <div className="row" style={{ padding: 10 }}>
            {this.props.groups.map(item => (
              <div key={item.ID} className="column" style={{ paddingTop: 50 }}>
                <div className="d-none d-lg-block">
                  <LazyLoad>
                    <img
                      onClick={() => {
                        // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
                        //               rows where N_HomeLocationGroup_ID === 1:-
                        if (item.N_BillboardOrigin_ID === 2) {
                          window.location = `../detailsMaster/${item.ID}`;
                        } else if (item.N_BillboardOrigin_ID === 3) {
                          window.location = `../detailsDetail/${item.ID}`;
                        }
                      }}
                      src={item.I_Image}
                      className="imageLinkLG"
                    ></img>
                  </LazyLoad>
                </div>

                <div className="d-none d-md-block d-lg-none">
                  <LazyLoad>
                    <img
                      onClick={() => {
                        // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
                        //               rows where N_HomeLocationGroup_ID === 1:-
                        if (item.N_BillboardOrigin_ID === 2) {
                          window.location = `../detailsMaster/${item.ID}`;
                        } else if (item.N_BillboardOrigin_ID === 3) {
                          window.location = `../detailsDetail/${item.ID}`;
                        }
                      }}
                      src={item.I_Image}
                      className="imageLinkMD"
                    ></img>
                  </LazyLoad>
                </div>

                <div className="d-sm-block d-md-none d-none">
                  <LazyLoad>
                    <img
                      onClick={() => {
                        // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
                        //               rows where N_HomeLocationGroup_ID === 1:-
                        if (item.N_BillboardOrigin_ID === 2) {
                          window.location = `../detailsMaster/${item.ID}`;
                        } else if (item.N_BillboardOrigin_ID === 3) {
                          window.location = `../detailsDetail/${item.ID}`;
                        }
                      }}
                      src={item.I_Image}
                      className="imageLinkSM"
                    ></img>
                  </LazyLoad>
                </div>

                <div className="d-xs-block d-sm-none d-md-none">
                  <LazyLoad>
                    <img
                      onClick={() => {
                        // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
                        //               rows where N_HomeLocationGroup_ID === 1:-
                        if (item.N_BillboardOrigin_ID === 2) {
                          window.location = `../detailsMaster/${item.ID}`;
                        } else if (item.N_BillboardOrigin_ID === 3) {
                          window.location = `../detailsDetail/${item.ID}`;
                        }
                      }}
                      src={item.I_Image}
                      className="imageLinkXS"
                    ></img>
                  </LazyLoad>
                </div>

                <div
                  className="d-none d-lg-block titleLinkBackground-lg titleLink-lg"
                  // style={{ textAlign: "right" }}
                  onClick={() => {
                    // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
                    //               rows where N_HomeLocationGroup_ID === 1:-
                    if (item.N_BillboardOrigin_ID === 2) {
                      window.location = `../detailsMaster/${item.ID}`;
                    } else if (item.N_BillboardOrigin_ID === 3) {
                      window.location = `../detailsDetail/${item.ID}`;
                    }
                  }}
                >
                  <div
                    className="titleLink-lg"
                    // style={{ textAlign: "right" }}
                  >
                    {item.A_Reference}
                  </div>
                  {/* <div
                    className="d-none d-md-block d-lg-none titleLink-md"
                    // style={{ textAlign: "right" }}
                  >
                    {item.A_Reference}
                  </div>
                  <div
                    className="d-sm-block d-md-none titleLink-sm"
                    // style={{ textAlign: "right" }}
                  >
                    {item.A_Reference}
                  </div> */}
                  {/* <div
                    className="titleLinkBlack"
                    style={{ marginTop: "5px" }}
                    // style={{ textAlign: "right" }}
                  >
                    {item.A_Summary}
                  </div> */}
                  {/* <div
                  // style={{ textAlign: "right" }}
                  className="redLink"
                  onClick={() => {
                    // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
                    //               rows where N_HomeLocationGroup_ID === 1:-
                    if (item.N_BillboardOrigin_ID === 2) {
                      window.location = `../detailsMaster/${item.ID}`;
                    } else if (item.N_BillboardOrigin_ID === 3) {
                      window.location = `../detailsDetail/${item.ID}`;
                    }
                  }}
                >
                  More...
                </div> */}
                </div>

                <div
                  className="d-none d-md-block d-lg-none titleLinkBackground-md titleLink-md"
                  // style={{ textAlign: "right" }}
                  onClick={() => {
                    // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
                    //               rows where N_HomeLocationGroup_ID === 1:-
                    if (item.N_BillboardOrigin_ID === 2) {
                      window.location = `../detailsMaster/${item.ID}`;
                    } else if (item.N_BillboardOrigin_ID === 3) {
                      window.location = `../detailsDetail/${item.ID}`;
                    }
                  }}
                >
                  <div
                    className="titleLink-md"
                    // style={{ textAlign: "right" }}
                  >
                    {item.A_Reference}
                  </div>
                  {/* <div
                    className="d-none d-md-block d-lg-none titleLink-md"
                    // style={{ textAlign: "right" }}
                  >
                    {item.A_Reference}
                  </div>
                  <div
                    className="d-sm-block d-md-none titleLink-sm"
                    // style={{ textAlign: "right" }}
                  >
                    {item.A_Reference}
                  </div> */}
                  {/* <div
                    className="titleLinkBlack"
                    style={{ marginTop: "5px" }}
                    // style={{ textAlign: "right" }}
                  >
                    {item.A_Summary}
                  </div> */}
                  {/* <div
                  // style={{ textAlign: "right" }}
                  className="redLink"
                  onClick={() => {
                    // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
                    //               rows where N_HomeLocationGroup_ID === 1:-
                    if (item.N_BillboardOrigin_ID === 2) {
                      window.location = `../detailsMaster/${item.ID}`;
                    } else if (item.N_BillboardOrigin_ID === 3) {
                      window.location = `../detailsDetail/${item.ID}`;
                    }
                  }}
                >
                  More...
                </div> */}
                </div>

                <div
                  className="d-sm-block d-md-none d-none titleLinkBackground-sm titleLink-sm"
                  // style={{ textAlign: "right" }}
                  onClick={() => {
                    // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
                    //               rows where N_HomeLocationGroup_ID === 1:-
                    if (item.N_BillboardOrigin_ID === 2) {
                      window.location = `../detailsMaster/${item.ID}`;
                    } else if (item.N_BillboardOrigin_ID === 3) {
                      window.location = `../detailsDetail/${item.ID}`;
                    }
                  }}
                >
                  <div
                    className="titleLink-sm"
                    // style={{ textAlign: "right" }}
                  >
                    {item.A_Reference}
                  </div>
                  {/* <div
                    className="d-none d-md-block d-lg-none titleLink-md"
                    // style={{ textAlign: "right" }}
                  >
                    {item.A_Reference}
                  </div>
                  <div
                    className="d-sm-block d-md-none titleLink-sm"
                    // style={{ textAlign: "right" }}
                  >
                    {item.A_Reference}
                  </div> */}
                  {/* <div
                    className="titleLinkBlack"
                    style={{ marginTop: "5px" }}
                    // style={{ textAlign: "right" }}
                  >
                    {item.A_Summary}
                  </div> */}
                  {/* <div
                  // style={{ textAlign: "right" }}
                  className="redLink"
                  onClick={() => {
                    // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
                    //               rows where N_HomeLocationGroup_ID === 1:-
                    if (item.N_BillboardOrigin_ID === 2) {
                      window.location = `../detailsMaster/${item.ID}`;
                    } else if (item.N_BillboardOrigin_ID === 3) {
                      window.location = `../detailsDetail/${item.ID}`;
                    }
                  }}
                >
                  More...
                </div> */}
                </div>

                <div
                  className="d-xs-block d-sm-none d-md-none titleLinkBackground-xs titleLink-xs"
                  // style={{ textAlign: "right" }}
                  onClick={() => {
                    // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
                    //               rows where N_HomeLocationGroup_ID === 1:-
                    if (item.N_BillboardOrigin_ID === 2) {
                      window.location = `../detailsMaster/${item.ID}`;
                    } else if (item.N_BillboardOrigin_ID === 3) {
                      window.location = `../detailsDetail/${item.ID}`;
                    }
                  }}
                >
                  <div
                    className="titleLink-xs"
                    // style={{ textAlign: "right" }}
                  >
                    {item.A_Reference}
                  </div>
                  {/* <div
                    className="d-none d-md-block d-lg-none titleLink-md"
                    // style={{ textAlign: "right" }}
                  >
                    {item.A_Reference}
                  </div>
                  <div
                    className="d-sm-block d-md-none titleLink-sm"
                    // style={{ textAlign: "right" }}
                  >
                    {item.A_Reference}
                  </div> */}
                  {/* <div
                    className="titleLinkBlack"
                    style={{ marginTop: "5px" }}
                    // style={{ textAlign: "right" }}
                  >
                    {item.A_Summary}
                  </div> */}
                  {/* <div
                  // style={{ textAlign: "right" }}
                  className="redLink"
                  onClick={() => {
                    // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
                    //               rows where N_HomeLocationGroup_ID === 1:-
                    if (item.N_BillboardOrigin_ID === 2) {
                      window.location = `../detailsMaster/${item.ID}`;
                    } else if (item.N_BillboardOrigin_ID === 3) {
                      window.location = `../detailsDetail/${item.ID}`;
                    }
                  }}
                >
                  More...
                </div> */}
                </div>
              </div>
            ))}
          </div>
        </div>
        <NavBarFooter
          // nameOfClass="footer"
          customizationSingleRow={this.props.customizationSingleRow}
          menuOnNavvar={this.props.menuOnNavvar}
          onClickSearch={this.onClickSearchHandle}
        />
        <div>
          <ScrollUpButton style={{ marginBottom: "80px" }} />
        </div>
      </div>
    );
  }
}

//
// Babak's Notes: goto tutorial directory '11-Integration with React' file '5- Connecting Components Using react-redux'
const mapStateToProps = state => ({
  //   groups: state.entities.groups.list.filter(group => !group.resolved)
  menu: state.entities.menus.listSingleRow,
  groups: state.entities.groups.list
});

const mapDispatchToProps = dispatch => ({
  loadMenu: id => dispatch(loadMenu(id)),
  loadGroups: id => dispatch(loadGroups(id))
});

// Container Component
//   Presentation (Groups)
//
// Babak's Notes: Below I populate 'this.props.groups' and 'this.props.loadGroups()':-
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailsGroup);
