import React, { Component } from "react";
import auth from "../../services/authService";
import Table from "../common/table";
import Active from "../common/active";

class HomeBillboardsTable extends Component {
  columns = [];

  async componentDidMount() {
    //console.log("componentDidMount UsersTable 01 : ");
  }

  homeBillboardChildColumn = {
    key: "tree",
    type: "text",
    content: homeBillboardChild =>
      auth.getCurrentUser().t_role_a_name === "Admin" ? (
        <button
          onClick={() => {
            if (
              homeBillboardChild.N_RadioGrpDirections_ID != 1 &&
              homeBillboardChild.N_RadioGrpDirections_ID != 4 &&
              homeBillboardChild.N_RadioGrpDirections_ID != 10
            )
              this.props.onHomeBillboardChild(homeBillboardChild);
          }}
          className={
            homeBillboardChild.N_HB_Count > 0 &&
            (homeBillboardChild.N_RadioGrpDirections_ID != 1 &&
              homeBillboardChild.N_RadioGrpDirections_ID != 4 &&
              homeBillboardChild.N_RadioGrpDirections_ID != 10)
              ? "btn btn-warning btn-sm pull-right enabled"
              : homeBillboardChild.N_HB_Count < 1 &&
                (homeBillboardChild.N_RadioGrpDirections_ID != 1 &&
                  homeBillboardChild.N_RadioGrpDirections_ID != 4 &&
                  homeBillboardChild.N_RadioGrpDirections_ID != 10)
              ? "btn btn-secondary btn-sm pull-right enabled"
              : "btn btn-secondary btn-sm pull-right disabled clickableNon"
          }
        >
          {"Child Level"}
        </button>
      ) : (
        <button
          className={
            homeBillboardChild.N_HB_Count > 0
              ? "btn btn-warning btn-sm pull-right enabled"
              : "btn btn-secondary btn-sm pull-right enabled"
          }
        >
          {"Child Level"}
        </button>
      )
  };

  updateColumn = {
    key: "update",
    type: "text",
    content: homeBillboard =>
      auth.getCurrentUser().t_role_a_name === "Admin" ? (
        <button
          onClick={() => this.props.onUpdate(homeBillboard)}
          className="btn btn-info btn-sm pull-right enabled"
        >
          Update
        </button>
      ) : (
        <button className="btn btn-info btn-sm pull-right disabled clickableNon">
          Update
        </button>
      )
  };

  deleteColumn = {
    key: "delete",
    type: "text",
    content: homeBillboard =>
      auth.getCurrentUser().t_role_a_name === "Admin" ? (
        <button
          onClick={() => {
            if (homeBillboard.N_HB_Count < 1) {
              if (
                window.confirm(
                  `Are you sure you want to delete Home Billboard '${homeBillboard.A_Reference}${homeBillboard.A_HoverSummary01}'?`
                )
              ) {
                this.props.onDelete(homeBillboard);
              }
            }
          }}
          className={
            homeBillboard.N_HB_Count > 0
              ? "btn btn-danger btn-sm disabled clickableNon"
              : "btn btn-danger btn-sm enabled"
          }
        >
          Delete
        </button>
      ) : (
        <button className="btn btn-danger btn-sm disabled">Delete</button>
      )
  };

  constructor() {
    super();
    const homeBillboards = [{ ID: 1 }];

    homeBillboards.map(
      item => (
        this.columns.push({
          path: "ID",
          label: "Identifier",
          hidden: true,
          type: "text"
        }),
        this.columns.push({
          path: "N_OrderNo",
          label: "Order",
          type: "text"
        }),
        this.columns.push({
          path: "A_RadioGrpDirections",
          label: "Direction",
          type: "text"
        }),
        this.columns.push({
          path: "A_HoverSummary01",
          label: "Hover",
          type: "text"
        }),
        this.columns.push({
          path: "A_Reference",
          label: "Reference",
          type: "text"
        }),
        // this.columns.push({
        //   path: "A_Summary",
        //   label: "Summary",
        //   type: "textEditor"
        // }),
        // this.columns.push({
        //   path: "A_Description",
        //   label: "Description",
        //   type: "textEditor"
        // }),
        this.columns.push({ path: "I_Image", label: "Image", type: "image" }),
        this.columns.push({
          ID: 9,
          path: "D_Inserted",
          label: "Inserted",
          type: "date"
        }),
        this.columns.push({
          ID: 10,
          path: "A_Inserted_By",
          label: "Inserted_By",
          type: "text"
        }),
        this.columns.push({
          ID: 11,
          path: "D_Updated",
          label: "Updated",
          type: "date"
        }),
        this.columns.push({
          ID: 12,
          path: "A_Updated_By",
          label: "Updated_By",
          type: "text"
        })
      )
    );

    this.columns.push(this.homeBillboardChildColumn);
    this.columns.push(this.updateColumn);
    this.columns.push(this.deleteColumn);

    //const user = auth.getCurrentUser();
    //console.log("Home Billboards details : ", homeBillboard);
  }
  // //
  // // Babak's Note: Below we add a condition that the user must be logged in and have an admin user in order to see
  // //               the 'delete' button:-
  // constructor() {
  //   super();
  //   const user = auth.getCurrentUser();
  //   if (user && user.isAdmin) this.columns.push(this.deleteColumn);
  // }

  render() {
    //console.log("render usersTable");
    const { homeBillboardsList, onSort, sortColumn } = this.props;
    return (
      <Table
        columns={this.columns}
        data={homeBillboardsList}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}
export default HomeBillboardsTable;
