import React, { Component } from "react";
import Flash from "react-reveal/Flash";
import HeadShake from "react-reveal/HeadShake";
import Jello from "react-reveal/Jello";
import Jump from "react-reveal/Jump";
import RubberBand from "react-reveal/RubberBand";
import Swing from "react-reveal/Swing";
import Tada from "react-reveal/Tada";
import Wobble from "react-reveal/Wobble";
import Zoom from "react-reveal/Zoom";
import Radium, { StyleRoot } from "radium";
import LazyLoad from "react-lazy-load";
import { NavLink, withRouter, Route } from "react-router-dom";
// import backgroundImage from "../../images/logo.jpeg";

import { bottom } from "@popperjs/core";

class RenderHomeBillboardChildsMainKeypad extends Component {
  // async componentWillReceiveProps(nextProps) {
  //   const { homeBillboardChildsMains } = nextProps;

  //   if (
  //     this.state.homeBillboardChildsMains !== nextProps.homeBillboardChildsMains
  //   ) {
  //     for (let i = 1; i < this.props.homeBillboardChildsMains.length; i++) {
  //       console.log(
  //         "HomeBillboardChildsMain, componentWillReceivePropsssssssssssssssssssssssssssss  :  ",
  //         homeBillboardChildsMains[i],
  //         this.props.homeBillboardChildsMains.length
  //       );
  //     }
  //     this.setState({ homeBillboardChildsMains });
  //   }

  //   // console.log(
  //   //   `HomeBillboardChildsMain, componentWillReceivePropsssssssssssssssssssssssssssss  :  ${nextProps.homeBillboardChildsMains}`
  //   // );
  //   // const { homeBillboardChildsMains } = nextProps.homeBillboardChildsMains;
  //   // this.setState({
  //   //   homeBillboardChildsMains
  //   // });
  // }

  RenderParentImage = ({
    image,
    imageOnImage,
    parentImageLink,
    reference,
    parentReferenceLink,
    summary,
    parentSummaryLink,
    urlLink
  }) => {
    const sectionStyle = {
      // height: "510px",
      // backgroundImage: `linear-gradient(to bottom, ${item.A_BGC}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,
      backgroundImage: `url(${image})`,
      // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
      backgroundPosition: "center center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover"
    };

    return (
      <LazyLoad>
        <NavLink style={{ color: "white" }} to={urlLink}>
          <div
            style={sectionStyle}
            // onClick={() => {
            //   // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
            //   //               rows where N_HomeLocationGroup_ID === 1:-
            //   if (item.N_BillboardOrigin_ID === 2) {
            //     window.location = `../detailsMaster/${item.ID}`;
            //   } else if (item.N_BillboardOrigin_ID === 3) {
            //     window.location = `../detailsDetail/${item.ID}`;
            //   }
            // }}
            // src={image}
            className={parentImageLink}
          >
            <div
              style={{
                paddingLeft: "10px",
                paddingRight: "10px"
              }}
            >
              {imageOnImage ? (
                <img
                  style={{
                    paddingTop: "10px",
                    float: "right",
                    maxWidth: "200px",
                    minWidth: "200px",
                    maxHeight: "150px",
                    minHeight: "150px"
                  }}
                  src={imageOnImage}
                ></img>
              ) : (
                ""
              )}
            </div>

            <div
              style={{
                backgroundColor: "white",
                opacity: "0.8",
                paddingBottom: "5px",
                paddingLeft: "5px",
                paddingRight: "5px"
              }}
            >
              <h2
                // style={{
                //   maxWidth: "50vw",
                //   minWidth: "50vw"
                // }}
                className={parentReferenceLink}
              >
                {reference}
              </h2>
              <h2 className={parentSummaryLink}>{summary}</h2>
            </div>
          </div>
        </NavLink>
      </LazyLoad>
    );
  };

  RenderChildImage = ({
    image,
    imageOnImage,
    childImageLink,
    reference,
    childReferenceLink,
    summary,
    childSummaryLink,
    urlLink,
    urlLinked,
    urlLinkedExternal
  }) => {
    const sectionStyle = {
      // height: "510px",
      // backgroundImage: `linear-gradient(to bottom, ${item.A_BGC}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,
      backgroundImage: `url(${image})`,
      // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
      backgroundPosition: "center center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover"
    };

    //  console.log(`Number of rows xxxxxxxxxxxxxxxxxxxxxxxxxx : ${reference}`);

    return (
      <LazyLoad>
        {urlLinked ? (
          urlLinkedExternal ? (
            <a style={{ color: "white" }} href={urlLink} target={"_blank"}>
              <div
                style={sectionStyle}
                // style={{ padding: "10px" }}
                // onClick={() => {
                //   // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
                //   //               rows where N_HomeLocationGroup_ID === 1:-
                //   if (item.N_BillboardOrigin_ID === 2) {
                //     window.location = `../detailsMaster/${item.ID}`;
                //   } else if (item.N_BillboardOrigin_ID === 3) {
                //     window.location = `../detailsDetail/${item.ID}`;
                //   }
                // }}
                // src={image}
                className={`${childImageLink} boxShadowLight`}
              >
                <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                  {imageOnImage ? (
                    <img
                      style={{
                        paddingTop: "10px",
                        float: "right",
                        maxWidth: "90px",
                        minWidth: "90px",
                        maxHeight: "70px",
                        minHeight: "70px"
                      }}
                      src={imageOnImage}
                    ></img>
                  ) : (
                    ""
                  )}
                </div>

                <div
                  style={{
                    backgroundColor: "white",
                    opacity: "0.8",
                    paddingBottom: "5px",
                    paddingLeft: "5px",
                    paddingRight: "5px"
                  }}
                >
                  <h2
                    style={{
                      maxWidth: "50vw",
                      minWidth: "50vw"
                    }}
                    className={childReferenceLink}
                  >
                    {reference}
                  </h2>
                  <h2 className={childSummaryLink}>{summary}</h2>
                </div>
              </div>
            </a>
          ) : (
            <NavLink style={{ color: "white" }} to={urlLink}>
              <div
                style={sectionStyle}
                // style={{ padding: "10px" }}
                // onClick={() => {
                //   // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
                //   //               rows where N_HomeLocationGroup_ID === 1:-
                //   if (item.N_BillboardOrigin_ID === 2) {
                //     window.location = `../detailsMaster/${item.ID}`;
                //   } else if (item.N_BillboardOrigin_ID === 3) {
                //     window.location = `../detailsDetail/${item.ID}`;
                //   }
                // }}
                // src={image}
                className={`${childImageLink} boxShadowLight`}
              >
                <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                  {imageOnImage ? (
                    <img
                      style={{
                        paddingTop: "10px",
                        float: "right",
                        maxWidth: "90px",
                        minWidth: "90px",
                        maxHeight: "70px",
                        minHeight: "70px"
                      }}
                      src={imageOnImage}
                    ></img>
                  ) : (
                    ""
                  )}
                </div>

                <div
                  style={{
                    backgroundColor: "white",
                    opacity: "0.8",
                    paddingBottom: "5px",
                    paddingLeft: "5px",
                    paddingRight: "5px"
                  }}
                >
                  <h2
                    style={{
                      maxWidth: "50vw",
                      minWidth: "50vw"
                    }}
                    className={childReferenceLink}
                  >
                    {reference}
                  </h2>
                  <h2 className={childSummaryLink}>{summary}</h2>
                </div>
              </div>
            </NavLink>
          )
        ) : (
          <div style={{ color: "white" }}>
            <div
              style={sectionStyle}
              // style={{ padding: "10px" }}
              // onClick={() => {
              //   // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
              //   //               rows where N_HomeLocationGroup_ID === 1:-
              //   if (item.N_BillboardOrigin_ID === 2) {
              //     window.location = `../detailsMaster/${item.ID}`;
              //   } else if (item.N_BillboardOrigin_ID === 3) {
              //     window.location = `../detailsDetail/${item.ID}`;
              //   }
              // }}
              // src={image}
              className={childImageLink}
            >
              <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                {imageOnImage ? (
                  <img
                    style={{
                      paddingTop: "10px",
                      float: "right",
                      maxWidth: "90px",
                      minWidth: "90px",
                      maxHeight: "70px",
                      minHeight: "70px"
                    }}
                    src={imageOnImage}
                  ></img>
                ) : (
                  ""
                )}
              </div>

              <div
                style={{
                  backgroundColor: "white",
                  opacity: "0.8",
                  paddingBottom: "5px",
                  paddingLeft: "5px",
                  paddingRight: "5px"
                }}
              >
                <h2
                  style={{
                    maxWidth: "50vw",
                    minWidth: "50vw"
                  }}
                  className={childReferenceLink}
                >
                  {reference}
                </h2>
                <h2 className={childSummaryLink}>{summary}</h2>
              </div>
            </div>
          </div>
        )}
      </LazyLoad>
    );
  };

  RenderReference = ({ reference, referenceCSS, urlLink }) => {
    return (
      <NavLink style={{ color: "white" }} to={urlLink}>
        <div
          className={referenceCSS}
          // style={{ textAlign: "right" }}
          // onClick={() => {
          //   // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
          //   //               rows where N_HomeLocationGroup_ID === 1:-
          //   if (item.N_BillboardOrigin_ID === 2) {
          //     window.location = `../detailsMaster/${item.ID}`;
          //   } else if (item.N_BillboardOrigin_ID === 3) {
          //     window.location = `../detailsDetail/${item.ID}`;
          //   }
          // }}
        >
          <div className="HomeTitleLink">{reference}</div>

          {/* <div
                  className="HomeTitleLinkBlack"
                  style={{ marginTop: "5px" }}
                  // style={{ textAlign: "right" }}
                >
                  {item.A_Summary}
                </div> */}
          {/* <div
                // style={{ textAlign: "right" }}
                className="HomeRedLink"
                onClick={() => {
                  // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
                  //               rows where N_HomeLocationGroup_ID === 1:-
                  if (item.N_BillboardOrigin_ID === 2) {
                    window.location = `../detailsMaster/${item.ID}`;
                  } else if (item.N_BillboardOrigin_ID === 3) {
                    window.location = `../detailsDetail/${item.ID}`;
                  }
                }}
              >
                More...
              </div> */}
        </div>
      </NavLink>
    );
  };

  render() {
    const {
      homeBillboardChildsMainKeypad,
      nameOfClassParentColumn,
      nameOfClassChildColumn,
      mediaAttribute,
      parentImageLink,
      parentReferenceLink,
      parentSummaryLink,
      childImageLink,
      childReferenceLink,
      childSummaryLink
    } = this.props;

    // console.log(
    //   `Number of rows xxxxxxxxxxxxxxxxxxxxxxxxxx : ${homeBillboardChildsMainKeypad.length}`
    // );

    return (
      <div key={1}>
        {/* <div className="col-6">Hello</div>
        <div className="col-6">
          <div className="col-6" style={{ float: "left" }}>
            Bye1
          </div>
          <div className="col-6" style={{ float: "left" }}>
            Bye2
          </div>
          <div className="col-6" style={{ float: "left" }}>
            Bye3
          </div>
          <div className="col-6" style={{ float: "left" }}>
            Bye4
          </div>
        </div> */}
        {/* *** Babak's Notes: Below is the make up of above *** */}

        {mediaAttribute === "LG" ? (
          <div
            key={2}
            className={nameOfClassParentColumn}
            style={{
              float: "left",
              // border: "1px solid white",
              paddingRight: "5px",
              paddingLeft: "0px"
            }}
          >
            {homeBillboardChildsMainKeypad.map((item, index) =>
              index < 4 ? (
                <div
                  className={nameOfClassChildColumn}
                  style={{
                    float: "left",
                    // border: "1px solid white",
                    paddingBottom:
                      index === 0
                        ? "5px"
                        : index === 1
                        ? "5px"
                        : index === 2
                        ? "0px"
                        : index === 3
                        ? "0px"
                        : "0px",

                    paddingTop:
                      index === 0
                        ? "0px"
                        : index === 1
                        ? "0px"
                        : index === 2
                        ? "5px"
                        : index === 3
                        ? "5px"
                        : "0px",

                    paddingLeft:
                      index === 0
                        ? "0px"
                        : index === 1
                        ? "5px"
                        : index === 2
                        ? "0px"
                        : index === 3
                        ? "5px"
                        : "0px",

                    paddingRight:
                      index === 0
                        ? "5px"
                        : index === 1
                        ? "0px"
                        : index === 2
                        ? "5px"
                        : index === 3
                        ? "0px"
                        : "0px"
                  }}
                >
                  <this.RenderChildImage
                    image={item.I_Image}
                    imageOnImage={item.I_ImageOnImage}
                    childImageLink={childImageLink}
                    reference={item.A_Reference}
                    childReferenceLink={childReferenceLink}
                    summary={item.A_Summary}
                    childSummaryLink={childSummaryLink}
                    urlLink={item.A_HoverSummary01_Link}
                    urlLinked={item.B_HoverSummary01_Linked}
                    urlLinkedExternal={item.B_HoverSummary01_Link_External}
                  />
                </div>
              ) : (
                ""
              )
            )}
          </div>
        ) : mediaAttribute === "MD" ? (
          <div key={3}>
            <div
              key={4}
              className={nameOfClassParentColumn}
              style={{
                float: "left",
                // border: "1px solid white",
                paddingLeft: "0px",
                paddingRight: "0px"
              }}
            >
              {homeBillboardChildsMainKeypad.map((item, index) => (
                <div
                  className={nameOfClassChildColumn}
                  style={{
                    float: "left",
                    // border: "1px solid white",
                    paddingBottom:
                      index === 0
                        ? "5px"
                        : index === 1
                        ? "5px"
                        : index === 2
                        ? "10px"
                        : index === 3
                        ? "10px"
                        : index === 4
                        ? "10px"
                        : index === 5
                        ? "10px"
                        : index === 6
                        ? "10px"
                        : index === 7
                        ? "10px"
                        : "0px",

                    paddingTop:
                      index === 0
                        ? "0px"
                        : index === 1
                        ? "0px"
                        : index === 2
                        ? "5px"
                        : index === 3
                        ? "5px"
                        : index === 4
                        ? "0px"
                        : index === 5
                        ? "0px"
                        : index === 6
                        ? "0px"
                        : index === 7
                        ? "0px"
                        : "0px",

                    paddingLeft:
                      index === 0
                        ? "0px"
                        : index === 1
                        ? "5px"
                        : index === 2
                        ? "0px"
                        : index === 3
                        ? "5px"
                        : index === 4
                        ? "0px"
                        : index === 5
                        ? "5px"
                        : index === 6
                        ? "0px"
                        : index === 7
                        ? "5px"
                        : "0px",

                    paddingRight:
                      index === 0
                        ? "5px"
                        : index === 1
                        ? "0px"
                        : index === 2
                        ? "5px"
                        : index === 3
                        ? "0px"
                        : index === 4
                        ? "5px"
                        : index === 5
                        ? "0px"
                        : index === 6
                        ? "5px"
                        : index === 7
                        ? "0px"
                        : "0px"
                  }}
                >
                  <this.RenderChildImage
                    image={item.I_Image}
                    imageOnImage={item.I_ImageOnImage}
                    childImageLink={childImageLink}
                    reference={item.A_Reference}
                    childReferenceLink={childReferenceLink}
                    summary={item.A_Summary}
                    childSummaryLink={childSummaryLink}
                    urlLink={item.A_HoverSummary01_Link}
                    urlLinked={item.B_HoverSummary01_Linked}
                    urlLinkedExternal={item.B_HoverSummary01_Link_External}
                  />
                </div>
              ))}
            </div>
          </div>
        ) : mediaAttribute === "SM" ? (
          <div key={5}>
            <div
              key={6}
              className={nameOfClassParentColumn}
              style={{
                float: "left",
                // border: "1px solid white",
                paddingLeft: "0px",
                paddingRight: "0px"
              }}
            >
              {homeBillboardChildsMainKeypad.map((item, index) => (
                <div
                  className={nameOfClassChildColumn}
                  style={{
                    float: "left",
                    // border: "1px solid white",
                    paddingBottom:
                      index === 0
                        ? "5px"
                        : index === 1
                        ? "5px"
                        : index === 2
                        ? "10px"
                        : index === 3
                        ? "10px"
                        : index === 4
                        ? "10px"
                        : index === 5
                        ? "10px"
                        : index === 6
                        ? "10px"
                        : index === 7
                        ? "10px"
                        : "0px",

                    paddingTop:
                      index === 0
                        ? "0px"
                        : index === 1
                        ? "0px"
                        : index === 2
                        ? "5px"
                        : index === 3
                        ? "5px"
                        : index === 4
                        ? "0px"
                        : index === 5
                        ? "0px"
                        : index === 6
                        ? "0px"
                        : index === 7
                        ? "0px"
                        : "0px",

                    paddingLeft:
                      index === 0
                        ? "0px"
                        : index === 1
                        ? "5px"
                        : index === 2
                        ? "0px"
                        : index === 3
                        ? "5px"
                        : index === 4
                        ? "0px"
                        : index === 5
                        ? "5px"
                        : index === 6
                        ? "0px"
                        : index === 7
                        ? "5px"
                        : "0px",

                    paddingRight:
                      index === 0
                        ? "5px"
                        : index === 1
                        ? "0px"
                        : index === 2
                        ? "5px"
                        : index === 3
                        ? "0px"
                        : index === 4
                        ? "5px"
                        : index === 5
                        ? "0px"
                        : index === 6
                        ? "5px"
                        : index === 7
                        ? "0px"
                        : "0px"
                  }}
                >
                  <this.RenderChildImage
                    image={item.I_Image}
                    imageOnImage={item.I_ImageOnImage}
                    childImageLink={childImageLink}
                    reference={item.A_Reference}
                    childReferenceLink={childReferenceLink}
                    summary={item.A_Summary}
                    childSummaryLink={childSummaryLink}
                    urlLink={item.A_HoverSummary01_Link}
                    urlLinked={item.B_HoverSummary01_Linked}
                    urlLinkedExternal={item.B_HoverSummary01_Link_External}
                  />
                </div>
              ))}
            </div>
          </div>
        ) : mediaAttribute === "XS" ? (
          <div key={7}>
            <div
              key={8}
              className={nameOfClassParentColumn}
              style={{
                float: "left",
                // border: "1px solid white",
                paddingLeft: "0px",
                paddingRight: "0px"
              }}
            >
              {homeBillboardChildsMainKeypad.map((item, index) => (
                <div
                  className={nameOfClassChildColumn}
                  style={{
                    float: "left",
                    // border: "1px solid white",
                    paddingBottom:
                      index === 0
                        ? "5px"
                        : index === 1
                        ? "5px"
                        : index === 2
                        ? "10px"
                        : index === 3
                        ? "10px"
                        : index === 4
                        ? "10px"
                        : index === 5
                        ? "10px"
                        : index === 6
                        ? "10px"
                        : index === 7
                        ? "10px"
                        : "0px",

                    paddingTop:
                      index === 0
                        ? "0px"
                        : index === 1
                        ? "0px"
                        : index === 2
                        ? "5px"
                        : index === 3
                        ? "5px"
                        : index === 4
                        ? "0px"
                        : index === 5
                        ? "0px"
                        : index === 6
                        ? "0px"
                        : index === 7
                        ? "0px"
                        : "0px",

                    paddingLeft:
                      index === 0
                        ? "0px"
                        : index === 1
                        ? "5px"
                        : index === 2
                        ? "0px"
                        : index === 3
                        ? "5px"
                        : index === 4
                        ? "0px"
                        : index === 5
                        ? "5px"
                        : index === 6
                        ? "0px"
                        : index === 7
                        ? "5px"
                        : "0px",

                    paddingRight:
                      index === 0
                        ? "5px"
                        : index === 1
                        ? "0px"
                        : index === 2
                        ? "5px"
                        : index === 3
                        ? "0px"
                        : index === 4
                        ? "5px"
                        : index === 5
                        ? "0px"
                        : index === 6
                        ? "5px"
                        : index === 7
                        ? "0px"
                        : "0px"
                  }}
                >
                  <this.RenderChildImage
                    image={item.I_Image}
                    imageOnImage={item.I_ImageOnImage}
                    childImageLink={childImageLink}
                    reference={item.A_Reference}
                    childReferenceLink={childReferenceLink}
                    summary={item.A_Summary}
                    childSummaryLink={childSummaryLink}
                    urlLink={item.A_HoverSummary01_Link}
                    urlLinked={item.B_HoverSummary01_Linked}
                    urlLinkedExternal={item.B_HoverSummary01_Link_External}
                  />
                </div>
              ))}
            </div>
          </div>
        ) : (
          ""
        )}

        {mediaAttribute === "LG" ? (
          <div
            key={9}
            className={nameOfClassParentColumn}
            style={{
              float: "left",
              // border: "1px solid white",
              paddingLeft: "5px",
              paddingRight: "0px"
            }}
          >
            {homeBillboardChildsMainKeypad.map((item, index) =>
              index > 3 ? (
                <div
                  className={nameOfClassChildColumn}
                  style={{
                    float: "left",
                    // border: "1px solid white",
                    paddingBottom:
                      index === 4
                        ? "0px"
                        : index === 5
                        ? "5px"
                        : index === 6
                        ? "0px"
                        : index === 7
                        ? "0px"
                        : "0px",

                    paddingTop:
                      index === 4
                        ? "0px"
                        : index === 5
                        ? "0px"
                        : index === 6
                        ? "5px"
                        : index === 7
                        ? "5px"
                        : "0px",

                    paddingLeft:
                      index === 4
                        ? "0px"
                        : index === 5
                        ? "5px"
                        : index === 6
                        ? "0px"
                        : index === 7
                        ? "5px"
                        : "0px",

                    paddingRight:
                      index === 4
                        ? "5px"
                        : index === 5
                        ? "5px"
                        : index === 6
                        ? "5px"
                        : index === 7
                        ? "0px"
                        : "0px"
                  }}
                >
                  <this.RenderChildImage
                    image={item.I_Image}
                    imageOnImage={item.I_ImageOnImage}
                    childImageLink={childImageLink}
                    reference={item.A_Reference}
                    childReferenceLink={childReferenceLink}
                    summary={item.A_Summary}
                    childSummaryLink={childSummaryLink}
                    urlLink={item.A_HoverSummary01_Link}
                    urlLinked={item.B_HoverSummary01_Linked}
                    urlLinkedExternal={item.B_HoverSummary01_Link_External}
                  />
                </div>
              ) : (
                ""
              )
            )}
          </div>
        ) : (
          ""
        )}
      </div>

      // <this.RenderReference
      //   reference={item.A_Reference}
      //   urlLink={item.A_HoverSummary01_Link}
      //   referenceCSS={referenceCSS}
      // />
    );
  }
}

export default RenderHomeBillboardChildsMainKeypad;
