import React, { Component } from "react";
import { getBillboardLargesSearchDynamic } from "../../services/billboardLargeService";
import LazyLoad from "react-lazy-load";
import { Redirect } from "react-router";
import NavBarFooter from "../navBarFooter";
import ScrollUpButton from "react-scroll-up-button";

class SearchDynamic extends Component {
  state = {
    billboardLargesSearchDynamic: [],
    searchFound: false,
    detailsGroup: false,
    groupID: 0,
    detailsMaster: false,
    masterID: 0,
    detailsDetail: false,
    detailsDetailID: 0
  };

  async componentDidMount() {
    const billLocationSearchDynamic = this.props.match.params.id;

    //console.log("componentDidMount (SearchDynamic) : ", billLocationSearchDynamic);
    const {
      data: billboardLargesSearchDynamic
    } = await getBillboardLargesSearchDynamic(billLocationSearchDynamic);

    let searchFound = true;
    this.setState({ billboardLargesSearchDynamic, searchFound });
  }

  render() {
    // if (this.state.detailsGroup === true) {
    //   return <Redirect to={`/detailsGroup/${this.state.groupID}`} />;
    // }
    // if (this.state.detailsMaster === true) {
    //   return <Redirect to={`/detailsMaster/${this.state.masterID}`} />;
    // }
    // if (this.state.detailsDetail === true) {
    //   return <Redirect to={`/detailsDetail/${this.state.detailsDetailID}`} />;
    // }

    const { billboardLargesSearchDynamic, searchFound } = this.state;

    if (!searchFound) {
      return <div className="hourGlass"></div>;
    } else if (billboardLargesSearchDynamic.length === 0) {
      return (
        <div className="container">
          <div className="dynamicNoDataFound">
            No data found, please try again.
          </div>
        </div>
      );
    }

    // console.log(
    //   "SearchDynamic render, Number of billboardLargesMenu: ",
    //   billboardLargesSearchDynamic
    // );
    return (
      <div>
        <div
          className="container"
          style={{ marginTop: "160px", marginBottom: "100px" }}
        >
          <div className="row" style={{ padding: 10 }}>
            {billboardLargesSearchDynamic.map(item => (
              <div key={item.ID} className="column" style={{ paddingTop: 50 }}>
                <div>
                  <LazyLoad>
                    <img
                      onClick={() => {
                        // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
                        //               rows where N_HomeLocationGroup_ID === 1:-
                        if (item.N_BillboardOrigin_ID === 1) {
                          window.location = `../detailsGroup/${item.ID}`;
                        } else if (item.N_BillboardOrigin_ID === 2) {
                          window.location = `../detailsMaster/${item.ID}`;
                        } else if (item.N_BillboardOrigin_ID === 3) {
                          window.location = `../detailsDetail/${item.ID}`;
                        }
                      }}
                      src={item.I_Image}
                      className="imageLink"
                    ></img>
                  </LazyLoad>
                </div>
                <div
                  className="d-none d-lg-block titleLinkBackground titleLink"
                  // style={{ textAlign: "right" }}
                  onClick={() => {
                    // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
                    //               rows where N_HomeLocationGroup_ID === 1:-
                    if (item.N_BillboardOrigin_ID === 1) {
                      window.location = `../detailsGroup/${item.ID}`;
                    } else if (item.N_BillboardOrigin_ID === 2) {
                      window.location = `../detailsMaster/${item.ID}`;
                    } else if (item.N_BillboardOrigin_ID === 3) {
                      window.location = `../detailsDetail/${item.ID}`;
                    }
                  }}
                >
                  <div
                    className="d-none d-lg-block titleLink"
                    // style={{ textAlign: "right" }}
                  >
                    {item.A_Reference}
                  </div>
                  <div
                    className="d-none d-md-block d-lg-none titleLink-md"
                    // style={{ textAlign: "right" }}
                  >
                    {item.A_Reference}
                  </div>
                  <div
                    className="d-sm-block d-md-none titleLink-sm"
                    // style={{ textAlign: "right" }}
                  >
                    {item.A_Reference}
                  </div>
                  {/* <div
                    className="titleLinkBlack"
                    style={{ marginTop: "5px" }}
                    // style={{ textAlign: "right" }}
                  >
                    {item.A_Summary}
                  </div> */}
                  {/* <div
                  // style={{ textAlign: "right" }}
                  className="redLink"
                  onClick={() => {
                    // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
                    //               rows where N_HomeLocationGroup_ID === 1:-
                    if (item.N_BillboardOrigin_ID === 2) {
                      window.location = `../detailsMaster/${item.ID}`;
                    } else if (item.N_BillboardOrigin_ID === 3) {
                      window.location = `../detailsDetail/${item.ID}`;
                    }
                  }}
                >
                  More...
                </div> */}
                </div>
                <div
                  className="d-none d-md-block d-lg-none titleLinkBackground-md titleLink"
                  // style={{ textAlign: "right" }}
                  onClick={() => {
                    // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
                    //               rows where N_HomeLocationGroup_ID === 1:-
                    if (item.N_BillboardOrigin_ID === 1) {
                      window.location = `../detailsGroup/${item.ID}`;
                    } else if (item.N_BillboardOrigin_ID === 2) {
                      window.location = `../detailsMaster/${item.ID}`;
                    } else if (item.N_BillboardOrigin_ID === 3) {
                      window.location = `../detailsDetail/${item.ID}`;
                    }
                  }}
                >
                  <div
                    className="d-none d-lg-block titleLink"
                    // style={{ textAlign: "right" }}
                  >
                    {item.A_Reference}
                  </div>
                  <div
                    className="d-none d-md-block d-lg-none titleLink-md"
                    // style={{ textAlign: "right" }}
                  >
                    {item.A_Reference}
                  </div>
                  <div
                    className="d-sm-block d-md-none titleLink-sm"
                    // style={{ textAlign: "right" }}
                  >
                    {item.A_Reference}
                  </div>
                  {/* <div
                    className="titleLinkBlack"
                    style={{ marginTop: "5px" }}
                    // style={{ textAlign: "right" }}
                  >
                    {item.A_Summary}
                  </div> */}
                  {/* <div
                  // style={{ textAlign: "right" }}
                  className="redLink"
                  onClick={() => {
                    // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
                    //               rows where N_HomeLocationGroup_ID === 1:-
                    if (item.N_BillboardOrigin_ID === 2) {
                      window.location = `../detailsMaster/${item.ID}`;
                    } else if (item.N_BillboardOrigin_ID === 3) {
                      window.location = `../detailsDetail/${item.ID}`;
                    }
                  }}
                >
                  More...
                </div> */}
                </div>
                <div
                  className="d-sm-block d-md-none titleLinkBackground-sm titleLink"
                  // style={{ textAlign: "right" }}
                  onClick={() => {
                    // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
                    //               rows where N_HomeLocationGroup_ID === 1:-
                    if (item.N_BillboardOrigin_ID === 1) {
                      window.location = `../detailsGroup/${item.ID}`;
                    } else if (item.N_BillboardOrigin_ID === 2) {
                      window.location = `../detailsMaster/${item.ID}`;
                    } else if (item.N_BillboardOrigin_ID === 3) {
                      window.location = `../detailsDetail/${item.ID}`;
                    }
                  }}
                >
                  <div
                    className="d-none d-lg-block titleLink"
                    // style={{ textAlign: "right" }}
                  >
                    {item.A_Reference}
                  </div>
                  <div
                    className="d-none d-md-block d-lg-none titleLink-md"
                    // style={{ textAlign: "right" }}
                  >
                    {item.A_Reference}
                  </div>
                  <div
                    className="d-sm-block d-md-none titleLink-sm"
                    // style={{ textAlign: "right" }}
                  >
                    {item.A_Reference}
                  </div>
                  {/* <div
                    className="titleLinkBlack"
                    style={{ marginTop: "5px" }}
                    // style={{ textAlign: "right" }}
                  >
                    {item.A_Summary}
                  </div> */}
                  {/* <div
                  // style={{ textAlign: "right" }}
                  className="redLink"
                  onClick={() => {
                    // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
                    //               rows where N_HomeLocationGroup_ID === 1:-
                    if (item.N_BillboardOrigin_ID === 2) {
                      window.location = `../detailsMaster/${item.ID}`;
                    } else if (item.N_BillboardOrigin_ID === 3) {
                      window.location = `../detailsDetail/${item.ID}`;
                    }
                  }}
                >
                  More...
                </div> */}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div>
          <ScrollUpButton style={{ marginBottom: "80px", display: "flex" }} />
        </div>
        <div>
          <NavBarFooter
            nameOfClass="footer"
            customizationSingleRow={this.props.customizationSingleRow}
            menuOnNavvar={this.props.menuOnNavvar}
            onClickSearch={this.onClickSearchHandle}
          />
        </div>
      </div>
    );
  }
}

export default SearchDynamic;
