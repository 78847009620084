import jwtDecode from "jwt-decode";
// import http from "./httpService";

// import { apiUrl } from "../config.json";
// const apiEndpoint = apiUrl + "/auth";

// const apiEndpoint = "/auth";

const tokenKey = "token";

// http.setJwt(getJwt());

// export async function login(a_email, a_password, b_rememberMe) {
//   //console.log(apiEndpoint, { a_email, a_password });

//   const { data: jwt } = await http.post(apiEndpoint, { a_email, a_password });
//   if (b_rememberMe) localStorage.setItem(tokenKey, jwt);
//   else sessionStorage.setItem(tokenKey, jwt);
// }

// export function loginWithJwt(jwt) {
//   if (localStorage.getItem(tokenKey)) localStorage.setItem(tokenKey, jwt);
//   else sessionStorage.setItem(tokenKey, jwt);
// }

export function logout() {
  if (localStorage.getItem(tokenKey)) localStorage.removeItem(tokenKey);
  else sessionStorage.removeItem(tokenKey);
}

export function getCurrentUser() {
  try {
    let jwt;
    if (localStorage.getItem(tokenKey)) jwt = localStorage.getItem(tokenKey);
    else jwt = sessionStorage.getItem(tokenKey);

    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export function getJwt() {
  if (localStorage.getItem(tokenKey)) return localStorage.getItem(tokenKey);
  else return sessionStorage.getItem(tokenKey);
}

export default {
  //   login,
  //   loginWithJwt,
  logout,
  getCurrentUser,
  getJwt
};
