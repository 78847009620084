import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

const Paginsation = props => {
  const { itemsCount, pageSize, currentPage, onPageChange } = props;
  //console.log("Paginsation 01 : ", itemsCount, pageSize, currentPage);

  let pagesCount = Math.ceil(itemsCount / pageSize);

  //console.log("Paginsation 02 : ", pagesCount);
  if (pagesCount === 1) return null;

  const pages = _.range(1, pagesCount + 1);

  //console.log("Paginsation 03 : ", pages);

  // Babak's Note: First version:-
  return (
    <nav>
      <ul className="pagination">
        <li
          key={"first"}
          className={
            currentPage === 1
              ? "page-item disabled"
              : "page-item active enabled"
          }
        >
          <a className="page-link" onClick={() => onPageChange(1)}>
            <span aria-hidden="true">&#171;</span>
            <span className="sr-only">First</span>
          </a>
        </li>
        <li
          key={"previous"}
          className={
            currentPage === 1
              ? "page-item disabled"
              : "page-item active enabled"
          }
        >
          <a
            className="page-link"
            onClick={() => onPageChange(currentPage - 1)}
          >
            <span aria-hidden="true">&#8249;</span>
            <span className="sr-only">Previous</span>
          </a>
        </li>
        {pages.map(page => (
          <li
            key={page}
            className={page === currentPage ? "page-item active" : "page-item"}
          >
            <a className="page-link" onClick={() => onPageChange(page)}>
              {page}
            </a>
          </li>
        ))}
        <li
          key={"next"}
          className={
            currentPage === pagesCount
              ? "page-item disabled"
              : "page-item active enabled"
          }
        >
          <a
            className="page-link"
            onClick={() => onPageChange(currentPage + 1)}
          >
            <span aria-hidden="true">&#8250;</span>
            <span className="sr-only">Next</span>
          </a>
        </li>
        <li
          key={"last"}
          className={
            currentPage === pagesCount
              ? "page-item disabled"
              : "page-item active enabled"
          }
        >
          <a className="page-link" onClick={() => onPageChange(pagesCount)}>
            <span aria-hidden="true">&#187;</span>
            <span className="sr-only">Last</span>
          </a>
        </li>
      </ul>
    </nav>
  );

  // // Babak's Note: Second version:-
  // return (
  //   <nav>
  //     <ul className="pagination">
  //       {pages.map(page =>
  //         page === 1 ||
  //         page === currentPage ||
  //         page === currentPage + 1 ||
  //         page === currentPage - 1 ||
  //         page === pagesCount ? (
  //           <li
  //             key={page}
  //             className={
  //               page === currentPage ? "page-item active" : "page-item"
  //             }
  //           >
  //             <a className="page-link" onClick={() => onPageChange(page)}>
  //               {page === 1 && currentPage > 1
  //                 ? "<<"
  //                 : page === pagesCount && currentPage < pagesCount
  //                 ? ">>"
  //                 : page}
  //             </a>
  //           </li>
  //         ) : (
  //           ""
  //         )
  //       )}
  //     </ul>
  //   </nav>
  // );

  // // Babak's Note: Third version:-
  // return (
  //   <nav>
  //     <ul className="pagination">
  //       {pages.map(page =>
  //         page === 1 ||
  //         page === currentPage ||
  //         page === currentPage + 1 ||
  //         page === currentPage - 1 ||
  //         page === currentPage + 2 ||
  //         page === currentPage - 2 ||
  //         page === pagesCount ? (
  //           <li
  //             key={page}
  //             className={
  //               page === currentPage ? "page-item active" : "page-item"
  //             }
  //           >
  //             <a className="page-link" onClick={() => onPageChange(page)}>
  //               {page === 1 && currentPage > 1
  //                 ? "<<"
  //                 : page === pagesCount && currentPage < pagesCount
  //                 ? ">>"
  //                 : page}
  //             </a>
  //           </li>
  //         ) : (
  //           ""
  //         )
  //       )}
  //     </ul>
  //   </nav>
  // );

  // // Babak's Note: Fourth version:-
  // return (
  //   <nav>
  //     <ul className="pagination">
  //       {pages.map(page =>
  //         page === 1 ||
  //         page === currentPage ||
  //         page === currentPage + 1 ||
  //         page === currentPage - 1 ||
  //         page === currentPage + 2 ||
  //         page === currentPage - 2 ||
  //         page === pagesCount ? (
  //           <li
  //             key={page}
  //             className={
  //               page === currentPage ? "page-item active" : "page-item"
  //             }
  //           >
  //             <a className="page-link" onClick={() => onPageChange(page)}>
  //               {page === 1 && currentPage > 1
  //                 ? "<<"
  //                 : page === pagesCount && currentPage < pagesCount
  //                 ? ">>"
  //                 : page}
  //             </a>
  //           </li>
  //         ) : (
  //           ""
  //         )
  //       )}
  //     </ul>
  //   </nav>
  // );
};

Paginsation.propTypes = {
  itemsCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired
};
export default Paginsation;
