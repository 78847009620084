import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import auth from "../../services/authService";
import {
  loadHomeBillboard,
  addHomeBillboard,
  changeHomeBillboard
} from "../../store/homeBillboards";
import { loadRadioGrpDirections } from "../../store/radioGrpDirections";
import { connect } from "react-redux";

//import ControlledEditor from "../common/controlledEditor";

class HomeBillboardForm extends Form {
  state = {
    data: {
      N_OrderNo: "",
      N_RadioGrpDirections_ID: "",
      // N_HomeBillboards_ID: "",
      // A_BillboardLargeLink: "",
      A_Reference: "",
      A_Summary: "",
      A_Description: "",
      A_HoverSummary01: "",
      A_HoverSummary01_Link: "",
      A_HoverSummary02: "",
      A_HoverSummary02_Link: "",
      A_HoverSummary03: "",
      A_HoverSummary03_Link: "",
      A_HoverSummary04: "",
      A_HoverSummary04_Link: "",
      A_HoverSummary05: "",
      A_HoverSummary05_Link: "",
      A_HoverSummary06: "",
      A_HoverSummary07: "",
      A_HoverSummary08: "",
      A_HoverSummary08_Link: "",
      B_HoverSummary08_External: false,
      A_BGC: "",
      A_BGC_Shadow: "",
      B_BGC_Shadow_Off: true,
      A_TextFC: "",
      I_Image: "",
      // N_PhotoSize01: "",
      waitingForFileUpload: false,
      I_ImageOnImage: "",
      // N_PhotoSize02: "",
      waitingForFileUpload02: false,
      A_Button_Link: "",
      B_Button_Link_External: false,
      A_Button: "",
      B_GoogleMapHomeBiilboard: false
    },
    errors: {},
    radioGrpDirection: [],
    N_RadioGrpDirections_ID: "",
    user: []
  };

  schemaCreate = {
    ID: Joi.number(),
    N_OrderNo: Joi.number()
      .required()
      .min(1)
      .max(100)
      .label("Order No"),
    N_RadioGrpDirections_ID: Joi.number()
      .required()
      .min(1)
      .max(10)
      .label("Direction"),
    // N_HomeBillboards_ID: Joi.number()
    //   .allow("")
    //   .optional(),
    // A_BillboardLargeLink: Joi.string()
    //   .allow("")
    //   // .required()
    //   .optional()
    //   // .min(5)
    //   .max(1000)
    //   .label("URL Link"),
    A_Reference: Joi.string()
      .allow("")
      // .required()
      .optional()
      // .min(5)
      .max(20)
      .label("Reference"),
    A_Summary: Joi.string()
      .allow("")
      // .required()
      .optional()
      //.min(5)
      .max(25)
      .label("Summary"),
    A_Description: Joi.string()
      .allow("")
      // .required()
      .optional()
      // .min(5)
      .max(10)
      .label("Description"),
    A_HoverSummary01: Joi.string()
      .allow("")
      .optional()
      .max(20)
      .label("Hover One"),
    A_HoverSummary01_Link: Joi.string()
      .allow("")
      // .required()
      .optional()
      // .min(5)
      .max(1000)
      .label("Hover One URL Link"),
    A_HoverSummary02: Joi.string()
      .allow("")
      .optional()
      .max(20)
      .label("Hover Two"),
    A_HoverSummary02_Link: Joi.string()
      .allow("")
      // .required()
      .optional()
      // .min(5)
      .max(1000)
      .label("Hover Two URL Link"),
    A_HoverSummary03: Joi.string()
      .allow("")
      .optional()
      .max(20)
      .label("Hover Three"),
    A_HoverSummary03_Link: Joi.string()
      .allow("")
      // .required()
      .optional()
      // .min(5)
      .max(1000)
      .label("Hover Three URL Link"),
    A_HoverSummary04: Joi.string()
      .allow("")
      .optional()
      .max(20)
      .label("Hover Four"),
    A_HoverSummary04_Link: Joi.string()
      .allow("")
      // .required()
      .optional()
      // .min(5)
      .max(1000)
      .label("Hover Four URL Link"),
    A_HoverSummary05: Joi.string()
      .allow("")
      .optional()
      .max(20)
      .label("Hover Five"),
    A_HoverSummary05_Link: Joi.string()
      .allow("")
      // .required()
      .optional()
      // .min(5)
      .max(1000)
      .label("Hover Five URL Link"),
    A_HoverSummary06: Joi.string()
      .allow("")
      .optional()
      .max(70)
      .label("Hover Six"),
    A_HoverSummary07: Joi.string()
      .allow("")
      .optional()
      .max(73)
      .label("Hover Seven"),
    A_HoverSummary08: Joi.string()
      .allow("")
      .optional()
      .max(20)
      .label("Hover Eight"),
    A_HoverSummary08_Link: Joi.string()
      .allow("")
      // .required()
      .optional()
      // .min(5)
      .max(1000)
      .label("Hover Eight URL Link"),
    B_HoverSummary08_External: Joi.boolean().label("Hover Eight External Link"),
    A_BGC: Joi.string()
      .required()
      .min(5)
      .max(50)
      .label("Background Colour"),
    A_BGC_Shadow: Joi.string()
      .required()
      .min(5)
      .max(50)
      .label("Background Colour Shadow"),
    B_BGC_Shadow_Off: Joi.boolean().label("Background Colour Shadow Off"),
    A_TextFC: Joi.string()
      .required()
      .min(5)
      .max(50)
      .label("Title Text Colour"),
    I_Image: Joi.any()
      .meta({ swaggerType: "file" })
      // .required()
      .optional()
      .label("Image"),
    N_PhotoSize01: Joi.number()
      .min(5000)
      .max(10000000)
      .optional(),
    waitingForFileUpload: Joi.boolean().optional(),
    I_ImageOnImage: Joi.any()
      .meta({ swaggerType: "file" })
      // .required()
      .optional()
      .label("ImageOnImage"),
    N_PhotoSize02: Joi.number()
      .min(5000)
      .max(10000000)
      .optional(),
    waitingForFileUpload02: Joi.boolean().optional(),
    A_Button_Link: Joi.string()
      .allow("")
      // .required()
      .optional()
      // .min(5)
      .max(1000)
      .label("Learn More URL Link"),
    B_Button_Link_External: Joi.boolean().label("Learn More URL Link External"),
    A_Button: Joi.string()
      .allow("")
      // .required()
      .optional()
      // .min(5)
      .max(15)
      .label("Learn More Button"),
    B_GoogleMapHomeBiilboard: Joi.boolean().label("Google Map")
  };

  schemaEdit = {
    ID: Joi.number(),
    N_OrderNo: Joi.number()
      .required()
      .min(1)
      .max(100)
      .label("Order No"),
    N_RadioGrpDirections_ID: Joi.number()
      .required()
      .min(1)
      .max(10)
      .label("Direction"),
    // N_HomeBillboards_ID: Joi.number()
    //   .allow("")
    //   .optional(),
    // A_BillboardLargeLink: Joi.string()
    //   .allow("")
    //   // .required()
    //   .optional()
    //   // .min(5)
    //   .max(1000)
    //   .label("URL Link"),
    A_Reference: Joi.string()
      .allow("")
      // .required()
      .optional()
      // .min(5)
      .max(20)
      .label("Reference"),
    A_Summary: Joi.string()
      .allow("")
      // .required()
      .optional()
      //.min(5)
      .max(25)
      .label("Summary"),
    A_Description: Joi.string()
      .allow("")
      // .required()
      .optional()
      //.min(5)
      .max(10)
      .label("Description"),
    A_HoverSummary01: Joi.string()
      .allow("")
      .optional()
      .max(20)
      .label("Hover One"),
    A_HoverSummary01_Link: Joi.string()
      .allow("")
      // .required()
      .optional()
      // .min(5)
      .max(1000)
      .label("Hover One URL Link"),
    A_HoverSummary02: Joi.string()
      .allow("")
      .optional()
      .max(20)
      .label("Hover Two"),
    A_HoverSummary02_Link: Joi.string()
      .allow("")
      // .required()
      .optional()
      // .min(5)
      .max(1000)
      .label("Hover Two URL Link"),
    A_HoverSummary03: Joi.string()
      .allow("")
      .optional()
      .max(20)
      .label("Hover Three"),
    A_HoverSummary03_Link: Joi.string()
      .allow("")
      // .required()
      .optional()
      // .min(5)
      .max(1000)
      .label("Hover Three URL Link"),
    A_HoverSummary04: Joi.string()
      .allow("")
      .optional()
      .max(20)
      .label("Hover Four"),
    A_HoverSummary04_Link: Joi.string()
      .allow("")
      // .required()
      .optional()
      // .min(5)
      .max(1000)
      .label("Hover Four URL Link"),
    A_HoverSummary05: Joi.string()
      .allow("")
      .optional()
      .max(20)
      .label("Hover Five"),
    A_HoverSummary05_Link: Joi.string()
      .allow("")
      // .required()
      .optional()
      // .min(5)
      .max(1000)
      .label("Hover Five URL Link"),
    A_HoverSummary06: Joi.string()
      .allow("")
      .optional()
      .max(70)
      .label("Hover Six"),
    A_HoverSummary07: Joi.string()
      .allow("")
      .optional()
      .max(73)
      .label("Hover Seven"),
    A_HoverSummary08: Joi.string()
      .allow("")
      .optional()
      .max(20)
      .label("Hover Eight"),
    A_HoverSummary08_Link: Joi.string()
      .allow("")
      // .required()
      .optional()
      // .min(5)
      .max(1000)
      .label("Hover Eight URL Link"),
    B_HoverSummary08_External: Joi.boolean().label("Hover Eight External Link"),
    A_BGC: Joi.string()
      .required()
      .min(5)
      .max(50)
      .label("Background Colour"),
    A_BGC_Shadow: Joi.string()
      .required()
      .min(5)
      .max(50)
      .label("Background Colour Shadow"),
    B_BGC_Shadow_Off: Joi.boolean().label("Background Colour Shadow Off"),
    A_TextFC: Joi.string()
      .required()
      .min(5)
      .max(50)
      .label("Title Text Colour"),
    I_Image: Joi.any()
      .meta({ swaggerType: "file" })
      // .required()
      .optional()
      .label("Image"),
    N_PhotoSize01: Joi.number()
      .min(5000)
      .max(10000000)
      .optional(),
    waitingForFileUpload: Joi.boolean().optional(),
    I_ImageOnImage: Joi.any()
      .meta({ swaggerType: "file" })
      // .required()
      .optional()
      .label("ImageOnImage"),
    N_PhotoSize02: Joi.number()
      .min(5000)
      .max(10000000)
      .optional(),
    waitingForFileUpload02: Joi.boolean().optional(),
    A_Button_Link: Joi.string()
      .allow("")
      // .required()
      .optional()
      // .min(5)
      .max(1000)
      .label("Learn More URL Link"),
    B_Button_Link_External: Joi.boolean().label("Learn More URL Link External"),
    A_Button: Joi.string()
      .allow("")
      // .required()
      .optional()
      // .min(5)
      .max(15)
      .label("Learn More Button"),
    B_GoogleMapHomeBiilboard: Joi.boolean().label("Google Map")
  };

  async populateHomeBillboard() {
    //console.log("populateHomeBillboard-1 : ", this.props.match.params.id);
    try {
      let data = { ...this.state.data };
      let {
        N_RadioGrpDirections_ID
        // N_HomeBillboards_ID,
        // A_BillboardLargeLink
      } = this.state;

      await this.props.loadRadioGrpDirections();

      const homeBillboardId = this.props.match.params.id;

      if (homeBillboardId === "new") {
        data["N_RadioGrpDirections_ID"] = 1;
        N_RadioGrpDirections_ID = 1;
        // data["N_HomeBillboards_ID"] = null;
        // N_HomeBillboards_ID = null;
        // data["A_BillboardLargeLink"] = null;
        // A_BillboardLargeLink = null;

        this.setState({
          data,
          N_RadioGrpDirections_ID,
          // N_HomeBillboards_ID,
          user: auth.getCurrentUser()
        });
        return;
      }

      await this.props.loadHomeBillboard(homeBillboardId);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async componentDidMount() {
    await this.populateHomeBillboard();
  }

  async componentWillReceiveProps(nextProps) {
    // window.alert("MenuForm, componentWillReceiveProps", nextProps.menuSuccess);

    let data = { ...this.state.data };
    let {
      N_RadioGrpDirections_ID
      // N_HomeBillboards_ID,
      // A_BillboardLargeLink
    } = this.state;

    if (
      this.props.match.params.id !== "new" &&
      this.state.data !== nextProps.homeBillboard
    ) {
      data["ID"] = nextProps.homeBillboard.ID;
      data["N_OrderNo"] = nextProps.homeBillboard.N_OrderNo;
      N_RadioGrpDirections_ID = nextProps.homeBillboard.N_RadioGrpDirections_ID;
      data["N_RadioGrpDirections_ID"] =
        nextProps.homeBillboard.N_RadioGrpDirections_ID;
      data["A_Reference"] = nextProps.homeBillboard.A_Reference;
      data["A_Summary"] = nextProps.homeBillboard.A_Summary;
      data["A_Description"] = nextProps.homeBillboard.A_Description;
      data["A_HoverSummary01"] = nextProps.homeBillboard.A_HoverSummary01;
      data["A_HoverSummary01_Link"] =
        nextProps.homeBillboard.A_HoverSummary01_Link;
      data["A_HoverSummary02"] = nextProps.homeBillboard.A_HoverSummary02;
      data["A_HoverSummary02_Link"] =
        nextProps.homeBillboard.A_HoverSummary02_Link;
      data["A_HoverSummary03"] = nextProps.homeBillboard.A_HoverSummary03;
      data["A_HoverSummary03_Link"] =
        nextProps.homeBillboard.A_HoverSummary03_Link;
      data["A_HoverSummary04"] = nextProps.homeBillboard.A_HoverSummary04;
      data["A_HoverSummary04_Link"] =
        nextProps.homeBillboard.A_HoverSummary04_Link;
      data["A_HoverSummary05"] = nextProps.homeBillboard.A_HoverSummary05;
      data["A_HoverSummary05_Link"] =
        nextProps.homeBillboard.A_HoverSummary05_Link;
      data["A_HoverSummary06"] = nextProps.homeBillboard.A_HoverSummary06;
      data["A_HoverSummary07"] = nextProps.homeBillboard.A_HoverSummary07;
      data["A_HoverSummary08"] = nextProps.homeBillboard.A_HoverSummary08;
      data["A_HoverSummary08_Link"] =
        nextProps.homeBillboard.A_HoverSummary08_Link;
      data["B_HoverSummary08_External"] =
        nextProps.homeBillboard.B_HoverSummary08_External;
      data["A_BGC"] = nextProps.homeBillboard.A_BGC;
      data["A_BGC_Shadow"] = nextProps.homeBillboard.A_BGC_Shadow;
      data["B_BGC_Shadow_Off"] = nextProps.homeBillboard.B_BGC_Shadow_Off;
      data["A_TextFC"] = nextProps.homeBillboard.A_TextFC;
      data["I_Image"] = nextProps.homeBillboard.I_Image;
      // data["N_PhotoSize01"] = 10000;
      data["I_ImageOnImage"] = nextProps.homeBillboard.I_ImageOnImage;
      // data["N_PhotoSize02"] = 10000;
      data["A_Button_Link"] = nextProps.homeBillboard.A_Button_Link;
      data["B_Button_Link_External"] =
        nextProps.homeBillboard.B_Button_Link_External;
      data["A_Button"] = nextProps.homeBillboard.A_Button;
      data["B_GoogleMapHomeBiilboard"] =
        nextProps.homeBillboard.B_GoogleMapHomeBiilboard;

      this.setState({
        data,
        N_RadioGrpDirections_ID,
        radioGrpDirections: await nextProps.radioGrpDirections,
        user: auth.getCurrentUser()
      });
    } else {
      data["N_RadioGrpDirections_ID"] = 1;
      N_RadioGrpDirections_ID = 1;
      // data["N_HomeBillboards_ID"] = null;
      // N_HomeBillboards_ID = null;
      // data["A_BillboardLargeLink"] = null;
      // A_BillboardLargeLink = null;

      this.setState({
        data,
        N_RadioGrpDirections_ID,
        radioGrpDirections: await nextProps.radioGrpDirections,
        user: auth.getCurrentUser()
      });
    }
  }

  // mapToViewModel(homeBillboard) {
  //   //console.log("mapToViewModel-1", month);
  //   return {
  //     ID: homeBillboard.ID,
  //     N_OrderNo: homeBillboard.N_OrderNo,
  //     N_RadioGrpDirection: homeBillboard.N_RadioGrpDirection,
  //     A_Reference: homeBillboard.A_Reference,
  //     A_Summary: homeBillboard.A_Summary,
  //     A_Description: homeBillboard.A_Description,
  //     A_HoverSummary01: homeBillboard.A_HoverSummary01,
  //     A_HoverSummary02: homeBillboard.A_HoverSummary02,
  //     A_HoverSummary03: homeBillboard.A_HoverSummary03,
  //     A_BulletPointParent: homeBillboard.A_BulletPointParent,
  //     A_BulletPointChild: homeBillboard.A_BulletPointChild,
  //     A_BulletPointerIconColour: homeBillboard.A_BulletPointerIconColour,
  //     A_BulletPointerIcon: homeBillboard.A_BulletPointerIcon,
  //     B_TimeLine: homeBillboard.B_TimeLine,
  //     //A_Description: convertFromRaw(JSON.parse(homeBillboard.A_Description)),
  //     I_Image: homeBillboard.I_Image
  //   };
  // }

  doSubmit = async () => {
    try {
      const homeBillboardId = this.props.match.params.id;
      if (homeBillboardId === "new") {
        const currentDate = new Date();
        const month = currentDate.getMonth() + 1;
        const currentDateWithoutTime =
          month +
          "/" +
          currentDate.getDate() +
          "/" +
          currentDate.getFullYear() +
          " " +
          currentDate.getHours() +
          ":" +
          currentDate.getMinutes();

        await this.props.addHomeBillboard(
          this.state.data,
          auth.getCurrentUser().a_name,
          currentDateWithoutTime
        );
      } else {
        const currentDate = new Date();
        const month = currentDate.getMonth() + 1;
        const currentDateWithoutTime =
          month +
          "/" +
          currentDate.getDate() +
          "/" +
          currentDate.getFullYear() +
          " " +
          currentDate.getHours() +
          ":" +
          currentDate.getMinutes();

        await this.props.changeHomeBillboard(
          this.state.data,
          auth.getCurrentUser().a_name,
          currentDateWithoutTime
        );
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.title = ex.response.data;
        this.setState({ errors });
      }
    }

    await this.props.history.goBack();
  };

  clearImages = () => {
    let obj = this.state.data;
    obj["I_Image"] = "";
    //
    // Babak's Note: Unquate below line to make Image mandatory:-
    // obj["N_PhotoSize01"] = "";
    this.setState({ data: obj });
  };

  clearSideImage = () => {
    let obj = this.state.data;
    obj["I_ImageOnImage"] = "";
    // obj["N_PhotoSize02"] = "";
    this.setState({ data: obj });
  };

  cancelForm = async () => {
    this.props.history.push("/homeBillboardsList");
    //  await this.props.history.goBack();
  };

  constructor() {
    // ...
    super();

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    // console.log(
    //   "render",
    //   this.state.data["N_OrderNo"],
    //   this.state.data["N_RadioGrpDirection"],
    //   this.state.N_RadioGrpDirection,
    //   this.state.data["A_Summary"],
    //   this.state.data["A_Description"]
    // );

    const homeBillboardId = this.props.match.params.id;

    return (
      <div
        className="container"
        style={{ marginTop: "160px", marginBottom: "100px" }}
      >
        <h1>Home Billboard Form</h1>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("N_OrderNo", "Order Number")}

          <br />
          {homeBillboardId === "new"
            ? this.renderRadioGroupDirections(
                "N_RadioGrpDirections_ID",
                "A_Destination",
                "Direction",
                this.props.radioGrpDirections,
                0
              )
            : this.renderRadioGroupDirections(
                "N_RadioGrpDirections_ID",
                "A_Destination",
                "Direction",
                this.props.radioGrpDirections,
                99
              )}
          <br />

          <br />
          {this.renderInputColourPicker("A_BGC", "Background Colour")}
          <br />

          {this.renderInputColourPicker(
            "A_BGC_Shadow",
            "Background Colour Shadow"
          )}
          {this.renderCheckbox("B_BGC_Shadow_Off", "Background Shadow")}
          <br />

          {this.renderImageFile("I_Image", "Background Image")}
          {this.state.waitingForFileUpload && (
            <div>
              <span>Uploading file...</span>
            </div>
          )}
          <button
            type="button"
            className="btn btn-warning"
            onClick={this.clearImages}
          >
            Clear Image
          </button>
          <br />
          <img style={{ maxWidth: "300px" }} src={this.state.data.I_Image} />
          {/* <img
            style={{ maxWidth: "300px" }}
            src={`data:image/jpeg;base64,${this.state.data.I_Photo01}`}
          /> */}

          {/* <br />
          <br />

          {this.renderInputBillboardLargeLink(
            "A_BillboardLargeLink",
            "URL Link"
          )} */}

          <br />
          <br />

          {this.renderSideImageFile("I_ImageOnImage", "Upload Side Image")}
          {this.state.waitingForFileUpload02 && (
            <div>
              <span>Uploading file...</span>
            </div>
          )}
          <button
            type="button"
            className="btn btn-warning"
            onClick={this.clearSideImage}
          >
            Clear Side Image
          </button>
          <br />
          <img
            style={{ maxWidth: "300px" }}
            src={this.state.data.I_ImageOnImage}
          />

          <br />
          <br />

          {this.renderInputHoverSummary("A_HoverSummary01", "Hover One")}
          {this.renderInputHoverSummary(
            "A_HoverSummary01_Link",
            "Hover One URL Link"
          )}
          {this.renderInputHoverSummary("A_HoverSummary02", "Hover Two")}
          {this.renderInputHoverSummary(
            "A_HoverSummary02_Link",
            "Hover Two URL Link"
          )}
          {this.renderInputHoverSummary("A_HoverSummary03", "Hover Three")}
          {this.renderInputHoverSummary(
            "A_HoverSummary03_Link",
            "Hover Three URL Link"
          )}
          {this.renderInputHoverSummary("A_HoverSummary04", "Hover Four")}
          {this.renderInputHoverSummary(
            "A_HoverSummary04_Link",
            "Hover Four URL Link"
          )}
          {this.renderInputHoverSummary("A_HoverSummary05", "Hover Five")}
          {this.renderInputHoverSummary(
            "A_HoverSummary05_Link",
            "Hover Five URL Link"
          )}
          {this.renderInputHoverSummary("A_HoverSummary06", "Hover Six")}
          {this.renderInputHoverSummary("A_HoverSummary07", "Hover Seven")}
          {this.renderInputHoverSummary("A_HoverSummary08", "Hover Eight")}
          {this.renderInputHoverSummary(
            "A_HoverSummary08_Link",
            "Hover Eight URL Link"
          )}
          {this.renderCheckboxHover(
            "B_HoverSummary08_External",
            "Hover Eight External Link"
          )}

          <br />
          <br />

          {this.renderInputColourPicker("A_TextFC", "Title Text Colour")}
          <br />

          {this.renderInputReference("A_Reference", "Reference")}
          {this.renderInputSummary("A_Summary", "Summary")}
          {this.renderInput("A_Description", "Description")}
          <br />

          {this.renderInputLinkButton("A_Button_Link", "Learn More URL Link")}
          {this.renderCheckboxLinkButton(
            "B_Button_Link_External",
            "Learn More URL Link External"
          )}
          {this.renderInputButton("A_Button", "Learn More Button")}

          <br />

          {this.renderCheckboxGoogleMap(
            "B_GoogleMapHomeBiilboard",
            "Google Map"
          )}

          <div>
            <br />
          </div>
          {this.renderButton("Save")}
          {this.renderButtonCancel("Cancel")}
          <br />
        </form>
        <br />
      </div>
    );
  }
}

//
// Babak's Notes: goto tutorial directory '11-Integration with React' file '5- Connecting Components Using react-redux'
const mapStateToProps = state => ({
  //   menus: state.entities.menus.list.filter(menu => !menu.resolved)
  homeBillboard: state.entities.homeBillboards.listSingleRow,
  radioGrpDirections: state.entities.radioGrpDirections.list
});

const mapDispatchToProps = dispatch => ({
  loadHomeBillboard: id => dispatch(loadHomeBillboard(id)),
  loadRadioGrpDirections: () => dispatch(loadRadioGrpDirections()),
  addHomeBillboard: (homeBillboard, user, currentDate) =>
    dispatch(addHomeBillboard(homeBillboard, user, currentDate)),
  changeHomeBillboard: (homeBillboard, user, currentDate) =>
    dispatch(changeHomeBillboard(homeBillboard, user, currentDate))
});

// Container Component
//   Presentation (Menus)
//
// Babak's Notes: Below I populate 'this.props.menus' and 'this.props.loadMenus()':-
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeBillboardForm);
