import axios from "axios";
import logger from "./logService";
import { toast } from "react-toastify";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.response.use(null, error => {
  //console.log("axios 01 : ", process.env.REACT_APP_API_URL);
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  //console.log("axios : ", axios.defaults.maxContentLength);

  if (!expectedError) {
    //console.log("axios 02 : ", error);
    logger.log(error);
    //console.log("Logging the error", error);
    // alert("An unexpected error occurred.");
    toast("An unexpected error occurred.");
    // toast.error("An unexpected error occurred.");
    //toast.success
    //toast.info
  }

  return Promise.reject(error);
});

function setJwt(jwt) {
  //
  // Babak's Note: Below line is for when ever we want a http request, we add this token to 'axios'. We require
  //               to add this as we set 'true' in project 'vidly-api-node' -> 'default.json' ->
  //               "requireAuth: true". So this means we require authority or authentication in order to want
  //               a http request and by adding our authentication token to 'axios' we are authenticaed.
  //               Therefore if user is not logged in, this token will not be set and so no authority with be
  //               given.
  //axios.defaults.maxContentLength = Infinity;
  axios.defaults.headers.common["x-auth-token"] = jwt;
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt
};
