import React, { Component } from "react";
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker
} from "react-google-maps";

let lat; // = 45.383321536272049;
let lng; // = -75.3372987731628;

function Map() {
  return (
    <GoogleMap defaultZoom={10} defaultCenter={{ lat: lat, lng: lng }}>
      <Marker key={1} position={{ lat: lat, lng: lng }} />
    </GoogleMap>
  );
}

class GoogleMaps extends Component {
  //
  // https://elfsight.com/blog/2018/06/how-to-get-google-maps-api-key-guide/

  render() {
    const customizationSingleRow = this.props.customizationSingleRow;

    // window.alert(
    //   `api key : ${process.env.REACT_APP_GOOGLE_API_KEY} -- ${process.env.REACT_APP_API_URL}`
    // );
    // window.alert(`api key : ${customizationSingleRow.A_GoogleKey}`);

    lat = customizationSingleRow.F_Lat;
    lng = customizationSingleRow.F_Lng;

    const WrapperMap = withScriptjs(withGoogleMap(Map));

    return (
      <div style={{ marginTop: "10px", height: "50vh" }}>
        <WrapperMap
          // googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&KEY=${process.env.REACT_APP_GOOGLE_API_KEY}`}
          googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&KEY=${customizationSingleRow.A_GoogleKey}`}
          loadingElement={<div style={{ height: `240px`, width: `100%` }} />}
          containerElement={<div style={{ height: `240px`, width: `100%` }} />}
          mapElement={<div style={{ height: `240px`, width: `100%` }} />}
        />
      </div>
    );
  }
}

export default GoogleMaps;
