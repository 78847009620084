import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
import moment from "moment";

const slice = createSlice({
  name: "homeLocationGroups",
  initialState: {
    list: [],
    loading: false,
    lastFetch: null
  },
  reducers: {
    //
    // Babak's Note: By adding 'homeLocationGroupsRequested' or 'homeLocationGroupsReceived' below, a action is created for us:-
    // actions => action handlers. The 'homeLocationGroups' below represents the 'state':-
    homeLocationGroupsRequested: (homeLocationGroups, action) => {
      console.log("homeLocationGroupsRequested : ", action.payload);
      homeLocationGroups.loading = true;
    },
    homeLocationGroupsReceived: (homeLocationGroups, action) => {
      console.log("homeLocationGroupsReceived : ", action.payload);
      homeLocationGroups.list = action.payload;
      homeLocationGroups.loading = false;
      homeLocationGroups.lastFetch = Date.now();
    },
    homeLocationGroupsRequestedFailed: (homeLocationGroups, action) => {
      homeLocationGroups.loading = false;
      // window.alert(`No HomeLocationGroups found.`);
    },
    homeLocationGroupRequested: (homeLocationGroup, action) => {
      console.log("homeLocationGroupRequested : ", action.payload);
      homeLocationGroup.loading = true;
    },
    homeLocationGroupReceived: (homeLocationGroup, action) => {
      console.log("homeLocationGroupReceived : ", action.payload);
      homeLocationGroup.list = action.payload;
      homeLocationGroup.loading = false;
      homeLocationGroup.lastFetch = Date.now();
    },
    homeLocationGroupRequestedFailed: (homeLocationGroup, action) => {
      homeLocationGroup.loading = false;
      // window.alert(`No HomeLocationGroup found.`);
    },
    homeLocationGroupChangeRequested: (homeLocationGroup, action) => {
      console.log("homeLocationGroupChangeRequested : ", action.payload);
      homeLocationGroup.loading = true;
    },
    homeLocationGroupChangeFailed: (homeLocationGroup, action) => {
      homeLocationGroup.loading = false;

      // //
      // // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete homeLocationGroup api at the back end.
      // const { ID: homeLocationGroupId, A_Name: homeLocationGroupName } = action.payload;

      // window.alert(`HomeLocationGroup '${homeLocationGroupName}' no longer exists.`);

      // const index = homeLocationGroup.list.findIndex(homeLocationGroup => homeLocationGroup.ID === homeLocationGroupId);
      // //console.log("homeLocationGroupDeleted ha ", index);
      // homeLocationGroup.list.splice(index, 1);
    },
    homeLocationGroupChanged: (homeLocationGroup, action) => {
      homeLocationGroup.loading = false;
    },
    homeLocationGroupDeleteRequested: (homeLocationGroup, action) => {
      console.log("homeLocationGroupDeleteRequested : ", action.payload);
      homeLocationGroup.loading = true;
    },
    homeLocationGroupDeleteFailed: (homeLocationGroups, action) => {
      homeLocationGroups.loading = false;

      //
      // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete homeLocationGroup api at the back end.
      const {
        ID: homeLocationGroupId,
        A_Name: homeLocationGroupName
      } = action.payload;

      window.alert(
        `An issue has occured with homeLocationGroup '${homeLocationGroupName}'. ${action.payload}`
      );

      const index = homeLocationGroups.list.findIndex(
        homeLocationGroup => homeLocationGroup.ID === homeLocationGroupId
      );
      //console.log("homeLocationGroupDeleted ha ", index);
      homeLocationGroups.list.splice(index, 1);
    },
    homeLocationGroupDeleted: (homeLocationGroups, action) => {
      //
      // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete homeLocationGroup api at the back end.
      const { ID: homeLocationGroupID } = action.payload;
      // console.log("homeLocationGroupDeleted ha ", action.payload, homeLocationGroupID, homeLocationGroups);
      const index = homeLocationGroups.list.findIndex(
        homeLocationGroup => homeLocationGroup.ID === homeLocationGroupID
      );
      //console.log("homeLocationGroupDeleted, ha! ha! ", index);
      homeLocationGroups.list.splice(index, 1);
    },
    homeLocationGroupAddRequested: (homeLocationGroup, action) => {
      console.log("homeLocationGroupAddRequested : ", action.payload);
      homeLocationGroup.loading = true;
    },
    homeLocationGroupAddFailed: (homeLocationGroup, action) => {
      homeLocationGroup.loading = false;
    },
    //
    // Babak's Note: The 'homeLocationGroups' below represents the 'state':-
    homeLocationGroupAdded: (homeLocationGroups, action) => {
      console.log("homeLocationGroupAdded : ", action.payload);
      homeLocationGroups.list.push(action.payload);
    }
    // homeLocationGroupAdded: (homeLocationGroups, action) => {
    //   homeLocationGroups.list.push({
    //     id: ++lastId,
    //     description: action.payload.description,
    //     resolved: false
    //   });
    // },
    // homeLocationGroupDeleted: (homeLocationGroups, action) => {
    //   console.log("homeLocationGroupDeleted ha ", action.payload.ID);
    //   homeLocationGroups.list.filter(homeLocationGroup => homeLocationGroup.ID !== action.payload.ID);
    // },
  }
});
const {
  homeLocationGroupAddRequested,
  homeLocationGroupAdded,
  homeLocationGroupAddFailed,
  homeLocationGroupChangeRequested,
  homeLocationGroupChanged,
  homeLocationGroupChangeFailed,
  homeLocationGroupDeleteRequested,
  homeLocationGroupDeleted,
  homeLocationGroupDeleteFailed,
  homeLocationGroupsReceived,
  homeLocationGroupsRequested,
  homeLocationGroupsRequestedFailed,
  homeLocationGroupReceived,
  homeLocationGroupRequested,
  homeLocationGroupRequestedFailed
} = slice.actions;
export default slice.reducer;

//
// Action Creators
const url = "/homeLocationGroups";

export const loadHomeLocationGroups = () => (dispatch, getState) => {
  const { lastFetch } = getState().entities.homeLocationGroups;

  console.log("loadHomeLocationGroups lastFetch : ", lastFetch);

  // //
  // // Babak's Note: Below we check with 'moment' that catch that if this request was already fetched less than 10 minutes ago then do not fetch again:-
  // const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  // if (diffInMinutes < 10) return;

  dispatch(
    apiCallBegan({
      url: url,
      onStart: homeLocationGroupsRequested.type,
      method: "get",
      onSuccess: homeLocationGroupsReceived.type,
      onError: homeLocationGroupsRequestedFailed.type
    })
  );
};

export const loadHomeLocationGroup = homeLocationGroupID => (
  dispatch,
  getState
) => {
  // const { lastFetch } = getState().entities.homeLocationGroups;

  //console.log("loadHomeLocationGroup : ", homeLocationGroupID);

  // //
  // // Babak's Note: Below we check with 'moment' that catch that if this request was already fetched less than 10 minutes ago then do not fetch again:-
  // const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  // if (diffInMinutes < 10) return;

  dispatch(
    apiCallBegan({
      url: url + "/" + homeLocationGroupID,
      onStart: homeLocationGroupRequested.type,
      method: "get",
      onSuccess: homeLocationGroupReceived.type,
      onError: homeLocationGroupRequestedFailed.type
    })
  );
};

//
// Babak's Note: Below function 'apiCallBegan' is returned back by which function (in this case from within 'index.js') calls 'addHomeLocationGroup':-
export const addHomeLocationGroup = homeLocationGroup =>
  apiCallBegan({
    url,
    method: "post",
    onStart: homeLocationGroupAddRequested.type,
    data: homeLocationGroup,
    onSuccess: homeLocationGroupAdded.type,
    onError: homeLocationGroupAddFailed.type
  });

export const getHomeLocationGroups = createSelector(
  state => state.entities.homeLocationGroups,
  //
  // Babak's Note: In the below line, by installing above 'reselect' (at the top of this file) if the list of (homeLocationGroups, projects) has not changed then do not requery again:-
  // (homeLocationGroups) => homeLocationGroups.list.filter(homeLocationGroup => !homeLocationGroup.B_Active)
  homeLocationGroups => homeLocationGroups.list
);

export const changeHomeLocationGroup = homeLocationGroup =>
  apiCallBegan({
    url: url + "/" + homeLocationGroup.ID,
    method: "put",
    onStart: homeLocationGroupChangeRequested.type,
    data: {
      A_Group: homeLocationGroup.A_Group
    },
    onSuccess: homeLocationGroupChanged.type,
    onError: homeLocationGroupChangeFailed.type
  });

export const deleteHomeLocationGroup = homeLocationGroup =>
  apiCallBegan({
    url: url + "/" + homeLocationGroup.ID,
    method: "delete",
    onStart: homeLocationGroupDeleteRequested.type,
    data: { ID: homeLocationGroup.ID, A_Group: homeLocationGroup.A_Group },
    onSuccess: homeLocationGroupDeleted.type,
    onError: homeLocationGroupDeleteFailed.type
  });
