import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import { loadRole, addRole, changeRole } from "../../store/roles";
import { connect } from "react-redux";

class RoleForm extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        ID: "",
        A_Name: "",
        A_Description: "",
        A_Discriminator: ""
      },
      errors: {}
    };
  }

  schemaCreate = {
    ID: Joi.string()
      .allow("")
      .optional(),
    A_Name: Joi.string()
      .required()
      .min(5)
      .max(10)
      .label("Role"),
    A_Description: Joi.string()
      .required()
      .min(5)
      .max(15)
      .label("Description"),
    A_Discriminator: Joi.string()
      .required()
      .min(5)
      .max(15)
      .label("Discriminator")
  };

  schemaEdit = {
    ID: Joi.string(),
    A_Name: Joi.string()
      .required()
      .min(5)
      .max(10)
      .label("Role"),
    A_Description: Joi.string()
      .required()
      .min(5)
      .max(15)
      .label("Description"),
    A_Discriminator: Joi.string()
      .required()
      .min(5)
      .max(15)
      .label("Discriminator")
  };

  // async populateRoles() {
  //   try {
  //     console.log("populateRoles - 1 : ", this.props.role);

  //   } catch (ex) {
  //     if (ex.response && ex.response.status === 404)
  //       this.props.history.replace("/not-found");
  //   }
  // }

  componentDidMount() {
    console.log("RoleForm, componentDidMount");

    const roleId = this.props.match.params.id;
    if (roleId === "new") return;

    //   const { data: role } = await getRole(roleId);
    //   this.setState({ data: this.mapToViewModel(role) });
    this.props.loadRole(roleId);

    //
    // Babak's Notes: 'componentDidMount' will not update 'this.props', we have to wait until we reach 'componentWillReceiveProps' or 'getDerivedStateFromProps'.
    //                'componentWillReceiveProps' should be replace by renwer version 'getDerivedStateFromProps'.
  }

  async componentWillReceiveProps(nextProps) {
    console.log("RoleForm, componentWillReceiveProps", nextProps.role);

    //
    // Babak's Note: 'componentWillReceiveProps' is ran after Redux store is updated, therefore 'this.props' is updated which passes values via param 'nextProps':-
    //               'componentWillReceiveProps' alos runs after 'componentDidMount'. 'componentDidMount' will not update 'this.props', we have to wait until
    //               we reach 'componentWillReceiveProps'.
    if (this.state.data !== nextProps.role) {
      this.setState({ data: await this.mapToViewModel(nextProps.role) });
    }
  }

  // static getDerivedStateFromProps(props, state) {
  //   //
  //   // Babak's Notes: DO NOT USE 'async' on 'getDerivedStateFromProps'.
  //   if (props.role !== state.data) {
  //     // window.alert("Hello : ");
  //     console.log("RoleForm, getDerivedStateFromProps", props.role, state.data);
  //     return {
  //       data: {
  //         ID: props.role.ID,
  //         A_Name: props.role.A_Name,
  //         A_Description: props.role.A_Description,
  //         A_Discriminator: props.role.A_Discriminator
  //       }
  //     };
  //   }
  //   // Return null to indicate no change to state.
  //   return null;
  // }

  mapToViewModel(role) {
    //console.log("mapToViewModel-1", role);
    return {
      ID: role.ID,
      A_Name: role.A_Name,
      A_Description: role.A_Description,
      A_Discriminator: role.A_Discriminator
    };
  }

  doSubmit = async () => {
    // await saveRole(this.state.data);
    // this.props.history.push("/roles");

    // console.log("RoleForm, doSubmit : ", this.state.data);

    try {
      const roleId = this.props.match.params.id;
      if (roleId === "new") await this.props.addRole(this.state.data);
      else await this.props.changeRole(this.state.data);
      // await saveRole(this.state.data);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.title = ex.response.data;
        this.setState({ errors });
      }
    }

    // this.props.history.push("/rolesList");
    //
    // Babak's Note: Use below rather than above:-
    this.props.history.goBack();
  };

  render() {
    console.log("RoleForm render", this.props.role, this.state.data);
    return (
      <div
        className="container pageTopMargin"
        style={{ marginTop: "160px", marginBottom: "100px" }}
      >
        <h1 style={{ marginBottom: 20 }}>Role Form</h1>
        <form onSubmit={this.handleSubmit}>
          {/* <form> */}
          {this.renderInput("A_Name", "Role")}
          {this.renderInput("A_Description", "Description")}
          {this.renderInput("A_Discriminator", "Discriminator")}
          {this.renderButton("Save")}
        </form>
      </div>
    );
  }
}

//
// Babak's Notes: goto tutorial directory '11-Integration with React' file '5- Connecting Components Using react-redux'
const mapStateToProps = state => ({
  //   roles: state.entities.roles.list.filter(role => !role.resolved)
  //
  // Babak's Note: Below line should change from '.list' to '.listSingleRow' and added to 'store' of 'roles' 'initialState':-
  role: state.entities.roles.list
});

const mapDispatchToProps = dispatch => ({
  loadRole: id => dispatch(loadRole(id)),
  addRole: role => dispatch(addRole(role)),
  changeRole: role => dispatch(changeRole(role))
});

// Container Component
//   Presentation (Roles)
//
// Babak's Notes: Below I populate 'this.props.roles' and 'this.props.loadRoles()':-
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleForm);
