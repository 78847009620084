import { combineReducers } from "redux";
import billboardOriginsReducer from "./billboardOrigins";
import homeLocationGroupsReducer from "./homeLocationGroups";
import billboardMenuBGsReducer from "./billboardMenuBGs";
import menusReducer from "./menus";
import groupsReducer from "./groups";
import sumOfArticlesReducer from "./sumOfArticles";
import articlesReducer from "./articles";
import homeBillboardsReducer from "./homeBillboards";
import homeBillboardChildsReducer from "./homeBillboardChilds";
import homeBillboardsMainsReducer from "./homeBillboardsMains";
import homeBillboardChildsMainsReducer from "./homeBillboardChildsMains";
import homeBillboardsMainsAboutUsReducer from "./homeBillboardsMainsAboutUs";
import homeBillboardsMainsContactUsReducer from "./homeBillboardsMainsContactUs";
import radioGrpDirectionsReducer from "./radioGrpDirections";
import customizationsReducer from "./customizations";
import customizationMaintainsReducer from "./customizationMaintains";
import homeBillboardSlidesReducer from "./homeBillboardSlides";
import rolesReducer from "./roles";
import usersReducer from "./users";
import authReducer from "./auth";

export default combineReducers({
  billboardOrigins: billboardOriginsReducer,
  homeLocationGroups: homeLocationGroupsReducer,
  billboardMenuBGs: billboardMenuBGsReducer,
  menus: menusReducer,
  groups: groupsReducer,
  sumOfArticles: sumOfArticlesReducer,
  articles: articlesReducer,
  homeBillboards: homeBillboardsReducer,
  homeBillboardChilds: homeBillboardChildsReducer,
  homeBillboardsMains: homeBillboardsMainsReducer,
  homeBillboardChildsMains: homeBillboardChildsMainsReducer,
  homeBillboardsMainsAboutUs: homeBillboardsMainsAboutUsReducer,
  homeBillboardsMainsContactUs: homeBillboardsMainsContactUsReducer,
  radioGrpDirections: radioGrpDirectionsReducer,
  customizations: customizationsReducer,
  customizationMaintains: customizationMaintainsReducer,
  homeBillboardSlides: homeBillboardSlidesReducer,
  roles: rolesReducer,
  users: usersReducer,
  auth: authReducer
});
