import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import { toast } from "react-toastify";
import SumOfArticlesTable from "./sumOfArticlesTable";
import Pagination from "../common/pagination";
import { paginate } from "../../utils/paginate";
import {
  loadSumOfArticles,
  loadParentOriginIDMenus,
  deleteSumOfArticle
} from "../../store/sumOfArticles";
import { connect } from "react-redux";
import _ from "lodash";
import SearchBox from "../searchBox";

class SumOfArticles extends Component {
  state = {
    sumOfArticles: [],
    articles: false,
    sumOfArticleForm: false,
    sumOfArticleID: 0,
    currentPage: 1,
    pageSize: 8,
    searchQuery: "",
    N_BillboardLarge_ID: "",
    N_ParentOrigin_ID: "",
    sortColumn: { path: "N_Order_No", order: "asc" }
  };

  async componentDidMount() {
    console.log(
      "componentDidMount SumOfArticles 01 : ",
      this.props.match.params.groupID
    );

    // const billboardLargeID = this.props.match.params.groupID;

    // const { data: sumOfArticles } = await getSumOfArticlesID(billboardLargeID);

    // const { data: parentOrigin } = await getParentOriginIDMenus(
    //   billboardLargeID
    // );
    // const rawParentOriginData = this.mapToViewModel(parentOrigin);
    // const N_ParentOrigin_ID = rawParentOriginData.N_ParentOrigin_ID;
    // const N_BillboardLarge_ID = rawParentOriginData.N_BillboardLarge_ID;

    // this.setState({ sumOfArticles, N_ParentOrigin_ID, N_BillboardLarge_ID });

    document.body.style.backgroundImage = `url()`;
    document.body.style.backgroundColor = "white";
    document.body.style.backgroundPosition = "center center";
    document.body.style.backgroundAttachment = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundSize = "cover";

    const billboardLargeID = this.props.match.params.groupID;

    // window.alert("GroupsList, componentDidMount : ");

    await this.props.loadSumOfArticles(billboardLargeID);

    await this.props.loadParentOriginIDMenus(billboardLargeID);
  }

  // mapToViewModel(parentOrigin) {
  //   //console.log("mapToViewModel-1", month);
  //   return {
  //     N_ParentOrigin_ID: parentOrigin.N_ParentOrigin_ID,
  //     N_BillboardLarge_ID: parentOrigin.N_BillboardLarge_ID
  //   };
  // }

  handleArticle = async sumOfArticle => {
    try {
      if (sumOfArticle.N_BillboardOrigin_ID === 3) {
        this.setState({ articles: true, sumOfArticleID: sumOfArticle.ID });
        // window.location = `/articles/articles/${sumOfArticle.ID}`;
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...ex.errors };
        this.setState({ errors });
      }
    }
  };

  handleUpdate = async sumOfArticle => {
    try {
      this.setState({
        sumOfArticleForm: true,
        sumOfArticleID: sumOfArticle.ID,
        groupID: this.props.match.params.groupID
      });
      // window.location = `/sumOfArticleForm/${sumOfArticle.ID}/${this.props.match.params.groupID}`;
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...ex.errors };
        this.setState({ errors });
      }
    }
  };

  // handleDelete = async sumOfArticle => {
  //   const originalSumOfArticles = this.state.sumOfArticles;
  //   const sumOfArticles = originalSumOfArticles.filter(
  //     m => m.ID !== sumOfArticle.ID
  //   );
  //   this.setState({ sumOfArticles });

  //   try {
  //     await deleteSumOfArticle(sumOfArticle.ID);
  //   } catch (ex) {
  //     if (ex.response && ex.response.status === 404)
  //       toast.error("This sumOfArticle has already been deleted.");

  //     this.setState({ sumOfArticles: originalSumOfArticles });
  //   }
  // };

  handlePageChange = page => {
    //console.log(page);
    this.setState({ currentPage: page });
  };

  //
  // Babak's Note: We have put this 'input' field inside of the 'SearchBox' component, this component raises
  //               an event and in the we get an string. So below 'query' will contain the string the user
  //               types in the search field.
  handleSearch = query => {
    console.log("query : ", query);
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  handleSort = sortColumn => {
    console.log("handleSort : ", sortColumn);
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;

    console.log("searchQuery 2 : ", searchQuery);

    const allSumOfArticles = this.props.sumOfArticles;

    let filtered = allSumOfArticles;
    if (searchQuery)
      filtered = allSumOfArticles.filter(m =>
        m.A_Reference.toLowerCase().includes(searchQuery.toLowerCase())
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const sumOfArticles = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: sumOfArticles };
  };

  render() {
    //console.log("render sumOfArticles");

    if (this.state.articles === true) {
      return (
        <Redirect to={`/articles/articlesList/${this.state.sumOfArticleID}`} />
      );
    }
    if (this.state.sumOfArticleForm === true) {
      return (
        <Redirect
          to={`/sumOfArticlesList/${this.state.sumOfArticleID}/${this.state.groupID}`}
        />
      );
    }

    // if (this.props.sumOfArticles.length === 0)
    //   return <div className="hourGlass"></div>;

    const { length: count } = this.state.sumOfArticles;

    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery
      // N_ParentOrigin_ID: ParentOriginID
    } = this.state;

    const parentOriginID = this.props.parentOriginIDMenus.N_ParentOrigin_ID;
    const billboardLarge_ID = this.props.parentOriginIDMenus
      .N_BillboardLarge_ID;

    // //
    // //Babak's Note: Below I renamed 'data' to 'sumOfArticles'
    // window.alert(
    //   `searchQuery 1 : ${this.state.N_BillboardLarge_ID}, ${ParentOriginID}`
    // );

    const { totalCount, data: sumOfArticlesList } = this.getPagedData();

    return (
      <div className="container">
        <div className="row">
          <div
            className="col"
            style={{ marginTop: "160px", marginBottom: "100px" }}
          >
            <h1>Sum Of Articles</h1>
            <br />
            <Link
              to={`/sumOfArticlesList/new/${this.props.match.params.groupID}`}
              className="btn btn-primary"
              style={{ marginBottom: 20 }}
            >
              New Sum Of Article
            </Link>
            <p>Showing {totalCount} Sum Of Articles in the database.</p>
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
            <SumOfArticlesTable
              sumOfArticlesList={sumOfArticlesList}
              sortColumn={sortColumn}
              onArticle={this.handleArticle}
              onDelete={sumOfArticle =>
                this.props.deleteSumOfArticle(sumOfArticle)
              }
              onUpdate={this.handleUpdate}
              onSort={this.handleSort}
            />
            <div className="paginationBar">
              <Pagination
                itemsCount={totalCount}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={this.handlePageChange}
              />
            </div>
            <div className="backBar">
              <Link
                to={
                  parentOriginID === 1
                    ? "/menusList"
                    : parentOriginID === 2
                    ? `/groups/groupsList/${billboardLarge_ID}`
                    : "--"
                }
                className="btn btn-primary"
                style={{ marginBottom: "100px" }}
              >
                {parentOriginID === 1
                  ? "Back to Menus"
                  : parentOriginID === 2
                  ? "Back to Groups"
                  : "--"}
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// Babak's Notes: goto tutorial directory '11-Integration with React' file '5- Connecting Components Using react-redux'
const mapStateToProps = state => ({
  //   bugs: state.entities.menus.list.filter(bug => !bug.resolved)
  sumOfArticles: state.entities.sumOfArticles.list,
  parentOriginIDMenus: state.entities.sumOfArticles.listParentOriginIDMenus
});

const mapDispatchToProps = dispatch => ({
  loadSumOfArticles: billboardLargeID =>
    dispatch(loadSumOfArticles(billboardLargeID)),
  loadParentOriginIDMenus: billboardLargeID =>
    dispatch(loadParentOriginIDMenus(billboardLargeID)),
  deleteSumOfArticle: sumOfArticle => dispatch(deleteSumOfArticle(sumOfArticle))
});

// Container Component
//   Presentation (Bugs)
//
// Babak's Notes: Below I populate 'this.props.bugs' and 'this.props.loadBugs()':-
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SumOfArticles);
