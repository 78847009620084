import React, { Component } from "react";
import Radium, { StyleRoot } from "radium";
import RenderHomeBillboardChildsMains from "./renderHomeBillboardChildsMains";
import RenderHomeBillboardChildsMainsXS from "./renderHomeBillboardChildsMainsXS";
//import slideInLeft from "react-animations/lib/slide-in-left";
import { getHomeBillboardChildsMains } from "../../services/homeBillboardChildsMains";

class HomeBillboardChildsMain extends Component {
  state = {
    homeBillboardChildsMains: []
  };

  async componentDidMount() {
    const homeBillboardsID = this.props.homeBillboardsID;

    // window.alert(`renderBillboardBulletPinterChilds 01 : ${homeBillboardsID}`);

    // console.log(
    //   `HomeBillboardBulletPointChilds, componentDidMount 01 : ${homeBillboardID}`
    // );

    const {
      data: homeBillboardChildsMains
    } = await getHomeBillboardChildsMains(homeBillboardsID);

    // console.log(
    //   "componentDidMount BulletPointChild 02 : ",
    //   homeBillboardChilds
    // );

    this.setState({ homeBillboardChildsMains });
  }

  render() {
    const sectionStyle = this.props.sectionStyle;
    const sectionStyleMD = this.props.sectionStyleMD;
    const sectionStyleSM = this.props.sectionStyleSM;
    const sectionStyleXS = this.props.sectionStyleXS;
    const homeBillboardsID = this.props.homeBillboardsID;
    const textFC = this.props.textFC;
    const reference = this.props.reference;
    const summary = this.props.summary;

    const { homeBillboardChildsMains } = this.state;
    const { length: count } = this.state.homeBillboardChildsMains;
    if (count === 0) return <div className="hourGlass"></div>;

    return (
      <div
      // style={{ marginTop: "20px" }}
      >
        <StyleRoot
          style={sectionStyle}
          // id={item.N_OrderNo}
          className="d-none d-lg-block HeroMain homeOverflowHidden"
        >
          <div className="ChildHeroMain-contentTopLG colorlib-about">
            <div className="ChildHeroMain-contentInnerTopLG">
              <div className="ChildHeroMain-subTitleTopLG">
                <div className="row">
                  <div>
                    <div
                      className="heading-meta"
                      style={{ marginBottom: "10px" }}
                    >
                      <span
                        className="borderInner"
                        style={{
                          color: `${textFC}`,
                          border: `1px solid ${textFC}`
                        }}
                      >
                        {reference}
                      </span>
                    </div>

                    <h2
                      style={{
                        color: `${textFC}`,
                        border: `1px solid ${textFC}`
                      }}
                      className="colorlib-heading borderInner"
                    >
                      {summary}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="ChildHeroMain-subTitleMiddleLG">
                <div className="row">
                  {homeBillboardChildsMains.map((item, index) => (
                    <RenderHomeBillboardChildsMains
                      item={item}
                      ChildImageLink={"ChildImageLinkLG"}
                      ChildImage={"ChildImageLG"}
                      nameOfClass={"col-4"}
                      referenceCSS={
                        "ChildTitleLinkBackgroundLG ChildTitleLinkLG"
                      }
                    ></RenderHomeBillboardChildsMains>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </StyleRoot>

        <StyleRoot
          style={sectionStyleMD}
          // id={item.N_OrderNo}
          className="d-none d-md-block d-lg-none HeroMain homeOverflowHidden"
        >
          <div className="ChildHeroMain-contentTopMD colorlib-about">
            <div className="ChildHeroMain-contentInnerTopMD">
              <div className="ChildHeroMain-subTitleTopMD">
                <div className="row">
                  <div>
                    <div
                      className="heading-meta"
                      style={{ marginBottom: "10px" }}
                    >
                      <span
                        className="borderInner"
                        style={{
                          color: `${textFC}`,
                          border: `1px solid ${textFC}`
                        }}
                      >
                        {reference}
                      </span>
                    </div>

                    <h2
                      style={{
                        color: `${textFC}`,
                        border: `1px solid ${textFC}`
                      }}
                      className="colorlib-heading borderInner"
                    >
                      {summary}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="ChildHeroMain-subTitleMiddleMD">
                <div className="row">
                  {homeBillboardChildsMains.map((item, index) => (
                    <RenderHomeBillboardChildsMains
                      item={item}
                      ChildImageLink={"ChildImageLinkMD"}
                      ChildImage={"ChildImageMD"}
                      nameOfClass={"col-4"}
                      referenceCSS={
                        "ChildTitleLinkBackgroundMD ChildTitleLinkMD"
                      }
                    ></RenderHomeBillboardChildsMains>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </StyleRoot>

        <StyleRoot
          style={sectionStyleSM}
          // id={item.N_OrderNo}
          className="d-sm-block d-md-none d-none HeroMain homeOverflowHidden"
        >
          <div className="ChildHeroMain-contentTopSM colorlib-about">
            <div className="ChildHeroMain-contentInnerTopSM">
              <div className="ChildHeroMain-subTitleTopSM">
                <div className="row">
                  <div>
                    <div
                      className="heading-meta"
                      style={{ marginBottom: "10px" }}
                    >
                      <span
                        className="borderInner"
                        style={{
                          color: `${textFC}`,
                          border: `1px solid ${textFC}`
                        }}
                      >
                        {reference}
                      </span>
                    </div>

                    <h2
                      style={{
                        color: `${textFC}`,
                        border: `1px solid ${textFC}`
                      }}
                      className="colorlib-heading borderInner"
                    >
                      {summary}
                    </h2>
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginTop: "-30px"
                }}
                className="ChildHeroMain-subTitleMiddleSM"
              >
                <div className="row">
                  {homeBillboardChildsMains.map((item, index) => (
                    <RenderHomeBillboardChildsMains
                      item={item}
                      ChildImageLink={"ChildImageLinkSM"}
                      ChildImage={"ChildImageSM"}
                      nameOfClass={"col-4"}
                      referenceCSS={
                        "ChildTitleLinkBackgroundSM ChildTitleLinkSM"
                      }
                    ></RenderHomeBillboardChildsMains>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </StyleRoot>

        <StyleRoot
          style={sectionStyleXS}
          // id={item.N_OrderNo}
          className="d-xs-block d-sm-none d-md-none HeroMain homeOverflowHidden"
        >
          <div className="ChildHeroMain-contentTopXS colorlib-about">
            <div className="ChildHeroMain-contentInnerTopXS">
              <div className="ChildHeroMain-subTitleTopXS">
                <div className="row">
                  <div>
                    <div
                      className="heading-meta"
                      style={{ marginBottom: "10px" }}
                    >
                      <span
                        className="borderInner"
                        style={{
                          color: `${textFC}`,
                          border: `1px solid ${textFC}`
                        }}
                      >
                        {reference}
                      </span>
                    </div>

                    <h2
                      style={{
                        color: `${textFC}`,
                        border: `1px solid ${textFC}`
                      }}
                      className="colorlib-heading borderInner"
                    >
                      {summary}
                    </h2>
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginTop: "-30px"
                }}
                className="ChildHeroMain-subTitleMiddleXS"
              >
                <div className="row">
                  {homeBillboardChildsMains.map((item, index) => (
                    <RenderHomeBillboardChildsMainsXS
                      item={item}
                      ChildImageLink={"ChildImageLinkXS"}
                      ChildImage={"ChildImageXS"}
                      nameOfClass={"col-md-12"}
                      referenceCSS={
                        "ChildTitleLinkBackgroundXS ChildTitleLinkXS"
                      }
                    ></RenderHomeBillboardChildsMainsXS>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </StyleRoot>

        {/* </div>
            </div>
          </div>
        </section> */}
      </div>
    );
  }
}

export default HomeBillboardChildsMain;
