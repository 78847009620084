import React, { Component } from "react";
import _ from "lodash";
import LazyLoad from "react-lazy-load";

//
// Babak's Note: I had to add 'Editor' to the above line and quate out below line in order not to display
//               the buttons at top of 'Editor' element (field) when displaying on a htmnl table td cell. The
//               buttons are automatically are displayed when using 'Editor' from below line of
//               'react-draft-wysiwyg'.
//import { Editor } from "../../../node_modules/react-draft-wysiwyg";

class TableBody extends Component {
  renderCell = (item, column) => {
    // if (column.hidden) {
    //   console.log("renderCell 1 : ", column.path, column.hidden);
    //   return;
    // }

    //console.log("renderCell 2 : ", item.ID, column.key);

    if (column.content) return column.content(item);

    //
    // Babak's Note: Using lodash:-
    return _.get(item, column.path);
  };

  renderDateCell = (item, column) => {
    // if (column.hidden) {
    //   console.log("renderCell 1 : ", column.path, column.hidden);
    //   return;
    // }

    //console.log("renderCell 2 : ", item.ID, column.key);

    if (column.content) return column.content(item);

    //return _.get(item, column.path);
    if (_.get(item, column.path)) {
      const date = new Date(_.get(item, column.path));
      const month = date.getMonth() + 1;
      return (
        (month.toString().length > 1 ? month : "0" + month) +
        "/" +
        date.getDate() +
        "/" +
        date.getFullYear() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes()
      );
    } else return "";
  };

  createKey = (item, column) => {
    return item.ID + (column.path || column.key);
  };

  render() {
    const { data, columns } = this.props;
    //console.log("Hello 06 : ");
    return (
      <tbody>
        {data.map(item => (
          <tr key={item.ID}>
            {columns
              .filter(e => !e.hidden)
              .map(column =>
                column.type === "text" ? (
                  <td key={this.createKey(item, column)}>
                    {this.renderCell(item, column)}
                  </td>
                ) : column.type === "image" ? (
                  <td key={this.createKey(item, column)}>
                    <LazyLoad>
                      <img
                        style={{ maxWidth: "65px", maxHeight: "65px" }}
                        src={this.renderCell(item, column)}
                      />
                    </LazyLoad>
                  </td>
                ) : column.type === "date" ? (
                  <td key={this.createKey(item, column)}>
                    {this.renderDateCell(item, column)}
                  </td>
                ) : (
                  ""
                )
              )}
          </tr>
        ))}
      </tbody>
    );
  }
}

export default TableBody;
