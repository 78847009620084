import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
import moment from "moment";

const slice = createSlice({
  name: "homeBillboardChilds",
  initialState: {
    list: [],
    listSingleRow: [],
    listOnNavvar: [],
    loading: false,
    lastFetch: null
  },
  reducers: {
    //
    // Babak's Note: By adding 'homeBillboardChildsRequested' or 'homeBillboardChildsReceived' below, a action is created for us:-
    // actions => action handlers. The 'homeBillboardChilds' below represents the 'state':-
    homeBillboardChildsRequested: (homeBillboardChilds, action) => {
      console.log("homeBillboardChildsRequested : ", action.payload);
      homeBillboardChilds.loading = true;
    },
    homeBillboardChildsReceived: (homeBillboardChilds, action) => {
      console.log("homeBillboardChildsReceived : ", action.payload);
      homeBillboardChilds.list = action.payload;
      homeBillboardChilds.loading = false;
      homeBillboardChilds.lastFetch = Date.now();
    },
    homeBillboardChildsRequestedFailed: (homeBillboardChilds, action) => {
      homeBillboardChilds.loading = false;
      //   window.alert(`No HomeBillboardChilds found.`);
    },

    homeBillboardChildsOnNavbarRequested: (homeBillboardChilds, action) => {
      console.log("homeBillboardChildsOnNavbarRequested : ", action.payload);
      homeBillboardChilds.loading = true;
    },
    homeBillboardChildsOnNavbarReceived: (homeBillboardChilds, action) => {
      console.log("homeBillboardChildsOnNavbarReceived : ", action.payload);
      homeBillboardChilds.listOnNavvar = action.payload;
      homeBillboardChilds.loading = false;
      homeBillboardChilds.lastFetch = Date.now();
    },
    homeBillboardChildsOnNavbarRequestedFailed: (
      homeBillboardChilds,
      action
    ) => {
      homeBillboardChilds.loading = false;
      //   window.alert(`No HomeBillboardChilds found.`);
    },

    homeBillboardChildRequested: (homeBillboardChild, action) => {
      console.log("homeBillboardChildRequested : ", action.payload);
      homeBillboardChild.loading = true;
    },
    homeBillboardChildReceived: (homeBillboardChild, action) => {
      console.log("homeBillboardChildReceived : ", action.payload);
      homeBillboardChild.listSingleRow = action.payload;
      homeBillboardChild.loading = false;
      homeBillboardChild.lastFetch = Date.now();
    },
    homeBillboardChildRequestedFailed: (homeBillboardChild, action) => {
      homeBillboardChild.loading = false;
      //   window.alert(`No HomeBillboardChild found.`);
    },
    homeBillboardChildChangeRequested: (homeBillboardChild, action) => {
      console.log("homeBillboardChildChangeRequested : ", action.payload);
      homeBillboardChild.loading = true;
    },
    homeBillboardChildChangeFailed: (homeBillboardChild, action) => {
      homeBillboardChild.loading = false;

      // //
      // // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete homeBillboardChild api at the back end.
      // const { ID: homeBillboardChildId, A_Name: homeBillboardChildName } = action.payload;

      // window.alert(`HomeBillboardChild '${homeBillboardChildName}' no longer exists.`);

      // const index = homeBillboardChild.list.findIndex(homeBillboardChild => homeBillboardChild.ID === homeBillboardChildId);
      // //console.log("homeBillboardChildDeleted ha ", index);
      // homeBillboardChild.list.splice(index, 1);
    },
    homeBillboardChildChanged: (homeBillboardChild, action) => {
      homeBillboardChild.loading = false;
    },
    homeBillboardChildDeleteRequested: (homeBillboardChild, action) => {
      console.log("homeBillboardChildDeleteRequested : ", action.payload);
      homeBillboardChild.loading = true;
    },
    homeBillboardChildDeleteFailed: (homeBillboardChilds, action) => {
      homeBillboardChilds.loading = false;

      //
      // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete homeBillboardChild api at the back end.
      const {
        ID: homeBillboardChildId,
        A_Name: homeBillboardChildName
      } = action.payload;

      window.alert(
        `An issue has occured with homeBillboardChild '${homeBillboardChildName}'. ${action.payload}`
      );

      const index = homeBillboardChilds.list.findIndex(
        homeBillboardChild => homeBillboardChild.ID === homeBillboardChildId
      );
      //console.log("homeBillboardChildDeleted ha ", index);
      homeBillboardChilds.list.splice(index, 1);
    },
    homeBillboardChildDeleted: (homeBillboardChilds, action) => {
      //
      // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete homeBillboardChild api at the back end.
      const { ID: homeBillboardChildID } = action.payload;
      // console.log("homeBillboardChildDeleted ha ", action.payload, homeBillboardChildID, homeBillboardChilds);
      const index = homeBillboardChilds.list.findIndex(
        homeBillboardChild => homeBillboardChild.ID === homeBillboardChildID
      );
      //console.log("homeBillboardChildDeleted, ha! ha! ", index);
      homeBillboardChilds.list.splice(index, 1);
    },
    homeBillboardChildAddRequested: (homeBillboardChild, action) => {
      console.log("homeBillboardChildAddRequested : ", action.payload);
      homeBillboardChild.loading = true;
    },
    homeBillboardChildAddFailed: (homeBillboardChild, action) => {
      homeBillboardChild.loading = false;
    },
    //
    // Babak's Note: The 'homeBillboardChilds' below represents the 'state':-
    homeBillboardChildAdded: (homeBillboardChilds, action) => {
      console.log("homeBillboardChildAdded : ", action.payload);
      homeBillboardChilds.list.push(action.payload);
    }
    // homeBillboardChildAdded: (homeBillboardChilds, action) => {
    //   homeBillboardChilds.list.push({
    //     id: ++lastId,
    //     description: action.payload.description,
    //     resolved: false
    //   });
    // },
    // homeBillboardChildDeleted: (homeBillboardChilds, action) => {
    //   console.log("homeBillboardChildDeleted ha ", action.payload.ID);
    //   homeBillboardChilds.list.filter(homeBillboardChild => homeBillboardChild.ID !== action.payload.ID);
    // },
  }
});
const {
  homeBillboardChildAddRequested,
  homeBillboardChildAdded,
  homeBillboardChildAddFailed,
  homeBillboardChildChangeRequested,
  homeBillboardChildChanged,
  homeBillboardChildChangeFailed,
  homeBillboardChildDeleteRequested,
  homeBillboardChildDeleted,
  homeBillboardChildDeleteFailed,
  homeBillboardChildsReceived,
  homeBillboardChildsRequested,
  homeBillboardChildsRequestedFailed,
  homeBillboardChildsOnNavbarReceived,
  homeBillboardChildsOnNavbarRequested,
  homeBillboardChildsOnNavbarRequestedFailed,
  homeBillboardChildReceived,
  homeBillboardChildRequested,
  homeBillboardChildRequestedFailed
} = slice.actions;
export default slice.reducer;

//
// Action Creators
const url = "/homeBillboardChilds";

export const loadHomeBillboardChilds = homeBillboardID => (
  dispatch,
  getState
) => {
  // const { lastFetch } = getState().entities.homeBillboardChilds;

  // console.log("loadHomeBillboardChilds lastFetch : ", lastFetch);

  // //
  // // Babak's Note: Below we check with 'moment' that catch that if this request was already fetched less than 10 minutes ago then do not fetch again:-
  // const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  // if (diffInMinutes < 10) return;

  dispatch(
    apiCallBegan({
      url: url + "/main" + "/" + homeBillboardID,
      onStart: homeBillboardChildsRequested.type,
      method: "get",
      onSuccess: homeBillboardChildsReceived.type,
      onError: homeBillboardChildsRequestedFailed.type
    })
  );
};

export const loadHomeBillboardChild = homeBillboardChildID => (
  dispatch,
  getState
) => {
  // const { lastFetch } = getState().entities.homeBillboardChilds;

  //console.log("loadHomeBillboardChild : ", homeBillboardChildID);

  // //
  // // Babak's Note: Below we check with 'moment' that catch that if this request was already fetched less than 10 minutes ago then do not fetch again:-
  // const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  // if (diffInMinutes < 10) return;

  dispatch(
    apiCallBegan({
      url: url + "/" + homeBillboardChildID,
      onStart: homeBillboardChildRequested.type,
      method: "get",
      onSuccess: homeBillboardChildReceived.type,
      onError: homeBillboardChildRequestedFailed.type
    })
  );
};

//
// Babak's Note: Below function 'apiCallBegan' is returned back by which function (in this case from within 'index.js') calls 'addHomeBillboardChild':-
export const addHomeBillboardChild = (homeBillboardChild, user, currentDate) =>
  apiCallBegan({
    url,
    method: "post",
    onStart: homeBillboardChildAddRequested.type,
    //data: homeBillboardChild,
    data: {
      N_OrderNo: homeBillboardChild.N_OrderNo,
      N_RadioGrpDirections_ID: 7,
      N_HomeBillboards_ID: homeBillboardChild.N_HomeBillboards_ID,
      A_Reference: homeBillboardChild.A_Reference,
      A_Summary: homeBillboardChild.A_Summary,
      // A_Description: homeBillboardChild.A_Description,
      // A_HoverSummary01: homeBillboardChild.A_HoverSummary01,
      A_HoverSummary01_Link: homeBillboardChild.A_HoverSummary01_Link,
      B_HoverSummary01_Linked: homeBillboardChild.B_HoverSummary01_Linked,
      B_HoverSummary01_Link_External:
        homeBillboardChild.B_HoverSummary01_Link_External,
      // A_HoverSummary02: homeBillboardChild.A_HoverSummary02,
      // A_HoverSummary02_Link: homeBillboardChild.A_HoverSummary02_Link,
      // A_HoverSummary03: homeBillboardChild.A_HoverSummary03,
      // A_HoverSummary03_Link: homeBillboardChild.A_HoverSummary03_Link,
      // A_HoverSummary04: homeBillboardChild.A_HoverSummary04,
      // A_HoverSummary04_Link: homeBillboardChild.A_HoverSummary04_Link,
      // A_HoverSummary05: homeBillboardChild.A_HoverSummary05,
      // A_HoverSummary05_Link: homeBillboardChild.A_HoverSummary05_Link,
      // A_HoverSummary06: homeBillboardChild.A_HoverSummary06,
      // A_HoverSummary07: homeBillboardChild.A_HoverSummary07,
      // A_HoverSummary08: homeBillboardChild.A_HoverSummary08,
      // A_HoverSummary08_Link: homeBillboardChild.A_HoverSummary08_Link,
      I_Image: homeBillboardChild.I_Image,
      I_ImageOnImage: homeBillboardChild.I_ImageOnImage,
      D_Inserted: currentDate,
      A_Inserted_By: user
    },
    onSuccess: homeBillboardChildAdded.type,
    onError: homeBillboardChildAddFailed.type
  });

export const getHomeBillboardChilds = createSelector(
  state => state.entities.homeBillboardChilds,
  //
  // Babak's Note: In the below line, by installing above 'reselect' (at the top of this file) if the list of (homeBillboardChilds, projects) has not changed then do not requery again:-
  // (homeBillboardChilds) => homeBillboardChilds.list.filter(homeBillboardChild => !homeBillboardChild.B_Active)
  homeBillboardChilds => homeBillboardChilds.list
);

export const changeHomeBillboardChild = (
  homeBillboardChild,
  user,
  currentDate
) =>
  apiCallBegan({
    url: url + "/" + homeBillboardChild.ID,
    method: "put",
    onStart: homeBillboardChildChangeRequested.type,
    data: {
      N_OrderNo: homeBillboardChild.N_OrderNo,
      A_Reference: homeBillboardChild.A_Reference,
      A_Summary: homeBillboardChild.A_Summary,
      // A_Description: homeBillboardChild.A_Description,
      // A_HoverSummary01: homeBillboardChild.A_HoverSummary01,
      A_HoverSummary01_Link: homeBillboardChild.A_HoverSummary01_Link,
      B_HoverSummary01_Linked: homeBillboardChild.B_HoverSummary01_Linked,
      B_HoverSummary01_Link_External:
        homeBillboardChild.B_HoverSummary01_Link_External,
      // A_HoverSummary02: homeBillboardChild.A_HoverSummary02,
      // A_HoverSummary02_Link: homeBillboardChild.A_HoverSummary02_Link,
      // A_HoverSummary03: homeBillboardChild.A_HoverSummary03,
      // A_HoverSummary03_Link: homeBillboardChild.A_HoverSummary03_Link,
      // A_HoverSummary04: homeBillboardChild.A_HoverSummary04,
      // A_HoverSummary04_Link: homeBillboardChild.A_HoverSummary04_Link,
      // A_HoverSummary05: homeBillboardChild.A_HoverSummary05,
      // A_HoverSummary05_Link: homeBillboardChild.A_HoverSummary05_Link,
      // A_HoverSummary06: homeBillboardChild.A_HoverSummary06,
      // A_HoverSummary07: homeBillboardChild.A_HoverSummary07,
      // A_HoverSummary08: homeBillboardChild.A_HoverSummary08,
      // A_HoverSummary08_Link: homeBillboardChild.A_HoverSummary08_Link,
      I_Image: homeBillboardChild.I_Image,
      I_ImageOnImage: homeBillboardChild.I_ImageOnImage,
      D_Updated: currentDate,
      A_Updated_By: user
    },
    onSuccess: homeBillboardChildChanged.type,
    onError: homeBillboardChildChangeFailed.type
  });

export const deleteHomeBillboardChild = homeBillboardChild =>
  apiCallBegan({
    url: url + "/" + homeBillboardChild.ID,
    method: "delete",
    onStart: homeBillboardChildDeleteRequested.type,
    data: {
      ID: homeBillboardChild.ID,
      A_Reference: homeBillboardChild.A_Reference
    },
    onSuccess: homeBillboardChildDeleted.type,
    onError: homeBillboardChildDeleteFailed.type
  });
