import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
import moment from "moment";

const slice = createSlice({
  name: "groups",
  initialState: {
    list: [],
    listSingleRow: [],
    listOnNavvar: [],
    loading: false,
    lastFetch: null
  },
  reducers: {
    //
    // Babak's Note: By adding 'groupsRequested' or 'groupsReceived' below, a action is created for us:-
    // actions => action handlers. The 'groups' below represents the 'state':-
    groupsRequested: (groups, action) => {
      console.log("groupsRequested : ", action.payload);
      groups.loading = true;
    },
    groupsReceived: (groups, action) => {
      console.log("groupsReceived : ", action.payload);
      groups.list = action.payload;
      groups.loading = false;
      groups.lastFetch = Date.now();
    },
    groupsRequestedFailed: (groups, action) => {
      groups.loading = false;
      //   window.alert(`No Groups found.`);
    },

    groupsOnNavbarRequested: (groups, action) => {
      console.log("groupsOnNavbarRequested : ", action.payload);
      groups.loading = true;
    },
    groupsOnNavbarReceived: (groups, action) => {
      console.log("groupsOnNavbarReceived : ", action.payload);
      groups.listOnNavvar = action.payload;
      groups.loading = false;
      groups.lastFetch = Date.now();
    },
    groupsOnNavbarRequestedFailed: (groups, action) => {
      groups.loading = false;
      //   window.alert(`No Groups found.`);
    },

    groupRequested: (group, action) => {
      console.log("groupRequested : ", action.payload);
      group.loading = true;
    },
    groupReceived: (group, action) => {
      console.log("groupReceived : ", action.payload);
      group.listSingleRow = action.payload;
      group.loading = false;
      group.lastFetch = Date.now();
    },
    groupRequestedFailed: (group, action) => {
      group.loading = false;
      //   window.alert(`No Group found.`);
    },
    groupChangeRequested: (group, action) => {
      console.log("groupChangeRequested : ", action.payload);
      group.loading = true;
    },
    groupChangeFailed: (group, action) => {
      group.loading = false;

      // //
      // // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete group api at the back end.
      // const { ID: groupId, A_Name: groupName } = action.payload;

      // window.alert(`Group '${groupName}' no longer exists.`);

      // const index = group.list.findIndex(group => group.ID === groupId);
      // //console.log("groupDeleted ha ", index);
      // group.list.splice(index, 1);
    },
    groupChanged: (group, action) => {
      group.loading = false;
    },
    groupDeleteRequested: (group, action) => {
      console.log("groupDeleteRequested : ", action.payload);
      group.loading = true;
    },
    groupDeleteFailed: (groups, action) => {
      groups.loading = false;

      //
      // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete group api at the back end.
      const { ID: groupId, A_Name: groupName } = action.payload;

      window.alert(
        `An issue has occured with group '${groupName}'. ${action.payload}`
      );

      const index = groups.list.findIndex(group => group.ID === groupId);
      //console.log("groupDeleted ha ", index);
      groups.list.splice(index, 1);
    },
    groupDeleted: (groups, action) => {
      //
      // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete group api at the back end.
      const { ID: groupID } = action.payload;
      // console.log("groupDeleted ha ", action.payload, groupID, groups);
      const index = groups.list.findIndex(group => group.ID === groupID);
      //console.log("groupDeleted, ha! ha! ", index);
      groups.list.splice(index, 1);
    },
    groupAddRequested: (group, action) => {
      console.log("groupAddRequested : ", group, action.payload);
      group.loading = true;
    },
    groupAddFailed: (group, action) => {
      group.loading = false;
    },
    //
    // Babak's Note: The 'groups' below represents the 'state':-
    groupAdded: (groups, action) => {
      console.log("groupAdded : ", action.payload);
      groups.list.push(action.payload);
    }
    // groupAdded: (groups, action) => {
    //   groups.list.push({
    //     id: ++lastId,
    //     description: action.payload.description,
    //     resolved: false
    //   });
    // },
    // groupDeleted: (groups, action) => {
    //   console.log("groupDeleted ha ", action.payload.ID);
    //   groups.list.filter(group => group.ID !== action.payload.ID);
    // },
  }
});
const {
  groupAddRequested,
  groupAdded,
  groupAddFailed,
  groupChangeRequested,
  groupChanged,
  groupChangeFailed,
  groupDeleteRequested,
  groupDeleted,
  groupDeleteFailed,
  groupsReceived,
  groupsRequested,
  groupsRequestedFailed,
  groupsOnNavbarReceived,
  groupsOnNavbarRequested,
  groupsOnNavbarRequestedFailed,
  groupReceived,
  groupRequested,
  groupRequestedFailed
} = slice.actions;
export default slice.reducer;

//
// Action Creators
const url = "/groups";

export const loadGroups = billboardLargeID => (dispatch, getState) => {
  // const { lastFetch } = getState().entities.groups;

  // console.log("loadGroups lastFetch : ", lastFetch);

  // //
  // // Babak's Note: Below we check with 'moment' that catch that if this request was already fetched less than 10 minutes ago then do not fetch again:-
  // const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  // if (diffInMinutes < 10) return;

  dispatch(
    apiCallBegan({
      url: url + "/groups" + "/" + billboardLargeID,
      onStart: groupsRequested.type,
      method: "get",
      onSuccess: groupsReceived.type,
      onError: groupsRequestedFailed.type
    })
  );
};

export const loadGroup = groupID => (dispatch, getState) => {
  // const { lastFetch } = getState().entities.groups;

  //console.log("loadGroup : ", groupID);

  // //
  // // Babak's Note: Below we check with 'moment' that catch that if this request was already fetched less than 10 minutes ago then do not fetch again:-
  // const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  // if (diffInMinutes < 10) return;

  dispatch(
    apiCallBegan({
      url: url + "/" + groupID,
      onStart: groupRequested.type,
      method: "get",
      onSuccess: groupReceived.type,
      onError: groupRequestedFailed.type
    })
  );
};

//
// Babak's Note: Below function 'apiCallBegan' is returned back by which function (in this case from within 'index.js') calls 'addGroup':-
export const addGroup = (group, user, currentDate) =>
  apiCallBegan({
    url,
    method: "post",
    onStart: groupAddRequested.type,
    //data: group,
    data: {
      N_BillboardOrigin_ID: group.N_BillboardOrigin_ID,
      N_HomeLocationGroup_ID: group.N_HomeLocationGroup_ID,
      N_Order_No: group.N_Order_No,
      A_Reference: group.A_Reference,
      A_Summary: group.A_Summary,
      N_BillboardLarge_ID: group.N_BillboardLarge_ID,
      N_ParentOrigin_ID: group.N_ParentOrigin_ID,
      I_Image: group.I_Image,
      D_Inserted: currentDate,
      A_Inserted_By: user
    },
    onSuccess: groupAdded.type,
    onError: groupAddFailed.type
  });

export const getGroups = createSelector(
  state => state.entities.groups,
  //
  // Babak's Note: In the below line, by installing above 'reselect' (at the top of this file) if the list of (groups, projects) has not changed then do not requery again:-
  // (groups) => groups.list.filter(group => !group.B_Active)
  groups => groups.list
);

export const changeGroup = (group, user, currentDate) =>
  apiCallBegan({
    url: url + "/" + group.ID,
    method: "put",
    onStart: groupChangeRequested.type,
    data: {
      N_BillboardOrigin_ID: group.N_BillboardOrigin_ID,
      N_HomeLocationGroup_ID: group.N_HomeLocationGroup_ID,
      N_Order_No: group.N_Order_No,
      A_Reference: group.A_Reference,
      A_Summary: group.A_Summary,
      N_ParentOrigin_ID: group.N_ParentOrigin_ID,
      I_Image: group.I_Image,
      D_Updated: currentDate,
      A_Updated_By: user
    },
    onSuccess: groupChanged.type,
    onError: groupChangeFailed.type
  });

export const deleteGroup = group =>
  apiCallBegan({
    url: url + "/" + group.ID,
    method: "delete",
    onStart: groupDeleteRequested.type,
    data: { ID: group.ID, A_Reference: group.A_Reference },
    onSuccess: groupDeleted.type,
    onError: groupDeleteFailed.type
  });
