import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
import moment from "moment";

const slice = createSlice({
  name: "auth",
  initialState: {
    list: [],
    loading: false,
    lastFetch: null,
    jwt: "",
    authError: ""
  },
  reducers: {
    //
    // Babak's Note: By adding 'usersRequested' or 'usersReceived' below, a action is created for us:-
    // actions => action handlers. The 'userLogin' below represents the 'state':-

    loadLoginRequested: (userLogin, action) => {
      console.log("loadLoginRequested : ", action.payload);
      userLogin.jwt = "";
      userLogin.authError = "";
      userLogin.loading = true;
    },
    loadLoginFailed: (userLogin, action) => {
      console.log("loadLoginFailed : ", action.payload);
      userLogin.authError = action.payload;
      userLogin.loading = false;
      // window.alert(`Error : ${action.payload}`);
    },
    //
    // Babak's Note: The 'userLogin' below represents the 'state':-
    loadLoginSucceeded: (userLogin, action) => {
      console.log("loadLoginSucceeded : ", action.payload);
      userLogin.jwt = action.payload;
      userLogin.loading = false;
    }
  }
});
const {
  loadLoginRequested,
  loadLoginSucceeded,
  loadLoginFailed
} = slice.actions;
export default slice.reducer;

//
// Action Creators
const url = "/auth";

//
// Babak's Note: Below function 'apiCallBegan' is returned back by which function (in this case from within 'index.js') calls 'adduser':-
export const loadLogin = userLogin =>
  apiCallBegan({
    url,
    method: "post",
    onStart: loadLoginRequested.type,
    data: {
      a_email: userLogin.username,
      a_password: userLogin.password
    },
    onSuccess: loadLoginSucceeded.type,
    onError: loadLoginFailed.type
  });

//   //
// // Babak's Note: Below function 'apiCallBegan' is returned back by which function (in this case from within 'index.js') calls 'adduser':-
// export const logout = userLogout() {
//     if (localStorage.getItem(tokenKey)) localStorage.removeItem(tokenKey);
//     else sessionStorage.removeItem(tokenKey);
// };

// export function logout() {
//   if (localStorage.getItem(tokenKey)) localStorage.removeItem(tokenKey);
//   else sessionStorage.removeItem(tokenKey);
// }
