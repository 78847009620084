import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
import moment from "moment";

const slice = createSlice({
  name: "customizationMaintains",
  initialState: {
    list: [],
    listSingleRow: [],
    loading: false,
    lastFetch: null
  },
  reducers: {
    //
    // Babak's Note: By adding 'customizationsRequested' or 'customizationsReceived' below, a action is created for us:-
    // actions => action handlers. The 'customizations' below represents the 'state':-
    customizationsRequested: (customizationMaintains, action) => {
      console.log("customizationsRequested : ", action.payload);
      customizationMaintains.loading = true;
    },
    customizationsReceived: (customizationMaintains, action) => {
      console.log("customizationsReceived : ", action.payload);
      customizationMaintains.list = action.payload;
      customizationMaintains.loading = false;
      customizationMaintains.lastFetch = Date.now();
    },
    customizationsRequestedFailed: (customizationMaintains, action) => {
      customizationMaintains.loading = false;
      // window.alert(`No Customizations found.`);
    },
    customizationRequested: (customizationMaintain, action) => {
      // window.alert("customizationRequested : ", action.payload);
      customizationMaintain.loading = true;
    },
    customizationReceived: (customizationMaintain, action) => {
      console.log("customizationReceived : ", action.payload);
      customizationMaintain.list = action.payload;
      customizationMaintain.loading = false;
      customizationMaintain.lastFetch = Date.now();
    },
    customizationRequestedFailed: (customizationMaintain, action) => {
      // window.alert(`No Customization found.`);
      customizationMaintain.loading = false;
    },

    customizationSingleRowRequested: (customizationMaintain, action) => {
      // window.alert("customizationSingleRowequested : ", action.payload);
      customizationMaintain.loading = true;
    },
    customizationSingleRowReceived: (customizationMaintain, action) => {
      console.log("customizationSingleRowReceived : ", action.payload);
      customizationMaintain.listSingleRow = action.payload;
      customizationMaintain.loading = false;
      customizationMaintain.lastFetch = Date.now();
    },
    customizationSingleRowRequestedFailed: (customizationMaintain, action) => {
      // window.alert(`No customizationSingleRowRequestedFailed found.`);
      customizationMaintain.loading = false;
    },

    customizationChangeRequested: (customizationMaintain, action) => {
      console.log("customizationChangeRequested : ", action.payload);
      customizationMaintain.loading = true;
    },
    customizationChangeFailed: (customizationMaintain, action) => {
      customizationMaintain.loading = false;

      // //
      // // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete customization api at the back end.
      // const { ID: customizationId, A_Name: customizationName } = action.payload;

      // window.alert(`Customization '${customizationName}' no longer exists.`);

      // const index = customization.list.findIndex(customization => customization.ID === customizationId);
      // //console.log("customizationDeleted ha ", index);
      // customization.list.splice(index, 1);
    },
    customizationChanged: (customizationMaintain, action) => {
      customizationMaintain.loading = false;
    },
    customizationDeleteRequested: (customizationMaintain, action) => {
      console.log("customizationDeleteRequested : ", action.payload);
      customizationMaintain.loading = true;
    },
    customizationDeleteFailed: (customizationMaintains, action) => {
      customizationMaintains.loading = false;

      //
      // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete customization api at the back end.
      const { ID: customizationId, A_Name: customizationName } = action.payload;

      // window.alert(
      //   `An issue has occured with customization '${customizationName}'. ${action.payload}`
      // );

      const index = customizationMaintains.list.findIndex(
        customizationMaintain => customizationMaintain.ID === customizationId
      );
      //console.log("customizationDeleted ha ", index);
      customizationMaintains.list.splice(index, 1);
    },
    customizationDeleted: (customizationMaintains, action) => {
      //
      // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete customization api at the back end.
      const { ID: customizationID } = action.payload;
      // console.log("customizationDeleted ha ", action.payload, customizationID, customizations);
      const index = customizationMaintains.list.findIndex(
        customizationMaintain => customizationMaintain.ID === customizationID
      );
      //console.log("customizationDeleted, ha! ha! ", index);
      customizationMaintains.list.splice(index, 1);
    },
    customizationAddRequested: (customizationMaintain, action) => {
      console.log("customizationAddRequested : ", action.payload);
      customizationMaintain.loading = true;
    },
    customizationAddFailed: (customizationMaintain, action) => {
      customizationMaintain.loading = false;
    },
    //
    // Babak's Note: The 'customizations' below represents the 'state':-
    customizationAdded: (customizationMaintains, action) => {
      console.log("customizationAdded : ", action.payload);
      customizationMaintains.list.push(action.payload);
    }
    // customizationAdded: (customizations, action) => {
    //   customizations.list.push({
    //     id: ++lastId,
    //     description: action.payload.description,
    //     resolved: false
    //   });
    // },
    // customizationDeleted: (customizations, action) => {
    //   console.log("customizationDeleted ha ", action.payload.ID);
    //   customizations.list.filter(customization => customization.ID !== action.payload.ID);
    // },
  }
});
const {
  customizationAddRequested,
  customizationAdded,
  customizationAddFailed,
  customizationChangeRequested,
  customizationChanged,
  customizationChangeFailed,
  customizationDeleteRequested,
  customizationDeleted,
  customizationDeleteFailed,
  customizationsReceived,
  customizationsRequested,
  customizationsRequestedFailed,
  customizationReceived,
  customizationRequested,
  customizationRequestedFailed,
  customizationSingleRowReceived,
  customizationSingleRowRequested,
  customizationSingleRowRequestedFailed
} = slice.actions;
export default slice.reducer;

//
// Action Creators
const url = "/customizations";

export const loadCustomizations = () => (dispatch, getState) => {
  const { lastFetch } = getState().entities.customizationMaintains;

  console.log("loadCustomizations lastFetch : ", lastFetch);

  // //
  // // Babak's Note: Below we check with 'moment' that catch that if this request was already fetched less than 10 minutes ago then do not fetch again:-
  // const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  // if (diffInMinutes < 10) return;

  dispatch(
    apiCallBegan({
      url: url,
      onStart: customizationsRequested.type,
      method: "get",
      onSuccess: customizationsReceived.type,
      onError: customizationsRequestedFailed.type
    })
  );
};

export const loadCustomization = customizationID => (dispatch, getState) => {
  // const { lastFetch } = getState().entities.customizations;

  //console.log("loadCustomization : ", customizationID);

  // //
  // // Babak's Note: Below we check with 'moment' that catch that if this request was already fetched less than 10 minutes ago then do not fetch again:-
  // const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  // if (diffInMinutes < 10) return;

  dispatch(
    apiCallBegan({
      url: url + "/" + customizationID,
      onStart: customizationRequested.type,
      method: "get",
      onSuccess: customizationReceived.type,
      onError: customizationRequestedFailed.type
    })
  );
};

export const loadCustomizationSingleRow = customizationID => (
  dispatch,
  getState
) => {
  // const { lastFetch } = getState().entities.customizations;

  //console.log("loadCustomization : ", customizationID);

  // //
  // // Babak's Note: Below we check with 'moment' that catch that if this request was already fetched less than 10 minutes ago then do not fetch again:-
  // const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  // if (diffInMinutes < 10) return;

  dispatch(
    apiCallBegan({
      url: url + "/" + customizationID,
      onStart: customizationSingleRowRequested.type,
      method: "get",
      onSuccess: customizationSingleRowReceived.type,
      onError: customizationSingleRowRequestedFailed.type
    })
  );
};

//
// Babak's Note: Below function 'apiCallBegan' is returned back by which function (in this case from within 'index.js') calls 'addCustomization':-
export const addCustomization = (customizationMaintain, user, currentDate) =>
  apiCallBegan({
    url,
    method: "post",
    onStart: customizationAddRequested.type,
    // data: customization,
    data: {
      A_WebAppTitle: customizationMaintain.A_WebAppTitle,
      A_BaseNavbarBGC: customizationMaintain.A_BaseNavbarBGC,
      A_BaseNavbarFGC: customizationMaintain.A_BaseNavbarFGC,
      A_BaseNavbarHover: customizationMaintain.A_BaseNavbarHover,
      A_BaseSearchBtnBGC: customizationMaintain.A_BaseSearchBtnBGC,
      A_BaseSearchBtnFGC: customizationMaintain.A_BaseSearchBtnFGC,
      A_BaseSearchBtnHover: customizationMaintain.A_BaseSearchBtnHover,
      B_ClientNavbarActive: customizationMaintain.B_ClientNavbarActive,
      B_TopNavbarActive: customizationMaintain.B_TopNavbarActive,
      A_TopNavbarBGC: customizationMaintain.A_TopNavbarBGC,
      A_TopNavbarFGC: customizationMaintain.A_TopNavbarFGC,
      A_TopNavbarHover: customizationMaintain.A_TopNavbarHover,
      A_TopSearchBtnBGC: customizationMaintain.A_TopSearchBtnBGC,
      A_TopSearchBtnFGC: customizationMaintain.A_TopSearchBtnFGC,
      A_TopSearchBtnHover: customizationMaintain.A_TopSearchBtnHover,
      I_LoginBGImage: customizationMaintain.I_LoginBGImage,
      N_AboutUsNo: customizationMaintain.N_AboutUsNo,
      N_ContactUsNo: customizationMaintain.N_ContactUsNo,
      B_GoogleMap: customizationMaintain.B_GoogleMap,
      F_Lat: customizationMaintain.F_Lat,
      F_Lng: customizationMaintain.F_Lng,
      D_Inserted: currentDate,
      A_Inserted_By: user
    },
    onSuccess: customizationAdded.type,
    onError: customizationAddFailed.type
  });

export const getCustomizations = createSelector(
  state => state.entities.customizations,
  //
  // Babak's Note: In the below line, by installing above 'reselect' (at the top of this file) if the list of (customizations, projects) has not changed then do not requery again:-
  // (customizations) => customizations.list.filter(customization => !customization.B_Active)
  customizations => customizations.list
);

export const changeCustomization = (customizationMaintain, user, currentDate) =>
  apiCallBegan({
    url: url + "/" + customizationMaintain.ID,
    method: "put",
    onStart: customizationChangeRequested.type,
    // data: customization,
    data: {
      ID: customizationMaintain.ID,
      A_WebAppTitle: customizationMaintain.A_WebAppTitle,
      A_BaseNavbarBGC: customizationMaintain.A_BaseNavbarBGC,
      A_BaseNavbarFGC: customizationMaintain.A_BaseNavbarFGC,
      A_BaseNavbarHover: customizationMaintain.A_BaseNavbarHover,
      A_BaseSearchBtnBGC: customizationMaintain.A_BaseSearchBtnBGC,
      A_BaseSearchBtnFGC: customizationMaintain.A_BaseSearchBtnFGC,
      A_BaseSearchBtnHover: customizationMaintain.A_BaseSearchBtnHover,
      B_ClientNavbarActive: customizationMaintain.B_ClientNavbarActive,
      B_TopNavbarActive: customizationMaintain.B_TopNavbarActive,
      A_TopNavbarBGC: customizationMaintain.A_TopNavbarBGC,
      A_TopNavbarFGC: customizationMaintain.A_TopNavbarFGC,
      A_TopNavbarHover: customizationMaintain.A_TopNavbarHover,
      A_TopSearchBtnBGC: customizationMaintain.A_TopSearchBtnBGC,
      A_TopSearchBtnFGC: customizationMaintain.A_TopSearchBtnFGC,
      A_TopSearchBtnHover: customizationMaintain.A_TopSearchBtnHover,
      I_LoginBGImage: customizationMaintain.I_LoginBGImage,
      N_AboutUsNo: customizationMaintain.N_AboutUsNo,
      N_ContactUsNo: customizationMaintain.N_ContactUsNo,
      B_GoogleMap: customizationMaintain.B_GoogleMap,
      F_Lat: customizationMaintain.F_Lat,
      F_Lng: customizationMaintain.F_Lng,
      D_Updated: currentDate,
      A_Updated_By: user
    },
    onSuccess: customizationChanged.type,
    onError: customizationChangeFailed.type
  });

export const deleteCustomization = customizationMaintain =>
  apiCallBegan({
    url: url + "/" + customizationMaintain.ID,
    method: "delete",
    onStart: customizationDeleteRequested.type,
    data: {
      ID: customizationMaintain.ID,
      A_Name: customizationMaintain.A_Name
    },
    onSuccess: customizationDeleted.type,
    onError: customizationDeleteFailed.type
  });
