import React, { Component } from "react";
// import auth from "../../services/authService";
import Table from "../common/table";

class RolesTable extends Component {
  //
  // Babak's Note: A template litaral is (including ``) : `/roles/${role.id}`
  //               Argument is : ${role.id}
  //
  // Babal's Note: Below are the columns in the home page 'roles'. The 'Title' column is rendered dynamically
  //               in order so we link it to another componet (another words a page), in this case 'roleForm'.
  //               Path is the database column name.
  columns = [
    {
      path: "ID",
      label: "Identifier",
      hidden: true,
      type: "text"
    },
    { path: "A_Name", label: "Role", type: "text" },
    { path: "A_Description", label: "Description", type: "text" },
    { path: "A_Discriminator", label: "Discriminator", type: "text" }
  ];

  updateColumn = {
    key: "update",
    type: "text",
    content: role => (
      <button
        onClick={() => this.props.onUpdate(role)}
        className="btn btn-info btn-sm pull-right"
      >
        Update
      </button>
    )
  };

  deleteColumn = {
    key: "delete",
    type: "text",
    content: role => (
      <button
        onClick={() => {
          if (
            window.confirm(
              `Are you sure you want to delete role '${role.A_Name}'?`
            )
          ) {
            this.props.onDelete(role);
          }
        }}
        className="btn btn-danger btn-sm"
      >
        Delete
      </button>
    )
  };

  constructor() {
    super();
    this.columns.push(this.updateColumn);
    this.columns.push(this.deleteColumn);

    //const user = auth.getCurrentUser();
  }
  // //
  // // Babak's Note: Below we add a condition that the user must be logged in and have an admin role in order to see
  // //               the 'delete' button:-
  // constructor() {
  //   super();
  //   const user = auth.getCurrentUser();
  //   if (user && user.isAdmin) this.columns.push(this.deleteColumn);
  // }

  render() {
    const { rolesList, onSort, sortColumn } = this.props;
    // const { rolesList } = this.props;
    // console.log("render rolesTable : ", rolesList);
    return (
      <Table
        columns={this.columns}
        data={rolesList}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}
export default RolesTable;
