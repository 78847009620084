import React, { Component, useState } from "react";
import auth from "../services/authService";
import { NavLink, withRouter, Route } from "react-router-dom";
import { getBillboardLargesSearchAutoComplete } from "../services/billboardLargeService";
import { Redirect } from "react-router";
import { Link, animateScroll as scroll } from "react-scroll";
import {
  Form,
  FormControl,
  Nav,
  Navbar,
  NavDropdown,
  Button
} from "react-bootstrap";

//
// Babak's Note: Below 'user' is from 'props', we have used destructering so by adding only 'user' reactjs will
//               automnatically take it from 'props':-
class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      navExpanded: false,
      suggestions: [],
      text: "",
      dynamicSearchIcon: ""
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  intervalID;

  // state = {
  //   // aboutUs: false,
  //   // contactUs: false,
  //   windowLocationPathname: false
  // }

  // AboutUsNavigate = ({ title, history }) => {
  //   return ( <Link
  //   className="nav-item nav-link text-warning clickable"
  //   activeClass="active"
  //   to="10"
  //   spy={true}
  //   smooth={true}
  //   offset={-70}
  //   duration={5000}
  //   onClick={this.closeNavToAboutUs}
  // >
  // {title}
  // </Link>
  // )};

  // AboutUsComponent = () => {
  //   return (
  //   <Route path="/" render={(props) => <this.AboutUsNavigate {...props} title="درباره ما" />} />
  // )}

  // ContactUsNavigate = ({ title, history }) => {
  //   return( <Link
  //   className="nav-item nav-link text-warning clickable"
  //   activeClass="active"
  //   to="10"
  //   spy={true}
  //   smooth={true}
  //   offset={-70}
  //   duration={5000}
  //   onClick={this.closeNavToContactUs}
  // >
  // {title}
  // </Link>
  // )};

  // ContactUsComponent = () => {
  //   return (
  //   <Route path="/" render={(props) => <this.ContactUsNavigate {...props} title="تماس با ما" />} />
  // )}

  HomeNavigate = ({ customizationSingleRow }) => {
    if (!customizationSingleRow.A_BaseNavbarFGC) return "";

    const [bgColour, setBgColour] = useState(
      `${customizationSingleRow.A_BaseNavbarFGC}`
    );

    const styles = {
      background: `${customizationSingleRow.A_BaseNavbarBGC}`,
      color: `${bgColour}`
    };

    // const bgColors = {
    //   default: "#81b71a",
    //   blue: "#00B1E1",
    //   cyan: "#37BC9B",
    //   green: "#8CC152",
    //   red: "#E9573F",
    //   yellow: "#F6BB42",
    //   white: "#fff",
    //   lightBlue: "#ADD8E6",
    //   darkCyan: "#008B8B",
    //   menuBlue: "#0078d4",
    //   white: "white"
    // };

    return (
      <NavLink
        // className="navbar-brand text-warning ml-sm-2"
        //
        // Babak's Note: "mb-1" from bottom up 1. "ml-sm-2" from left right 2. "https://getbootstrap.com/docs/4.0/utilities/spacing/",
        //                                                                     "https://getbootstrap.com/docs/4.0/utilities/display/" :-
        className="navbar-brand mb-1"
        style={styles}
        onMouseEnter={() =>
          setBgColour(`${customizationSingleRow.A_BaseNavbarHover}`)
        }
        onMouseLeave={() =>
          setBgColour(`${customizationSingleRow.A_BaseNavbarFGC}`)
        }
        to="/"
        onClick={this.closeNav}
      >
        Home
      </NavLink>
    );
  };

  MenuNavigate = ({ customizationSingleRow, menu, index, rowLength }) => {
    if (!customizationSingleRow.A_BaseNavbarFGC) return "";

    const [bgColour, setBgColour] = useState(
      `${customizationSingleRow.A_BaseNavbarFGC}`
    );

    const styles = {
      background: `${customizationSingleRow.A_BaseNavbarBGC}`,
      color: `${bgColour}`
    };

    return (
      <NavLink
        // className="navbar-brand text-warning ml-sm-2"
        className="d-lg-none nav-item nav-link"
        style={styles}
        onMouseEnter={() =>
          setBgColour(`${customizationSingleRow.A_BaseNavbarHover}`)
        }
        onMouseLeave={() =>
          setBgColour(`${customizationSingleRow.A_BaseNavbarFGC}`)
        }
        to={
          menu.N_BillboardOrigin_ID === 1
            ? `/detailsGroup/${menu.ID}`
            : menu.N_BillboardOrigin_ID === 2
            ? `/detailsMaster/${menu.ID}`
            : menu.N_BillboardOrigin_ID === 3
            ? `/detailsDetail/${menu.ID}`
            : ""
        }
        onClick={this.closeNav2}
      >
        {menu.A_Reference}
      </NavLink>
    );
  };

  MaintenanceNavigate = ({ customizationSingleRow }) => {
    if (!customizationSingleRow.A_BaseNavbarFGC) return "";

    const [bgColour, setBgColour] = useState(
      `${customizationSingleRow.A_BaseNavbarFGC}`
    );

    const styles = {
      background: `${customizationSingleRow.A_BaseNavbarBGC}`,
      color: `${bgColour}`
    };

    return (
      <span
        // className="text-primary my-auto textShadow"
        className="my-auto"
        style={styles}
        onMouseEnter={() =>
          setBgColour(`${customizationSingleRow.A_BaseNavbarHover}`)
        }
        onMouseLeave={() =>
          setBgColour(`${customizationSingleRow.A_BaseNavbarFGC}`)
        }
        // data-toggle="dropdown"
        // style={{ color: bgColors.darkCyan }}
      >
        Maintenance
      </span>
    );
  };

  RolesNavigate = ({ customizationSingleRow }) => {
    if (!customizationSingleRow.A_BaseNavbarFGC) return "";

    const [bgColour, setBgColour] = useState(
      `${customizationSingleRow.A_BaseNavbarFGC}`
    );

    const styles = {
      background: `${customizationSingleRow.A_BaseNavbarBGC}`,
      color: `${bgColour}`
    };

    return (
      <NavLink
        // className="nav-item nav-link text-warning"
        className="nav-item nav-link"
        style={styles}
        onMouseEnter={() =>
          setBgColour(`${customizationSingleRow.A_BaseNavbarHover}`)
        }
        onMouseLeave={() =>
          setBgColour(`${customizationSingleRow.A_BaseNavbarFGC}`)
        }
        to="/rolesList"
        onClick={this.closeNav}
      >
        Roles
      </NavLink>
    );
  };

  UsersNavigate = ({ customizationSingleRow }) => {
    if (!customizationSingleRow.A_BaseNavbarFGC) return "";

    const [bgColour, setBgColour] = useState(
      `${customizationSingleRow.A_BaseNavbarFGC}`
    );

    const styles = {
      background: `${customizationSingleRow.A_BaseNavbarBGC}`,
      color: `${bgColour}`
    };

    return (
      <NavLink
        // className="nav-item nav-link text-warning"
        className="nav-item nav-link"
        style={styles}
        onMouseEnter={() =>
          setBgColour(`${customizationSingleRow.A_BaseNavbarHover}`)
        }
        onMouseLeave={() =>
          setBgColour(`${customizationSingleRow.A_BaseNavbarFGC}`)
        }
        to="/usersList"
        onClick={this.closeNav}
      >
        Users
      </NavLink>
    );
  };

  BillboardsNavigate = ({ customizationSingleRow }) => {
    if (!customizationSingleRow.A_BaseNavbarFGC) return "";

    const [bgColour, setBgColour] = useState(
      `${customizationSingleRow.A_BaseNavbarFGC}`
    );

    const styles = {
      background: `${customizationSingleRow.A_BaseNavbarBGC}`,
      color: `${bgColour}`
    };

    return (
      <NavLink
        className="nav-item nav-link"
        style={styles}
        onMouseEnter={() =>
          setBgColour(`${customizationSingleRow.A_BaseNavbarHover}`)
        }
        onMouseLeave={() =>
          setBgColour(`${customizationSingleRow.A_BaseNavbarFGC}`)
        }
        to="/menusList"
        onClick={this.closeNav}
      >
        Client Menus
      </NavLink>
    );
  };

  HomeLayoutsNavigate = ({ customizationSingleRow }) => {
    if (!customizationSingleRow.A_BaseNavbarFGC) return "";

    const [bgColour, setBgColour] = useState(
      `${customizationSingleRow.A_BaseNavbarFGC}`
    );

    const styles = {
      background: `${customizationSingleRow.A_BaseNavbarBGC}`,
      color: `${bgColour}`
    };

    return (
      <NavLink
        className="nav-item nav-link"
        style={styles}
        onMouseEnter={() =>
          setBgColour(`${customizationSingleRow.A_BaseNavbarHover}`)
        }
        onMouseLeave={() =>
          setBgColour(`${customizationSingleRow.A_BaseNavbarFGC}`)
        }
        to="/homeBillboardsList"
        onClick={this.closeNav}
      >
        Home Layout
      </NavLink>
    );
  };

  CustomizationsNavigate = ({ customizationSingleRow }) => {
    if (!customizationSingleRow.A_BaseNavbarFGC) return "";

    const [bgColour, setBgColour] = useState(
      `${customizationSingleRow.A_BaseNavbarFGC}`
    );

    const styles = {
      background: `${customizationSingleRow.A_BaseNavbarBGC}`,
      color: `${bgColour}`
    };

    return (
      <NavLink
        className="nav-item nav-link"
        style={styles}
        onMouseEnter={() =>
          setBgColour(`${customizationSingleRow.A_BaseNavbarHover}`)
        }
        onMouseLeave={() =>
          setBgColour(`${customizationSingleRow.A_BaseNavbarFGC}`)
        }
        to="/customizationsList"
        onClick={this.closeNav}
      >
        Customizations
      </NavLink>
    );
  };

  AboutUsNavigate = ({ customizationSingleRow }) => {
    if (!customizationSingleRow.A_BaseNavbarFGC) return "";

    const [bgColour, setBgColour] = useState(
      `${customizationSingleRow.A_BaseNavbarFGC}`
    );

    const styles = {
      background: `${customizationSingleRow.A_BaseNavbarBGC}`,
      color: `${bgColour}`
    };

    // const bgColors = {
    //   default: "#81b71a",
    //   blue: "#00B1E1",
    //   cyan: "#37BC9B",
    //   green: "#8CC152",
    //   red: "#E9573F",
    //   yellow: "#F6BB42",
    //   white: "#fff",
    //   lightBlue: "#ADD8E6",
    //   darkCyan: "#008B8B",
    //   menuBlue: "#0078d4",
    //   white: "white"
    // };

    if (window.location.pathname === "/homeBillboardsMain") {
      return (
        <Link
          // className="nav-item nav-link text-warning clickable"
          className="nav-item nav-link clickable"
          style={styles}
          onMouseEnter={() =>
            setBgColour(`${customizationSingleRow.A_BaseNavbarHover}`)
          }
          onMouseLeave={() =>
            setBgColour(`${customizationSingleRow.A_BaseNavbarFGC}`)
          }
          activeClass="active"
          to={customizationSingleRow.N_AboutUsNo}
          spy={true}
          smooth={true}
          offset={-70}
          duration={1000}
          onClick={this.closeNav}
        >
          About Us
        </Link>
      );
    } else {
      return (
        <NavLink
          // className="nav-item nav-link text-warning"
          className="nav-item nav-link"
          style={styles}
          onMouseEnter={() =>
            setBgColour(`${customizationSingleRow.A_BaseNavbarHover}`)
          }
          onMouseLeave={() =>
            setBgColour(`${customizationSingleRow.A_BaseNavbarFGC}`)
          }
          to="/aboutUs"
          onClick={this.closeNav}
        >
          About Us
        </NavLink>
      );
    }
  };

  ContactUsNavigate = ({ customizationSingleRow }) => {
    if (!customizationSingleRow.A_BaseNavbarFGC) return "";

    const [bgColour, setBgColour] = useState(
      `${customizationSingleRow.A_BaseNavbarFGC}`
    );

    const styles = {
      background: `${customizationSingleRow.A_BaseNavbarBGC}`,
      color: `${bgColour}`
    };

    // const bgColors = {
    //   default: "#81b71a",
    //   blue: "#00B1E1",
    //   cyan: "#37BC9B",
    //   green: "#8CC152",
    //   red: "#E9573F",
    //   yellow: "#F6BB42",
    //   white: "#fff",
    //   lightBlue: "#ADD8E6",
    //   darkCyan: "#008B8B",
    //   menuBlue: "#0078d4",
    //   white: "white"
    // };
    if (window.location.pathname === "/homeBillboardsMain") {
      return (
        <Link
          // className="nav-item nav-link text-warning clickable"
          className="nav-item nav-link clickable textShadow"
          style={styles}
          onMouseEnter={() =>
            setBgColour(`${customizationSingleRow.A_BaseNavbarHover}`)
          }
          onMouseLeave={() =>
            setBgColour(`${customizationSingleRow.A_BaseNavbarFGC}`)
          }
          activeClass="active"
          to={customizationSingleRow.N_ContactUsNo}
          spy={true}
          smooth={true}
          offset={-70}
          duration={1000}
          onClick={this.closeNav}
        >
          Contact Us
        </Link>
      );
    } else {
      return (
        <NavLink
          // className="nav-item nav-link text-warning"
          className="nav-item nav-link textShadow"
          style={styles}
          onMouseEnter={() =>
            setBgColour(`${customizationSingleRow.A_BaseNavbarHover}`)
          }
          onMouseLeave={() =>
            setBgColour(`${customizationSingleRow.A_BaseNavbarFGC}`)
          }
          to="/contactUs"
          onClick={this.closeNav}
        >
          Contact Us
        </NavLink>
      );
    }
  };

  HelloNavigate = ({ customizationSingleRow, user }) => {
    if (!customizationSingleRow.A_BaseNavbarFGC) return "";

    const [bgColour, setBgColour] = useState(
      `${customizationSingleRow.A_BaseNavbarFGC}`
    );

    const styles = {
      background: `${customizationSingleRow.A_BaseNavbarBGC}`,
      color: `${bgColour}`
    };

    return (
      <NavLink
        // className="nav-item nav-link text-warning"
        className="nav-item nav-link"
        style={styles}
        onMouseEnter={() =>
          setBgColour(`${customizationSingleRow.A_BaseNavbarHover}`)
        }
        onMouseLeave={() =>
          setBgColour(`${customizationSingleRow.A_BaseNavbarFGC}`)
        }
        to="/profileForm"
        onClick={this.closeNav}
      >
        Hello {user.a_name}
      </NavLink>
    );
  };

  LogoutNavigate = ({ customizationSingleRow }) => {
    if (!customizationSingleRow.A_BaseNavbarFGC) return "";

    const [bgColour, setBgColour] = useState(
      `${customizationSingleRow.A_BaseNavbarFGC}`
    );

    const styles = {
      background: `${customizationSingleRow.A_BaseNavbarBGC}`,
      color: `${bgColour}`
    };

    return (
      <NavLink
        // className="nav-item nav-link text-warning"
        className="nav-item nav-link"
        style={styles}
        onMouseEnter={() =>
          setBgColour(`${customizationSingleRow.A_BaseNavbarHover}`)
        }
        onMouseLeave={() =>
          setBgColour(`${customizationSingleRow.A_BaseNavbarFGC}`)
        }
        to="/logout"
        onClick={this.closeNav}
      >
        Logout
      </NavLink>
    );
  };

  SearchNavigate = ({ customizationSingleRow }) => {
    if (!customizationSingleRow.A_BaseSearchBtnFGC) return "";

    const [bgColour, setBgColour] = useState(
      `${customizationSingleRow.A_BaseSearchBtnFGC}`
    );

    const styles = {
      background: `${customizationSingleRow.A_BaseSearchBtnBGC}`,
      color: `${bgColour}`
    };

    return (
      <Button
        type="submit"
        variant="outline-success"
        className="ml-sm-2"
        style={styles}
        onMouseEnter={() =>
          setBgColour(`${customizationSingleRow.A_BaseSearchBtnHover}`)
        }
        onMouseLeave={() =>
          setBgColour(`${customizationSingleRow.A_BaseSearchBtnFGC}`)
        }
        // onClick={e => {
        //   onClickSearch(e);
        // }}
      >
        Search
      </Button>
    );
  };

  LoginNavigate = ({ customizationSingleRow, user }) => {
    if (!customizationSingleRow.A_BaseNavbarFGC) return "";

    const [bgColour, setBgColour] = useState(
      `${customizationSingleRow.A_BaseNavbarFGC}`
    );

    const styles = {
      background: `${customizationSingleRow.A_BaseNavbarBGC}`,
      color: `${bgColour}`
    };

    return (
      <NavLink
        // className="nav-item nav-link text-warning"
        className="nav-item nav-link"
        style={styles}
        onMouseEnter={() =>
          setBgColour(`${customizationSingleRow.A_BaseNavbarHover}`)
        }
        onMouseLeave={() =>
          setBgColour(`${customizationSingleRow.A_BaseNavbarFGC}`)
        }
        to="/login"
        onClick={this.closeNav}
      >
        Login
      </NavLink>
    );
  };

  handleOpen = () => {
    //window.alert(`Open`);
    this.setState({ isOpen: true });
  };

  handleClose = () => {
    //window.alert(`Leave`);
    this.setState({ isOpen: false });
  };

  setNavExpanded = expanded => {
    this.setState({ navExpanded: expanded });
  };

  closeNav = () => {
    // console.log(
    //   "NavBar 1 : ",
    //   this.state.navExpanded,
    //   window.location.pathname
    // );
    // if (window.location.pathname != "/homeBillboardsMain") {
    //   window.location = `/appointments`;
    // }

    // e.stopPropagation();
    // console.log(e.target.className); // This gets the className of the target
    //
    // Babak's Note: Had to add below line so when tabbing (by finger when in tablet or mobile mode) the hamberger menu does not un-expand.
    //               I don't why it works???
    // let origin = e.target.placeholder;
    // window.alert(`Hello close : ${this.state.navExpanded}  :   `);
    this.setState({ navExpanded: false });
  };

  closeNav2 = () => {
    // console.log(
    //   "NavBar 1 : ",
    //   this.state.navExpanded,
    //   window.location.pathname
    // );
    // if (window.location.pathname != "/homeBillboardsMain") {
    //   window.location = `/appointments`;
    // }

    // e.stopPropagation();
    // console.log(e.target.className); // This gets the className of the target
    window.reload(false);
    // this.setState({ navExpanded: false });
  };

  // closeNavToAboutUs = () => {
  //   console.log(
  //     "NavBar 2 : ",
  //     this.state.navExpanded,
  //     window.location.pathname
  //   );

  //   //https://stackoverflow.com/questions/42123261/programmatically-navigate-using-react-router-v4
  //   if (window.location.pathname != "/homeBillboardsMain") {
  //     //window.location = `/infoUs/38`;
  //     //this.props.location.push(`/infoUs/38`);

  //     this.setState({ aboutUs: true });
  //   }

  //   // e.stopPropagation();
  //   //console.log(e.target.className); // This gets the className of the target

  //   this.setState({ navExpanded: false });
  // };

  // closeNavToContactUs = () => {
  //   console.log(
  //     "NavBar 2 : ",
  //     this.state.navExpanded,
  //     window.location.pathname
  //   );

  //   if (window.location.pathname != "/homeBillboardsMain") {
  //     // window.location = `/infoUs/26`;

  //     this.setState({ contactUs: true });
  //   }

  //   // e.stopPropagation();
  //   //console.log(e.target.className); // This gets the className of the target

  //   this.setState({ navExpanded: false });
  // };

  // shouldComponentUpdate() {
  //   console.log("hello shouldComponentUpdate : ");
  // }

  delaySearchAutoComplete = () => {
    clearInterval(this.intervalID);

    const { suggestions } = this.state;
    if (suggestions.length === 0) {
      this.setState(() => ({
        dynamicSearchIcon: "",
        suggestions: "",
        text: ""
      }));
    }
  };

  onTextChanged = async e => {
    // window.alert(`Hello onTextChanged : ${this.state.navExpanded}  :   `);
    const value = e.target.value;
    let suggestions = [];
    if (value.length > 0) {
      //const regex = new RegExp(`^${value}`, "i");
      //suggestions = this.items.sort().filter(v => regex.test(v));

      // this.intervalID = setInterval(
      //   this.delaySearchAutoComplete.bind(this),
      //   10000
      // );

      this.setState(
        { dynamicSearchIcon: "dynamicSearchIcon", suggestions, text: value },
        async () => {
          await getBillboardLargesSearchAutoComplete(value).then(result =>
            this.setState({
              dynamicSearchIcon: "",
              text: value,
              suggestions: [...result.data]
            })
          );
          // Axios.get('/endpoint')
          //   .then(result => this.setState({
          //     loading: false,
          //     data: [...result.data],
          //   }));
        }
      );

      // const { data: suggestion } = await getBillboardLargesSearchAutoComplete(value);
      // suggestions = suggestion;
    }
    this.setState(() => ({ suggestions, text: value }));
  };

  suggestionSelected(e, value) {
    e.preventDefault();
    window.location = `/searchDynamic/'${value}'`;
    this.setState(() => ({
      suggestions: [],
      text: value,
      dynamicSearchView: true
    }));
  }

  renderSuggestions() {
    const { suggestions, text } = this.state;
    if (suggestions.length === 0) {
      if (text) {
        // return (
        //   <div>
        //     <span className="password__dropup__show  ml-sm-2 form-control"></span>{" "}
        //   </div>
        // );
        return null;
      } else {
        return null;
      }
    }
    return (
      <ul className="dropup-content form-control">
        {suggestions.map(item => (
          <li onClick={e => this.suggestionSelected(e, item.A_Reference)}>
            {item.A_Reference}
          </li>
        ))}
      </ul>
    );
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    clearInterval(this.intervalID);
  }

  handleScroll() {
    // const { scrollPos } = this.state;
    // this.setState({
    //   scrollPos: document.body.getBoundingClientRect().top,
    //   show: document.body.getBoundingClientRect().top < scrollPos
    // });
    if (document.body.getBoundingClientRect().top < -100) {
      // this.closeNav();
      this.setState({ suggestions: "", text: "", isOpen: false });
    }
    // this.setState({
    //   show: document.body.getBoundingClientRect().top < -100 ? true : false
    // });
  }

  render() {
    const { text } = this.state;

    //console.log(`navBar render() : ${text}`);

    // if (this.state.aboutUs === true) {
    //   return <Redirect to='/infoUs/38' />
    // }
    // if (this.state.contactUs === true) {
    //   return <Redirect to='/infoUs/26' />
    // }

    const {
      user,
      customizationSingleRow,
      menuOnNavvar,
      onClickSearch
    } = this.props;

    // const menuOnNavvar = [
    //   { id: 1, description: "Menu One", colour: "red" },
    //   { id: 2, description: "Menu Two", colour: "green" },
    //   { id: 3, description: "Menu Three", colour: "blue" },
    //   { id: 4, description: "Menu Four", colour: "cyan" },
    //   { id: 5, description: "Menu Five", colour: "orange" }
    // ];

    const rowLength = menuOnNavvar.length;

    const bgColors = {
      default: "#81b71a",
      blue: "#00B1E1",
      cyan: "#37BC9B",
      green: "#8CC152",
      red: "#E9573F",
      yellow: "#F6BB42",
      white: "#fff",
      lightBlue: "#ADD8E6",
      darkCyan: "#008B8B",
      menuBlue: "#0078d4",
      white: "white"
    };

    const openMenu = this.state.isOpen;

    return (
      // <nav className="navbar navbar-expand-lg navbar-light bg-light">
      //   <Link className="navbar-brand" to="/">
      //     Varkana
      //   </Link>
      //   <button
      //     className="navbar-toggler"
      //     type="button"
      //     data-toggle="collapse"
      //     data-target="#navbarSupportedContent"
      //     aria-controls="navbarSupportedContent"
      //     aria-expanded="false"
      //     aria-label="Toggle navigation"
      //   >
      //     <span className="navbar-toggler-icon" />
      //   </button>
      //   <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
      //     <div className="navbar-nav">
      // <NavLink className="nav-item nav-link" to="/months">
      //   Months
      // </NavLink>
      // {/* Babak's Note: Below 'user' value comes from above 'const NavBar = ({ user }) => {'*/}
      // {!user && (
      //   <React.Fragment>
      //     <NavLink className="nav-item nav-link" to="/login">
      //       Login
      //     </NavLink>
      //     <NavLink className="nav-item nav-link" to="/register">
      //       Register
      //     </NavLink>
      //   </React.Fragment>
      // )}
      // {user && (
      //   <React.Fragment>
      //     <NavLink className="nav-item nav-link" to="/profile">
      //       {user.name}
      //     </NavLink>
      //     <NavLink className="nav-item nav-link" to="/logout">
      //       Logout
      //     </NavLink>
      //   </React.Fragment>
      // )}
      //     </div>
      //   </div>
      // </nav>

      // https://stackoverflow.com/questions/32452695/react-bootstrap-how-to-collapse-menu-when-item-is-selected
      //
      // Babak's Note: Look at below line "style={{ marginTop: 20 }}"
      // <Navbar fixed="top" bg="light" expand="lg" style={{ marginTop: 20 }}>
      <Navbar
        fixed="top"
        // bg="light"
        expand="lg"
        // Babak's Note: for Farsi
        className="navbar"
        onToggle={this.setNavExpanded}
        expanded={this.state.navExpanded}
        // style={{ backgroundColor: bgColors.menuBlue }}
        style={{ backgroundColor: customizationSingleRow.A_BaseNavbarBGC }}
      >
        <this.HomeNavigate
          customizationSingleRow={this.props.customizationSingleRow}
        />

        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          style={{
            borderColor: customizationSingleRow.A_BaseNavbarFGC
          }}
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            {/* Babak's Note: Add className="ml-auto" to have menu on the left hand side or className="mr-auto" to have menu on the right hand side, try className="md-auto" */}
            {/* <NavLink className="nav-item nav-link" to="/months">
              Months
            </NavLink> */}

            {user && auth.getCurrentUser().t_role_a_name === "Admin" ? (
              <NavDropdown
                title={
                  <this.MaintenanceNavigate
                    customizationSingleRow={this.props.customizationSingleRow}
                  />
                }
                id="basic-nav-dropdown"
                onMouseEnter={this.handleOpen}
                onMouseLeave={this.handleClose}
                show={openMenu}
              >
                <div
                  className="dropdownMenuCentre"
                  style={{
                    backgroundColor: customizationSingleRow.A_BaseNavbarBGC,
                    textAlign: "left"
                  }}
                >
                  <this.RolesNavigate
                    customizationSingleRow={this.props.customizationSingleRow}
                  />

                  <this.UsersNavigate
                    customizationSingleRow={this.props.customizationSingleRow}
                  />

                  <NavDropdown.Divider />

                  <this.BillboardsNavigate
                    customizationSingleRow={this.props.customizationSingleRow}
                  />

                  <this.HomeLayoutsNavigate
                    customizationSingleRow={this.props.customizationSingleRow}
                  />

                  <NavDropdown.Divider />

                  <this.CustomizationsNavigate
                    customizationSingleRow={this.props.customizationSingleRow}
                  />
                </div>
              </NavDropdown>
            ) : (
              ""
            )}

            {/* {user && auth.getCurrentUser().t_role_a_name === "Client" ? (
              <React.Fragment>
                <NavLink
                  className="nav-item nav-link text-warning"
                  to="/appointments"
                  style={{ color: bgColors.white }}
                  onClick={this.closeNav}
                >
                  Appointments
                </NavLink>
              </React.Fragment>
            ) : (
              ""
            )} */}
            {/* Babak's Note: Below 'user' value comes from above 'const NavBar = ({ user }) => {'*/}
            {!user && (
              <React.Fragment>
                {/* {menuOnNavvar.map((menu, index) => (
                  <this.MenuNavigate
                    key={menu.ID}
                    customizationSingleRow={this.props.customizationSingleRow}
                    menu={menu}
                    index={index}
                    rowLength={rowLength}
                  />
                ))} */}

                <this.AboutUsNavigate
                  customizationSingleRow={this.props.customizationSingleRow}
                />

                <this.ContactUsNavigate
                  customizationSingleRow={this.props.customizationSingleRow}
                />

                <this.LoginNavigate
                  customizationSingleRow={this.props.customizationSingleRow}
                />

                {/* <NavLink
                  className="nav-item nav-link text-warning"
                  to="/register"
                  style={{ color: bgColors.white }}
                  onClick={this.closeNav}
                >
                  Register
                </NavLink> */}
              </React.Fragment>
            )}
            {user && (
              <React.Fragment>
                {/* <NavLink className="nav-item nav-link" to="/users">
                  Users
                </NavLink>
                <NavLink className="nav-item nav-link" to="/homeBillboards">
                  Billboards
                </NavLink>
                <NavLink className="nav-item nav-link" to="/roles">
                  Roles
                </NavLink>
                <NavLink className="nav-item nav-link" to="/menus">
                  Menus
                </NavLink> */}

                {/* Notice how in v4 we can have any other component interleaved */}
                {/* <this.AboutUsComponent />  */}
                {/* {menuOnNavvar.map((menu, index) => (
                  <this.MenuNavigate
                    key={menu.ID}
                    customizationSingleRow={this.props.customizationSingleRow}
                    menu={menu}
                    index={index}
                    rowLength={rowLength}
                  />
                ))} */}

                <this.AboutUsNavigate
                  customizationSingleRow={this.props.customizationSingleRow}
                />

                <this.ContactUsNavigate
                  customizationSingleRow={this.props.customizationSingleRow}
                />

                {/* <this.ContactUsComponent />  */}

                <this.HelloNavigate
                  customizationSingleRow={this.props.customizationSingleRow}
                  user={user}
                />

                <this.LogoutNavigate
                  customizationSingleRow={this.props.customizationSingleRow}
                />
              </React.Fragment>
            )}
          </Nav>
          <Form inline onSubmit={e => onClickSearch(e)}>
            <div className="btn-group">
              <input
                value={text}
                type="text"
                id="searchID"
                autoComplete="off"
                placeholder="Search"
                //
                // Babak's Note: Below mr-sm-2 have space on right hand side between search input element and search button, ml-sm-2 on left side:-
                className={`mr-sm-2 form-control ${this.state.dynamicSearchIcon}`}
                style={{
                  minWidth: "200px",
                  maxWidth: "200px"
                }}
                onChange={this.onTextChanged}
              />
              {this.renderSuggestions()}
            </div>
            {/* <div className="input-group">
              <input
                value={text}
                type="text"
                id="searchID"
                autoComplete="off"
                placeholder="Search"
                //
                // Babak's Note: Below mr-sm-2 have space on right hand side between search input element and search button, ml-sm-2 on left side:-
                className="form-control"
                onChange={this.onTextChanged}
                style={{
                  borderTopRightRadius: "4px",
                  borderBottomRightRadius: "4px",
                  borderStyle: "hidden"
                }}
              />
              <div
                className={`mr-sm-2 ${this.state.dynamicSearchIcon}`}
                style={{ borderStyle: "hidden" }}
              ></div>
            </div>
            {this.renderSuggestions()} */}
            <this.SearchNavigate
              customizationSingleRow={this.props.customizationSingleRow}
            />
          </Form>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

// export default withRouter(NavBar);
export default NavBar;
