import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import { toast } from "react-toastify";
import GroupsTable from "./groupsTable";
import Pagination from "../common/pagination";
import { paginate } from "../../utils/paginate";
import { loadGroups, deleteGroup } from "../../store/groups";
import { connect } from "react-redux";
import _ from "lodash";
import SearchBox from "../searchBox";

class GroupsList extends Component {
  state = {
    groups: [],
    sumOfArticles: false,
    articles: false,
    groupID: 0,
    groupForm: false,
    menuID: 0,
    currentPage: 1,
    pageSize: 8,
    searchQuery: "",
    sortColumn: { path: "N_Order_No", order: "asc" }
  };

  async componentDidMount() {
    // window.alert("componentDidMount GroupsList 01 : ");

    document.body.style.backgroundImage = `url()`;
    document.body.style.backgroundColor = "white";
    document.body.style.backgroundPosition = "center center";
    document.body.style.backgroundAttachment = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundSize = "cover";

    const menuId = this.props.match.params.menuID;

    // window.alert("GroupsList, componentDidMount : ");

    await this.props.loadGroups(menuId);
  }

  handleSumOfArticle = async group => {
    try {
      if (group.N_BillboardOrigin_ID === 2) {
        this.setState({ sumOfArticles: true, groupID: group.ID });
        // window.location = `/sumOfArticles/sumOfArticles/${group.ID}`;
      } else if (group.N_BillboardOrigin_ID === 3) {
        this.setState({ articles: true, groupID: group.ID });
        // window.location = `/articles/articles/${group.ID}`;
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...ex.errors };
        this.setState({ errors });
      }
    }
  };

  handleUpdate = async group => {
    try {
      this.setState({
        groupForm: true,
        groupID: group.ID,
        menuID: this.props.match.params.menuID
      });
      // window.location = `/groupForm/${group.ID}/${this.props.match.params.menuID}`;
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...ex.errors };
        this.setState({ errors });
      }
    }
  };

  //   handleDelete = async group => {
  //     const originalGroups = this.state.groups;
  //     const groups = originalGroups.filter(m => m.ID !== group.ID);
  //     this.setState({ groups });

  //     try {
  //       await deleteGroup(group.ID);
  //     } catch (ex) {
  //       if (ex.response && ex.response.status === 404)
  //         toast.error("This group has already been deleted.");

  //       this.setState({ groups: originalGroups });
  //     }
  //   };

  handlePageChange = page => {
    //console.log(page);
    this.setState({ currentPage: page });
  };

  //
  // Babak's Note: We have put this 'input' field inside of the 'SearchBox' component, this component raises
  //               an event and in the we get an string. So below 'query' will contain the string the user
  //               types in the search field.
  handleSearch = query => {
    // console.log("query : ", query);
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  handleSort = sortColumn => {
    // console.log("handleSort : ", sortColumn);
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;

    //console.log("searchQuery 2 : ", searchQuery);

    const allGroups = this.props.groups;

    let filtered = allGroups;
    if (searchQuery)
      filtered = allGroups.filter(m =>
        m.A_Reference.toLowerCase().includes(searchQuery.toLowerCase())
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const groups = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: groups };
  };

  render() {
    //console.log("render groups");

    if (this.state.sumOfArticles === true) {
      return (
        <Redirect
          push
          to={`/sumOfArticles/sumOfArticlesList/${this.state.groupID}`}
        />
      );
    }
    if (this.state.articles === true) {
      return (
        <Redirect push to={`/articles/articlesList/${this.state.groupID}`} />
      );
    }
    if (this.state.groupForm === true) {
      return (
        <Redirect
          push
          to={`/groupsList/${this.state.groupID}/${this.state.menuID}`}
        />
      );
    }

    // if (this.props.groups.length === 0)
    //   return <div className="hourGlass"></div>;

    // const { length: count } = this.state.groups;

    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;

    //
    //Babak's Note: Below I renamed 'data' to 'groups'
    // console.log("searchQuery 1 : ");

    const { totalCount, data: groupsList } = this.getPagedData();

    return (
      <div className="container">
        <div className="row">
          <div
            className="col"
            style={{ marginTop: "160px", marginBottom: "100px" }}
          >
            <h1>Groups</h1>
            <br />
            <Link
              to={`/groupsList/new/${this.props.match.params.menuID}`}
              className="btn btn-primary"
              style={{ marginBottom: 20 }}
            >
              New Group
            </Link>
            <p>Showing {totalCount} groups in the database.</p>
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
            <GroupsTable
              groupsList={groupsList}
              sortColumn={sortColumn}
              onSumOfArticle={this.handleSumOfArticle}
              onUpdate={this.handleUpdate}
              onDelete={group => this.props.deleteGroup(group)}
              onSort={this.handleSort}
            />
            <div className="paginationBar">
              <Pagination
                itemsCount={totalCount}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={this.handlePageChange}
              />
            </div>
            <div className="backBar">
              <Link
                to="/menusList"
                className="btn btn-primary"
                style={{ marginBottom: "100px" }}
              >
                Back to Menus
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

//
// Babak's Notes: goto tutorial directory '11-Integration with React' file '5- Connecting Components Using react-redux'
const mapStateToProps = state => ({
  //   bugs: state.entities.menus.list.filter(bug => !bug.resolved)
  groups: state.entities.groups.list
});

const mapDispatchToProps = dispatch => ({
  loadGroups: billboardLargeID => dispatch(loadGroups(billboardLargeID)),
  deleteGroup: group => dispatch(deleteGroup(group))
});

// Container Component
//   Presentation (Bugs)
//
// Babak's Notes: Below I populate 'this.props.bugs' and 'this.props.loadBugs()':-
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupsList);
