import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import HomeBillboardsTable from "./homeBillboardsTable";
import Pagination from "../common/pagination";
import { paginate } from "../../utils/paginate";
import {
  loadHomeBillboards,
  deleteHomeBillboard
} from "../../store/homeBillboards";
import { connect } from "react-redux";
import _ from "lodash";
import SearchBox from "../searchBox";
import { Redirect } from "react-router";

class HomeBillboardsList extends Component {
  state = {
    homeBillboards: [],
    homeBillboardChilds: false,
    homeBillboardForm: false,
    homeBillboardID: 0,
    RadioGrpDirectionsID: 0,
    currentPage: 1,
    pageSize: 5,
    searchQuery: "",
    sortColumn: { path: "N_OrderNo", order: "asc" }
  };

  async componentDidMount() {
    //console.log("componentDidMount HomeBillboards 01 : ");

    document.body.style.backgroundImage = `url()`;
    document.body.style.backgroundColor = "white";
    document.body.style.backgroundPosition = "center center";
    document.body.style.backgroundAttachment = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundSize = "cover";

    await this.props.loadHomeBillboards();
  }

  handleHomeBillboardChild = async homeBillboard => {
    try {
      this.setState({
        homeBillboardChilds: true,
        homeBillboardID: homeBillboard.ID,
        RadioGrpDirectionsID: homeBillboard.N_RadioGrpDirections_ID
      });
      // window.location = `/articles/articles/${sumOfArticle.ID}`;
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...ex.errors };
        this.setState({ errors });
      }
    }
  };

  //   handleDelete = async homeBillboard => {
  //     const originalHomeBillboards = this.state.homeBillboards;
  //     const homeBillboards = originalHomeBillboards.filter(
  //       m => m.ID !== homeBillboard.ID
  //     );
  //     this.setState({ homeBillboards });

  //     try {
  //       await deleteHomeBillboard(homeBillboard.ID);
  //     } catch (ex) {
  //       if (ex.response && ex.response.status === 404)
  //         toast.error("This Home Billboard has already been deleted.");

  //       this.setState({ homeBillboards: originalHomeBillboards });
  //     }
  //   };

  handleUpdate = async homeBillboard => {
    try {
      this.setState({
        homeBillboardForm: true,
        homeBillboardID: homeBillboard.ID
      });
      //   window.location = `/homeBillboards/${homeBillboard.ID}`;
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...ex.errors };
        this.setState({ errors });
      }
    }
  };

  handlePageChange = page => {
    //console.log(page);
    this.setState({ currentPage: page });
  };

  //
  // Babak's Note: We have put this 'input' field inside of the 'SearchBox' component, this component raises
  //               an event and in the we get an string. So below 'query' will contain the string the user
  //               types in the search field.
  handleSearch = query => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  handleSort = sortColumn => {
    //console.log("handleSort : ", sortColumn);
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;

    const allHomeBillboards = this.props.homeBillboards;

    let filtered = allHomeBillboards;
    if (searchQuery)
      filtered = allHomeBillboards.filter(m =>
        m.A_Summary.toLowerCase().includes(searchQuery.toLowerCase())
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const homeBillboards = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: homeBillboards };
  };

  render() {
    if (this.state.homeBillboardChilds === true) {
      // window.alert(
      //   `RadioGrpDirectionsID 1 : ${this.state.RadioGrpDirectionsID}`
      // );
      return (
        <Redirect
          push
          to={`/homeBillboardChilds/homeBillboardChildsList/${this.state.homeBillboardID}/${this.state.RadioGrpDirectionsID}`}
        />
      );
    }
    if (this.state.homeBillboardForm === true) {
      // window.alert(`menuList, render() : ${this.state.menuID}`);
      return (
        <Redirect
          push
          to={`/homeBillboardsList/${this.state.homeBillboardID}`}
        />
      );
    }

    // if (this.props.homeBillboards.length === 0)
    //   return <div className="hourGlass"></div>;

    //console.log("render homeBillboards");
    const { length: count } = this.state.homeBillboards;

    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;

    //
    //Babak's Note: Below I renamed 'data' to 'homeBillboards'
    const { totalCount, data: homeBillboardsList } = this.getPagedData();

    return (
      <div
        className="container"
        style={{ marginTop: "160px", marginBottom: "100px" }}
      >
        <div className="row">
          <div className="col">
            <h1>Home Billboards</h1>
            <br />
            <Link
              to="/homeBillboardsList/new"
              className="btn btn-primary"
              style={{ marginBottom: 20 }}
            >
              New Home Billboard
            </Link>
            <p>Showing {totalCount} Home Billboards in the database.</p>
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
            <HomeBillboardsTable
              homeBillboardsList={homeBillboardsList}
              sortColumn={sortColumn}
              onHomeBillboardChild={this.handleHomeBillboardChild}
              onDelete={homeBillboard =>
                this.props.deleteHomeBillboard(homeBillboard)
              }
              onUpdate={this.handleUpdate}
              onSort={this.handleSort}
            />
            <div className="paginationBar">
              <Pagination
                itemsCount={totalCount}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={this.handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

//
// Babak's Notes: goto tutorial directory '11-Integration with React' file '5- Connecting Components Using react-redux'
const mapStateToProps = state => ({
  //   bugs: state.entities.menus.list.filter(bug => !bug.resolved)
  homeBillboards: state.entities.homeBillboards.list
});

const mapDispatchToProps = dispatch => ({
  loadHomeBillboards: () => dispatch(loadHomeBillboards()),
  deleteHomeBillboard: homeBillboard =>
    dispatch(deleteHomeBillboard(homeBillboard))
});

// Container Component
//   Presentation (Bugs)
//
// Babak's Notes: Below I populate 'this.props.bugs' and 'this.props.loadBugs()':-
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeBillboardsList);
