const toast = store => next => action => {
  //console.log("Logging : ", param);
  //   console.log("store : ", store);
  //   console.log("next : ", next);
  //   console.log("action : ", action);
  if (action.type === "error")
    console.log("Toastify : ", action.payload.message);
  else next(action);
};

export default toast;
