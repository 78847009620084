import React, { Component } from "react";
import auth from "../../services/authService";
import { Link } from "react-router-dom";
import Active from "../common/active";
import Table from "../common/table";

class UsersTable extends Component {
  // //
  // // Babak's Note: A template litaral is (including ``) : `/users/${user.id}`
  // //               Argument is : ${user.id}
  // //
  // // Babal's Note: Below are the columns in the home page 'users'. The 'Title' column is rendered dynamically
  // //               in order so we link it to another componet (another words a page), in this case 'userForm'.
  // //               Path is the database column name.
  // columns = [
  //   {
  //     path: "ID",
  //     label: "Identifier",
  //     hidden: true
  //   },
  //   { path: "A_Email", label: "Email" },
  //   { path: "A_Name", label: "Name" },
  //   { path: "T_Role_A_Name", label: "Role" },
  //   {
  //     path: "B_Active",
  //     label: "Active",
  //     content: user => <Active active={user.B_Active} />
  //   }
  // ];
  columns = [];

  async componentDidMount() {
    //console.log("componentDidMount UsersTable 01 : ");
  }

  updateColumn = {
    key: "update",
    type: "text",
    content: user =>
      auth.getCurrentUser().t_role_a_name === "Admin" ? (
        <button
          onClick={() => this.props.onUpdate(user)}
          className="btn btn-info btn-sm pull-right enabled"
        >
          Update
        </button>
      ) : (
        <button className="btn btn-info btn-sm pull-right disabled">
          Update
        </button>
      )
  };

  deleteColumn = {
    key: "delete",
    type: "text",
    content: user =>
      auth.getCurrentUser().t_role_a_name === "Admin" ? (
        <button
          onClick={() => {
            if (
              window.confirm(
                `Are you sure you want to delete user '${user.A_Email}'?`
              )
            ) {
              this.props.onDelete(user);
            }
          }}
          className="btn btn-danger btn-sm enabled"
        >
          Delete
        </button>
      ) : (
        <button className="btn btn-danger btn-sm disabled">Delete</button>
      )
  };

  constructor() {
    super();
    const patientAppointments = [{ ID: 1 }];

    patientAppointments.map(
      item => (
        this.columns.push({
          path: "ID",
          label: "Identifier",
          hidden: true,
          type: "text"
        }),
        this.columns.push({
          path: "A_Email",
          label: "Email",
          type: "text"
        }),
        this.columns.push({ path: "A_Name", label: "Name", type: "text" }),
        this.columns.push({
          path: "T_Role_A_Name",
          label: "Role",
          type: "text"
        }),
        this.columns.push({
          path: "B_Active",
          label: "Active",
          type: "text",
          content: user => <Active active={user.B_Active} />
        })
      )
    );

    this.columns.push(this.updateColumn);
    this.columns.push(this.deleteColumn);

    // const user = auth.getCurrentUser();
    // console.log("User details : ", user);
  }
  // //
  // // Babak's Note: Below we add a condition that the user must be logged in and have an admin user in order to see
  // //               the 'delete' button:-
  // constructor() {
  //   super();
  //   const user = auth.getCurrentUser();
  //   if (user && user.isAdmin) this.columns.push(this.deleteColumn);
  // }

  render() {
    //console.log("render usersTable");
    const { usersList, onSort, sortColumn } = this.props;
    return (
      <Table
        columns={this.columns}
        data={usersList}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}
export default UsersTable;
