import React, { Component } from "react";
import Radium, { StyleRoot } from "radium";
import Carousel from "react-bootstrap/Carousel";
import { NavLink, withRouter, Route } from "react-router-dom";
import { loadHomeBillboardSlides } from "../../store/homeBillboardSlides";
import { Redirect } from "react-router";
import { connect } from "react-redux";

class CarouselSlide extends Component {
  state = {
    homeBillboardChilds: [],
    detailsMaster: false,
    masterID: 0,
    detailsDetail: false,
    detailsDetailID: 0
  };

  //
  // https://stackoverflow.com/questions/43592711/how-to-change-react-bootstrap-carousel-animation-ton-fadein
  // Simply add fade={true} to the Carousel component. Or if you want it to slide, add slide={true}. That's it!

  async componentDidMount() {
    //console.log("componentDidMount CarouselSlide 01 : ");

    // const { data: homeBillboardChilds } = await getHomeBillboardSlides();
    await this.props.loadHomeBillboardSlides();

    // console.log("componentDidMount CarouselSlide 02 : ", homeBillboardChilds);

    // this.setState({ homeBillboardChilds });
  }

  onClickBillboardLarges = async item => {
    // console.log(
    //   "NavBar 2 : ",
    //   item.N_BillboardOrigin_ID,
    //   window.location.pathname
    // );

    // if (item.N_BillboardOrigin_ID === 2) {
    //   window.location = `../detailsMaster/${item.ID}`;
    // } else if (item.N_BillboardOrigin_ID === 3) {
    //   window.location = `../detailsDetail/${item.ID}`;
    // }
    if (item.N_BillboardOrigin_ID === 2) {
      //window.location = `../detailsMaster/${item.ID}`;
      this.setState({ detailsMaster: true, masterID: item.ID });
    } else if (item.N_BillboardOrigin_ID === 3) {
      //window.location = `../detailsDetail/${item.ID}`;
      this.setState({ detailsDetail: true, detailsDetailID: item.ID });
    }

    // window.location = `/profileForm`;

    // e.stopPropagation();
    //console.log(e.target.className); // This gets the className of the target
  };

  renderBillboardSlides = ({ item, rowCount, minHeight, maxHeight }) => {
    // console.log("renderBillboardSlides 01 : ", item, rowCount);

    // https://www.npmjs.com/package/react-animations

    // const styles = StyleSheet.create({
    //   bounce: {
    //     animationName: bounce,
    //     animationDuration: "1s"
    //   }
    // });

    // const style = {
    //   animation: animations.popIn
    // };

    // const position = useMousePosition();

    // Babak's Notes: Commented out on 20200115: START
    return (
      <div>
        {/* <div className="cursorPointerPoof">
          {position.x}:{position.y}
        </div> */}
        <img
          className="d-block w-100 clickable"
          onClick={() => this.onClickBillboardLarges(item)}
          src={item.I_Image}
          style={{ minHeight: minHeight, maxHeight: maxHeight }}
          alt="First slide"
        />
        <Carousel.Caption>
          <h3
            className="clickable"
            onClick={() => this.onClickBillboardLarges(item)}
            style={{ color: "white", textShadow: "2px 1px rgba(0, 0, 0, 0.9)" }}
          >
            {item.A_Reference}
          </h3>
          <p
            className="clickable"
            onClick={() => this.onClickBillboardLarges(item)}
            style={{ color: "white", textShadow: "2px 1px rgba(0, 0, 0, 0.9)" }}
          >
            {item.A_Summary}
          </p>
          <p
            className="clickable"
            onClick={() => this.onClickBillboardLarges(item)}
            style={{ color: "white", textShadow: "2px 1px rgba(0, 0, 0, 0.9)" }}
          >
            More...
          </p>
        </Carousel.Caption>
      </div>
    );
  };

  render() {
    if (this.state.detailsMaster === true) {
      //   return <Redirect to={`/detailsMaster/${this.state.masterID}`} />;
      window.location = `../detailsMaster/${this.state.masterID}`;
    }
    if (this.state.detailsDetail === true) {
      //   return <Redirect to={`/detailsDetail/${this.state.detailsDetailID}`} />;
      window.location = `../detailsDetail/${this.state.detailsDetailID}`;
    }

    const sectionStyle = this.props.sectionStyle;
    const sectionStyleMD = this.props.sectionStyleMD;
    const sectionStyleSM = this.props.sectionStyleSM;
    const sectionStyleXS = this.props.sectionStyleXS;
    const textFC = this.props.textFC;
    const reference = this.props.reference;
    const summary = this.props.summary;

    // const { homeBillboardChilds } = this.state;
    const { length: count } = this.props.homeBillboardSlides;
    if (count === 0) return <p></p>;
    // console.log("render homeBillboard Childs", homeBillboardChilds, count);

    return (
      <div>
        <StyleRoot
          style={sectionStyle}
          // id={item.N_OrderNo}
          className="d-none d-lg-block HeroMain homeOverflowHidden"
        >
          {/* <div className="container"> */}
          {/* <div classNames="row">
            <div className="col-sm-12"> */}
          <div
            className="boxShadowOnly"
            style={{
              marginTop: "1px"
            }}
          >
            <div className="carouselSlideMain-contentTopLG colorlib-about">
              <div className="carouselSlideMain-contentInnerTopLG">
                <div className="carouselSlideMain-subTitleTopLG">
                  <div className="carouselSlideMain-subTitleTopSubLG">
                    <div className="row">
                      <div>
                        <div
                          className="heading-meta"
                          style={{ marginBottom: "10px" }}
                        >
                          <span
                            className="borderInner"
                            style={{
                              color: `${textFC}`,
                              border: `1px solid ${textFC}`
                            }}
                          >
                            {reference}
                          </span>
                        </div>

                        <h2
                          style={{
                            color: `${textFC}`,
                            border: `1px solid ${textFC}`
                          }}
                          className="colorlib-heading borderInner"
                        >
                          {summary}
                        </h2>
                      </div>
                    </div>
                  </div>

                  <Carousel style={{ position: "relative" }} fade={true}>
                    {this.props.homeBillboardSlides.map((item, index) => (
                      <Carousel.Item key={index}>
                        <this.renderBillboardSlides
                          key={index}
                          item={item}
                          rowCount={index}
                          minHeight={"600px"}
                          maxHeight={"600px"}
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
          {/* </div>
          </div> */}
          {/* </div> */}
        </StyleRoot>

        <StyleRoot
          style={sectionStyleMD}
          // id={item.N_OrderNo}
          className="d-none d-md-block d-lg-none HeroMain boxShadowOnly homeOverflowHidden"
        >
          {/* <div className="container"> */}
          {/* <div classNames="row">
            <div className="col-sm-12"> */}
          <div
            className="boxShadowOnly"
            style={{
              marginTop: "1px"
            }}
          >
            <div className="carouselSlideMain-contentTopMD colorlib-about">
              <div className="carouselSlideMain-contentInnerTopMD">
                <div className="carouselSlideMain-subTitleTopMD">
                  <div className="carouselSlideMain-subTitleTopSubMD">
                    <div className="row">
                      <div>
                        <div
                          className="heading-meta"
                          style={{ marginBottom: "10px" }}
                        >
                          <span
                            className="borderInner"
                            style={{
                              color: `${textFC}`,
                              border: `1px solid ${textFC}`
                            }}
                          >
                            {reference}
                          </span>
                        </div>

                        <h2
                          style={{
                            color: `${textFC}`,
                            border: `1px solid ${textFC}`
                          }}
                          className="colorlib-heading borderInner"
                        >
                          {summary}
                        </h2>
                      </div>
                    </div>
                  </div>

                  <Carousel style={{ position: "relative" }} fade={true}>
                    {this.props.homeBillboardSlides.map((item, index) => (
                      <Carousel.Item key={index}>
                        <this.renderBillboardSlides
                          key={index}
                          item={item}
                          rowCount={index}
                          minHeight={"400px"}
                          maxHeight={"400px"}
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
          {/* </div>
          </div> */}
          {/* </div> */}
        </StyleRoot>

        <StyleRoot
          style={sectionStyleSM}
          // id={item.N_OrderNo}
          className="d-sm-block d-md-none d-none HeroMain boxShadowOnly homeOverflowHidden"
        >
          {/* <div className="container"> */}
          {/* <div classNames="row">
            <div className="col-sm-12"> */}
          <div
            className="boxShadowOnly"
            style={{
              marginTop: "-45px"
            }}
          >
            <div className="carouselSlideMain-contentTopSM colorlib-about">
              <div className="carouselSlideMain-contentInnerTopSM">
                <div className="carouselSlideMain-subTitleTopSM">
                  <div className="carouselSlideMain-subTitleTopSubSM">
                    <div className="row">
                      <div>
                        <div
                          className="heading-meta"
                          style={{ marginBottom: "10px" }}
                        >
                          <span
                            className="borderInner"
                            style={{
                              color: `${textFC}`,
                              border: `1px solid ${textFC}`
                            }}
                          >
                            {reference}
                          </span>
                        </div>

                        <h2
                          style={{
                            color: `${textFC}`,
                            border: `1px solid ${textFC}`
                          }}
                          className="colorlib-heading borderInner"
                        >
                          {summary}
                        </h2>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      marginTop: "-30px"
                    }}
                  >
                    <Carousel style={{ position: "relative" }} fade={true}>
                      {this.props.homeBillboardSlides.map((item, index) => (
                        <Carousel.Item key={index}>
                          <this.renderBillboardSlides
                            key={index}
                            item={item}
                            rowCount={index}
                            minHeight={"400px"}
                            maxHeight={"400px"}
                          />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div>
          </div> */}
          {/* </div> */}
        </StyleRoot>

        <StyleRoot
          style={sectionStyleXS}
          // id={item.N_OrderNo}
          className="d-xs-block d-sm-none d-md-none HeroMain boxShadowOnly homeOverflowHidden"
        >
          {/* <div className="container"> */}
          {/* <div classNames="row">
            <div className="col-sm-12"> */}
          <div
            className="boxShadowOnly"
            style={{
              marginTop: "-55px"
            }}
          >
            <div className="carouselSlideMain-contentTopXS colorlib-about">
              <div className="carouselSlideMain-contentInnerTopXS">
                <div className="carouselSlideMain-subTitleTopXS">
                  <div className="carouselSlideMain-subTitleTopSubXS">
                    <div className="row">
                      <div>
                        <div
                          className="heading-meta"
                          style={{ marginBottom: "10px" }}
                        >
                          <span
                            className="borderInner"
                            style={{
                              color: `${textFC}`,
                              border: `1px solid ${textFC}`
                            }}
                          >
                            {reference}
                          </span>
                        </div>

                        <h2
                          style={{
                            color: `${textFC}`,
                            border: `1px solid ${textFC}`
                          }}
                          className="colorlib-heading borderInner"
                        >
                          {summary}
                        </h2>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      marginTop: "-30px"
                    }}
                  >
                    <Carousel style={{ position: "relative" }} fade={true}>
                      {this.props.homeBillboardSlides.map((item, index) => (
                        <Carousel.Item key={index}>
                          <this.renderBillboardSlides
                            key={index}
                            item={item}
                            rowCount={index}
                            minHeight={"350px"}
                            maxHeight={"350px"}
                          />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div>
          </div> */}
          {/* </div> */}
        </StyleRoot>
      </div>
    );
  }
}

//
// Babak's Notes: goto tutorial directory '11-Integration with React' file '5- Connecting Components Using react-redux'
const mapStateToProps = state => ({
  //   bugs: state.entities.customizations.list.filter(bug => !bug.resolved)
  homeBillboardSlides: state.entities.homeBillboardSlides.list
});

const mapDispatchToProps = dispatch => ({
  loadHomeBillboardSlides: () => dispatch(loadHomeBillboardSlides())
});

// Container Component
//   Presentation (Bugs)
//
// Babak's Notes: Below I populate 'this.props.bugs' and 'this.props.loadBugs()':-
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CarouselSlide);
