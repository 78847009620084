import React, { Component } from "react";
import Radium, { StyleRoot } from "radium";
import RenderHomeBillboardChildsMainContactUs from "./renderHomeBillboardChildsMainContactUs";
//import slideInLeft from "react-animations/lib/slide-in-left";
import { getHomeBillboardChildsMains } from "../../services/homeBillboardChildsMainContactUs";
import GoogleMaps from "../googleMaps/googleMaps";
import LazyLoad from "react-lazy-load";

class HomeBillboardChildsMainContactUs extends Component {
  state = {
    homeBillboardChildsMainContactUs: []
  };

  async componentDidMount() {
    const homeBillboardsID = this.props.homeBillboardsID;

    // window.alert(`renderBillboardBulletPinterChilds 01 : ${homeBillboardsID}`);

    // console.log(
    //   `HomeBillboardBulletPointChilds, componentDidMount 01 : ${homeBillboardID}`
    // );

    const {
      data: homeBillboardChildsMainContactUs
    } = await getHomeBillboardChildsMains(homeBillboardsID);

    // console.log(
    //   "componentDidMount BulletPointChild 02 : ",
    //   homeBillboardChilds
    // );

    this.setState({ homeBillboardChildsMainContactUs });
  }

  render() {
    const mainView = this.props.mainView;
    const identifier = this.props.identifier;
    const sectionStyle = this.props.sectionStyle;
    const sectionStyleMD = this.props.sectionStyleMD;
    const sectionStyleSM = this.props.sectionStyleSM;
    const sectionStyleXS = this.props.sectionStyleXS;
    const homeBillboardsID = this.props.homeBillboardsID;
    const textFC = this.props.textFC;
    const reference = this.props.reference;
    const summary = this.props.summary;
    const imageParent = this.props.imageParent;
    const urlLinkParent = this.props.urlLinkParent;
    const customizationSingleRow = this.props.customizationSingleRow;
    const googleMapHomeBiilboard = this.props.googleMapHomeBiilboard;

    const { homeBillboardChildsMainContactUs } = this.state;
    // const { length: count } = this.state.homeBillboardChildsMainContactUs;
    // window.alert(`Number of rows : ${count}`);
    // if (count === 0) return <div className="hourGlass"></div>;

    // window.alert(`Number of rows : ${reference}`);

    return (
      <div>
        <StyleRoot
          style={sectionStyle}
          className="d-none d-lg-block HeroMain homeOverflowHidden"
        >
          <div
            style={{
              marginTop: mainView ? "250px" : "0px",
              marginBottom: mainView ? "100px" : "0px"
            }}
            className="ContactUsChildHeroMain-contentTopLG colorlib-about"
          >
            <div className="ContactUsChildHeroMain-contentInnerTopLG">
              <div className="ContactUsChildHeroMain-subTitleTopLG">
                <div className="row">
                  <div>
                    <div
                      className="heading-meta"
                      style={{ marginBottom: "10px" }}
                    >
                      <span
                        className="borderInner"
                        style={{
                          color: `${textFC}`,
                          border: `1px solid ${textFC}`
                        }}
                      >
                        {reference}
                      </span>
                    </div>

                    <h2
                      style={{
                        color: `${textFC}`,
                        border: `1px solid ${textFC}`
                      }}
                      className="colorlib-heading borderInner"
                    >
                      {summary}
                    </h2>
                  </div>
                </div>
              </div>

              <div className="ContactUsChildHeroMain-subTitleMiddleLG">
                <div className="row">
                  {/* <div className="container"> */}
                  <RenderHomeBillboardChildsMainContactUs
                    homeBillboardChildsMainContactUs={
                      homeBillboardChildsMainContactUs
                    }
                    nameOfClassParentColumn={"col-6"}
                    nameOfClassChildColumn={"col-6"}
                    mediaAttribute={"LG"}
                    parentImageLink={"ContactUsChildLargeImageLG"}
                    parentReferenceLink={
                      "ContactUsChildLargeReferenceLG textTitleNoShadowBlack"
                    }
                    parentSummaryLink={
                      "ContactUsChildLargeSummaryLG textTitleNoShadowBlack"
                    }
                    childImageLink={"ContactUsChildSmallImageLG"}
                    childReferenceLink={
                      "ContactUsChildSmallReferenceLG textTitleNoShadowBlack"
                    }
                    childSummaryLink={
                      "ContactUsChildSmallSummaryLG textTitleNoShadowBlack"
                    }
                  ></RenderHomeBillboardChildsMainContactUs>
                  {/* </div> */}
                </div>
              </div>

              {customizationSingleRow.B_GoogleMap && googleMapHomeBiilboard ? (
                <div className="ContactUsGoogleMapChildHeroMain-subTitleMiddleLG">
                  {/* <div className="row">
                    <div className="container"> */}
                  <GoogleMaps customizationSingleRow={customizationSingleRow} />
                  {/* </div>
                  </div> */}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </StyleRoot>

        <StyleRoot
          style={sectionStyleMD}
          className="d-none d-md-block d-lg-none HeroMain homeOverflowHidden"
        >
          <div
            style={{
              marginTop: mainView ? "100px" : "0px",
              marginBottom: mainView ? "100px" : "0px"
            }}
            className="ContactUsChildHeroMain-contentTopMD colorlib-about"
          >
            <div className="ContactUsChildHeroMain-contentInnerTopMD">
              <div className="ContactUsChildHeroMain-subTitleTopMD">
                <div className="row">
                  <div>
                    <div
                      className="heading-meta"
                      style={{ marginBottom: "10px" }}
                    >
                      <span
                        className="borderInner"
                        style={{
                          color: `${textFC}`,
                          border: `1px solid ${textFC}`
                        }}
                      >
                        {reference}
                      </span>
                    </div>

                    <h2
                      style={{
                        color: `${textFC}`,
                        border: `1px solid ${textFC}`
                      }}
                      className="colorlib-heading borderInner"
                    >
                      {summary}
                    </h2>
                  </div>
                </div>
              </div>

              <div className="ContactUsChildHeroMain-subTitleMiddleMD">
                <div className="row">
                  <RenderHomeBillboardChildsMainContactUs
                    homeBillboardChildsMainContactUs={
                      homeBillboardChildsMainContactUs
                    }
                    nameOfClassParentColumn={"col-md-12"}
                    nameOfClassChildColumn={"col-6"}
                    mediaAttribute={"MD"}
                    parentImageLink={"ContactUsChildLargeImageMD"}
                    parentReferenceLink={
                      "ContactUsChildLargeReferenceMD textTitleNoShadowBlack"
                    }
                    parentSummaryLink={
                      "ContactUsChildLargeSummaryMD textTitleNoShadowBlack"
                    }
                    childImageLink={"ContactUsChildSmallImageMD"}
                    childReferenceLink={
                      "ContactUsChildSmallReferenceMD textTitleNoShadowBlack"
                    }
                    childSummaryLink={
                      "ContactUsChildSmallSummaryMD textTitleNoShadowBlack"
                    }
                  ></RenderHomeBillboardChildsMainContactUs>
                  {/* <div className="col-md-9">
                    <div>top row</div>
                  </div>
                  <div className="col-md-9">
                    <div className="col-6" style={{ float: "left" }}>
                      top row2
                    </div>
                    <div className="col-6" style={{ float: "left" }}>
                      bottom row2
                    </div>
                    <div className="col-6" style={{ float: "left" }}>
                      top row3
                    </div>
                    <div className="col-6" style={{ float: "left" }}>
                      bottom row3
                    </div>
                  </div> */}
                </div>
              </div>

              {customizationSingleRow.B_GoogleMap && googleMapHomeBiilboard ? (
                <div className="ContactUsGoogleMapChildHeroMain-subTitleMiddleMD">
                  <GoogleMaps customizationSingleRow={customizationSingleRow} />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </StyleRoot>

        <StyleRoot
          style={sectionStyleSM}
          className="d-sm-block d-md-none d-none HeroMain homeOverflowHidden"
        >
          <div
            style={{
              marginTop: mainView ? "50px" : "-50px",
              marginBottom: mainView ? "100px" : "0px"
            }}
            className="ContactUsChildHeroMain-contentTopSM colorlib-about"
          >
            <div className="ContactUsChildHeroMain-contentInnerTopSM">
              <div className="ContactUsChildHeroMain-subTitleTopSM">
                <div className="row">
                  <div>
                    <div
                      className="heading-meta"
                      style={{ marginBottom: "10px" }}
                    >
                      <span
                        className="borderInner"
                        style={{
                          color: `${textFC}`,
                          border: `1px solid ${textFC}`
                        }}
                      >
                        {reference}
                      </span>
                    </div>

                    <h2
                      style={{
                        color: `${textFC}`,
                        border: `1px solid ${textFC}`
                      }}
                      className="colorlib-heading borderInner"
                    >
                      {summary}
                    </h2>
                  </div>
                </div>
              </div>

              <div
                style={{
                  marginTop: mainView ? "-30px" : "-30px"
                }}
                className="ContactUsChildHeroMain-subTitleMiddleSM"
              >
                <div className="row">
                  <RenderHomeBillboardChildsMainContactUs
                    homeBillboardChildsMainContactUs={
                      homeBillboardChildsMainContactUs
                    }
                    nameOfClassParentColumn={"col-md-12"}
                    nameOfClassChildColumn={"col-6"}
                    mediaAttribute={"SM"}
                    parentImageLink={"ContactUsChildLargeImageSM"}
                    parentReferenceLink={
                      "ContactUsChildLargeReferenceSM textTitleNoShadowBlack"
                    }
                    parentSummaryLink={
                      "ContactUsChildLargeSummarySM textTitleNoShadowBlack"
                    }
                    childImageLink={"ContactUsChildSmallImageSM"}
                    childReferenceLink={
                      "ContactUsChildSmallReferenceSM textTitleNoShadowBlack"
                    }
                    childSummaryLink={
                      "ContactUsChildSmallSummarySM textTitleNoShadowBlack"
                    }
                  ></RenderHomeBillboardChildsMainContactUs>
                </div>
              </div>

              {customizationSingleRow.B_GoogleMap && googleMapHomeBiilboard ? (
                <div className="ContactUsGoogleMapChildHeroMain-subTitleMiddleSM">
                  <GoogleMaps customizationSingleRow={customizationSingleRow} />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </StyleRoot>

        <StyleRoot
          style={sectionStyleXS}
          className="d-xs-block d-sm-none d-md-none HeroMain homeOverflowHidden"
        >
          <div
            style={{
              marginTop: mainView ? "50px" : "-50px",
              marginBottom: mainView ? "100px" : "0px"
            }}
            className="ContactUsChildHeroMain-contentTopXS colorlib-about"
          >
            <div className="ContactUsChildHeroMain-contentInnerTopXS">
              <div className="ContactUsChildHeroMain-subTitleTopXS">
                <div className="row">
                  <div>
                    <div
                      className="heading-meta"
                      style={{ marginBottom: "10px" }}
                    >
                      <span
                        className="borderInner"
                        style={{
                          color: `${textFC}`,
                          border: `1px solid ${textFC}`
                        }}
                      >
                        {reference}
                      </span>
                    </div>

                    <h2
                      style={{
                        color: `${textFC}`,
                        border: `1px solid ${textFC}`
                      }}
                      className="colorlib-heading borderInner"
                    >
                      {summary}
                    </h2>
                  </div>
                </div>
              </div>

              <div
                style={{
                  marginTop: mainView ? "-30px" : "-30px"
                }}
                className="ContactUsChildHeroMain-subTitleMiddleXS"
              >
                <div className="row">
                  <RenderHomeBillboardChildsMainContactUs
                    homeBillboardChildsMainContactUs={
                      homeBillboardChildsMainContactUs
                    }
                    nameOfClassParentColumn={"col-md-12"}
                    nameOfClassChildColumn={"col-6"}
                    mediaAttribute={"XS"}
                    parentImageLink={"ContactUsChildLargeImageXS"}
                    parentReferenceLink={
                      "ContactUsChildLargeReferenceXS textTitleNoShadowBlack"
                    }
                    parentSummaryLink={
                      "ContactUsChildLargeSummaryXS textTitleNoShadowBlack"
                    }
                    childImageLink={"ContactUsChildSmallImageXS"}
                    childReferenceLink={
                      "ContactUsChildSmallReferenceXS textTitleNoShadowBlack"
                    }
                    childSummaryLink={
                      "ContactUsChildSmallSummaryXS textTitleNoShadowBlack"
                    }
                  ></RenderHomeBillboardChildsMainContactUs>
                </div>
              </div>

              {customizationSingleRow.B_GoogleMap && googleMapHomeBiilboard ? (
                <div className="ContactUsGoogleMapChildHeroMain-subTitleMiddleXS">
                  <GoogleMaps customizationSingleRow={customizationSingleRow} />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </StyleRoot>
      </div>
    );
  }
}

export default HomeBillboardChildsMainContactUs;
