import React from "react";
import { Redirect } from "react-router-dom";
import Joi from "joi-browser";
import Form from "../common/form";
// import backgroundImage from "../../images/masthead-alt-06-wide-large.jpg";
import { NavLink } from "react-router-dom";
import { loadLogin } from "../../store/auth";
import auth from "../../services/authService";
import { connect } from "react-redux";

class LoginForm extends Form {
  state = {
    data: { username: "", password: "", rememberMe: false },
    jwt: "",
    errors: {}
  };

  schemaCreate = {
    username: Joi.string()
      // .email()
      .required()
      .label("Username"),
    password: Joi.string()
      .required()
      .label("Password"),
    rememberMe: Joi.boolean().label("Remember Me")
  };

  schemaEdit = {
    username: Joi.string()
      // .email()
      .required()
      .label("Username"),
    password: Joi.string()
      .required()
      .label("Password"),
    rememberMe: Joi.boolean().label("Remember Me")
  };

  componentDidMount() {
    // const { customizationSingleRow } = this.props;
    // document.body.style.backgroundImage = `url(${customizationSingleRow.I_LoginBGImage})`;
    // // document.body.style.backgroundColor = "white";
    // document.body.style.backgroundPosition = "center center";
    // document.body.style.backgroundAttachment = "fixed";
    // document.body.style.backgroundRepeat = "no-repeat";
    // document.body.style.backgroundSize = "cover";
    // // console.log("LoginForm, componentDidMount()");
  }

  async componentWillReceiveProps(nextProps) {
    console.log(
      "LoginForm, componentWillReceiveProps 1 : ",
      nextProps.login,
      nextProps.authError
    );

    const tokenKey = "token";
    const { data } = this.state;

    //
    // Babak's Note: 'componentWillReceiveProps' is ran after Redux store is updated, therefore 'this.props' is updated which passes values via param 'nextProps':-
    //               'componentWillReceiveProps' alos runs after 'componentDidMount'. 'componentDidMount' will not update 'this.props', we have to wait until
    //               we reach 'componentWillReceiveProps'.
    if (nextProps.authError) {
      // this.setState({ errors: nextProps.authError });
      const errors = { ...this.state.data };
      // console.log(
      //   "componentWillReceiveProps 2 : ",
      //   nextProps.authError,
      //   errors,
      //   this.state.errors.username
      // );
      errors.username = nextProps.authError;
      // console.log(
      //   "componentWillReceiveProps 3 : ",
      //   nextProps.authError,
      //   errors,
      //   this.state.errors.username
      // );
      this.setState({ errors });
    } else if (this.state.jwt !== nextProps.jwt) {
      // this.setState({ data: nextProps.login });
      const jwt = nextProps.jwt;
      // console.log(
      //   "LoginForm, componentWillReceiveProps 2 : ",
      //   data.rememberMe,
      //   jwt
      // );
      if (data.rememberMe) await localStorage.setItem(tokenKey, jwt);
      else await sessionStorage.setItem(tokenKey, jwt);

      const { state } = this.props.location;
      window.location = state ? state.from.pathname : "/";
    }
  }

  // getDerivedStateFromProps(props, state) {
  //   console.log("LoginForm, getDerivedStateFromProps 1 : ");
  // }

  // validate = () => {
  //   // const result = Joi.validate(this.state.data, this.schema, {
  //   //   abortEarly: false
  //   // });
  //   //console.log(result);
  //   // if (!result.error) return null;
  //   // Babak's Note: Object destructering from above to below
  //   const options = { abortEarly: false };
  //   const { error } = Joi.validate(this.state.data, this.schema, options);
  //   if (!error) return null;

  //   const errors = {};
  //   for (let item of error.details) errors[item.path[0]] = item.message;
  //   return errors;
  // };

  // validateProperty = ({ name, value }) => {
  //   //const obj = { username: value };
  //   // Babak's Note: Changed above line to the below for 'Computed Properties' :-
  //   const obj = { [name]: value };
  //   const schema = { [name]: this.schema[name] };
  //   const { error } = Joi.validate(obj, schema);
  //   // if (error) return null;
  //   // return error.details[0].message;
  //   // Babak's Note: Changed above two lines to the below for 'Turner Operater' :-
  //   return error ? error.details[0].message : null;

  //   // if (name === "username") {
  //   //   if (value.trim() === "") return "Username is required";
  //   //   // other rules...
  //   // }
  //   // if (name === "password") {
  //   //   if (value.trim() === "") return "Password is required";
  //   //   // other rules...
  //   // }
  // };
  //username = React.createRef();

  // componentDidMount() {
  //   this.username.current.focus();
  // }

  doSubmit = async () => {
    // console.log("LoginForm, doSubmit 1 : ", this.props.login);

    //
    // Babak's Note: Call the server

    try {
      const { data } = this.state;
      // await auth.login(data.username, data.password, data.rememberMe);
      await this.props.loadLogin(data);

      //
      // Babak's Note: Replaced above line with below two lines so if 'state' that is passed from 'protectedRoute'
      //               is populated then we go to 'pathname'(which ever page we clicked on) after logging in otherwise
      //               go to home ("/") page :-
      // console.log(
      //   "LoginForm, doSubmit 2 : ",
      //   this.props.login,
      //   this.props.authError,
      //   this.state.errors
      // );
      // const { state } = this.props.location;
      // window.location = state ? state.from.pathname : "/";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.data };
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  render() {
    // console.log(
    //   "LoginForm, render() : ",
    //   this.props.login,
    //   this.props.authError,
    //   this.state.errors
    // );

    const { customizationSingleRow } = this.props;

    //
    // Babak's note: Below configures if we are currently on IOS (IPhone):
    const is_ios = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);

    const sectionStyle = {
      height: "100vh",
      backgroundImage: `url(${customizationSingleRow.I_LoginBGImage})`,
      backgroundPosition: "center center",
      backgroundAttachment: is_ios ? "scroll" : "fixed",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover"
    };

    //
    // Babak's Note: If we are logged in we can still goto 'loginForm' (this page) if we enter in the url
    //               'http://localhost:3000/login'. This is not correct. We add the following so we are redirected
    //               back to 'movies' page. Also we did not use 'window.location = "/";' and used '<Redirect to="/" />'.
    //               The user is not trying to login as we are already trying to login. We use 'window.location = "/";'
    //               when we want to remount the application:-

    if (auth.getCurrentUser()) return <Redirect to="/" />;

    return (
      <div style={sectionStyle}>
        <div className="container homeContainer">
          <h1
            className="d-none d-lg-block loginTextTitleShadowWhite"
            style={{
              transform: "translateY(100px)",
              textAlign: "center",
              paddingTop: "120px"
            }}
          >
            {customizationSingleRow.A_LoginTitle}
          </h1>
          <h1
            className="d-lg-none loginTextTitleSmallShadowWhite"
            style={{
              transform: "translateY(100px)",
              textAlign: "center",
              paddingTop: "120px"
            }}
          >
            {customizationSingleRow.A_LoginTitle}
          </h1>
          <div className="row" style={{ paddingTop: "60px" }}>
            <div
              className="col-sm-9 col-md-7 col-lg-5 mx-auto"
              style={{ transform: "translateY(32%)", paddingBottom: "50px" }}
            >
              <div
                className="card card-signin my-5"
                style={{
                  backgroundColor: "#add8e6",
                  borderRadius: "1rem",
                  borderWidth: "5px",
                  borderColor: "#0078d4"
                }}
              >
                <div
                  className="card-body"
                  style={{
                    backgroundColor: "#add8e6",
                    borderRadius: "1rem"
                  }}
                >
                  {/* <div className="rtlTextAlign"> */}
                  <h1>Patient Login</h1>
                  {/* </div> */}
                  <br />
                  <form className="form-signin" onSubmit={this.handleSubmit}>
                    {this.renderInputLoginFocus("username", "E-Mail")}
                    {this.renderInput("password", "Password", "password")}

                    <div className="row">
                      <div className="col-6">{this.renderButton("Login")}</div>
                      <div className="col-6">
                        <span className="float-right">
                          {this.renderCheckboxRememberMe(
                            "rememberMe",
                            "Remember Me"
                          )}
                        </span>
                      </div>
                    </div>
                    {/* <NavLink
                    className="nav-item nav-link text-primary marginLeft"
                    to="/register"
                  >
                    Register
                  </NavLink> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

//
// Babak's Notes: goto tutorial directory '11-Integration with React' file '5- Connecting Components Using react-redux'
const mapStateToProps = state => ({
  //   users: state.entities.users.list.filter(user => !user.resolved)
  jwt: state.entities.auth.jwt,
  authError: state.entities.auth.authError
});

const mapDispatchToProps = dispatch => ({
  loadLogin: userLogin => dispatch(loadLogin(userLogin))
});

// Container Component
//   Presentation (Users)
//
// Babak's Notes: Below I populate 'this.props.users' and 'this.props.loadUsers()':-
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginForm);
