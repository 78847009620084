import http from "./httpService";

// import { apiUrl } from "../config.json";
// const apiEndpoint = apiUrl + "/billboardLarges";
const apiEndpoint = "/billboardLarges";

function BillboardLargesSearchAutoCompleteUrl(id) {
  return `${apiEndpoint}/searchAutoComplete/${id}`;
}

function BillboardLargesSearchDynamicUrl(id) {
  return `${apiEndpoint}/searchDynamic/${id}`;
}

function BillboardLargesSlidesUrl() {
  return `${apiEndpoint}/slides`;
}

function BillboardLargesWindowsUrl() {
  return `${apiEndpoint}/windows`;
}

export function getBillboardLargesSearchAutoComplete(
  billLocationSearchAutoComplete
) {
  // console.log(
  //   "getBillboardLargesSearchAutoComplete : ",
  //   BillboardLargesSearchAutoCompleteUrl(billLocationSearchAutoComplete)
  // );
  return http.get(
    BillboardLargesSearchAutoCompleteUrl(billLocationSearchAutoComplete)
  );
}

export function getBillboardLargesSearchDynamic(billLocationSearchDynamic) {
  // console.log(
  //   "getBillboardLargesSearchDynamic : ",
  //   BillboardLargesSearchDynamicUrl(billLocationSearchDynamic)
  // );
  return http.get(BillboardLargesSearchDynamicUrl(billLocationSearchDynamic));
}

export function getBillboardLargesSlides() {
  // console.log(
  //   "getBillboardLargesSearchDynamic : ",
  //   BillboardLargesSearchDynamicUrl(billLocationSearchDynamic)
  // );
  return http.get(BillboardLargesSlidesUrl());
}

export function getBillboardLargesWindows() {
  // console.log(
  //   "getBillboardLargesSearchDynamic : ",
  //   BillboardLargesSearchDynamicUrl(billLocationSearchDynamic)
  // );
  return http.get(BillboardLargesWindowsUrl());
}
