import React, { Component, Suspense } from "react";
import { loadHomeBillboardsMainsAboutUs } from "../../store/homeBillboardsMainsAboutUs";
import HomeBillboardChildsMainAboutUs from "./homeBillboardChildsMainAboutUs";
import NavBarFooter from "../navBarFooter";
import { connect } from "react-redux";
// import LazyLoad from "react-lazy-load";
// import {
//   fadeIn,
//   fadeInUp,
//   bounce,
//   flipInX,
//   flipInY,
//   rubberBand,
//   shake,
//   rotateInUpRight,
//   rotateInUpLeft,
//   slideInUp
// } from "react-animations";
// import Radium, { StyleRoot } from "radium";
import ScrollUpButton from "react-scroll-up-button";

class AboutUs extends Component {
  state = {
    data: {
      A_Reference: "",
      A_Summary: "",
      I_Image: ""
    }
  };

  async populateHomeBillboardsMains() {
    // window.alert(
    //   `AboutUs  componentDidMount : ${this.props.customizationSingleRow.N_AboutUsNo}`
    // );

    await this.props.loadHomeBillboardsMainsAboutUs(
      this.props.customizationSingleRow.N_AboutUsNo
    );
  }

  async componentDidMount() {
    await this.populateHomeBillboardsMains();
  }

  //   async componentWillReceiveProps(nextProps) {
  //     // window.alert("MenuForm, componentWillReceiveProps", nextProps.menuSuccess);

  //     let data = { ...this.state.data };

  //     // if (this.state.data !== nextProps.menu) {

  //     data["A_Reference"] = nextProps.menus.A_Reference;
  //     data["A_Summary"] = nextProps.menus.A_Summary;
  //     data["I_Image"] = nextProps.menus.I_Image;

  //     this.setState({
  //       data
  //     });
  //     // }
  //   }

  //   async mapToViewModel(billboardLarge) {
  //     //console.log("mapToViewModel-1", billboardLarge);
  //     return {
  //       A_Reference: billboardLarge.A_Reference,
  //       A_Summary: billboardLarge.A_Summary,
  //       I_Image: billboardLarge.I_Image
  //     };
  //   }

  renderBillboardData = ({ item }) => {
    // console.log("renderBillboardData 01 : ", item, rowCount);

    // let sectionStyleTop;
    let sectionStyle, sectionStyleMD, sectionStyleSM, sectionStyleXS;
    // let sectionStyleBottom;

    // const styles = {
    //   bounce: {
    //     animation: "y 3s",
    //     animationName: Radium.keyframes(bounce, "bounce")
    //   },
    //   fadeIn: {
    //     animation: "y 8s",
    //     animationName: Radium.keyframes(fadeIn, "fadeIn")
    //   },
    //   fadeInUp01: {
    //     animation: "y 1s",
    //     animationName: Radium.keyframes(fadeInUp, "fadeInUp01")
    //   },
    //   fadeInUp02: {
    //     animation: "y 2s",
    //     animationName: Radium.keyframes(fadeInUp, "fadeInUp02")
    //   },
    //   fadeInUp03: {
    //     animation: "y 3s",
    //     animationName: Radium.keyframes(fadeInUp, "fadeInUp03")
    //   },
    //   fadeInUp04: {
    //     animation: "y 4s",
    //     animationName: Radium.keyframes(fadeInUp, "fadeInUp04")
    //   },
    //   flipInX: {
    //     animation: "y 3s",
    //     animationName: Radium.keyframes(flipInX, "flipInX")
    //   },
    //   flipInY: {
    //     animation: "y 3s",
    //     animationName: Radium.keyframes(flipInY, "flipInY")
    //   },
    //   rubberBand: {
    //     animation: "y 3s",
    //     animationName: Radium.keyframes(rubberBand, "rubberBand")
    //   },
    //   shake: {
    //     animation: "y 3s",
    //     animationName: Radium.keyframes(shake, "shake")
    //   },
    //   rotateInUpRight: {
    //     animation: "y 3s",
    //     animationName: Radium.keyframes(rotateInUpRight, "rotateInUpRight")
    //   },
    //   rotateInUpLeft: {
    //     animation: "y 3s",
    //     animationName: Radium.keyframes(rotateInUpLeft, "rotateInUpLeft")
    //   },
    //   slideInUp01: {
    //     animation: "y 3s",
    //     animationName: Radium.keyframes(slideInUp, fadeIn, "slideInUp01")
    //   },
    //   slideInUp02: {
    //     animation: "y 5s",
    //     animationName: Radium.keyframes(slideInUp, "slideInUp03")
    //   },
    //   slideInUp03: {
    //     animation: "y 8s",
    //     animationName: Radium.keyframes(slideInUp, "slideInUp03")
    //   }
    // };

    // document.body.style.backgroundImage = `url(${item.I_Image})`;
    // document.body.style.backgroundRepeat = "no-repeat";
    // document.body.style.backgroundSize = "cover";

    if (item.N_RadioGrpDirections_ID === 5) {
      // sectionStyleTop = {
      //   height: "30vh",
      //   color: "white",
      //   backgroundPosition: "center center",
      //   backgroundRepeat: "no-repeat",
      //   backgroundSize: "cover"
      // };
      sectionStyle = {
        height: "1150px",
        backgroundImage: `linear-gradient(to bottom, ${item.A_BGC}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,
        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      sectionStyleMD = {
        height: "1150px",
        backgroundImage: `linear-gradient(to bottom, ${item.A_BGC}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,
        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      sectionStyleSM = {
        height: "1150px",
        backgroundImage: `linear-gradient(to bottom, ${item.A_BGC}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,
        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      sectionStyleXS = {
        height: "1150px",
        backgroundImage: `linear-gradient(to bottom, ${item.A_BGC}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,
        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      // sectionStyleBottom = {
      //   height: "30vh",
      //   color: "white",
      //   backgroundPosition: "center center",
      //   backgroundRepeat: "no-repeat",
      //   backgroundSize: "cover"
      // };
    }

    // let sectionStyleSmallerLg;
    // sectionStyleSmallerLg = {
    //   height: "50vh",
    //   backgroundImage: `url(${item.I_Image})`,
    //   backgroundPosition: "center center",
    //   backgroundRepeat: "no-repeat",
    //   backgroundSize: "cover"
    // };

    // Babak's Notes: Commented out on 20200115: START
    return (
      // <div style={sectionStyle} className="section-1 box"></div>
      <div id={item.N_OrderNo}>
        <HomeBillboardChildsMainAboutUs
          mainView={true}
          identifier={item.N_OrderNo}
          sectionStyle={sectionStyle}
          sectionStyleMD={sectionStyleMD}
          sectionStyleSM={sectionStyleSM}
          sectionStyleXS={sectionStyleXS}
          homeBillboardsID={item.ID}
          reference={item.A_Reference}
          summary={item.A_Summary}
          imageParent={item.I_Image}
          urlLinkParent={item.A_HoverSummary01_Link}
        ></HomeBillboardChildsMainAboutUs>
      </div>
    );
  };

  render() {
    const { data } = this.state;
    // const { billboardLargesGroup } = this.props.groups;

    // let sectionStyle;

    // sectionStyle = {
    //   // height: "100vh",
    //   // backgroundAttachment: "fixed",
    //   // backgroundSize: "cover",
    //   // width: width,
    //   //opacity: 0.5,
    //   backgroundImage: `url(${data.I_Image})`,
    //   backgroundRepeat: "no-repeat",
    //   backgroundSize: "cover"
    // };

    // console.log(
    //   "DetailsGroup render, Number of billboardLargesGroup: ",
    //   this.props.match.params.id,
    //   data,
    //   this.props.groups,
    //   this.props.groups.length
    // );

    if (this.props.homeBillboardsMainsAboutUs.length === 0)
      return <div className="hourGlass"></div>;

    return (
      <div>
        <div>
          {this.props.homeBillboardsMainsAboutUs.map((item, index) => (
            <this.renderBillboardData item={item} />
          ))}
        </div>
        <NavBarFooter
          nameOfClass="footer"
          customizationSingleRow={this.props.customizationSingleRow}
          menuOnNavvar={this.props.menuOnNavvar}
          onClickSearch={this.onClickSearchHandle}
        />
        <div>
          <ScrollUpButton style={{ marginBottom: "80px" }} />
        </div>
      </div>
    );
  }
}

//
// Babak's Notes: goto tutorial directory '11-Integration with React' file '5- Connecting Components Using react-redux'
const mapStateToProps = state => ({
  //   bugs: state.entities.menus.list.filter(bug => !bug.resolved)
  homeBillboardsMainsAboutUs: state.entities.homeBillboardsMainsAboutUs.list
});

const mapDispatchToProps = dispatch => ({
  loadHomeBillboardsMainsAboutUs: orderNo =>
    dispatch(loadHomeBillboardsMainsAboutUs(orderNo))
});

// Container Component
//   Presentation (Bugs)
//
// Babak's Notes: Below I populate 'this.props.bugs' and 'this.props.loadBugs()':-
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AboutUs);
