import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
import moment from "moment";

const slice = createSlice({
  name: "roles",
  initialState: {
    list: [],
    loading: false,
    lastFetch: null
  },
  reducers: {
    //
    // Babak's Note: By adding 'rolesRequested' or 'rolesReceived' below, a action is created for us:-
    // actions => action handlers. The 'roles' below represents the 'state':-
    rolesRequested: (roles, action) => {
      console.log("rolesRequested : ", action.payload);
      roles.loading = true;
    },
    rolesReceived: (roles, action) => {
      console.log("rolesReceived : ", action.payload);
      roles.list = action.payload;
      roles.loading = false;
      roles.lastFetch = Date.now();
    },
    rolesRequestedFailed: (roles, action) => {
      roles.loading = false;
      // window.alert(`No Roles found.`);
    },
    roleRequested: (role, action) => {
      console.log("roleRequested : ", action.payload);
      role.loading = true;
    },
    roleReceived: (role, action) => {
      console.log("roleReceived : ", action.payload);
      role.list = action.payload;
      role.loading = false;
      role.lastFetch = Date.now();
    },
    roleRequestedFailed: (role, action) => {
      role.loading = false;
      // window.alert(`No Role found.`);
    },
    roleChangeRequested: (role, action) => {
      console.log("roleChangeRequested : ", action.payload);
      role.loading = true;
    },
    roleChangeFailed: (role, action) => {
      role.loading = false;

      // //
      // // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete role api at the back end.
      // const { ID: roleId, A_Name: roleName } = action.payload;

      // window.alert(`Role '${roleName}' no longer exists.`);

      // const index = role.list.findIndex(role => role.ID === roleId);
      // //console.log("roleDeleted ha ", index);
      // role.list.splice(index, 1);
    },
    roleChanged: (role, action) => {
      role.loading = false;
    },
    roleDeleteRequested: (role, action) => {
      console.log("roleDeleteRequested : ", action.payload);
      role.loading = true;
    },
    roleDeleteFailed: (roles, action) => {
      roles.loading = false;

      //
      // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete role api at the back end.
      const { ID: roleId, A_Name: roleName } = action.payload;

      window.alert(
        `An issue has occured with role '${roleName}'. ${action.payload}`
      );

      const index = roles.list.findIndex(role => role.ID === roleId);
      //console.log("roleDeleted ha ", index);
      roles.list.splice(index, 1);
    },
    roleDeleted: (roles, action) => {
      //
      // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete role api at the back end.
      const { ID: roleID } = action.payload;
      // console.log("roleDeleted ha ", action.payload, roleID, roles);
      const index = roles.list.findIndex(role => role.ID === roleID);
      //console.log("roleDeleted, ha! ha! ", index);
      roles.list.splice(index, 1);
    },
    roleAddRequested: (role, action) => {
      console.log("roleAddRequested : ", action.payload);
      role.loading = true;
    },
    roleAddFailed: (role, action) => {
      role.loading = false;
    },
    //
    // Babak's Note: The 'roles' below represents the 'state':-
    roleAdded: (roles, action) => {
      console.log("roleAdded : ", action.payload);
      roles.list.push(action.payload);
    }
    // roleAdded: (roles, action) => {
    //   roles.list.push({
    //     id: ++lastId,
    //     description: action.payload.description,
    //     resolved: false
    //   });
    // },
    // roleDeleted: (roles, action) => {
    //   console.log("roleDeleted ha ", action.payload.ID);
    //   roles.list.filter(role => role.ID !== action.payload.ID);
    // },
  }
});
const {
  roleAddRequested,
  roleAdded,
  roleAddFailed,
  roleChangeRequested,
  roleChanged,
  roleChangeFailed,
  roleDeleteRequested,
  roleDeleted,
  roleDeleteFailed,
  rolesReceived,
  rolesRequested,
  rolesRequestedFailed,
  roleReceived,
  roleRequested,
  roleRequestedFailed
} = slice.actions;
export default slice.reducer;

//
// Action Creators
const url = "/roles";

export const loadRoles = () => (dispatch, getState) => {
  const { lastFetch } = getState().entities.roles;

  console.log("loadRoles lastFetch : ", lastFetch);

  // //
  // // Babak's Note: Below we check with 'moment' that catch that if this request was already fetched less than 10 minutes ago then do not fetch again:-
  // const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  // if (diffInMinutes < 10) return;

  dispatch(
    apiCallBegan({
      url: url,
      onStart: rolesRequested.type,
      method: "get",
      onSuccess: rolesReceived.type,
      onError: rolesRequestedFailed.type
    })
  );
};

export const loadRole = roleID => (dispatch, getState) => {
  // const { lastFetch } = getState().entities.roles;

  //console.log("loadRole : ", roleID);

  // //
  // // Babak's Note: Below we check with 'moment' that catch that if this request was already fetched less than 10 minutes ago then do not fetch again:-
  // const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  // if (diffInMinutes < 10) return;

  dispatch(
    apiCallBegan({
      url: url + "/" + roleID,
      onStart: roleRequested.type,
      method: "get",
      onSuccess: roleReceived.type,
      onError: roleRequestedFailed.type
    })
  );
};

//
// Babak's Note: Below function 'apiCallBegan' is returned back by which function (in this case from within 'index.js') calls 'addRole':-
export const addRole = role =>
  apiCallBegan({
    url,
    method: "post",
    onStart: roleAddRequested.type,
    data: role,
    onSuccess: roleAdded.type,
    onError: roleAddFailed.type
  });

export const getRoles = createSelector(
  state => state.entities.roles,
  //
  // Babak's Note: In the below line, by installing above 'reselect' (at the top of this file) if the list of (roles, projects) has not changed then do not requery again:-
  // (roles) => roles.list.filter(role => !role.B_Active)
  roles => roles.list
);

export const changeRole = role =>
  apiCallBegan({
    url: url + "/" + role.ID,
    method: "put",
    onStart: roleChangeRequested.type,
    data: {
      A_Name: role.A_Name,
      A_Description: role.A_Description,
      A_Discriminator: role.A_Discriminator
    },
    onSuccess: roleChanged.type,
    onError: roleChangeFailed.type
  });

export const deleteRole = role =>
  apiCallBegan({
    url: url + "/" + role.ID,
    method: "delete",
    onStart: roleDeleteRequested.type,
    data: { ID: role.ID, A_Name: role.A_Name },
    onSuccess: roleDeleted.type,
    onError: roleDeleteFailed.type
  });
