import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import auth from "../../services/authService";
import { loadArticle, addArticle, changeArticle } from "../../store/articles";
import { connect } from "react-redux";

class ArticleForm extends Form {
  state = {
    data: {
      N_BillboardOrigin_ID: "",
      N_HomeLocationGroup_ID: "",
      N_Order_No: "",
      A_Reference: "",
      A_Summary: "",
      A_Description: "",
      I_Image: "",
      N_BillboardLarge_ID: "",
      N_ParentOrigin_ID: "",
      waitingForFileUpload: false
    },
    errors: {},
    billboardOrigin: [],
    //homeLocationGroup: [],
    N_BillboardOrigin_ID: "",
    N_HomeLocationGroup_ID: "",
    N_ParentOrigin_ID: "",
    user: []
  };

  schemaCreate = {
    ID: Joi.number(),
    N_BillboardOrigin_ID: Joi.number()
      .allow("")
      .optional()
      //.required()
      // .min(1)
      // .max(100)
      .label("Billboard Origin"),
    N_HomeLocationGroup_ID: Joi.number()
      .allow("")
      .optional()
      //.required()
      // .min(1)
      // .max(100)
      .label("Home Location Group"),
    N_Order_No: Joi.number()
      .required()
      .min(1)
      .max(100)
      .label("Order No"),
    A_Reference: Joi.string()
      .allow("")
      .optional()
      // .required()
      // .min(3)
      .max(30)
      .label("Reference"),
    A_Summary: Joi.string()
      .allow("")
      .optional()
      // .required()
      // .min(3)
      .max(200)
      .label("Summary"),
    A_Description: Joi.string()
      .allow("")
      .optional()
      // .required()
      // .min(3)
      .max(2000)
      .label("Description"),
    I_Image: Joi.any()
      .meta({ swaggerType: "file" })
      // .required()
      .optional()
      .label("Image"),
    N_PhotoSize01: Joi.number()
      .min(5000)
      .max(10000000)
      .optional(),
    waitingForFileUpload: Joi.boolean().optional(),
    N_BillboardLarge_ID: Joi.number()
      .allow("")
      .optional(),
    N_ParentOrigin_ID: Joi.number()
      .allow("")
      .optional()
  };

  schemaEdit = {
    ID: Joi.number(),
    N_BillboardOrigin_ID: Joi.number()
      .allow("")
      .optional()
      //.required()
      // .min(1)
      // .max(100)
      .label("Billboard Origin"),
    N_HomeLocationGroup_ID: Joi.number()
      .allow("")
      .optional()
      //.required()
      // .min(1)
      // .max(100)
      .label("Home Location Group"),
    N_Order_No: Joi.number()
      .required()
      // .min(1)
      // .max(100)
      .label("Order No"),
    A_Reference: Joi.string()
      .allow("")
      .optional()
      // .required()
      // .min(3)
      .max(30)
      .label("Reference"),
    A_Summary: Joi.string()
      .allow("")
      .optional()
      // .required()
      // .min(3)
      .max(200)
      .label("Summary"),
    A_Description: Joi.string()
      .allow("")
      .optional()
      // .required()
      // .min(3)
      .max(2000)
      .label("Description"),
    I_Image: Joi.any()
      .meta({ swaggerType: "file" })
      // .required()
      .optional()
      .label("Image"),
    N_PhotoSize01: Joi.number()
      .min(5000)
      .max(10000000)
      .optional(),
    waitingForFileUpload: Joi.boolean().optional(),
    N_BillboardLarge_ID: Joi.number()
      .allow("")
      .optional(),
    N_ParentOrigin_ID: Joi.number()
      .allow("")
      .optional()
  };

  async populateArticles() {
    try {
      console.log("populateArticles-1", this.props.match.params.sumOfArticleID);

      let data = { ...this.state.data };
      let {
        N_BillboardOrigin_ID,
        N_HomeLocationGroup_ID,
        N_ParentOrigin_ID
      } = this.state;

      const articleId = this.props.match.params.id;

      if (articleId === "new") {
        data["N_BillboardOrigin_ID"] = 0;
        N_BillboardOrigin_ID = 0;
        data["N_HomeLocationGroup_ID"] = 4;
        N_HomeLocationGroup_ID = 4;
        data["N_BillboardLarge_ID"] = this.props.match.params.sumOfArticleID;
        data["N_ParentOrigin_ID"] = 4;
        N_ParentOrigin_ID = 4;

        this.setState({
          data,
          //   homeLocationGroup,
          N_BillboardOrigin_ID,
          N_HomeLocationGroup_ID,
          N_ParentOrigin_ID,
          user: auth.getCurrentUser()
        });
        return;
      }

      await this.props.loadArticle(articleId);

      // const { data: article } = await getArticle(ArticleId);

      // const rawData = this.mapToViewModel(article);

      // data["ID"] = rawData.ID;
      // N_BillboardOrigin_ID = rawData.N_BillboardOrigin_ID;
      // data["N_BillboardOrigin_ID"] = rawData.N_BillboardOrigin_ID;
      // N_HomeLocationGroup_ID = rawData.N_HomeLocationGroup_ID;
      // data["N_HomeLocationGroup_ID"] = rawData.N_HomeLocationGroup_ID;
      // data["N_Order_No"] = rawData.N_Order_No;
      // data["A_Reference"] = rawData.A_Reference;
      // data["A_Summary"] = rawData.A_Summary;
      // data["A_Description"] = rawData.A_Description;
      // data["I_Image"] = rawData.I_Image;
      // data["N_BillboardLarge_ID"] = rawData.N_BillboardLarge_ID;
      // N_ParentOrigin_ID = rawData.N_ParentOrigin_ID;
      // data["N_ParentOrigin_ID"] = rawData.N_ParentOrigin_ID;

      // console.log(
      //   "populateArticles-1",
      //   rawData.N_BillboardLarge_ID,
      //   sumOfArticleID
      // );

      // this.setState({
      //   data,
      //   // billboardOrigin,
      //   // homeLocationGroup,
      //   N_BillboardOrigin_ID,
      //   N_HomeLocationGroup_ID,
      //   N_ParentOrigin_ID,
      //   user: auth.getCurrentUser()
      // });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async componentDidMount() {
    await this.populateArticles();
  }

  // mapToViewModel(article) {
  //   //console.log("mapToViewModel-1", article);
  //   return {
  //     ID: article.ID,
  //     N_BillboardOrigin_ID: article.N_BillboardOrigin_ID,
  //     N_HomeLocationGroup_ID: article.N_HomeLocationGroup_ID,
  //     N_Order_No: article.N_Order_No,
  //     A_Reference: article.A_Reference,
  //     A_Summary: article.A_Summary,
  //     A_Description: article.A_Description,
  //     I_Image: article.I_Image,
  //     N_BillboardLarge_ID: article.N_BillboardLarge_ID,
  //     N_ParentOrigin_ID: article.N_ParentOrigin_ID
  //   };
  // }

  async componentWillReceiveProps(nextProps) {
    // window.alert("MenuForm, componentWillReceiveProps", nextProps.menuSuccess);

    let data = { ...this.state.data };
    let {
      N_BillboardOrigin_ID,
      N_HomeLocationGroup_ID,
      N_ParentOrigin_ID
    } = this.state;

    if (
      this.props.match.params.id !== "new" &&
      this.state.data !== nextProps.sumOfArticle
    ) {
      data["ID"] = nextProps.article.ID;
      N_BillboardOrigin_ID = nextProps.article.N_BillboardOrigin_ID;
      data["N_BillboardOrigin_ID"] = nextProps.article.N_BillboardOrigin_ID;
      N_HomeLocationGroup_ID = nextProps.article.N_HomeLocationGroup_ID;
      data["N_HomeLocationGroup_ID"] = nextProps.article.N_HomeLocationGroup_ID;
      data["N_Order_No"] = nextProps.article.N_Order_No;
      data["A_Reference"] = nextProps.article.A_Reference;
      data["A_Summary"] = nextProps.article.A_Summary;
      data["A_Description"] = nextProps.article.A_Description;
      data["I_Image"] = nextProps.article.I_Image;
      N_ParentOrigin_ID = nextProps.article.N_ParentOrigin_ID;
      data["N_ParentOrigin_ID"] = nextProps.article.N_ParentOrigin_ID;

      this.setState({
        data,
        user: auth.getCurrentUser()
      });
    } else {
      data["N_BillboardOrigin_ID"] = 0;
      N_BillboardOrigin_ID = 0;
      data["N_HomeLocationGroup_ID"] = 4;
      N_HomeLocationGroup_ID = 4;
      data["N_BillboardLarge_ID"] = this.props.match.params.sumOfArticleID;
      data["N_ParentOrigin_ID"] = 4;
      N_ParentOrigin_ID = 4;

      this.setState({
        data,
        user: auth.getCurrentUser()
      });
    }
  }

  doSubmit = async () => {
    try {
      const articleId = this.props.match.params.id;
      if (articleId === "new") {
        const currentDate = new Date();
        const month = currentDate.getMonth() + 1;
        const currentDateWithoutTime =
          month +
          "/" +
          currentDate.getDate() +
          "/" +
          currentDate.getFullYear() +
          " " +
          currentDate.getHours() +
          ":" +
          currentDate.getMinutes();

        await this.props.addArticle(
          this.state.data,
          auth.getCurrentUser().a_name,
          currentDateWithoutTime
        );
      } else {
        const currentDate = new Date();
        const month = currentDate.getMonth() + 1;
        const currentDateWithoutTime =
          month +
          "/" +
          currentDate.getDate() +
          "/" +
          currentDate.getFullYear() +
          " " +
          currentDate.getHours() +
          ":" +
          currentDate.getMinutes();

        await this.props.changeArticle(
          this.state.data,
          auth.getCurrentUser().a_name,
          currentDateWithoutTime
        );
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.title = ex.response.data;
        this.setState({ errors });
      }
    }

    this.props.history.push(
      `/articles/articlesList/${this.props.match.params.sumOfArticleID}`
    );
  };

  clearImages = () => {
    let obj = this.state.data;
    obj["I_Image"] = "";
    this.setState({ data: obj });
  };

  cancelForm = () => {
    this.props.history.push(
      `/articles/articlesList/${this.props.match.params.sumOfArticleID}`
    );
  };

  constructor() {
    // ...
    super();

    // this.handleRadioGroupChange = this.handleRadioGroupChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    const billboardOrigin = this.state.billboardOrigin;
    const homeLocationGroup = this.state.homeLocationGroup;

    // console.log(
    //   "Article render : ",
    //   this.state.N_BillboardOrigin_ID,
    //   this.state.data["N_BillboardOrigin_ID"]
    // );

    // Babak's Note: *** Below 3 lines will stop rendering of child component 'TableBodyAppoints' section
    //               'console.log("Hello 04 : ");' first *** :-
    // const { length: count } = billboardOrigin;
    // //if (count === 0) return <p></p>;
    // if (count === 0)
    //   return <p>There are currently no Billboard Origins in the database.</p>;

    // let string = this.props.match.params.id;
    // let substring = "new";

    return (
      <div
        className="container"
        // style={{ marginTop: 80, marginBottom: 40, direction: "rtl" }}
        style={{ marginTop: "160px", marginBottom: "100px" }}
      >
        <h1>Article Form</h1>
        <br />
        <form onSubmit={this.handleSubmit}>
          <br />
          {this.renderInput("N_Order_No", "Order Number", "number")}
          <br />
          {this.renderInput("A_Reference", "Reference")}

          <br />

          {this.renderInput("A_Summary", "Summary")}
          {this.renderTextArea("A_Description", "Description")}
          <br />

          {this.renderImageFile("I_Image", "Upload Image")}
          {this.state.waitingForFileUpload && (
            <div>
              <span>Uploading file...</span>
            </div>
          )}

          <button
            type="button"
            className="btn btn-warning"
            onClick={this.clearImages}
          >
            Clear Image
          </button>
          <br />
          <img style={{ maxWidth: "300px" }} src={this.state.data.I_Image} />
          {/* <img
            style={{ maxWidth: "300px" }}
            src={`data:image/jpeg;base64,${this.state.data.I_Photo01}`}
          /> */}
          <br />
          <br />
          <div>
            <br />
          </div>
          {this.renderButton("Save")}
          {this.renderButtonCancel("Cancel")}
          <br />
        </form>
      </div>
    );
  }
}

//
// Babak's Notes: goto tutorial directory '11-Integration with React' file '5- Connecting Components Using react-redux'
const mapStateToProps = state => ({
  //   sumOfArticles: state.entities.sumOfArticles.list.filter(sumOfArticle => !sumOfArticle.resolved)
  article: state.entities.articles.listSingleRow
});

const mapDispatchToProps = dispatch => ({
  loadArticle: id => dispatch(loadArticle(id)),
  addArticle: (Article, user, currentDate) =>
    dispatch(addArticle(Article, user, currentDate)),
  changeArticle: (Article, user, currentDate) =>
    dispatch(changeArticle(Article, user, currentDate))
});

// Container Component
//   Presentation (Groups)
//
// Babak's Notes: Below I populate 'this.props.sumOfArticles' and 'this.props.loadGroups()':-
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticleForm);
