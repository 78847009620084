import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import { loadUser, addUser, changeUser } from "../../store/users";
import { loadRoles } from "../../store/roles";
import { connect } from "react-redux";

class UserForm extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        //
        // Babak's Note: I can set default values here as like below line:-
        // A_Email: "dingdong",
        // Also we are in a nested state
        A_Email: "",
        A_RolesID: "",
        A_Password: "",
        A_Name: "",
        N_Mobile: "",
        passwordConfirm: "",
        B_Active: true,
        I_Photo01: "",
        waitingForFileUpload: false,
        isPasswordVisible: true
      },
      roles: [],
      errors: {},
      type: "input",
      score: "null"
    };
    this.showHide = this.showHide.bind(this);
    this.passwordStrength = this.passwordStrength.bind(this);
  }

  schemaCreate = {
    ID: Joi.string(),
    A_RolesID: Joi.string()
      .required()
      .label("Role"),
    A_Email: Joi.string()
      .required()
      .email()
      .label("Email"),
    A_Password: Joi.string()
      .required()
      .min(4)
      .label("Password"),
    passwordConfirm: Joi.string()
      .required()
      .min(4)
      .label("Password Confirm"),
    A_Name: Joi.string()
      .required()
      .label("Name"),
    N_Mobile: Joi.number()
      .required()
      .label("Mobile")
      .min(11),
    B_Active: Joi.boolean().label("Active"),
    I_Photo01: Joi.any()
      .meta({ swaggerType: "file" })
      .optional(),
    N_PhotoSize01: Joi.number()
      .min(5000)
      .max(10000000)
      .optional(),
    waitingForFileUpload: Joi.boolean().optional(),
    isPasswordVisible: Joi.boolean().optional()
  };

  schemaEdit = {
    ID: Joi.string(),
    A_RolesID: Joi.string()
      .required()
      .label("Role"),
    A_Email: Joi.string()
      .required()
      .email()
      .label("Email"),
    A_Password: Joi.string()
      .allow("")
      .optional()
      .min(4)
      .label("Password"),
    passwordConfirm: Joi.string()
      .min(4)
      .label("Password Confirm"),
    A_Name: Joi.string()
      .required()
      .label("Name"),
    N_Mobile: Joi.number()
      .required()
      .label("Mobile")
      .min(11),
    B_Active: Joi.boolean().label("Active"),
    I_Photo01: Joi.any()
      .meta({ swaggerType: "file" })
      .optional(),
    N_PhotoSize01: Joi.number()
      .min(5000)
      .max(10000000)
      .optional()
    // .required()
  };

  // async populateRoles() {
  //   const { data: roles } = await getRoles();
  //   this.setState({ roles });
  // }

  // async populateUsers() {
  //   //console.log("populateUsers-1");
  //   try {
  //     const userId = this.props.match.params.id;
  //     if (userId === "new") return;

  //     const { data: user } = await getUser(userId);
  //     this.setState({ data: this.mapToViewModel(user) });
  //     //console.log("populateUsers-2", this.mapToViewModel(user));
  //     //this.setState({ data: user });
  //     //console.log("populateUsers-2", this.state.data);
  //   } catch (ex) {
  //     if (ex.response && ex.response.status === 404)
  //       this.props.history.replace("/not-found");
  //   }
  // }

  // async componentDidMount() {
  //   this.setState({ hidden: true });
  //   //console.log("SubmicomponentDidMounttted");
  //   await this.populateRoles();
  //   await this.populateUsers();
  // }

  componentDidMount() {
    const userId = this.props.match.params.id;
    console.log("UserForm, componentDidMount : ", userId);

    if (userId === "new") {
      this.props.loadRoles();
      return;
    }

    //   const { data: user } = await getuser(userId);
    //   this.setState({ data: this.mapToViewModel(user) });
    this.props.loadUser(userId);
    this.props.loadRoles();

    //
    // Babak's Notes: 'componentDidMount' will not update 'this.props', we have to wait until we reach 'componentWillReceiveProps' or 'getDerivedStateFromProps'.
    //                'componentWillReceiveProps' should be replace by renwer version 'getDerivedStateFromProps'.
  }

  async componentWillReceiveProps(nextProps) {
    console.log("UserForm, componentWillReceiveProps", nextProps.userSuccess);
    //
    // Babak's Note: 'componentWillReceiveProps' is ran after Redux store is updated, therefore 'this.props' is updated which passes values via param 'nextProps':-
    //               'componentWillReceiveProps' alos runs after 'componentDidMount'. 'componentDidMount' will not update 'this.props', we have to wait until
    //               we reach 'componentWillReceiveProps'.
    // window.alert(`No Userrrr found : ${nextProps.userError}`);
    if (nextProps.userError) {
      // this.setState({ errors: nextProps.userError });
      const errors = { ...this.state.data };
      // console.log(
      //   "componentWillReceiveProps 2 : ",
      //   nextProps.A_Email,
      //   errors,
      //   this.state.errors.username
      // );
      errors.A_Email = nextProps.userError;
      // console.log(
      //   "componentWillReceiveProps 3 : ",
      //   nextProps.userError,
      //   errors,
      //   this.state.errors.username
      // );
      this.setState({ errors });
    } else if (nextProps.userSuccess) {
      this.props.history.goBack();
    } else if (
      this.props.match.params.id !== "new" &&
      this.state.data !== nextProps.user
    ) {
      // console.log("UserForm, componentWillReceiveProps", nextProps.user);
      this.setState({
        data: await this.mapToViewModel(nextProps.user),
        roles: await nextProps.roles
      });
    } else if (this.state.roles !== nextProps.roles) {
      this.setState({ roles: await nextProps.roles });
    }
  }

  // static getDerivedStateFromProps(props, state) {
  //   //
  //   // Babak's Notes: DO NOT USE 'async' on 'getDerivedStateFromProps'.
  //   if (props.user !== state.data) {
  //     // window.alert("Hello : ");
  //     console.log("UserForm, getDerivedStateFromProps", props.user, state.data);
  //     return {
  //       data: {
  //         ID: props.user.ID,
  //         A_Name: props.user.A_Name,
  //         A_Description: props.user.A_Description,
  //         A_Discriminator: props.user.A_Discriminator
  //       }
  //     };
  //   }
  //   // Return null to indicate no change to state.
  //   return null;
  // }

  mapToViewModel(user) {
    console.log("mapToViewModel-1", user);
    return {
      ID: user.ID,
      A_RolesID: user.A_RolesID,
      A_Email: user.A_Email,
      A_Password: "",
      A_Name: user.A_Name,
      N_Mobile: user.N_Mobile,
      B_Active: user.B_Active,
      I_Photo01: user.I_Photo01
    };
  }

  doSubmit = async () => {
    // await saveUser(this.state.data);
    // this.props.history.push("/users");

    // console.log("UserForm, doSubmit : ", this.state.data);

    try {
      const userId = this.props.match.params.id;
      const { A_Password, passwordConfirm } = this.state.data;

      if (userId === "new") {
        if (A_Password !== passwordConfirm) {
          //alert("Passwords don't match");
          const errors = { ...this.state.errors };
          errors.passwordConfirm = "Passwords don't match";
          this.setState({ errors });
        } else {
          //const response = await saveUser(this.state.data);
          await this.props.addUser(this.state.data);
        }
      } else {
        //const response = await saveUser(this.state.data);
        await this.props.changeUser(this.state.data);
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.A_Email = ex.response.data;
        this.setState({ errors });
      }
    }

    // this.props.history.push("/usersList");
    //
    // Babak's Note: Use below rather than above:-
    // this.props.history.goBack();
  };

  toggleShow = () => {
    this.setState({ isPasswordVisible: !this.state.hidden });
    console.log("toggleShow wwwwwwwwwwwwwwwwwwwwwwww: ", this.state.hidden);
  };

  clearImages = () => {
    let obj = this.state.data;
    obj["I_Photo01"] = "";
    this.setState({ data: obj });
  };

  render() {
    // // Babak's Note: *** Below 3 lines will stop rendering of child component 'TableBodyAppoints' section
    // //               'console.log("Hello 04 : ");' first *** :-
    // const { length: count } = this.state.roles;
    // //if (count === 0) return <p></p>;
    // if (count === 0) return <div className="hourGlass"></div>;

    const userId = this.props.match.params.id;
    console.log("UserForm render", this.props.user, this.state.data);
    return (
      <div
        className="container pageTopMargin"
        style={{ marginTop: "160px", marginBottom: "100px" }}
      >
        <h1>User Form</h1>
        <form onSubmit={this.handleSubmit}>
          {userId === "new"
            ? this.renderInputCreateEmail("A_Email", "Email", "email")
            : this.renderInputEditEmail("A_Email", "Email", "email")}
          <div className="password">
            {this.renderPasswordInput("A_Password", "Password", "password")}
            <span className="password__show" onClick={this.showHide}>
              {this.state.type === "input" ? "Hide" : "Show"}
            </span>
            <span
              className="password__strength"
              data-score={this.state.score}
            />
          </div>
          {/* <div className="input-append">
            {this.renderPasswordInput("A_Password", "Password", "password")}
            <div
              className="buttonPassword"
              onClick={() =>
                this.setState({
                  isPasswordVisible: !this.state.isPasswordVisible
                })
              }
            >
              {this.state.isPasswordVisible ? "Hide" : "Show"} Password
            </div>
          </div> */}
          {userId === "new"
            ? this.renderInputCreatePasswordConfirm(
                "passwordConfirm",
                "Password Confirm",
                "password"
              )
            : ""}
          {this.renderInput("A_Name", "Name")}
          {this.renderSelect("A_RolesID", "Role", this.state.roles)}
          {this.renderInputCreateMobile("N_Mobile", "Mobile")}
          {this.renderCheckbox("B_Active", "Active")}
          {this.renderImageFile("I_Photo01", "Upload Image")}
          {this.state.waitingForFileUpload && (
            <div>
              <span>Uploading image file...</span>
            </div>
          )}
          <img
            style={{ maxWidth: "300px", marginBottom: "10px" }}
            src={this.state.data.I_Photo01}
          />
          {/* <img
            style={{ maxWidth: "300px" }}
            src={`data:image/jpeg;base64,${this.state.data.I_Photo01}`}
          /> */}
          <br />
          <div className="btn btn-primary" onClick={this.clearImages}>
            Clear Image
          </div>
          <div>
            <br />
          </div>
          <div className="pageBottomMargin">{this.renderButton("Submit")}</div>
        </form>
      </div>
    );
  }
}

//
// Babak's Notes: goto tutorial directory '11-Integration with React' file '5- Connecting Components Using react-redux'
const mapStateToProps = state => ({
  //   users: state.entities.users.list.filter(user => !user.resolved)
  user: state.entities.users.listSingleRow,
  roles: state.entities.roles.list,
  userError: state.entities.users.userError,
  userSuccess: state.entities.users.userSuccess
});

const mapDispatchToProps = dispatch => ({
  loadUser: id => dispatch(loadUser(id)),
  loadRoles: () => dispatch(loadRoles()),
  addUser: users => dispatch(addUser(users)),
  changeUser: user => dispatch(changeUser(user))
});

// Container Component
//   Presentation (Users)
//
// Babak's Notes: Below I populate 'this.props.users' and 'this.props.loadUsers()':-
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserForm);
