import React, { Component } from "react";
import auth from "../../services/authService";
//
// Babak's Note: Removed below line and replaced it by above, so 'auth' is an object. We access 'logout' by
//               entering 'auth.logout();' rather than just 'logout();' in 'componentDidMount()'. This is an
//               more object oriented way. In the 'authService.js' we must add :-
//               export default {
//                login,
//                logout
//             };
// import { auth } from "../services/authService";

class Logout extends Component {
  componentDidMount() {
    auth.logout();

    window.location = "/";
  }

  render() {
    return null;
  }
}

export default Logout;
