import React, { Component, Suspense } from "react";
// import {
//   getBillboardLarge,
//   getBillboardLargesGroup
// } from "../../services/billboardLargeService";
import { loadSumOfArticle } from "../../store/sumOfArticles";
import { loadArticles } from "../../store/articles";
import { connect } from "react-redux";
import LazyLoad from "react-lazy-load";
import Radium, { StyleRoot } from "radium";
import NavBarFooter from "../navBarFooter";
import ScrollUpButton from "react-scroll-up-button";
import { Link, animateScroll as scroll } from "react-scroll";

class DetailsGroup extends Component {
  state = {
    data: {
      A_Reference: "",
      A_Summary: "",
      I_Image: ""
    },
    billboardLargesGroup: []
  };

  async populateSumOfArticlesArticles() {
    const sumOfArticleId = this.props.match.params.id;

    // window.alert(`componentDidMount (DetailsGroup) : ${menuId}`);

    await this.props.loadSumOfArticle(sumOfArticleId);

    await this.props.loadArticles(sumOfArticleId);
  }

  async componentDidMount() {
    await this.populateSumOfArticlesArticles();
  }

  async componentWillReceiveProps(nextProps) {
    // window.alert("MenuForm, componentWillReceiveProps", nextProps.menuSuccess);

    let data = { ...this.state.data };

    // if (this.state.data !== nextProps.menu) {

    data["A_Reference"] = nextProps.sumOfArticle.A_Reference;
    data["A_Summary"] = nextProps.sumOfArticle.A_Summary;
    data["I_Image"] = nextProps.sumOfArticle.I_Image;

    this.setState({
      data
    });
    // }
  }

  //   async mapToViewModel(billboardLarge) {
  //     //console.log("mapToViewModel-1", billboardLarge);
  //     return {
  //       A_Reference: billboardLarge.A_Reference,
  //       A_Summary: billboardLarge.A_Summary,
  //       I_Image: billboardLarge.I_Image
  //     };
  //   }

  renderBillboardData = ({ item, row }) => {
    // console.log("renderBillboardData 01 : ", item, rowCount);

    let sectionStyle;

    // document.body.style.backgroundImage = `url(${item.I_Image})`;
    // document.body.style.backgroundRepeat = "no-repeat";
    // document.body.style.backgroundSize = "cover";

    if (row === 0) {
      sectionStyle = {
        height: "100vh",
        backgroundImage: `url(${item.I_Image})`,
        backgroundPosition: "center center",
        backgroundAttachment: "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
    } else {
      sectionStyle = {
        height: "500px",
        backgroundImage: `url(${item.I_Image})`,
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
    }

    let sectionStyleSmallerLg;
    sectionStyleSmallerLg = {
      height: "50vh",
      backgroundImage: `url(${item.I_Image})`,
      backgroundPosition: "center center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover"
    };
    // } else if (item.N_RadioGrpDirection === "5") {
    //   sectionStyle = {
    //     backgroundImage: `url(${item.I_Image})`,
    //     backgroundPosition: "center center",
    //     backgroundAttachment: "fixed",
    //     backgroundRepeat: "no-repeat",
    //     backgroundSize: "cover"
    //   };
    // } else {
    //   sectionStyle = {
    //     // height: "100vh",
    //     // backgroundAttachment: "fixed",
    //     // backgroundSize: "cover",
    //     // width: width,
    //     //opacity: 0.5,
    //     backgroundColor: "white",
    //     backgroundRepeat: "no-repeat",
    //     backgroundSize: "cover"
    //   };
    // }

    // https://www.npmjs.com/package/react-animations
    // const styles = {
    //   bounce: {
    //     animation: "y 3s",
    //     animationName: Radium.keyframes(bounce, "bounce")
    //   },
    //   fadeIn: {
    //     animation: "y 8s",
    //     animationName: Radium.keyframes(fadeIn, "fadeIn")
    //   },
    //   fadeInUp00: {
    //     animation: "y 1s",
    //     animationName: Radium.keyframes(fadeInUp, "fadeInUp01")
    //   },
    //   fadeInUp01: {
    //     animation: "y 3s",
    //     animationName: Radium.keyframes(fadeInUp, "fadeInUp01")
    //   },
    //   fadeInUp02: {
    //     animation: "y 5s",
    //     animationName: Radium.keyframes(fadeInUp, "fadeInUp02")
    //   },
    //   fadeInUp03: {
    //     animation: "y 8s",
    //     animationName: Radium.keyframes(fadeInUp, "fadeInUp03")
    //   },
    //   flipInX: {
    //     animation: "y 3s",
    //     animationName: Radium.keyframes(flipInX, "flipInX")
    //   },
    //   flipInY: {
    //     animation: "y 3s",
    //     animationName: Radium.keyframes(flipInY, "flipInY")
    //   },
    //   rubberBand: {
    //     animation: "y 3s",
    //     animationName: Radium.keyframes(rubberBand, "rubberBand")
    //   },
    //   shake: {
    //     animation: "y 3s",
    //     animationName: Radium.keyframes(shake, "shake")
    //   },
    //   rotateInUpRight: {
    //     animation: "y 3s",
    //     animationName: Radium.keyframes(rotateInUpRight, "rotateInUpRight")
    //   },
    //   rotateInUpLeft: {
    //     animation: "y 3s",
    //     animationName: Radium.keyframes(rotateInUpLeft, "rotateInUpLeft")
    //   },
    //   slideInUp01: {
    //     animation: "y 3s",
    //     animationName: Radium.keyframes(slideInUp, fadeIn, "slideInUp01")
    //   },
    //   slideInUp02: {
    //     animation: "y 5s",
    //     animationName: Radium.keyframes(slideInUp, "slideInUp03")
    //   },
    //   slideInUp03: {
    //     animation: "y 8s",
    //     animationName: Radium.keyframes(slideInUp, "slideInUp03")
    //   }
    // };

    // Babak's Notes: Commented out on 20200115: START
    return (
      // <div style={sectionStyle} className="section-1 box"></div>
      <div className="homeOverflowHidden">
        {row === 0 ? (
          <div>
            <StyleRoot
              id={item.N_Order_No}
              style={sectionStyle}
              className="d-none d-lg-block Hero boxShadowOnly"
            >
              <div style={{ height: "100vh" }}>
                <div
                  className="Hero-background Lazy is-lazyLoaded"
                  data-component="lazy"
                ></div>
              </div>

              <div className="Hero-content PageSidePadding mediumPlus-PageSidePadding">
                <div className="Hero-contentInner">
                  <h1 className="Hero-subTitle textTitleShadowGray">
                    {item.A_Reference}
                  </h1>

                  <h2 className="Hero-subHeading textTitleShadowGray">
                    {item.A_Summary}
                  </h2>

                  <h2 className="Hero-subHeading textTitleShadowGray">
                    {item.A_Description}
                  </h2>
                </div>
              </div>
            </StyleRoot>

            <StyleRoot
              id={item.N_Order_No}
              style={sectionStyleSmallerLg}
              className="d-none d-md-block d-lg-none Hero"
            >
              <div className="Hero-content-smallerLG PageSidePadding mediumPlus-PageSidePadding">
                <div className="Hero-contentInner">
                  <h1 className="Hero-subTitle textTitleShadowGray">
                    {item.A_Reference}
                  </h1>

                  <h2 className="Hero-subHeading textTitleShadowGray">
                    {item.A_Summary}
                  </h2>

                  <h2 className="Hero-subHeading textTitleShadowGray">
                    {item.A_Description}
                  </h2>
                </div>
              </div>
            </StyleRoot>
          </div>
        ) : (
          <div className="homeOverflowHidden">
            <StyleRoot
              id={item.N_Order_No}
              className="d-none d-lg-block container"
            >
              <div style={{ marginBottom: "100px" }}>
                <div
                  style={{
                    textAlign: "center",
                    fontWeight: "600",
                    fontSize: "25px",
                    marginTop: 60,
                    marginLeft: 20,
                    marginRight: 20
                  }}
                >
                  {item.A_Reference}
                </div>
                <div
                  style={{
                    textAlign: "center",
                    fontWeight: "400",
                    fontSize: "23px",
                    marginLeft: 20,
                    marginRight: 20,
                    marginBottom: 20
                  }}
                >
                  {item.A_Summary}
                </div>

                <div
                  style={{
                    textAlign: "center",
                    fontWeight: "400",
                    fontSize: "20px",
                    marginLeft: 20,
                    marginRight: 20,
                    marginBottom: 20
                  }}
                >
                  {item.A_Description}
                </div>

                <div>
                  <img
                    style={{ height: "500px" }}
                    src={item.I_Image}
                    className="imageTitle"
                  ></img>
                </div>
              </div>
            </StyleRoot>
            <StyleRoot
              id={item.N_Order_No}
              className="d-none d-md-block d-lg-none container"
            >
              <div style={{ marginBottom: "100px" }}>
                <div
                  style={{
                    textAlign: "center",
                    fontWeight: "600",
                    fontSize: "25px",
                    marginTop: 60,
                    marginLeft: 20,
                    marginRight: 20
                  }}
                >
                  {item.A_Reference}
                </div>
                <div
                  style={{
                    textAlign: "center",
                    fontWeight: "400",
                    fontSize: "23px",
                    marginLeft: 20,
                    marginRight: 20,
                    marginBottom: 20
                  }}
                >
                  {item.A_Summary}
                </div>

                <div
                  style={{
                    textAlign: "center",
                    fontWeight: "400",
                    fontSize: "20px",
                    marginLeft: 20,
                    marginRight: 20,
                    marginBottom: 20
                  }}
                >
                  {item.A_Description}
                </div>

                <div>
                  <img
                    style={{ height: "400px" }}
                    src={item.I_Image}
                    className="imageTitle"
                  ></img>
                </div>
              </div>
            </StyleRoot>
          </div>
        )}

        {row === 0 ? (
          <StyleRoot id={item.N_Order_No} className="d-sm-block d-md-none">
            <div style={{ marginBottom: "100px" }}>
              <div>
                <img
                  style={{ height: "250px" }}
                  src={item.I_Image}
                  className="imageTitle"
                ></img>
              </div>
              <div
                style={{
                  textAlign: "center",
                  fontWeight: "600",
                  fontSize: "25px",
                  marginTop: 0,
                  marginLeft: 20,
                  marginRight: 20
                }}
              >
                {item.A_Reference}
              </div>
              <div
                style={{
                  textAlign: "center",
                  fontWeight: "400",
                  fontSize: "23px",
                  marginLeft: 20,
                  marginRight: 20,
                  marginBottom: 20
                }}
              >
                {item.A_Summary}
              </div>

              <div
                style={{
                  textAlign: "center",
                  fontWeight: "400",
                  fontSize: "20px",
                  marginLeft: 20,
                  marginRight: 20,
                  marginBottom: 20
                }}
              >
                {item.A_Description}
              </div>
            </div>
          </StyleRoot>
        ) : (
          <div className="homeOverflowHidden">
            <StyleRoot
              id={item.N_Order_No}
              className="d-sm-block d-md-none container"
            >
              <div style={{ marginBottom: "100px" }}>
                <div
                  style={{
                    textAlign: "center",
                    fontWeight: "600",
                    fontSize: "25px",
                    marginTop: 0,
                    marginLeft: 20,
                    marginRight: 20
                  }}
                >
                  {item.A_Reference}
                </div>
                <div
                  style={{
                    textAlign: "center",
                    fontWeight: "400",
                    fontSize: "23px",
                    marginLeft: 20,
                    marginRight: 20,
                    marginBottom: 20
                  }}
                >
                  {item.A_Summary}
                </div>

                <div
                  style={{
                    textAlign: "center",
                    fontWeight: "400",
                    fontSize: "20px",
                    marginLeft: 20,
                    marginRight: 20,
                    marginBottom: 20
                  }}
                >
                  {item.A_Description}
                </div>

                <div>
                  <img
                    style={{ height: "200px" }}
                    src={item.I_Image}
                    className="imageTitle homeOverflowHidden"
                  ></img>
                </div>
              </div>
            </StyleRoot>
          </div>
        )}
      </div>
    );
  };

  scrollToTop = () => {
    // scroll.scrollToTop();
    // scroll.scrollToBottom();
    scroll.scrollTo(3);
  };

  render() {
    const { data } = this.state;
    // const { billboardLargesGroup } = this.props.groups;

    // let sectionStyle;

    // sectionStyle = {
    //   // height: "100vh",
    //   // backgroundAttachment: "fixed",
    //   // backgroundSize: "cover",
    //   // width: width,
    //   //opacity: 0.5,
    //   backgroundImage: `url(${data.I_Image})`,
    //   backgroundRepeat: "no-repeat",
    //   backgroundSize: "cover"
    // };

    // console.log(
    //   "DetailsGroup render, Number of billboardLargesGroup: ",
    //   this.props.match.params.id,
    //   data,
    //   this.props.groups,
    //   this.props.groups.length
    // );

    if (this.props.articles.length === 0)
      return <div className="hourGlass"></div>;

    //
    // Babak's Note: Goto at top of screen:- https://www.youtube.com/watch?v=7f0QXAgFtrE
    //                                       https://www.digitalocean.com/community/tutorials/how-to-implement-smooth-scrolling-in-react
    //                                       https://stackoverflow.com/questions/58771756/react-scroll-to-element-when-element-exists
    // this.scrollToTop();
    window.scrollTo(0, 0);
    // window.scroll({
    //   top: document.body.offsetHeight,
    //   left: 0,
    //   behavior: "smooth"
    // });

    return (
      <div>
        {/* <Suspense fallback={<div className="hourGlass"></div>}> */}
        <this.renderBillboardData item={data} row={0} />

        {this.props.articles.map((item, index) => (
          <this.renderBillboardData item={item} row={index + 1} />
        ))}
        <div>
          <ScrollUpButton style={{ marginBottom: "80px", display: "flex" }} />
        </div>
        <div>
          <NavBarFooter
            // nameOfClass="footer"
            customizationSingleRow={this.props.customizationSingleRow}
            menuOnNavvar={this.props.menuOnNavvar}
            onClickSearch={this.onClickSearchHandle}
          />
        </div>
        {/* </Suspense> */}
        {/* <div>
          <ScrollUpButton />
        </div> */}
      </div>
    );

    // return (
    //   <StyleRoot
    //     style={sectionStyle}
    //     // content="width=device-width, initial-scale=1"
    //   >
    //     <div className="container" style={{ paddingBottom: "80px" }}>
    //       <div
    //         style={{
    //           textAlign: "center",
    //           fontWeight: "bold",
    //           marginTop: 20
    //         }}
    //       >
    //         {data.A_Reference}
    //       </div>
    //       <div style={{ textAlign: "center", marginBottom: 20 }}>
    //         {data.A_Summary}
    //       </div>
    //       <div className="row" style={{ padding: 10, direction: "rtl" }}>
    //         {this.props.groups.map(item => (
    //           <div key={item.ID} className="column" style={{ paddingTop: 50 }}>
    //             <div>
    //               <LazyLoad>
    //                 <img
    //                   onClick={() => {
    //                     // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
    //                     //               rows where N_HomeLocationGroup_ID === 1:-
    //                     if (item.N_BillboardOrigin_ID === 2) {
    //                       window.location = `../detailsMaster/${item.ID}`;
    //                     } else if (item.N_BillboardOrigin_ID === 3) {
    //                       window.location = `../detailsDetail/${item.ID}`;
    //                     }
    //                   }}
    //                   src={item.I_Image}
    //                   className="imageLink"
    //                 ></img>
    //               </LazyLoad>
    //             </div>
    //             <div
    //               style={{ textAlign: "right" }}
    //               className="titleLink"
    //               onClick={() => {
    //                 // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
    //                 //               rows where N_HomeLocationGroup_ID === 1:-
    //                 if (item.N_BillboardOrigin_ID === 2) {
    //                   window.location = `../detailsMaster/${item.ID}`;
    //                 } else if (item.N_BillboardOrigin_ID === 3) {
    //                   window.location = `../detailsDetail/${item.ID}`;
    //                 }
    //               }}
    //             >
    //               {item.A_Reference}
    //             </div>
    //             <div style={{ textAlign: "right" }}>{item.A_Summary}</div>
    //             <div
    //               style={{ textAlign: "right" }}
    //               className="redLink"
    //               onClick={() => {
    //                 // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
    //                 //               rows where N_HomeLocationGroup_ID === 1:-
    //                 if (item.N_BillboardOrigin_ID === 2) {
    //                   window.location = `../detailsMaster/${item.ID}`;
    //                 } else if (item.N_BillboardOrigin_ID === 3) {
    //                   window.location = `../detailsDetail/${item.ID}`;
    //                 }
    //               }}
    //             >
    //               بیشتر...
    //             </div>
    //           </div>
    //         ))}
    //       </div>
    //     </div>
    //     <div>
    //       <ScrollUpButton />
    //     </div>
    //   </StyleRoot>
    // );
  }
}

//
// Babak's Notes: goto tutorial directory '11-Integration with React' file '5- Connecting Components Using react-redux'
const mapStateToProps = state => ({
  //   groups: state.entities.groups.list.filter(group => !group.resolved)
  sumOfArticle: state.entities.sumOfArticles.listSingleRow,
  articles: state.entities.articles.list
});

const mapDispatchToProps = dispatch => ({
  loadSumOfArticle: id => dispatch(loadSumOfArticle(id)),
  loadArticles: id => dispatch(loadArticles(id))
});

// Container Component
//   Presentation (Groups)
//
// Babak's Notes: Below I populate 'this.props.groups' and 'this.props.loadGroups()':-
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailsGroup);
