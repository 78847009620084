import React, { Component } from "react";
// import StoreContext from "../contexts/storeContext";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
// import { Redirect } from "react-router";
import Pagination from "../common/pagination";
// import {
//   loadCustomizations,
//   getCustomizations,
//   deleteCustomization
// } from "../../store/customizationMaintains";
import {
  getCustomizations,
  deleteCustomization
} from "../../services/customizationService";
import CustomizationsTable from "./customizationsTable";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import SearchBox from "../searchBox";
// import { connect } from "react-redux";

class CustomizationsList extends Component {
  // static contextType = StoreContext;

  state = {
    // customizationsList: [],
    customizations: [],
    currentPage: 1,
    pageSize: 10,
    searchQuery: "",
    sortColumn: { path: "A_WebAppTitle", order: "asc" }
  };

  async componentDidMount() {
    document.body.style.backgroundImage = `url()`;
    document.body.style.backgroundColor = "white";
    document.body.style.backgroundPosition = "center center";
    document.body.style.backgroundAttachment = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundSize = "cover";

    // this.props.loadCustomizations();
    const { data: customizations } = await getCustomizations();
    // console.log("CustomizationsList, componentDidMount : ");
    this.setState({ customizations });
  }

  // componentWillUnmount() {
  //   this.unsubscribe();
  // }

  handleUpdate = async customization => {
    // //console.log("CustomizationsList, handleUpdate : ", customization.ID);

    // //
    // // Babak's Notes: 'Redirect' does not work for some reason (back to prev screen browser arrow key), no time to investifate for now:-
    // // this.setState({ CustomizationForm: true, customizationID: customization.ID });

    // try {
    //   window.location = `/customizationsList/${customization.ID}`;
    // } catch (ex) {
    //   if (ex.response && ex.response.status === 400) {
    //     const errors = { ...ex.errors };
    //     this.setState({ errors });
    //   }
    // }

    const originalCustomizations = this.state.customizations;
    const customizations = originalCustomizations.filter(
      m => m.ID !== customization.ID
    );
    this.setState({ customizations });

    try {
      await deleteCustomization(customization.ID);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error("This Customization has already been deleted.");

      this.setState({ customizations: originalCustomizations });
    }
  };

  handleUpdate = async customization => {
    try {
      window.location = `/customizationsList/${customization.ID}`;
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...ex.errors };
        this.setState({ errors });
      }
    }
  };

  handlePageChange = page => {
    //console.log(page);
    this.setState({ currentPage: page });
  };

  //
  // Babak's Note: We have put this 'input' field inside of the 'SearchBox' component, this component raises
  //               an event and in the we get an string. So below 'query' will contain the string the user
  //               types in the search field.
  handleSearch = query => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  handleSort = sortColumn => {
    //console.log("handleSort : ", sortColumn);
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      customizations: allCustomizations
    } = this.state;

    // console.log(
    //   "CustomizationsList, getPageData : ",
    //   this.props.customizations
    // );

    // const allCustomizations = this.props.customizations;

    let filtered = allCustomizations;
    if (searchQuery)
      filtered = allCustomizations.filter(m =>
        m.A_Name.toLowerCase().includes(searchQuery.toLowerCase())
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const customizations = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: customizations };
  };

  render() {
    // console.log("CustomizationsList, render() : ", this.props.customizations);

    // if (this.state.CustomizationForm === true) {
    //   //console.log("CustomizationsList, render(), Redirect : ", this.state.customizationID);
    //   return <Redirect to={`/customizationsList/${this.state.customizationID}`} />;
    // }

    // const { pageSize, currentPage, sortColumn, searchQuery } = this.state;

    // //
    // //Babak's Note: Below I renamed 'data' to 'customizations'
    // const { totalCount, data: customizationsList } = this.getPagedData();

    const { length: count } = this.state.customizations;

    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;

    //
    //Babak's Note: Below I renamed 'data' to 'users'
    const { totalCount, data: customizations } = this.getPagedData();

    return (
      <div className="container">
        <div className="row">
          <div
            className="col"
            style={{ marginTop: "160px", marginBottom: "100px" }}
          >
            <h1>Customizations</h1>
            <br />
            {totalCount < 1 ? (
              <Link
                to="/customizationsList/new"
                className="btn btn-primary"
                style={{ marginBottom: 20 }}
              >
                New Customization
              </Link>
            ) : (
              <button
                title="Maximum number of Customization reached!"
                disabled
                className="btn btn-danger disabled clickableNon"
                style={{ marginBottom: 20 }}
              >
                Maximum number of Customization reached!
              </button>
            )}
            {/* <Link
              to="/customizationsList/new"
              className="btn btn-primary"
              style={{ marginBottom: 20 }}
            >
              New Customization
            </Link> */}
            <p>Showing {totalCount} customizations in the database.</p>
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
            <CustomizationsTable
              customizationsList={customizations}
              sortColumn={sortColumn}
              // onDelete={customization =>
              //   this.props.deleteCustomization(customization)
              // }
              onDelete={this.handleDelete}
              onUpdate={this.handleUpdate}
              onSort={this.handleSort}
            />
            <div className="paginationBar">
              <Pagination
                itemsCount={totalCount}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={this.handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// //
// // Babak's Notes: goto tutorial directory '11-Integration with React' file '5- Connecting Components Using react-redux'
// const mapStateToProps = state => ({
//   //   bugs: state.entities.customizations.list.filter(bug => !bug.resolved)
//   customizations: state.entities.customizationMaintains.list
// });

// const mapDispatchToProps = dispatch => ({
//   loadCustomizations: () => dispatch(loadCustomizations()),
//   deleteCustomization: id => dispatch(deleteCustomization(id))
// });

// // Container Component
// //   Presentation (Bugs)
// //
// // Babak's Notes: Below I populate 'this.props.bugs' and 'this.props.loadBugs()':-
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(CustomizationsList);

export default CustomizationsList;
