import React, { Component, useState } from "react";
// import Flash from "react-reveal/Flash";
// import HeadShake from "react-reveal/HeadShake";
// import Jello from "react-reveal/Jello";
// import Jump from "react-reveal/Jump";
// import RubberBand from "react-reveal/RubberBand";
// import Swing from "react-reveal/Swing";
// import Tada from "react-reveal/Tada";
// import Wobble from "react-reveal/Wobble";
import Fade from "react-reveal/Fade";
// import _ from "lodash";
import auth from "../services/authService";
import { NavLink, withRouter, Route } from "react-router-dom";
// import { getBillboardLargesSearchAutoComplete } from "../services/billboardLargeService";
import { Redirect } from "react-router";
import { Link, animateScroll as scroll } from "react-scroll";
import {
  Form,
  FormControl,
  Nav,
  Navbar,
  NavDropdown,
  Button
} from "react-bootstrap";

//
// Babak's Note: Below 'user' is from 'props', we have used destructering so by adding only 'user' reactjs will
//               automnatically take it from 'props':-
class NavBar3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      navExpanded: false,
      suggestions: [],
      text: "",
      dynamicSearchIcon: "",
      reRun: true
    };
  }

  intervalID;

  // renderCell = (item, column) => {
  //   //
  //   // Babak's Note: Using lodash:-
  //   return _.get(item, column);
  // };

  closeNav = () => {
    // console.log(
    //   "NavBar 1 : ",
    //   this.state.navExpanded,
    //   window.location.pathname
    // );

    // window.location = `/detailsGroup/7`;
    // window.location.reload(false);
    window.reload(false);

    // e.stopPropagation();
    // console.log(e.target.className); // This gets the className of the target

    // this.setState({ navExpanded: false });
  };

  MenuItemNavigate = ({ menu, index, rowLength }) => {
    const [bgColour, setBgColour] = useState(
      "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)"
    );

    let styles;

    if (rowLength === index + 1) {
      if (menu.N_BillboardMenuBG_ID === 1) {
        styles = {
          background: "white",
          color: `${menu.A_MenuCursorColour}`,
          borderLeft: "0.5px solid white",
          borderTop: "0.5px solid white",
          borderBottom: "0.5px solid white",
          borderRight: "0.5px solid white",
          boxShadow: `${bgColour}`,
          paddingTop: "15px",
          paddingBottom: "15px"
        };
      } else {
        styles = {
          background: "white",
          color: `${menu.A_MenuCursorColour}`,
          borderLeft: "0.5px solid white",
          borderTop: "0.5px solid white",
          borderBottom: "0.5px solid white",
          borderRight: "0.5px solid white",
          boxShadow: `${bgColour}`,
          paddingTop: "15px",
          paddingBottom: "15px",
          backgroundImage: `url(${menu.I_Image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover"
        };
      }
    } else if (menu.N_BillboardMenuBG_ID === 1) {
      styles = {
        background: "white",
        color: `${menu.A_MenuCursorColour}`,
        borderLeft: "0.5px solid white",
        borderTop: "0.5px solid white",
        borderBottom: "0.5px solid white",
        boxShadow: `${bgColour}`,
        paddingTop: "15px",
        paddingBottom: "15px"
      };
    } else {
      styles = {
        background: "white",
        color: `${menu.A_MenuCursorColour}`,
        borderLeft: "0.5px solid white",
        borderTop: "0.5px solid white",
        borderBottom: "0.5px solid white",
        boxShadow: `${bgColour}`,
        paddingTop: "15px",
        paddingBottom: "15px",
        backgroundImage: `url(${menu.I_Image})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
    }

    return (
      <NavLink
        // className="nav-item nav-link text-warning"
        className="nav-item nav-link boxShadow"
        style={styles}
        title={menu.A_Reference}
        onMouseEnter={
          () =>
            //setBgColour(`inset 0px -5px 0px ${menu.A_MenuCursorColour}`)
            // setBgColour(`inset 1rem -5rem 0 0 ${menu.A_MenuCursorColour}`)
            setBgColour(`0 0.5rem 0 0 ${menu.A_MenuCursorColour}`)
          // setBgColour(`inset 1rem 0.5rem 0 0 ${menu.A_MenuCursorColour}`)
        }
        onMouseLeave={() => setBgColour("0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)")}
        to={
          menu.N_BillboardOrigin_ID === 1
            ? `/detailsGroup/${menu.ID}`
            : menu.N_BillboardOrigin_ID === 2
            ? `/detailsMaster/${menu.ID}`
            : menu.N_BillboardOrigin_ID === 3
            ? `/detailsDetail/${menu.ID}`
            : ""
        }
        onClick={this.closeNav}
      >
        {menu.N_BillboardMenuBG_ID === 1 ? (
          <div>{menu.A_Reference}</div>
        ) : (
          <div
            style={{
              // color: `${menu.A_MenuCursorColour}`,
              // textShadow: `2px 1px ${menu.A_MenuCursorColour}`
              color: "#ffffff",
              textShadow: "2px 2px #000000"
            }}
          >
            {menu.A_Reference}
          </div>
        )}
      </NavLink>
    );
  };

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const { text } = this.state;

    //console.log(`navBar render() : ${text}`);

    // if (this.state.aboutUs === true) {
    //   return <Redirect to='/infoUs/38' />
    // }
    // if (this.state.contactUs === true) {
    //   return <Redirect to='/infoUs/26' />
    // }

    const { user, menuOnNavvar, onClickSearch } = this.props;

    // const menuOnNavvar = [
    //   { id: 1, description: "Menu One", colour: "red" },
    //   { id: 2, description: "Menu Two", colour: "green" },
    //   { id: 3, description: "Menu Three", colour: "blue" },
    //   { id: 4, description: "Menu Four", colour: "cyan" },
    //   { id: 5, description: "Menu Five", colour: "orange" }
    // ];

    const rowLength = menuOnNavvar.length;

    return (
      // https://stackoverflow.com/questions/32452695/react-bootstrap-how-to-collapse-menu-when-item-is-selected
      //
      // Babak's Note: Look at below line "style={{ marginTop: 20 }}"
      // <Navbar fixed="top" bg="light" expand="lg" style={{ marginTop: 20 }}>
      // Also look at "https://getbootstrap.com/docs/4.0/utilities/display/" for "<div className="d-lg-none">hide on screens wider than lg</div>
      //                                                                          <div className="d-none d-lg-block">hide on screens smaller than lg</div>"
      // <div className="d-none d-lg-block" fixed="top">
      //   <Fade top big>
      <Navbar
        fixed="top"
        // bg="light"
        expand="lg"
        // Babak's Note: for Farsi
        className="navbar"
        onToggle={this.setNavExpanded}
        expanded={this.state.navExpanded}
        // style={{ backgroundColor: bgColors.menuBlue }}
        style={{
          // border: "0.5px solid grey",
          // backgroundColor: "grey",
          paddingTop: "0px",
          paddingBottom: "0px",
          marginTop: "60px"
        }}
      >
        <div className="d-none d-lg-block container" fixed="top">
          <Fade top big>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              {/* <Nav className="mr-auto"> */}
              <Nav className="mx-auto">
                {/* Babak's Note: Add className="ml-auto" to have menu on the left hand side or className="mr-auto" to have menu on the right hand side, try className="md-auto" */}

                <React.Fragment>
                  {menuOnNavvar.map((menu, index) => (
                    <this.MenuItemNavigate
                      key={menu.ID}
                      menu={menu}
                      index={index}
                      rowLength={rowLength}
                    />
                  ))}
                </React.Fragment>
              </Nav>
            </Navbar.Collapse>
          </Fade>
        </div>
      </Navbar>
      //   </Fade>
      // </div>
    );
  }
}

// export default withRouter(NavBar);
export default NavBar3;
