import React from "react";

// Input: liked: boolean
// Output: onClick

const Active = props => {
  let classes = "fa fa-check";
  if (!props.active) classes = "fa fa-times";
  return (
    <i
      //   onClick={props.onToggle}
      // style={{ cursor: "pointer" }}
      //style={{ cursor: "none" }}
      style={{ cursor: "not-allowed" }}
      className={classes}
      aria-hidden="true"
    />
  );
};

// class Like extends Component {
//   render() {
//     let classes = "fa fa-heart";
//     if (!this.props.liked) classes += "-o";
//     return (
//       <i
//         onClick={this.props.onToggle}
//         style={{ cursor: "pointer" }}
//         className={classes}
//         aria-hidden="true"
//       />
//     );
//   }
// }

export default Active;
