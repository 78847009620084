import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import auth from "../../services/authService";
import { loadGroup, addGroup, changeGroup } from "../../store/groups";
import { loadBillboardOrigins } from "../../store/billboardOrigins";
import { loadHomeLocationGroups } from "../../store/homeLocationGroups";
import { connect } from "react-redux";

class GroupForm extends Form {
  state = {
    data: {
      N_BillboardOrigin_ID: "",
      N_HomeLocationGroup_ID: "",
      N_Order_No: "",
      A_Reference: "",
      A_Summary: "",
      // A_Description: "",
      I_Image: "",
      N_BillboardLarge_ID: "",
      N_ParentOrigin_ID: "",
      waitingForFileUpload: false
    },
    errors: {},
    billboardOrigin: [],
    homeLocationGroup: [],
    N_BillboardOrigin_ID: "",
    N_HomeLocationGroup_ID: "",
    N_ParentOrigin_ID: "",
    user: []
  };

  schemaCreate = {
    ID: Joi.number(),
    N_BillboardOrigin_ID: Joi.number()
      .allow("")
      .optional()
      //.required()
      // .min(1)
      // .max(100)
      .label("Billboard Origin"),
    N_HomeLocationGroup_ID: Joi.number()
      .allow("")
      .optional()
      //.required()
      // .min(1)
      // .max(100)
      .label("Home Location Group"),
    N_Order_No: Joi.number()
      .required()
      .min(1)
      .max(100)
      .label("Order No"),
    A_Reference: Joi.string()
      .required()
      .min(3)
      .max(30)
      .label("Reference"),
    A_Summary: Joi.string()
      //   .allow("")
      //   .optional()
      .required()
      .min(3)
      .max(200)
      .label("Summary"),
    // A_Description: Joi.string()
    //   .required()
    //   .min(3)
    //   .max(1000)
    //   .label("Description"),
    I_Image: Joi.any()
      .meta({ swaggerType: "file" })
      // .required()
      .optional()
      .label("Image"),
    N_PhotoSize01: Joi.number()
      .min(5000)
      .max(10000000)
      .optional(),
    waitingForFileUpload: Joi.boolean().optional(),
    N_BillboardLarge_ID: Joi.number()
      .allow("")
      .optional(),
    N_ParentOrigin_ID: Joi.number()
      .allow("")
      .optional()
  };

  schemaEdit = {
    ID: Joi.number(),
    N_BillboardOrigin_ID: Joi.number()
      .allow("")
      .optional()
      //.required()
      .min(1)
      .max(100)
      .label("Billboard Origin"),
    N_HomeLocationGroup_ID: Joi.number()
      .allow("")
      .optional()
      //.required()
      // .min(1)
      // .max(100)
      .label("Home Location Group"),
    N_Order_No: Joi.number()
      .required()
      // .min(1)
      // .max(100)
      .label("Order No"),
    A_Reference: Joi.string()
      .required()
      .min(3)
      .max(30)
      .label("Reference"),
    A_Summary: Joi.string()
      //   .allow("")
      //   .optional()
      .required()
      .min(3)
      .max(200)
      .label("Summary"),
    // A_Description: Joi.string()
    //   .required()
    //   .min(3)
    //   .max(1000)
    //   .label("Description"),
    I_Image: Joi.any()
      .meta({ swaggerType: "file" })
      // .required()
      .optional()
      .label("Image"),
    N_PhotoSize01: Joi.number()
      .min(5000)
      .max(10000000)
      .optional(),
    waitingForFileUpload: Joi.boolean().optional(),
    N_BillboardLarge_ID: Joi.number()
      .allow("")
      .optional(),
    N_ParentOrigin_ID: Joi.number()
      .allow("")
      .optional()
  };

  async populateGroups() {
    try {
      //console.log("populateGroups-1", auth.getCurrentUser());

      let data = { ...this.state.data };
      let {
        N_BillboardOrigin_ID,
        N_HomeLocationGroup_ID,
        N_ParentOrigin_ID
      } = this.state;

      await this.props.loadBillboardOrigins();

      await this.props.loadHomeLocationGroups();

      const groupId = this.props.match.params.id;

      if (groupId === "new") {
        data["N_BillboardOrigin_ID"] = 2;
        N_BillboardOrigin_ID = 2;
        data["N_HomeLocationGroup_ID"] = 4;
        N_HomeLocationGroup_ID = 4;
        data["N_BillboardLarge_ID"] = this.props.match.params.menuID;
        data["N_ParentOrigin_ID"] = 2;
        N_ParentOrigin_ID = 2;

        this.setState({
          data,
          //   billboardOrigin,
          //   homeLocationGroup,
          N_BillboardOrigin_ID,
          N_HomeLocationGroup_ID,
          N_ParentOrigin_ID,
          user: auth.getCurrentUser()
        });
        return;
      }

      //   const { data: group } = await getGroup(this.props.match.params.id);
      await this.props.loadGroup(groupId);

      //   const rawData = this.mapToViewModel(group);

      //   data["ID"] = rawData.ID;
      //   N_BillboardOrigin_ID = rawData.N_BillboardOrigin_ID;
      //   data["N_BillboardOrigin_ID"] = rawData.N_BillboardOrigin_ID;
      //   N_HomeLocationGroup_ID = rawData.N_HomeLocationGroup_ID;
      //   data["N_HomeLocationGroup_ID"] = rawData.N_HomeLocationGroup_ID;
      //   data["N_Order_No"] = rawData.N_Order_No;
      //   data["A_Reference"] = rawData.A_Reference;
      //   data["A_Summary"] = rawData.A_Summary;
      //   // data["A_Description"] = rawData.A_Description;
      //   data["I_Image"] = rawData.I_Image;
      //   data["N_BillboardLarge_ID"] = rawData.N_BillboardLarge_ID;
      //   N_ParentOrigin_ID = rawData.N_ParentOrigin_ID;
      //   data["N_ParentOrigin_ID"] = rawData.N_ParentOrigin_ID;

      //   //console.log("populateGroups-1", rawData.N_BillboardLarge_ID, MenuId);

      //   this.setState({
      //     data,
      //     billboardOrigin,
      //     homeLocationGroup,
      //     N_BillboardOrigin_ID,
      //     N_HomeLocationGroup_ID,
      //     N_ParentOrigin_ID,
      //     user: auth.getCurrentUser()
      //   });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async componentDidMount() {
    await this.populateGroups();
  }

  async componentWillReceiveProps(nextProps) {
    // window.alert("MenuForm, componentWillReceiveProps", nextProps.menuSuccess);

    let data = { ...this.state.data };
    let {
      N_BillboardOrigin_ID,
      N_HomeLocationGroup_ID,
      N_ParentOrigin_ID
    } = this.state;

    if (
      this.props.match.params.id !== "new" &&
      this.state.data !== nextProps.group
    ) {
      data["ID"] = nextProps.group.ID;
      N_BillboardOrigin_ID = nextProps.group.N_BillboardOrigin_ID;
      data["N_BillboardOrigin_ID"] = nextProps.group.N_BillboardOrigin_ID;
      N_HomeLocationGroup_ID = nextProps.group.N_HomeLocationGroup_ID;
      data["N_HomeLocationGroup_ID"] = nextProps.group.N_HomeLocationGroup_ID;
      data["N_Order_No"] = nextProps.group.N_Order_No;
      data["A_Reference"] = nextProps.group.A_Reference;
      data["A_Summary"] = nextProps.group.A_Summary;
      data["I_Image"] = nextProps.group.I_Image;
      N_ParentOrigin_ID = nextProps.group.N_ParentOrigin_ID;
      data["N_ParentOrigin_ID"] = nextProps.group.N_ParentOrigin_ID;

      this.setState({
        data,
        billboardOrigins: await nextProps.billboardOrigins,
        homeLocationGroups: await nextProps.homeLocationGroups,
        user: auth.getCurrentUser()
      });
    } else {
      data["N_BillboardOrigin_ID"] = 2;
      N_BillboardOrigin_ID = 2;
      data["N_HomeLocationGroup_ID"] = 4;
      N_HomeLocationGroup_ID = 4;
      data["N_ParentOrigin_ID"] = 2;
      data["N_BillboardLarge_ID"] = this.props.match.params.menuID;
      N_ParentOrigin_ID = 2;

      this.setState({
        data,
        billboardOrigins: await nextProps.billboardOrigins,
        homeLocationGroups: await nextProps.homeLocationGroups,
        user: auth.getCurrentUser()
      });
    }
  }

  //   mapToViewModel(group) {
  //     //console.log("mapToViewModel-1", group);
  //     return {
  //       ID: group.ID,
  //       N_BillboardOrigin_ID: group.N_BillboardOrigin_ID,
  //       N_HomeLocationGroup_ID: group.N_HomeLocationGroup_ID,
  //       N_Order_No: group.N_Order_No,
  //       A_Reference: group.A_Reference,
  //       A_Summary: group.A_Summary,
  //       // A_Description: group.A_Description,
  //       I_Image: group.I_Image,
  //       N_BillboardLarge_ID: group.N_BillboardLarge_ID,
  //       N_ParentOrigin_ID: group.N_ParentOrigin_ID
  //     };
  //   }

  doSubmit = async () => {
    try {
      const groupId = this.props.match.params.id;
      if (groupId === "new") {
        const currentDate = new Date();
        const month = currentDate.getMonth() + 1;
        const currentDateWithoutTime =
          month +
          "/" +
          currentDate.getDate() +
          "/" +
          currentDate.getFullYear() +
          " " +
          currentDate.getHours() +
          ":" +
          currentDate.getMinutes();

        await this.props.addGroup(
          this.state.data,
          auth.getCurrentUser().a_name,
          currentDateWithoutTime
        );
      } else {
        const currentDate = new Date();
        const month = currentDate.getMonth() + 1;
        const currentDateWithoutTime =
          month +
          "/" +
          currentDate.getDate() +
          "/" +
          currentDate.getFullYear() +
          " " +
          currentDate.getHours() +
          ":" +
          currentDate.getMinutes();

        await this.props.changeGroup(
          this.state.data,
          auth.getCurrentUser().a_name,
          currentDateWithoutTime
        );
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.title = ex.response.data;
        this.setState({ errors });
      }
    }

    // this.props.history.push(
    //   `/groups/groupsList/${this.props.match.params.menuID}`
    // );
    await this.props.history.goBack();
  };

  clearImages = () => {
    let obj = this.state.data;
    obj["I_Image"] = "";
    this.setState({ data: obj });
  };

  cancelForm = () => {
    this.props.history.push(
      `/groups/groupsList/${this.props.match.params.menuID}`
    );
  };

  constructor() {
    // ...
    super();

    // this.handleRadioGroupChange = this.handleRadioGroupChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    const billboardOrigin = this.state.billboardOrigin;
    const homeLocationGroup = this.state.homeLocationGroup;

    console.log(
      "group render : ",
      this.state.N_BillboardOrigin_ID,
      this.state.data["N_BillboardOrigin_ID"]
    );

    // Babak's Note: *** Below 3 lines will stop rendering of child component 'TableBodyAppoints' section
    //               'console.log("Hello 04 : ");' first *** :-
    // const { length: count } = billboardOrigin;
    // //if (count === 0) return <p></p>;
    // if (count === 0)
    //   return <p>There are currently no Billboard Origins in the database.</p>;

    // let string = this.props.match.params.id;
    // let substring = "new";
    const groupId = this.props.match.params.id;

    return (
      <div
        className="container"
        // style={{ marginTop: 80, marginBottom: 40, direction: "rtl" }}
        style={{ marginTop: "160px", marginBottom: "100px" }}
      >
        <h1>Group Form</h1>
        <br />
        <form onSubmit={this.handleSubmit}>
          {groupId === "new"
            ? this.renderRadioGroup(
                "N_BillboardOrigin_ID",
                "A_Origin",
                "Billboard Origin",
                this.props.billboardOrigins,
                1
              )
            : this.renderRadioGroup(
                "N_BillboardOrigin_ID",
                "A_Origin",
                "Billboard Origin",
                this.props.billboardOrigins,
                99
              )}
          <br />
          {groupId === "new"
            ? this.renderRadioGroup(
                "N_HomeLocationGroup_ID",
                "A_Group",
                "Home Location Group",
                this.props.homeLocationGroups,
                1
              )
            : this.renderRadioGroup(
                "N_HomeLocationGroup_ID",
                "A_Group",
                "Home Location Group",
                this.props.homeLocationGroups,
                1
              )}
          <br />
          {this.renderInput("N_Order_No", "Order Number", "number")}
          <br />
          {this.renderInput("A_Reference", "Reference")}

          <br />

          {this.renderInput("A_Summary", "Summary")}
          {/* {this.renderInput("A_Description", "Description")} */}
          <br />

          {this.renderImageFile("I_Image", "Upload Image")}
          {this.state.waitingForFileUpload && (
            <div>
              <span>Uploading file...</span>
            </div>
          )}

          <button
            type="button"
            className="btn btn-warning"
            onClick={this.clearImages}
          >
            Clear Image
          </button>
          <br />
          <img style={{ maxWidth: "300px" }} src={this.state.data.I_Image} />
          {/* <img
            style={{ maxWidth: "300px" }}
            src={`data:image/jpeg;base64,${this.state.data.I_Photo01}`}
          /> */}
          <br />
          <br />
          <div>
            <br />
          </div>
          {this.renderButton("Sunmit")}
          {this.renderButtonCancel("Cancel")}
          <br />
        </form>
      </div>
    );
  }
}

//
// Babak's Notes: goto tutorial directory '11-Integration with React' file '5- Connecting Components Using react-redux'
const mapStateToProps = state => ({
  //   groups: state.entities.groups.list.filter(group => !group.resolved)
  group: state.entities.groups.listSingleRow,
  billboardOrigins: state.entities.billboardOrigins.list,
  homeLocationGroups: state.entities.homeLocationGroups.list
});

const mapDispatchToProps = dispatch => ({
  loadGroup: id => dispatch(loadGroup(id)),
  loadBillboardOrigins: () => dispatch(loadBillboardOrigins()),
  loadHomeLocationGroups: () => dispatch(loadHomeLocationGroups()),
  addGroup: (group, user, currentDate) =>
    dispatch(addGroup(group, user, currentDate)),
  changeGroup: (group, user, currentDate) =>
    dispatch(changeGroup(group, user, currentDate))
});

// Container Component
//   Presentation (Groups)
//
// Babak's Notes: Below I populate 'this.props.groups' and 'this.props.loadGroups()':-
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupForm);
