import React, { Component } from "react";
import { Route, Redirect, Switch, BrowserRouter } from "react-router-dom";
import { connect } from "react-redux";

import auth from "./services/authService";

// import { ToastContainer } from "react-toastify";
import ProtectedRoute from "./components/common/protectedRoute";
import LoginForm from "./components/authentication/loginForm";
import Logout from "./components/authentication/logout";

// import Customization from "./components/customizations/customization";
import { loadCustomizationSingleRow } from "../src/store/customizations";
import { loadMenusOnNavbar } from "../src/store/menus";
import CustomizationForm from "./components/customizations/customizationForm";
import CustomizationsList from "./components/customizations/customizationsList";

import SearchDynamic from "./components/home/searchDynamic";

import DetailsGroup from "./components/home/detailsGroup";
import DetailsMaster from "./components/home/detailsMaster";
import DetailsDetail from "./components/home/detailsDetail";

import AboutUs from "./components/homeBillboards/aboutUs";
import ContactUs from "./components/homeBillboards/contactUs";

import RoleForm from "./components/authorization/roleForm";
import RolesList from "./components/authorization/rolesList";
import UserForm from "./components/users/userForm";
import UsersList from "./components/users/usersList";

import MenuForm from "./components/billboardAdmin/menuForm";
import MenusList from "./components/billboardAdmin/menusList";
import GroupForm from "./components/billboardAdmin/groupForm";
import GroupsList from "./components/billboardAdmin/groupsList";
import SumOfArticleForm from "./components/billboardAdmin/sumOfArticleForm";
import SumOfArticlesList from "./components/billboardAdmin/sumOfArticlesList";
import ArticleForm from "./components/billboardAdmin/articleForm";
import ArticlesList from "./components/billboardAdmin/articlesList";

import HomeBillboardForm from "./components/homeBillboards/homeBillboardForm";
import HomeBillboardsList from "./components/homeBillboards/homeBillboardsList";
import HomeBillboardChildForm from "./components/homeBillboards/homeBillboardChildForm";
import HomeBillboardChildsList from "./components/homeBillboards/homeBillboardChildsList";

import HomeBillboardsMain from "./components/homeBillboards/homeBillboardsMain";

import NotFound from "./components/notFound";
import NavBar from "./components/navBar";
import NavBar2 from "./components/navBar2";
import NavBar3 from "./components/navBar3";
import NavBar4 from "./components/navBar4";
import NavBarFooter from "./components/navBarFooter";

import "./App.css";

class App extends Component {
  state = {
    data: {
      B_TopNavbarActive: false
    }
  };

  async componentDidMount() {
    const user = auth.getCurrentUser();
    await this.props.loadCustomizationSingleRow(1);
    await this.props.loadMenusOnNavbar(1);

    // const topNavbarActive = this.mapCustomizationToViewModel(Customization());
    // const topNavbarActive = Customization();
    console.log(`App componentDidMount() : ${user} `);
    this.setState({
      user
    });
  }

  // mapCustomizationToViewModel(customization) {
  //   console.log(
  //     "App, mapCustomizationToViewModel() iiiiiiiiiiiiiiiii : ",
  //     customization.B_TopNavbarActive
  //   );
  //   return {
  //     B_TopNavbarActive: customization.B_TopNavbarActive
  //   };
  // }

  onClickSearchHandle = async event => {
    //window.confirm(`Are you sure you ${event.target.elements.searchID.value}?`);
    if (event.target.elements.searchID.value) {
      event.preventDefault();
      window.location = `/searchDynamic/'${event.target.elements.searchID.value}'`;
    } else {
      event.preventDefault();
      // window.alert("متن جستجو را اضافه کنید");
    }
  };

  render() {
    const { B_TopNavbarActive } = this.props.customizationSingleRow;

    // console.log(
    //   "App, Render() iiiiiiiiiiiiiiiii : ",
    //   this.props.customizationSingleRow,
    //   B_TopNavbarActive
    // );
    const { user } = this.state;
    return (
      <BrowserRouter>
        <React.Fragment>
          {/* <ToastContainer /> */}
          <title>{this.props.customizationSingleRow.A_WebAppTitle}</title>
          <NavBar
            user={user}
            customizationSingleRow={this.props.customizationSingleRow}
            menuOnNavvar={this.props.menuOnNavvar}
            onClickSearch={this.onClickSearchHandle}
          />
          {this.props.customizationSingleRow.B_ClientNavbarActive ? (
            <NavBar3
              user={user}
              menuOnNavvar={this.props.menuOnNavvar}
              onClickSearch={this.onClickSearchHandle}
            />
          ) : (
            ""
          )}
          {this.props.customizationSingleRow.B_TopNavbarActive ? (
            <div>
              <NavBar2
                user={user}
                customizationSingleRow={this.props.customizationSingleRow}
                menuOnNavvar={this.props.menuOnNavvar}
                onClickSearch={this.onClickSearchHandle}
              />
              {this.props.customizationSingleRow.B_ClientNavbarActive ? (
                <NavBar4
                  user={user}
                  menuOnNavvar={this.props.menuOnNavvar}
                  onClickSearch={this.onClickSearchHandle}
                />
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}

          {/* <NavBarFooter
            user={user}
            customizationSingleRow={this.props.customizationSingleRow}
            menuOnNavvar={this.props.menuOnNavvar}
            onClickSearch={this.onClickSearchHandle}
          /> */}

          {/* <div style={{ marginTop: 80 }}>
            <Customization />
          </div> */}
          <main>
            <Switch>
              <Route
                path="/login"
                render={props => (
                  <LoginForm
                    {...props}
                    customizationSingleRow={this.props.customizationSingleRow}
                  />
                )}
              />
              <Route path="/logout" component={Logout} />

              <Route
                path="/detailsGroup/:id"
                render={props => (
                  <DetailsGroup
                    {...props}
                    user={this.state.user}
                    customizationSingleRow={this.props.customizationSingleRow}
                  />
                )}
              />
              <Route
                path="/detailsMaster/:id"
                render={props => (
                  <DetailsMaster
                    {...props}
                    user={this.state.user}
                    customizationSingleRow={this.props.customizationSingleRow}
                  />
                )}
              />
              <Route
                path="/detailsdetail/:id"
                render={props => (
                  <DetailsDetail
                    {...props}
                    user={this.state.user}
                    customizationSingleRow={this.props.customizationSingleRow}
                  />
                )}
              />

              <Route
                path="/aboutUs"
                render={props => (
                  <AboutUs
                    {...props}
                    user={this.state.user}
                    customizationSingleRow={this.props.customizationSingleRow}
                  />
                )}
              />
              <Route
                path="/contactUs"
                render={props => (
                  <ContactUs
                    {...props}
                    user={this.state.user}
                    customizationSingleRow={this.props.customizationSingleRow}
                  />
                )}
              />

              <ProtectedRoute path="/menusList/:id" component={MenuForm} />
              <ProtectedRoute
                path="/menusList"
                render={props => (
                  <MenusList {...props} user={this.state.user} />
                )}
              />

              <ProtectedRoute
                path="/groupsList/:id/:menuID"
                component={GroupForm}
              />
              <ProtectedRoute
                path="/groups/groupsList/:menuID"
                render={props => (
                  <GroupsList {...props} user={this.state.user} />
                )}
              />

              <ProtectedRoute
                path="/sumOfArticlesList/:id/:groupID"
                component={SumOfArticleForm}
              />
              <ProtectedRoute
                path="/sumOfArticles/sumOfArticlesList/:groupID"
                render={props => (
                  <SumOfArticlesList {...props} user={this.state.user} />
                )}
              />

              <ProtectedRoute
                path="/articlesList/:id/:sumOfArticleID"
                component={ArticleForm}
              />
              <ProtectedRoute
                path="/articles/articlesList/:sumOfArticleID"
                render={props => (
                  <ArticlesList {...props} user={this.state.user} />
                )}
              />

              <ProtectedRoute
                path="/homeBillboardsList/:id"
                component={HomeBillboardForm}
              />
              <ProtectedRoute
                path="/homeBillboardsList"
                render={props => (
                  <HomeBillboardsList {...props} user={this.state.user} />
                )}
              />

              <ProtectedRoute
                path="/homeBillboardChildsList/:id/:homeBillboardID/:RadioGrpDirectionsID"
                component={HomeBillboardChildForm}
              />
              <ProtectedRoute
                path="/homeBillboardChilds/homeBillboardChildsList/:homeBillboardID/:RadioGrpDirectionsID"
                render={props => (
                  <HomeBillboardChildsList {...props} user={this.state.user} />
                )}
              />

              <ProtectedRoute path="/rolesList/:id" component={RoleForm} />
              <ProtectedRoute
                path="/rolesList"
                render={props => (
                  <RolesList {...props} user={this.state.user} />
                )}
              />

              <ProtectedRoute path="/usersList/:id" component={UserForm} />
              <ProtectedRoute
                path="/usersList"
                render={props => (
                  <UsersList {...props} user={this.state.user} />
                )}
              />

              <ProtectedRoute
                path="/customizationsList/:id"
                component={CustomizationForm}
              />
              <ProtectedRoute
                path="/customizationsList"
                render={props => (
                  <CustomizationsList {...props} user={this.state.user} />
                )}
              />

              {/* <ProtectedRoute
                path="/customization"
                render={props => (
                  <Customization {...props} user={this.state.user} />
                )}
              /> */}

              <Route
                path="/searchDynamic/:id"
                render={props => (
                  <SearchDynamic
                    {...props}
                    user={this.state.user}
                    customizationSingleRow={this.props.customizationSingleRow}
                    menuOnNavvar={this.props.menuOnNavvar}
                    onClickSearch={this.onClickSearchHandle}
                  />
                )}
              />

              <Route
                path="/homeBillboardsMain"
                render={props => (
                  <HomeBillboardsMain
                    {...props}
                    user={this.state.user}
                    customizationSingleRow={this.props.customizationSingleRow}
                    menuOnNavvar={this.props.menuOnNavvar}
                    onClickSearch={this.onClickSearchHandle}
                  />
                )}
              />

              <Route path="/not-found" component={NotFound} />
              <Redirect from="/" exact to="/homeBillboardsMain" />
              <Redirect to="/not-found" />
            </Switch>
          </main>
        </React.Fragment>
      </BrowserRouter>
    );
  }
}

//
// Babak's Notes: goto tutorial directory '11-Integration with React' file '5- Connecting Components Using react-redux'
const mapStateToProps = state => ({
  //   bugs: state.entities.customizations.list.filter(bug => !bug.resolved)
  customizationSingleRow: state.entities.customizations.listSingleRow,
  menuOnNavvar: state.entities.menus.listOnNavvar
});

const mapDispatchToProps = dispatch => ({
  loadCustomizationSingleRow: id => dispatch(loadCustomizationSingleRow(id)),
  loadMenusOnNavbar: billLocationGroupID =>
    dispatch(loadMenusOnNavbar(billLocationGroupID))
});

// Container Component
//   Presentation (Bugs)
//
// Babak's Notes: Below I populate 'this.props.bugs' and 'this.props.loadBugs()':-
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

// export default App;
