// import Raven from "raven-js";

function init() {
  // Raven.config("https://5f9320a4e45c45fcb36eee0a5dc8eab5@sentry.io/1431933", {
  //   release: "1-0-0",
  //   environment: "development-test"
  // }).install();
}

function log(error) {
  console.error(error);
  // Raven.captureException(error);
}

export default {
  init,
  log
};
