import React, { Component } from "react";
import auth from "../../services/authService";
import Table from "../common/table";
import Active from "../common/active";

class HomeBillboardChildsTable extends Component {
  columns = [];

  async componentDidMount() {
    //console.log("componentDidMount UsersTable 01 : ");
  }

  updateColumn = {
    key: "update",
    type: "text",
    content: homeBillboardChild =>
      auth.getCurrentUser().t_role_a_name === "Admin" ? (
        <button
          onClick={() => this.props.onUpdate(homeBillboardChild)}
          className="btn btn-info btn-sm pull-right enabled"
        >
          Update
        </button>
      ) : (
        <button className="btn btn-info btn-sm pull-right disabled">
          Update
        </button>
      )
  };

  deleteColumn = {
    key: "delete",
    type: "text",
    content: homeBillboardChild =>
      auth.getCurrentUser().t_role_a_name === "Admin" ? (
        <button
          onClick={() => {
            if (homeBillboardChild.N_HB_Count < 1) {
              if (
                window.confirm(
                  `Are you sure you want to delete Home Billboard '${homeBillboardChild.A_Reference}${homeBillboardChild.A_HoverSummary01}'?`
                )
              ) {
                this.props.onDelete(homeBillboardChild);
              }
            }
          }}
          className={
            homeBillboardChild.N_HB_Count > 0
              ? "btn btn-danger btn-sm disabled"
              : "btn btn-danger btn-sm enabled"
          }
        >
          Delete
        </button>
      ) : (
        <button className="btn btn-danger btn-sm disabled">Delete</button>
      )
  };

  constructor() {
    super();
    const homeBillboardChilds = [{ ID: 1 }];

    homeBillboardChilds.map(
      item => (
        this.columns.push({
          path: "ID",
          label: "Identifier",
          hidden: true,
          type: "text"
        }),
        this.columns.push({
          path: "N_OrderNo",
          label: "Order",
          type: "text"
        }),
        // this.columns.push({
        //   path: "A_RadioGrpDirections",
        //   label: "Direction",
        //   type: "text"
        // }),
        this.columns.push({
          path: "A_HoverSummary01",
          label: "Hover",
          type: "text"
        }),
        this.columns.push({
          path: "A_Reference",
          label: "Reference",
          type: "text"
        }),
        // this.columns.push({
        //   path: "A_Summary",
        //   label: "Summary",
        //   type: "textEditor"
        // }),
        // this.columns.push({
        //   path: "A_Description",
        //   label: "Description",
        //   type: "textEditor"
        // }),
        this.columns.push({
          path: "N_HomeBillboards_ID",
          label: "BillboardHomesIdentifier",
          hidden: true,
          type: "text"
        }),
        this.columns.push({ path: "I_Image", label: "Image", type: "image" }),
        this.columns.push({
          ID: 9,
          path: "D_Inserted",
          label: "Inserted",
          type: "date"
        }),
        this.columns.push({
          ID: 10,
          path: "A_Inserted_By",
          label: "Inserted_By",
          type: "text"
        }),
        this.columns.push({
          ID: 11,
          path: "D_Updated",
          label: "Updated",
          type: "date"
        }),
        this.columns.push({
          ID: 12,
          path: "A_Updated_By",
          label: "Updated_By",
          type: "text"
        })
      )
    );

    this.columns.push(this.updateColumn);
    this.columns.push(this.deleteColumn);

    //const user = auth.getCurrentUser();
    //console.log("Home Billboards details : ", homeBillboard);
  }
  // //
  // // Babak's Note: Below we add a condition that the user must be logged in and have an admin user in order to see
  // //               the 'delete' button:-
  // constructor() {
  //   super();
  //   const user = auth.getCurrentUser();
  //   if (user && user.isAdmin) this.columns.push(this.deleteColumn);
  // }

  render() {
    //console.log("render usersTable");
    const { homeBillboardChildsList, onSort, sortColumn } = this.props;
    return (
      <Table
        columns={this.columns}
        data={homeBillboardChildsList}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}
export default HomeBillboardChildsTable;
