import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import { toast } from "react-toastify";
import HomeBillboardChildsTable from "./homeBillboardChildsTable";
import Pagination from "../common/pagination";
import { paginate } from "../../utils/paginate";
import {
  loadHomeBillboardChilds,
  deleteHomeBillboardChild
} from "../../store/homeBillboardChilds";
import { connect } from "react-redux";
import _ from "lodash";
import SearchBox from "../searchBox";

class HomeBillboardChildsList extends Component {
  state = {
    groups: [],
    homeBillboardID: 0,
    homeBillboardChildForm: false,
    homeBillboardID: 0,
    currentPage: 1,
    pageSize: 8,
    searchQuery: "",
    sortColumn: { path: "N_Order_No", order: "asc" }
  };

  async componentDidMount() {
    // window.alert("componentDidMount GroupsList 01 : ");

    document.body.style.backgroundImage = `url()`;
    document.body.style.backgroundColor = "white";
    document.body.style.backgroundPosition = "center center";
    document.body.style.backgroundAttachment = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundSize = "cover";

    const homeBillboardID = this.props.match.params.homeBillboardID;

    // window.alert(
    //   `RadioGrpDirectionsID 2 : ${this.props.match.params.RadioGrpDirectionsID}`
    // );

    await this.props.loadHomeBillboardChilds(homeBillboardID);
  }

  handleUpdate = async homeBillboardChild => {
    try {
      this.setState({
        homeBillboardChildForm: true,
        homeBillboardChildID: homeBillboardChild.ID,
        homeBillboardID: this.props.match.params.homeBillboardID
      });
      // window.location = `/groupForm/${group.ID}/${this.props.match.params.homeBillboardID}`;
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...ex.errors };
        this.setState({ errors });
      }
    }
  };

  handlePageChange = page => {
    //console.log(page);
    this.setState({ currentPage: page });
  };

  //
  // Babak's Note: We have put this 'input' field inside of the 'SearchBox' component, this component raises
  //               an event and in the we get an string. So below 'query' will contain the string the user
  //               types in the search field.
  handleSearch = query => {
    // console.log("query : ", query);
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  handleSort = sortColumn => {
    // console.log("handleSort : ", sortColumn);
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;

    //console.log("searchQuery 2 : ", searchQuery);

    const allHomeBillboardChilds = this.props.homeBillboardChilds;

    let filtered = allHomeBillboardChilds;
    if (searchQuery)
      filtered = allHomeBillboardChilds.filter(m =>
        m.A_Reference.toLowerCase().includes(searchQuery.toLowerCase())
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const homeBillboardChilds = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: homeBillboardChilds };
  };

  render() {
    //console.log("render homeBillboardChilds");

    const RadioGrpDirectionsID = this.props.match.params.RadioGrpDirectionsID;

    if (this.state.homeBillboardChildForm === true) {
      return (
        <Redirect
          push
          to={`/homeBillboardChildsList/${this.state.homeBillboardChildID}/${this.state.homeBillboardID}/${RadioGrpDirectionsID}`}
        />
      );
    }

    // if (this.props.homeBillboardChilds.length === 0)
    //   return <div className="hourGlass"></div>;

    // const { length: count } = this.state.homeBillboardChilds;

    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;

    //
    //Babak's Note: Below I renamed 'data' to 'homeBillboardChilds'
    // console.log("searchQuery 1 : ");

    const { totalCount, data: homeBillboardChildsList } = this.getPagedData();

    // console.log(
    //   "render homeBillboardChilds ddddddddddddddddddddddddddd : ",
    //   RadioGrpDirectionsID,
    //   totalCount
    // );

    return (
      <div className="container">
        <div className="row">
          <div
            className="col"
            style={{ marginTop: "160px", marginBottom: "100px" }}
          >
            <h1>Home Billboard Childs</h1>
            <br />

            {RadioGrpDirectionsID === "1" && totalCount === 1 ? (
              <Link
                to={`/homeBillboardChildsList/new/${this.props.match.params.homeBillboardID}/${RadioGrpDirectionsID}`}
                className="btn btn-primary"
                style={{ marginBottom: 20 }}
              >
                New Home Billboard Child
              </Link>
            ) : RadioGrpDirectionsID === "2" && totalCount <= 2 ? (
              <Link
                to={`/homeBillboardChildsList/new/${this.props.match.params.homeBillboardID}/${RadioGrpDirectionsID}`}
                className="btn btn-primary"
                style={{ marginBottom: 20 }}
              >
                New Home Billboard Child
              </Link>
            ) : RadioGrpDirectionsID === "3" && totalCount <= 4 ? (
              <Link
                to={`/homeBillboardChildsList/new/${this.props.match.params.homeBillboardID}/${RadioGrpDirectionsID}`}
                className="btn btn-primary"
                style={{ marginBottom: 20 }}
              >
                New Home Billboard Child
              </Link>
            ) : RadioGrpDirectionsID === "4" && totalCount <= 6 ? (
              <Link
                to={`/homeBillboardChildsList/new/${this.props.match.params.homeBillboardID}/${RadioGrpDirectionsID}`}
                className="btn btn-primary"
                style={{ marginBottom: 20 }}
              >
                New Home Billboard Child
              </Link>
            ) : RadioGrpDirectionsID === "5" && totalCount <= 4 ? (
              <Link
                to={`/homeBillboardChildsList/new/${this.props.match.params.homeBillboardID}/${RadioGrpDirectionsID}`}
                className="btn btn-primary"
                style={{ marginBottom: 20 }}
              >
                New Home Billboard Child
              </Link>
            ) : RadioGrpDirectionsID === "6" && totalCount <= 4 ? (
              <Link
                to={`/homeBillboardChildsList/new/${this.props.match.params.homeBillboardID}/${RadioGrpDirectionsID}`}
                className="btn btn-primary"
                style={{ marginBottom: 20 }}
              >
                New Home Billboard Child
              </Link>
            ) : RadioGrpDirectionsID === "7" && totalCount <= 7 ? (
              <Link
                to={`/homeBillboardChildsList/new/${this.props.match.params.homeBillboardID}/${RadioGrpDirectionsID}`}
                className="btn btn-primary"
                style={{ marginBottom: 20 }}
              >
                New Home Billboard Child
              </Link>
            ) : RadioGrpDirectionsID === "8" && totalCount <= 4 ? (
              <Link
                to={`/homeBillboardChildsList/new/${this.props.match.params.homeBillboardID}/${RadioGrpDirectionsID}`}
                className="btn btn-primary"
                style={{ marginBottom: 20 }}
              >
                New Home Billboard Child
              </Link>
            ) : RadioGrpDirectionsID === "9" && totalCount <= 4 ? (
              <Link
                to={`/homeBillboardChildsList/new/${this.props.match.params.homeBillboardID}/${RadioGrpDirectionsID}`}
                className="btn btn-primary"
                style={{ marginBottom: 20 }}
              >
                New Home Billboard Child
              </Link>
            ) : (
              <button
                title="Maximum number of Home Billboard Childs reached!"
                disabled
                className="btn btn-danger disabled clickableNon"
                style={{ marginBottom: 20 }}
              >
                Maximum number of Home Billboard Childs reached!
              </button>
            )}

            <p>Showing {totalCount} Home Billboard Childs in the database.</p>
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
            <HomeBillboardChildsTable
              homeBillboardChildsList={homeBillboardChildsList}
              sortColumn={sortColumn}
              onUpdate={this.handleUpdate}
              onDelete={homeBillboardChild =>
                this.props.deleteHomeBillboardChild(homeBillboardChild)
              }
              onSort={this.handleSort}
            />
            <div className="paginationBar">
              <Pagination
                itemsCount={totalCount}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={this.handlePageChange}
              />
            </div>
            <div className="backBar">
              <Link
                to="/homeBillboardsList"
                className="btn btn-primary"
                style={{ marginBottom: "100px" }}
              >
                Back to Home Billboards
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

//
// Babak's Notes: goto tutorial directory '11-Integration with React' file '5- Connecting Components Using react-redux'
const mapStateToProps = state => ({
  //   bugs: state.entities.menus.list.filter(bug => !bug.resolved)
  homeBillboardChilds: state.entities.homeBillboardChilds.list
});

const mapDispatchToProps = dispatch => ({
  loadHomeBillboardChilds: homeBillboardID =>
    dispatch(loadHomeBillboardChilds(homeBillboardID)),
  deleteHomeBillboardChild: homeBillboardChild =>
    dispatch(deleteHomeBillboardChild(homeBillboardChild))
});

// Container Component
//   Presentation (Bugs)
//
// Babak's Notes: Below I populate 'this.props.bugs' and 'this.props.loadBugs()':-
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeBillboardChildsList);
