import React, { Component } from "react";
import auth from "../../services/authService";
import Table from "../common/table";

class CustomizationsTable extends Component {
  //
  // Babak's Note: A template litaral is (including ``) : `/customizations/${customization.id}`
  //               Argument is : ${customization.id}
  //
  // Babal's Note: Below are the columns in the home page 'customizations'. The 'Title' column is rendered dynamically
  //               in order so we link it to another componet (another words a page), in this case 'customizationForm'.
  //               Path is the database column name.
  columns = [
    {
      path: "ID",
      label: "Identifier",
      hidden: true,
      type: "text"
    },
    { path: "A_WebAppTitle", label: "WebApp Title", type: "text" },
    { path: "D_Inserted", label: "Inserted", type: "text" },
    { path: "A_Inserted_By", label: "Inserted By", type: "text" },
    { path: "D_Updated", label: "Updated", type: "text" },
    { path: "A_Updated_By", label: "Updated By", type: "text" }
  ];

  updateColumn = {
    key: "update",
    type: "text",
    content: customization =>
      auth.getCurrentUser().t_role_a_name === "Admin" ? (
        <button
          onClick={() => this.props.onUpdate(customization)}
          className="btn btn-info btn-sm pull-right enabled"
        >
          Update
        </button>
      ) : (
        <button className="btn btn-info btn-sm pull-right disabled">
          Update
        </button>
      )
  };

  deleteColumn = {
    key: "delete",
    type: "text",
    content: customization =>
      auth.getCurrentUser().t_role_a_name === "Admin" ? (
        <button
          onClick={() => {
            if (
              window.confirm(
                `Are you sure you want to delete user '${customization.A_WebAppTitle}'?`
              )
            ) {
              this.props.onDelete(customization);
            }
          }}
          className="btn btn-danger btn-sm enabled"
        >
          Delete
        </button>
      ) : (
        <button className="btn btn-danger btn-sm disabled">Delete</button>
      )
  };

  constructor() {
    super();
    this.columns.push(this.updateColumn);
    this.columns.push(this.deleteColumn);

    //const user = auth.getCurrentUser();
  }
  // //
  // // Babak's Note: Below we add a condition that the user must be logged in and have an admin customization in order to see
  // //               the 'delete' button:-
  // constructor() {
  //   super();
  //   const user = auth.getCurrentUser();
  //   if (user && user.isAdmin) this.columns.push(this.deleteColumn);
  // }

  render() {
    const { customizationsList, onSort, sortColumn } = this.props;
    // const { customizationsList } = this.props;
    // console.log("render customizationsTable : ", customizationsList);
    return (
      <Table
        columns={this.columns}
        data={customizationsList}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}
export default CustomizationsTable;
