import React, { Component } from "react";
import Flash from "react-reveal/Flash";
import HeadShake from "react-reveal/HeadShake";
import Jello from "react-reveal/Jello";
import Jump from "react-reveal/Jump";
import RubberBand from "react-reveal/RubberBand";
import Swing from "react-reveal/Swing";
import Tada from "react-reveal/Tada";
import Wobble from "react-reveal/Wobble";
import Zoom from "react-reveal/Zoom";
import Radium, { StyleRoot } from "radium";
import LazyLoad from "react-lazy-load";
import { NavLink, withRouter, Route } from "react-router-dom";

import { bottom } from "@popperjs/core";

class RenderHomeBillboardChildsMainsXS extends Component {
  // async componentWillReceiveProps(nextProps) {
  //   const { homeBillboardChildsMains } = nextProps;

  //   if (
  //     this.state.homeBillboardChildsMains !== nextProps.homeBillboardChildsMains
  //   ) {
  //     for (let i = 1; i < this.props.homeBillboardChildsMains.length; i++) {
  //       console.log(
  //         "HomeBillboardChildsMain, componentWillReceivePropsssssssssssssssssssssssssssss  :  ",
  //         homeBillboardChildsMains[i],
  //         this.props.homeBillboardChildsMains.length
  //       );
  //     }
  //     this.setState({ homeBillboardChildsMains });
  //   }

  //   // console.log(
  //   //   `HomeBillboardChildsMain, componentWillReceivePropsssssssssssssssssssssssssssss  :  ${nextProps.homeBillboardChildsMains}`
  //   // );
  //   // const { homeBillboardChildsMains } = nextProps.homeBillboardChildsMains;
  //   // this.setState({
  //   //   homeBillboardChildsMains
  //   // });
  // }

  RenderImage = ({
    image,
    ChildImageLink,
    ChildImage,
    urlLink,
    urlLinked,
    urlLinkedExternal
  }) => {
    const sectionStyle = {
      // height: "510px",
      // backgroundImage: `linear-gradient(to bottom, ${item.A_BGC}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,
      backgroundImage: `url(${image})`,
      // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
      backgroundPosition: "center center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover"
    };

    return (
      <LazyLoad>
        {urlLinked ? (
          urlLinkedExternal ? (
            <a style={{ color: "white" }} href={urlLink} target={"_blank"}>
              <div>
                <div style={sectionStyle} className="boxShadowLight">
                  <img
                    // onClick={() => {
                    //   // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
                    //   //               rows where N_HomeLocationGroup_ID === 1:-
                    //   if (item.N_BillboardOrigin_ID === 2) {
                    //     window.location = `../detailsMaster/${item.ID}`;
                    //   } else if (item.N_BillboardOrigin_ID === 3) {
                    //     window.location = `../detailsDetail/${item.ID}`;
                    //   }
                    // }}
                    className={ChildImageLink}
                  ></img>
                </div>
              </div>
            </a>
          ) : (
            <NavLink style={{ color: "white" }} to={urlLink}>
              <div>
                <div style={sectionStyle} className="boxShadowLight">
                  <img
                    // onClick={() => {
                    //   // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
                    //   //               rows where N_HomeLocationGroup_ID === 1:-
                    //   if (item.N_BillboardOrigin_ID === 2) {
                    //     window.location = `../detailsMaster/${item.ID}`;
                    //   } else if (item.N_BillboardOrigin_ID === 3) {
                    //     window.location = `../detailsDetail/${item.ID}`;
                    //   }
                    // }}
                    className={ChildImageLink}
                  ></img>
                </div>
              </div>
            </NavLink>
          )
        ) : (
          <div style={{ color: "white" }}>
            <div>
              <div style={sectionStyle} className="boxShadowLight">
                <img
                  // onClick={() => {
                  //   // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
                  //   //               rows where N_HomeLocationGroup_ID === 1:-
                  //   if (item.N_BillboardOrigin_ID === 2) {
                  //     window.location = `../detailsMaster/${item.ID}`;
                  //   } else if (item.N_BillboardOrigin_ID === 3) {
                  //     window.location = `../detailsDetail/${item.ID}`;
                  //   }
                  // }}
                  src={image}
                  className={ChildImage}
                ></img>
              </div>
            </div>
          </div>
        )}
      </LazyLoad>
    );
  };

  RenderReference = ({
    reference,
    referenceCSS,
    urlLink,
    urlLinked,
    urlLinkedExternal
  }) => {
    return (
      <div>
        {urlLinked ? (
          urlLinkedExternal ? (
            <a style={{ color: "white" }} href={urlLink} target={"_blank"}>
              <div
                className={referenceCSS}
                // style={{ textAlign: "right" }}
                // onClick={() => {
                //   // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
                //   //               rows where N_HomeLocationGroup_ID === 1:-
                //   if (item.N_BillboardOrigin_ID === 2) {
                //     window.location = `../detailsMaster/${item.ID}`;
                //   } else if (item.N_BillboardOrigin_ID === 3) {
                //     window.location = `../detailsDetail/${item.ID}`;
                //   }
                // }}
              >
                <div className="HomeTitleLink">{reference}</div>

                {/* <div
                  className="HomeTitleLinkBlack"
                  style={{ marginTop: "5px" }}
                  // style={{ textAlign: "right" }}
                >
                  {item.A_Summary}
                </div> */}
                {/* <div
                // style={{ textAlign: "right" }}
                className="HomeRedLink"
                onClick={() => {
                  // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
                  //               rows where N_HomeLocationGroup_ID === 1:-
                  if (item.N_BillboardOrigin_ID === 2) {
                    window.location = `../detailsMaster/${item.ID}`;
                  } else if (item.N_BillboardOrigin_ID === 3) {
                    window.location = `../detailsDetail/${item.ID}`;
                  }
                }}
              >
                More...
              </div> */}
              </div>
            </a>
          ) : (
            <NavLink style={{ color: "white" }} to={urlLink}>
              <div
                className={referenceCSS}
                // style={{ textAlign: "right" }}
                // onClick={() => {
                //   // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
                //   //               rows where N_HomeLocationGroup_ID === 1:-
                //   if (item.N_BillboardOrigin_ID === 2) {
                //     window.location = `../detailsMaster/${item.ID}`;
                //   } else if (item.N_BillboardOrigin_ID === 3) {
                //     window.location = `../detailsDetail/${item.ID}`;
                //   }
                // }}
              >
                <div className="HomeTitleLink">{reference}</div>

                {/* <div
                  className="HomeTitleLinkBlack"
                  style={{ marginTop: "5px" }}
                  // style={{ textAlign: "right" }}
                >
                  {item.A_Summary}
                </div> */}
                {/* <div
                // style={{ textAlign: "right" }}
                className="HomeRedLink"
                onClick={() => {
                  // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
                  //               rows where N_HomeLocationGroup_ID === 1:-
                  if (item.N_BillboardOrigin_ID === 2) {
                    window.location = `../detailsMaster/${item.ID}`;
                  } else if (item.N_BillboardOrigin_ID === 3) {
                    window.location = `../detailsDetail/${item.ID}`;
                  }
                }}
              >
                More...
              </div> */}
              </div>
            </NavLink>
          )
        ) : (
          <div style={{ color: "white" }} to={urlLink}>
            <div
              className={referenceCSS}
              // style={{ textAlign: "right" }}
              // onClick={() => {
              //   // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
              //   //               rows where N_HomeLocationGroup_ID === 1:-
              //   if (item.N_BillboardOrigin_ID === 2) {
              //     window.location = `../detailsMaster/${item.ID}`;
              //   } else if (item.N_BillboardOrigin_ID === 3) {
              //     window.location = `../detailsDetail/${item.ID}`;
              //   }
              // }}
            >
              <div className="HomeTitleLink">{reference}</div>

              {/* <div
                  className="HomeTitleLinkBlack"
                  style={{ marginTop: "5px" }}
                  // style={{ textAlign: "right" }}
                >
                  {item.A_Summary}
                </div> */}
              {/* <div
                // style={{ textAlign: "right" }}
                className="HomeRedLink"
                onClick={() => {
                  // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
                  //               rows where N_HomeLocationGroup_ID === 1:-
                  if (item.N_BillboardOrigin_ID === 2) {
                    window.location = `../detailsMaster/${item.ID}`;
                  } else if (item.N_BillboardOrigin_ID === 3) {
                    window.location = `../detailsDetail/${item.ID}`;
                  }
                }}
              >
                More...
              </div> */}
            </div>
          </div>
        )}
      </div>
    );
  };

  render() {
    const {
      item,
      nameOfClass,
      referenceCSS,
      ChildImageLink,
      ChildImage
    } = this.props;

    return (
      <div key={item.ID} className={nameOfClass} style={{ float: "left" }}>
        <div
          className="col-6"
          style={{
            float: "left",
            paddingRight: "0"
          }}
        >
          <this.RenderImage
            image={item.I_Image}
            ChildImageLink={ChildImageLink}
            ChildImage={ChildImage}
            urlLink={item.A_HoverSummary01_Link}
            urlLinked={item.B_HoverSummary01_Linked}
            urlLinkedExternal={item.B_HoverSummary01_Link_External}
          />
        </div>

        <div
          className="col-6"
          style={{
            float: "left",
            paddingLeft: "0"
          }}
        >
          <this.RenderReference
            reference={item.A_Reference}
            referenceCSS={referenceCSS}
            urlLink={item.A_HoverSummary01_Link}
            urlLinked={item.B_HoverSummary01_Linked}
            urlLinkedExternal={item.B_HoverSummary01_Link_External}
          />
        </div>
      </div>
    );
  }
}

export default RenderHomeBillboardChildsMainsXS;
