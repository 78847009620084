import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import auth from "../../services/authService";
import {
  getCustomization,
  saveCustomization
} from "../../services/customizationService";
// import {
//   loadCustomization,
//   addCustomization,
//   changeCustomization
// } from "../../store/customizations";
// import { connect } from "react-redux";

class CustomizationForm extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        ID: "",
        A_WebAppTitle: "",
        A_BaseNavbarBGC: "",
        A_BaseNavbarFGC: "",
        A_BaseNavbarHover: "",
        A_BaseSearchBtnBGC: "",
        A_BaseSearchBtnFGC: "",
        A_BaseSearchBtnHover: "",
        B_ClientNavbarActive: false,
        B_TopNavbarActive: false,
        A_TopNavbarBGC: "",
        A_TopNavbarFGC: "",
        A_TopNavbarHover: "",
        A_TopSearchBtnBGC: "",
        A_TopSearchBtnFGC: "",
        A_TopSearchBtnHover: "",
        I_LoginBGImage: "",
        A_LoginTitle: "",
        N_AboutUsNo: 0,
        N_ContactUsNo: 0,
        B_GoogleMap: false,
        A_GoogleKey: "",
        F_Lat: 0,
        F_Lng: 0,
        D_Inserted: "",
        A_Inserted_By: "",
        D_Updated: "",
        A_Updated_By: ""
      },
      errors: {}
    };
  }

  schemaCreate = {
    ID: Joi.number()
      .allow("")
      .optional(),
    A_WebAppTitle: Joi.string()
      .required()
      .min(10)
      .max(300)
      .label("WebApp Title"),
    A_BaseNavbarBGC: Joi.string()
      .required()
      .min(5)
      .max(50)
      .label("Base Navbar BGC"),
    A_BaseNavbarFGC: Joi.string()
      .required()
      .min(5)
      .max(50)
      .label("Base Navbar FGC"),
    A_BaseNavbarHover: Joi.string()
      .required()
      .min(5)
      .max(50)
      .label("Base Navbar Hover"),
    A_BaseSearchBtnBGC: Joi.string()
      .required()
      .min(5)
      .max(50)
      .label("Base Search BGC"),
    A_BaseSearchBtnFGC: Joi.string()
      .required()
      .min(5)
      .max(50)
      .label("Base Search FGC"),
    A_BaseSearchBtnHover: Joi.string()
      .required()
      .min(5)
      .max(50)
      .label("Base Search Hover"),
    B_ClientNavbarActive: Joi.boolean().label("Client Navbar Active"),
    B_TopNavbarActive: Joi.boolean().label("Top Navbar Active"),
    A_TopNavbarBGC: Joi.string()
      .required()
      .min(5)
      .max(50)
      .label("Top Navbar BGC"),
    A_TopNavbarFGC: Joi.string()
      .required()
      .min(5)
      .max(50)
      .label("Top Navbar FGC"),
    A_TopNavbarHover: Joi.string()
      .required()
      .min(5)
      .max(50)
      .label("Top Navbar Hover"),
    A_TopSearchBtnBGC: Joi.string()
      .required()
      .min(5)
      .max(50)
      .label("Top Search BGC"),
    A_TopSearchBtnFGC: Joi.string()
      .required()
      .min(5)
      .max(50)
      .label("Top Search FGC"),
    A_TopSearchBtnHover: Joi.string()
      .required()
      .min(5)
      .max(50)
      .label("Top Search Hover"),
    I_LoginBGImage: Joi.any()
      .meta({ swaggerType: "file" })
      .optional(),
    A_LoginTitle: Joi.string()
      .min(10)
      .max(40)
      .required()
      .label("Login Title"),
    N_PhotoSize01: Joi.number()
      .min(5000)
      .max(10000000)
      .optional(),
    waitingForFileUpload: Joi.boolean().optional(),
    N_AboutUsNo: Joi.number()
      .allow("")
      .optional(),
    N_ContactUsNo: Joi.number()
      .allow("")
      .optional(),
    B_GoogleMap: Joi.boolean().label("Google Map"),
    A_GoogleKey: Joi.string()
      .min(10)
      .max(500)
      .required()
      .label("Google Key"),
    F_Lat: Joi.number()
      .allow("")
      .optional(),
    F_Lng: Joi.number()
      .allow("")
      .optional(),
    D_Inserted: Joi.string()
      .allow("")
      .optional(),
    A_Inserted_By: Joi.string()
      .allow("")
      .optional(),
    D_Updated: Joi.string()
      .allow("")
      .optional(),
    A_Updated_By: Joi.string()
      .allow("")
      .optional()
  };

  schemaEdit = {
    ID: Joi.number(),
    A_WebAppTitle: Joi.string()
      .required()
      .min(10)
      .max(300)
      .label("WebApp Title"),
    A_BaseNavbarBGC: Joi.string()
      .required()
      .min(5)
      .max(50)
      .label("Base Navbar BGC"),
    A_BaseNavbarFGC: Joi.string()
      .required()
      .min(5)
      .max(50)
      .label("Base Navbar FGC"),
    A_BaseNavbarHover: Joi.string()
      .required()
      .min(5)
      .max(50)
      .label("Base Navbar Hover"),
    A_BaseSearchBtnBGC: Joi.string()
      .required()
      .min(5)
      .max(50)
      .label("Base Search BGC"),
    A_BaseSearchBtnFGC: Joi.string()
      .required()
      .min(5)
      .max(50)
      .label("Base Search FGC"),
    A_BaseSearchBtnHover: Joi.string()
      .required()
      .min(5)
      .max(50)
      .label("Base Search Hover"),
    B_ClientNavbarActive: Joi.boolean().label("Client Navbar Active"),
    B_TopNavbarActive: Joi.boolean().label("Top Navbar Active"),
    A_TopNavbarBGC: Joi.string()
      .required()
      .min(5)
      .max(50)
      .label("Top Navbar BGC"),
    A_TopNavbarFGC: Joi.string()
      .required()
      .min(5)
      .max(50)
      .label("Top Navbar FGC"),
    A_TopNavbarHover: Joi.string()
      .required()
      .min(5)
      .max(50)
      .label("Top Navbar Hover"),
    A_TopSearchBtnBGC: Joi.string()
      .required()
      .min(5)
      .max(50)
      .label("Top Search BGC"),
    A_TopSearchBtnFGC: Joi.string()
      .required()
      .min(5)
      .max(50)
      .label("Top Search FGC"),
    A_TopSearchBtnHover: Joi.string()
      .required()
      .min(5)
      .max(50)
      .label("Top Search Hover"),
    I_LoginBGImage: Joi.any()
      .meta({ swaggerType: "file" })
      .optional(),
    A_LoginTitle: Joi.string()
      .min(10)
      .max(40)
      .required()
      .label("Login Title"),
    N_PhotoSize01: Joi.number()
      .min(5000)
      .max(10000000)
      .optional(),
    waitingForFileUpload: Joi.boolean().optional(),
    N_AboutUsNo: Joi.number()
      .allow("")
      .optional(),
    N_ContactUsNo: Joi.number()
      .allow("")
      .optional(),
    B_GoogleMap: Joi.boolean().label("Google Map"),
    A_GoogleKey: Joi.string()
      .min(10)
      .max(500)
      .required()
      .label("Google Key"),
    F_Lat: Joi.number()
      .allow("")
      .optional(),
    F_Lng: Joi.number()
      .allow("")
      .optional(),
    D_Inserted: Joi.string()
      .allow("")
      .optional(),
    A_Inserted_By: Joi.string()
      .allow("")
      .optional(),
    D_Updated: Joi.string()
      .allow("")
      .optional(),
    A_Updated_By: Joi.string()
      .allow("")
      .optional()
  };

  // async componentDidMount() {
  //   // console.log("CustomizationForm, componentDidMount");

  //   const customizationId = this.props.match.params.id;
  //   if (customizationId === "new") return;

  //   this.props.loadCustomization(customizationId);
  // }

  async populateCustomization() {
    //console.log("populateUsers-1");
    try {
      const customizationId = this.props.match.params.id;
      if (customizationId === "new") return;

      const { data: customization } = await getCustomization(customizationId);
      this.setState({ data: this.mapToViewModel(customization) });
      //console.log("populateUsers-2", this.mapToViewModel(user));
      //this.setState({ data: user });
      //console.log("populateUsers-2", this.state.data);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async componentDidMount() {
    //console.log("SubmicomponentDidMounttted");

    await this.populateCustomization();
  }

  // async componentWillReceiveProps(nextProps) {
  //   console.log(
  //     "CustomizationForm, componentWillReceiveProps",
  //     nextProps.customization
  //   );

  //   //
  //   // Babak's Note: 'componentWillReceiveProps' is ran after Redux store is updated, therefore 'this.props' is updated which passes values via param 'nextProps':-
  //   //               'componentWillReceiveProps' alos runs after 'componentDidMount'. 'componentDidMount' will not update 'this.props', we have to wait until
  //   //               we reach 'componentWillReceiveProps'.
  //   if (this.state.data !== nextProps.customization) {
  //     this.setState({
  //       data: await this.mapToViewModel(nextProps.customization)
  //     });
  //   }
  // }

  mapToViewModel(customization) {
    //console.log("mapToViewModel-1", customization);
    return {
      ID: customization.ID,
      A_WebAppTitle: customization.A_WebAppTitle,
      A_BaseNavbarBGC: customization.A_BaseNavbarBGC,
      A_BaseNavbarFGC: customization.A_BaseNavbarFGC,
      A_BaseNavbarHover: customization.A_BaseNavbarHover,
      A_BaseSearchBtnBGC: customization.A_BaseSearchBtnBGC,
      A_BaseSearchBtnFGC: customization.A_BaseSearchBtnFGC,
      A_BaseSearchBtnHover: customization.A_BaseSearchBtnHover,
      B_ClientNavbarActive: customization.B_ClientNavbarActive,
      B_TopNavbarActive: customization.B_TopNavbarActive,
      A_TopNavbarBGC: customization.A_TopNavbarBGC,
      A_TopNavbarFGC: customization.A_TopNavbarFGC,
      A_TopNavbarHover: customization.A_TopNavbarHover,
      A_TopSearchBtnBGC: customization.A_TopSearchBtnBGC,
      A_TopSearchBtnFGC: customization.A_TopSearchBtnFGC,
      A_TopSearchBtnHover: customization.A_TopSearchBtnHover,
      I_LoginBGImage: customization.I_LoginBGImage,
      A_LoginTitle: customization.A_LoginTitle,
      N_AboutUsNo: customization.N_AboutUsNo,
      N_ContactUsNo: customization.N_ContactUsNo,
      B_GoogleMap: customization.B_GoogleMap,
      A_GoogleKey: customization.A_GoogleKey,
      F_Lat: customization.F_Lat,
      F_Lng: customization.F_Lng,
      D_Inserted: customization.D_Inserted,
      A_Inserted_By: customization.A_Inserted_By
    };
  }

  clearImages = () => {
    let obj = this.state.data;
    obj["I_LoginBGImage"] = "";
    this.setState({ data: obj });
  };

  doSubmit = async () => {
    // await saveCustomization(this.state.data);
    // this.props.history.push("/customizations");

    // console.log("CustomizationForm, doSubmit : ", this.state.data);

    try {
      const customizationId = this.props.match.params.id;
      if (customizationId === "new") {
        // console.log("userrrrrrrrrrrrrr  :   ", auth.getCurrentUser().a_name);
        const currentDate = new Date();
        const month = currentDate.getMonth() + 1;
        const currentDateWithoutTime =
          month +
          "/" +
          currentDate.getDate() +
          "/" +
          currentDate.getFullYear() +
          " " +
          currentDate.getHours() +
          ":" +
          currentDate.getMinutes();
        // await this.props.addCustomization(
        //   this.state.data,
        //   auth.getCurrentUser().a_name,
        //   currentDateWithoutTime
        // );
        const response = await saveCustomization(this.state.data);
        this.props.history.goBack();
      } else {
        const currentDate = new Date();
        const month = currentDate.getMonth() + 1;
        const currentDateWithoutTime =
          month +
          "/" +
          currentDate.getDate() +
          "/" +
          currentDate.getFullYear() +
          " " +
          currentDate.getHours() +
          ":" +
          currentDate.getMinutes();
        // await this.props.changeCustomization(
        //   this.state.data,
        //   auth.getCurrentUser().a_name,
        //   currentDateWithoutTime
        // );
        const response = await saveCustomization(this.state.data);
        this.props.history.goBack();
      }
      // await savecustomization(this.state.data);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.A_WebAppTitle = ex.response.data;
        this.setState({ errors });
      }
    }

    // this.props.history.push("/customizationsList");
    //
    // Babak's Note: Use below rather than above:-
    // this.props.history.goBack();
  };

  render() {
    console.log(
      "CustomizationForm render",
      this.props.customization,
      this.state.data
    );
    return (
      <div
        className="container pageTopMargin"
        style={{ marginTop: "160px", marginBottom: "100px" }}
      >
        <h1 style={{ marginBottom: 20 }}>Customization Form</h1>
        <form onSubmit={this.handleSubmit}>
          {/* <form> */}
          {this.renderInput("A_WebAppTitle", "WebApp Title")}

          <div className="line">
            <span>{"Colour codes"}</span>
          </div>
          {this.renderInputColourPickerCust(
            "A_BaseNavbarBGC",
            "Base Navbar BGC"
          )}
          {this.renderInputColourPickerCust(
            "A_BaseNavbarFGC",
            "Base Navbar FGC"
          )}
          {this.renderInputColourPickerCust(
            "A_BaseNavbarHover",
            "Base Navbar Hover"
          )}
          {this.renderInputColourPickerCust(
            "A_BaseSearchBtnBGC",
            "Base Search BGC"
          )}
          {this.renderInputColourPickerCust(
            "A_BaseSearchBtnFGC",
            "Base Search FGC"
          )}
          {this.renderInputColourPickerCust(
            "A_BaseSearchBtnHover",
            "Base Search Hover"
          )}
          {this.renderCheckbox("B_ClientNavbarActive", "Client Navbar Active")}
          {this.renderCheckbox("B_TopNavbarActive", "Top Navbar Active")}
          {this.renderInputColourPickerCust("A_TopNavbarBGC", "Top Navbar BGC")}
          {this.renderInputColourPickerCust("A_TopNavbarFGC", "Top Navbar FGC")}
          {this.renderInputColourPickerCust(
            "A_TopNavbarHover",
            "Top Navbar Hover"
          )}
          {this.renderInputColourPickerCust(
            "A_TopSearchBtnBGC",
            "Top Search BGC"
          )}
          {this.renderInputColourPickerCust(
            "A_TopSearchBtnFGC",
            "Top Search FGC"
          )}

          <div className="line">
            <span>{"Login Background Image"}</span>
          </div>
          {this.renderImageFile(
            "I_LoginBGImage",
            "Upload Login Background Image"
          )}
          {this.state.waitingForFileUpload && (
            <div>
              <span>Uploading image file...</span>
            </div>
          )}
          <img
            style={{ maxWidth: "300px", marginBottom: "10px" }}
            src={this.state.data.I_LoginBGImage}
          />
          {this.renderInputColourPickerCust(
            "A_TopSearchBtnHover",
            "Top Search Hover"
          )}
          {this.renderInput("A_LoginTitle", "Login Title")}

          <div className="line">
            <span>{"About Us & Contact Us Coordinates"}</span>
          </div>
          {this.renderInput("N_AboutUsNo", "About Us Menu Link Number")}
          {this.renderInput("N_ContactUsNo", "ContactUs Menu Link Number")}

          <div className="line">
            <span>{"Google Map Coordinates: www.latlong.net"}</span>
          </div>
          {this.renderCheckbox("B_GoogleMap", "Google Map")}
          {this.renderInput("A_GoogleKey", "Google Key")}
          {this.renderInputGoogleMap("F_Lat", "Latitude")}
          {this.renderInputGoogleMap("F_Lng", "Longitude")}

          <br />
          <br />

          {this.renderButton("Submit")}
        </form>
      </div>
    );
  }
}

// //
// // Babak's Notes: goto tutorial directory '11-Integration with React' file '5- Connecting Components Using react-redux'
// const mapStateToProps = state => ({
//   //   customizations: state.entities.customizations.list.filter(customization => !customization.resolved)
//   customization: state.entities.customizations.list
// });

// const mapDispatchToProps = dispatch => ({
//   loadCustomization: id => dispatch(loadCustomization(id)),
//   addCustomization: (customization, user, currentDate) =>
//     dispatch(addCustomization(customization, user, currentDate)),
//   changeCustomization: (customization, user, currentDate) =>
//     dispatch(changeCustomization(customization, user, currentDate))
// });

// // Container Component
// //   Presentation (Customizations)
// //
// // Babak's Notes: Below I populate 'this.props.customizations' and 'this.props.loadCustomizations()':-
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(CustomizationForm);

export default CustomizationForm;
