import React, { Component } from "react";
import TableHeader from "./tableHeader";
import TableBody from "./tableBody";

//
// Babak's Note: Old version
// const Table = props => {
//   const { columns, sortColumn, onSort, data } = props;

//   return (
//     <table className="table">
//       <TableHeader columns={columns} sortColumn={sortColumn} onSort={onSort} />
//       <TableBody columns={columns} data={data} />
//     </table>
//   );
// };

//
// Babak's Note: Old and better version
const Table = ({ columns, sortColumn, onSort, data }) => {
  return (
    <table className="table">
      <TableHeader columns={columns} sortColumn={sortColumn} onSort={onSort} />
      <TableBody columns={columns} data={data} />
    </table>
  );
};

export default Table;
