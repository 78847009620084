import React, { Component } from "react";
import Radium, { StyleRoot } from "radium";
import RenderHomeBillboardChildsMainKeypadListRight from "./renderHomeBillboardChildsMainKeypadListRight";
//import slideInLeft from "react-animations/lib/slide-in-left";
import { getHomeBillboardChildsMains } from "../../services/homeBillboardChildsMainKeypadList";
import { getBillboardLargesSlides } from "../../services/billboardLargeService";

class HomeBillboardChildsMainKeypadListRight extends Component {
  state = {
    homeBillboardChildsMainKeypadList: [],
    homeBillboardLargesWindows: []
  };

  async componentDidMount() {
    const homeBillboardsID = this.props.homeBillboardsID;

    // window.alert(`renderBillboardBulletPinterChilds 01 : ${homeBillboardsID}`);

    // console.log(
    //   `HomeBillboardBulletPointChilds, componentDidMount 01 : ${homeBillboardID}`
    // );

    const {
      data: homeBillboardChildsMainKeypadList
    } = await getHomeBillboardChildsMains(homeBillboardsID);

    const {
      data: homeBillboardLargesWindows
    } = await getBillboardLargesSlides();

    // console.log(
    //   "componentDidMount BulletPointChild 02 : ",
    //   homeBillboardChilds
    // );

    this.setState({
      homeBillboardChildsMainKeypadList,
      homeBillboardLargesWindows
    });
  }

  render() {
    const identifier = this.props.identifier;
    const sectionStyle = this.props.sectionStyle;
    const sectionStyleMD = this.props.sectionStyleMD;
    const sectionStyleSM = this.props.sectionStyleSM;
    const sectionStyleXS = this.props.sectionStyleXS;
    const homeBillboardsID = this.props.homeBillboardsID;
    const textFC = this.props.textFC;
    const reference = this.props.reference;
    const summary = this.props.summary;
    const description = this.props.description;
    const imageParent = this.props.imageParent;
    const urlLinkParent = this.props.urlLinkParent;

    const {
      homeBillboardChildsMainKeypadList,
      homeBillboardLargesWindows
    } = this.state;

    // const { length: count } = this.state.homeBillboardChildsMainKeypadList;
    // window.alert(`Number of rows : ${count}`);
    // if (count === 0) return <div className="hourGlass"></div>;

    // window.alert(`Number of rows : ${reference}`);

    return (
      <div>
        <StyleRoot
          style={sectionStyle}
          className="d-none d-lg-block HeroMain homeOverflowHidden"
        >
          <div className="KeypadListChildHeroMain-contentTopLG colorlib-about">
            <div className="KeypadListChildHeroMain-contentInnerTopLG">
              <div className="KeypadListChildHeroMain-subTitleTopLG">
                <div className="row">
                  <div>
                    <div
                      className="heading-meta"
                      style={{ marginBottom: "10px" }}
                    >
                      <span
                        className="borderInner"
                        style={{
                          color: `${textFC}`,
                          border: `1px solid ${textFC}`
                        }}
                      >
                        {reference}
                      </span>
                    </div>

                    <h2
                      style={{
                        color: `${textFC}`,
                        border: `1px solid ${textFC}`
                      }}
                      className="colorlib-heading borderInner"
                    >
                      {summary}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="KeypadListChildHeroMain-subTitleMiddleLG">
                <div className="row">
                  <RenderHomeBillboardChildsMainKeypadListRight
                    homeBillboardChildsMainKeypadList={
                      homeBillboardChildsMainKeypadList
                    }
                    homeBillboardLargesWindows={homeBillboardLargesWindows}
                    nameOfClassParentColumn={"col-6"}
                    nameOfClassChildColumn={"col-6"}
                    mediaAttribute={"LG"}
                    parentImageLink={"KeypadListChildLargeImageLG"}
                    parentReferenceLink={
                      "KeypadListChildLargeReferenceLG textTitleNoShadowBlack"
                    }
                    parentSummaryLink={
                      "KeypadListChildLargeSummaryLG textTitleNoShadowBlack"
                    }
                    childImageLink={"KeypadListChildSmallImageLG"}
                    childReferenceLink={
                      "KeypadListChildSmallReferenceLG textTitleNoShadowBlack"
                    }
                    childSummaryLink={
                      "KeypadListChildSmallSummaryLG textTitleNoShadowBlack"
                    }
                    textFC={textFC}
                    description={description}
                  ></RenderHomeBillboardChildsMainKeypadListRight>
                </div>
              </div>
            </div>
          </div>
        </StyleRoot>

        <StyleRoot
          style={sectionStyleMD}
          className="d-none d-md-block d-lg-none HeroMain homeOverflowHidden"
        >
          <div className="KeypadListChildHeroMain-contentTopMD colorlib-about">
            <div className="KeypadListChildHeroMain-contentInnerTopMD">
              <div className="KeypadListChildHeroMain-subTitleTopMD">
                <div className="row">
                  <div>
                    <div
                      className="heading-meta"
                      style={{ marginBottom: "10px" }}
                    >
                      <span
                        className="borderInner"
                        style={{
                          color: `${textFC}`,
                          border: `1px solid ${textFC}`
                        }}
                      >
                        {reference}
                      </span>
                    </div>

                    <h2
                      style={{
                        color: `${textFC}`,
                        border: `1px solid ${textFC}`
                      }}
                      className="colorlib-heading borderInner"
                    >
                      {summary}
                    </h2>
                  </div>
                </div>
              </div>

              <div className="KeypadListChildHeroMain-subTitleMiddleMD">
                <div className="row">
                  <RenderHomeBillboardChildsMainKeypadListRight
                    homeBillboardChildsMainKeypadList={
                      homeBillboardChildsMainKeypadList
                    }
                    homeBillboardLargesWindows={homeBillboardLargesWindows}
                    nameOfClassParentColumn={"col-6"}
                    nameOfClassChildColumn={"col-6"}
                    mediaAttribute={"MD"}
                    parentImageLink={"KeypadListChildLargeImageMD"}
                    parentReferenceLink={
                      "KeypadListChildLargeReferenceMD textTitleNoShadowBlack"
                    }
                    parentSummaryLink={
                      "KeypadListChildLargeSummaryMD textTitleNoShadowBlack"
                    }
                    childImageLink={"KeypadListChildSmallImageMD"}
                    childReferenceLink={
                      "KeypadListChildSmallReferenceMD textTitleNoShadowBlack"
                    }
                    childSummaryLink={
                      "KeypadListChildSmallSummaryMD textTitleNoShadowBlack"
                    }
                    textFC={textFC}
                    description={description}
                  ></RenderHomeBillboardChildsMainKeypadListRight>
                  {/* <div className="col-md-9">
                    <div>top row</div>
                  </div>
                  <div className="col-md-9">
                    <div className="col-6" style={{ float: "left" }}>
                      top row2
                    </div>
                    <div className="col-6" style={{ float: "left" }}>
                      bottom row2
                    </div>
                    <div className="col-6" style={{ float: "left" }}>
                      top row3
                    </div>
                    <div className="col-6" style={{ float: "left" }}>
                      bottom row3
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </StyleRoot>

        <StyleRoot
          style={sectionStyleSM}
          className="d-sm-block d-md-none d-none HeroMain homeOverflowHidden"
        >
          <div className="KeypadListChildHeroMain-contentTopSM colorlib-about">
            <div className="KeypadListChildHeroMain-contentInnerTopSM">
              <div className="KeypadListChildHeroMain-subTitleTopSM">
                <div className="row">
                  <div>
                    <div
                      className="heading-meta"
                      style={{ marginBottom: "10px" }}
                    >
                      <span
                        className="borderInner"
                        style={{
                          color: `${textFC}`,
                          border: `1px solid ${textFC}`
                        }}
                      >
                        {reference}
                      </span>
                    </div>

                    <h2
                      style={{
                        color: `${textFC}`,
                        border: `1px solid ${textFC}`
                      }}
                      className="colorlib-heading borderInner"
                    >
                      {summary}
                    </h2>
                  </div>
                </div>
              </div>

              <div
                style={{
                  marginTop: "-25px"
                }}
                className="KeypadListChildHeroMain-subTitleMiddleSM"
              >
                <div className="row">
                  <RenderHomeBillboardChildsMainKeypadListRight
                    homeBillboardChildsMainKeypadList={
                      homeBillboardChildsMainKeypadList
                    }
                    homeBillboardLargesWindows={homeBillboardLargesWindows}
                    nameOfClassParentColumn={"col-md-12"}
                    nameOfClassChildColumn={"col-6"}
                    mediaAttribute={"SM"}
                    parentImageLink={"KeypadListChildLargeImageSM"}
                    parentReferenceLink={
                      "KeypadListChildLargeReferenceSM textTitleNoShadowBlack"
                    }
                    parentSummaryLink={
                      "KeypadListChildLargeSummarySM textTitleNoShadowBlack"
                    }
                    childImageLink={"KeypadListChildSmallImageSM"}
                    childReferenceLink={
                      "KeypadListChildSmallReferenceSM textTitleNoShadowBlack"
                    }
                    childSummaryLink={
                      "KeypadListChildSmallSummarySM textTitleNoShadowBlack"
                    }
                    textFC={textFC}
                    description={description}
                  ></RenderHomeBillboardChildsMainKeypadListRight>
                </div>
              </div>
            </div>
          </div>
        </StyleRoot>

        <StyleRoot
          style={sectionStyleXS}
          className="d-xs-block d-sm-none d-md-none HeroMain homeOverflowHidden"
        >
          <div className="KeypadListChildHeroMain-contentTopXS colorlib-about">
            <div className="KeypadListChildHeroMain-contentInnerTopXS">
              <div className="KeypadListChildHeroMain-subTitleTopXS">
                <div className="row">
                  <div>
                    <div
                      className="heading-meta"
                      style={{ marginBottom: "10px" }}
                    >
                      <span
                        className="borderInner"
                        style={{
                          color: `${textFC}`,
                          border: `1px solid ${textFC}`
                        }}
                      >
                        {reference}
                      </span>
                    </div>

                    <h2
                      style={{
                        color: `${textFC}`,
                        border: `1px solid ${textFC}`
                      }}
                      className="colorlib-heading borderInner"
                    >
                      {summary}
                    </h2>
                  </div>
                </div>
              </div>

              <div
                style={{
                  marginTop: "-35px"
                }}
                className="KeypadListChildHeroMain-subTitleMiddleXS"
              >
                <div className="row">
                  <RenderHomeBillboardChildsMainKeypadListRight
                    homeBillboardChildsMainKeypadList={
                      homeBillboardChildsMainKeypadList
                    }
                    homeBillboardLargesWindows={homeBillboardLargesWindows}
                    nameOfClassParentColumn={"col-md-12"}
                    nameOfClassChildColumn={"col-6"}
                    mediaAttribute={"XS"}
                    parentImageLink={"KeypadListChildLargeImageXS"}
                    parentReferenceLink={
                      "KeypadListChildLargeReferenceXS textTitleNoShadowBlack"
                    }
                    parentSummaryLink={
                      "KeypadListChildLargeSummaryXS textTitleNoShadowBlack"
                    }
                    childImageLink={"KeypadListChildSmallImageXS"}
                    childReferenceLink={
                      "KeypadListChildSmallReferenceXS textTitleNoShadowBlack"
                    }
                    childSummaryLink={
                      "KeypadListChildSmallSummaryXS textTitleNoShadowBlack"
                    }
                    textFC={textFC}
                    description={description}
                  ></RenderHomeBillboardChildsMainKeypadListRight>
                </div>
              </div>
            </div>
          </div>
        </StyleRoot>
      </div>
    );
  }
}

export default HomeBillboardChildsMainKeypadListRight;
