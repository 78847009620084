import React from "react";

const RadioGroup = ({
  name,
  columnName,
  value,
  label,
  options,
  onChange,
  disableRadio,
  error,
  ...rest
}) => {
  return (
    <div>
      <label>{label}</label>
      {options.map(option => (
        <div className="radio" key={option.ID}>
          <label
            key={option.ID}
            className={
              disableRadio === 99
                ? "clickableNon"
                : disableRadio >= option.ID
                ? "clickableNon"
                : "clickable"
            }
          >
            <input
              type="radio"
              disabled={
                disableRadio === 99
                  ? true
                  : disableRadio >= option.ID
                  ? true
                  : false
              }
              className={
                disableRadio === 99
                  ? "clickableNon"
                  : disableRadio >= option.ID
                  ? "clickableNon"
                  : "clickable"
              }
              style={{ marginRight: 6 }}
              key={option.ID}
              value={option.ID}
              checked={value === option.ID}
              onChange={onChange}
            />
            {option[columnName]}
          </label>
        </div>
      ))}
    </div>
  );
};

export default RadioGroup;
