import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
import moment from "moment";

const slice = createSlice({
  name: "menus",
  initialState: {
    list: [],
    listSingleRow: [],
    listOnNavvar: [],
    loading: false,
    lastFetch: null
  },
  reducers: {
    //
    // Babak's Note: By adding 'menusRequested' or 'menusReceived' below, a action is created for us:-
    // actions => action handlers. The 'menus' below represents the 'state':-
    menusRequested: (menus, action) => {
      console.log("menusRequested : ", action.payload);
      menus.loading = true;
    },
    menusReceived: (menus, action) => {
      console.log("menusReceived : ", action.payload);
      menus.list = action.payload;
      menus.loading = false;
      menus.lastFetch = Date.now();
    },
    menusRequestedFailed: (menus, action) => {
      menus.loading = false;
      //   window.alert(`No Menus found.`);
    },

    menusOnNavbarRequested: (menus, action) => {
      console.log("menusOnNavbarRequested : ", action.payload);
      menus.loading = true;
    },
    menusOnNavbarReceived: (menus, action) => {
      console.log("menusOnNavbarReceived : ", action.payload);
      menus.listOnNavvar = action.payload;
      menus.loading = false;
      menus.lastFetch = Date.now();
    },
    menusOnNavbarRequestedFailed: (menus, action) => {
      menus.loading = false;
      //   window.alert(`No Menus found.`);
    },

    menuRequested: (menu, action) => {
      console.log("menuRequested : ", action.payload);
      menu.loading = true;
    },
    menuReceived: (menu, action) => {
      console.log("menuReceived : ", action.payload);
      menu.listSingleRow = action.payload;
      menu.loading = false;
      menu.lastFetch = Date.now();
    },
    menuRequestedFailed: (menu, action) => {
      menu.loading = false;
      //   window.alert(`No Menu found.`);
    },
    menuChangeRequested: (menu, action) => {
      console.log("menuChangeRequested : ", action.payload);
      menu.loading = true;
    },
    menuChangeFailed: (menu, action) => {
      menu.loading = false;

      // //
      // // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete menu api at the back end.
      // const { ID: menuId, A_Name: menuName } = action.payload;

      // window.alert(`Menu '${menuName}' no longer exists.`);

      // const index = menu.list.findIndex(menu => menu.ID === menuId);
      // //console.log("menuDeleted ha ", index);
      // menu.list.splice(index, 1);
    },
    menuChanged: (menu, action) => {
      menu.loading = false;
    },
    menuDeleteRequested: (menu, action) => {
      console.log("menuDeleteRequested : ", action.payload);
      menu.loading = true;
    },
    menuDeleteFailed: (menus, action) => {
      menus.loading = false;

      //
      // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete menu api at the back end.
      const { ID: menuId, A_Name: menuName } = action.payload;

      window.alert(
        `An issue has occured with menu '${menuName}'. ${action.payload}`
      );

      const index = menus.list.findIndex(menu => menu.ID === menuId);
      //console.log("menuDeleted ha ", index);
      menus.list.splice(index, 1);
    },
    menuDeleted: (menus, action) => {
      //
      // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete menu api at the back end.
      const { ID: menuID } = action.payload;
      // console.log("menuDeleted ha ", action.payload, menuID, menus);
      const index = menus.list.findIndex(menu => menu.ID === menuID);
      //console.log("menuDeleted, ha! ha! ", index);
      menus.list.splice(index, 1);
    },
    menuAddRequested: (menu, action) => {
      console.log("menuAddRequested : ", action.payload);
      menu.loading = true;
    },
    menuAddFailed: (menu, action) => {
      menu.loading = false;
    },
    //
    // Babak's Note: The 'menus' below represents the 'state':-
    menuAdded: (menus, action) => {
      console.log("menuAdded : ", action.payload);
      menus.list.push(action.payload);
    }
    // menuAdded: (menus, action) => {
    //   menus.list.push({
    //     id: ++lastId,
    //     description: action.payload.description,
    //     resolved: false
    //   });
    // },
    // menuDeleted: (menus, action) => {
    //   console.log("menuDeleted ha ", action.payload.ID);
    //   menus.list.filter(menu => menu.ID !== action.payload.ID);
    // },
  }
});
const {
  menuAddRequested,
  menuAdded,
  menuAddFailed,
  menuChangeRequested,
  menuChanged,
  menuChangeFailed,
  menuDeleteRequested,
  menuDeleted,
  menuDeleteFailed,
  menusReceived,
  menusRequested,
  menusRequestedFailed,
  menusOnNavbarReceived,
  menusOnNavbarRequested,
  menusOnNavbarRequestedFailed,
  menuReceived,
  menuRequested,
  menuRequestedFailed
} = slice.actions;
export default slice.reducer;

//
// Action Creators
const url = "/menus";

export const loadMenus = billLocationGroupID => (dispatch, getState) => {
  // const { lastFetch } = getState().entities.menus;

  // console.log("loadMenus lastFetch : ", lastFetch);

  // //
  // // Babak's Note: Below we check with 'moment' that catch that if this request was already fetched less than 10 minutes ago then do not fetch again:-
  // const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  // if (diffInMinutes < 10) return;

  dispatch(
    apiCallBegan({
      url: url + "/menusMaintaince" + "/" + billLocationGroupID,
      onStart: menusRequested.type,
      method: "get",
      onSuccess: menusReceived.type,
      onError: menusRequestedFailed.type
    })
  );
};

export const loadMenusOnNavbar = billLocationGroupID => (
  dispatch,
  getState
) => {
  // const { lastFetch } = getState().entities.menus;

  // console.log("loadMenus lastFetch : ", lastFetch);

  // //
  // // Babak's Note: Below we check with 'moment' that catch that if this request was already fetched less than 10 minutes ago then do not fetch again:-
  // const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  // if (diffInMinutes < 10) return;

  dispatch(
    apiCallBegan({
      url: url + "/menus" + "/" + billLocationGroupID,
      onStart: menusOnNavbarRequested.type,
      method: "get",
      onSuccess: menusOnNavbarReceived.type,
      onError: menusOnNavbarRequestedFailed.type
    })
  );
};

export const loadMenu = menuID => (dispatch, getState) => {
  // const { lastFetch } = getState().entities.menus;

  //console.log("loadMenu : ", menuID);

  // //
  // // Babak's Note: Below we check with 'moment' that catch that if this request was already fetched less than 10 minutes ago then do not fetch again:-
  // const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  // if (diffInMinutes < 10) return;

  dispatch(
    apiCallBegan({
      url: url + "/" + menuID,
      onStart: menuRequested.type,
      method: "get",
      onSuccess: menuReceived.type,
      onError: menuRequestedFailed.type
    })
  );
};

//
// Babak's Note: Below function 'apiCallBegan' is returned back by which function (in this case from within 'index.js') calls 'addMenu':-
export const addMenu = (menu, user, currentDate) =>
  apiCallBegan({
    url,
    method: "post",
    onStart: menuAddRequested.type,
    //data: menu,
    data: {
      B_Billboard: menu.B_Billboard,
      N_BillboardOrigin_ID: menu.N_BillboardOrigin_ID,
      N_HomeLocationGroup_ID: menu.N_HomeLocationGroup_ID,
      N_BillboardMenuBG_ID: menu.N_BillboardMenuBG_ID,
      N_Order_No: menu.N_Order_No,
      A_Reference: menu.A_Reference,
      A_Summary: menu.A_Summary,
      N_ParentOrigin_ID: menu.N_ParentOrigin_ID,
      I_Image: menu.I_Image,
      A_MenuCursorColour: menu.A_MenuCursorColour,
      D_Inserted: currentDate,
      A_Inserted_By: user
    },
    onSuccess: menuAdded.type,
    onError: menuAddFailed.type
  });

export const getMenus = createSelector(
  state => state.entities.menus,
  //
  // Babak's Note: In the below line, by installing above 'reselect' (at the top of this file) if the list of (menus, projects) has not changed then do not requery again:-
  // (menus) => menus.list.filter(menu => !menu.B_Active)
  menus => menus.list
);

export const changeMenu = (menu, user, currentDate) =>
  apiCallBegan({
    url: url + "/" + menu.ID,
    method: "put",
    onStart: menuChangeRequested.type,
    data: {
      B_Billboard: menu.B_Billboard,
      N_BillboardOrigin_ID: menu.N_BillboardOrigin_ID,
      N_HomeLocationGroup_ID: menu.N_HomeLocationGroup_ID,
      N_BillboardMenuBG_ID: menu.N_BillboardMenuBG_ID,
      N_Order_No: menu.N_Order_No,
      A_Reference: menu.A_Reference,
      A_Summary: menu.A_Summary,
      N_ParentOrigin_ID: menu.N_ParentOrigin_ID,
      I_Image: menu.I_Image,
      A_MenuCursorColour: menu.A_MenuCursorColour,
      D_Updated: currentDate,
      A_Updated_By: user
    },
    onSuccess: menuChanged.type,
    onError: menuChangeFailed.type
  });

export const deleteMenu = menu =>
  apiCallBegan({
    url: url + "/" + menu.ID,
    method: "delete",
    onStart: menuDeleteRequested.type,
    data: { ID: menu.ID, A_Reference: menu.A_Reference },
    onSuccess: menuDeleted.type,
    onError: menuDeleteFailed.type
  });
