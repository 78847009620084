import React, { Component } from "react";
import auth from "../../services/authService";
import Table from "../common/table";

class GroupsTable extends Component {
  columns = [];

  async componentDidMount() {
    //console.log("componentDidMount GroupsTable 01 : ");
  }

  sumOfArticleColumn = {
    key: "tree",
    type: "text",
    content: group =>
      auth.getCurrentUser().t_role_a_name === "Admin" ? (
        <button
          onClick={() => this.props.onSumOfArticle(group)}
          className={
            group.N_BillboardOrigin_ID === 2
              ? group.N_BB_Count > 0
                ? "btn btn-warning btn-sm pull-right enabled"
                : "btn btn-secondary btn-sm pull-right enabled"
              : group.N_BillboardOrigin_ID === 3
              ? group.N_BB_Count > 0
                ? "btn btn-warning btn-sm pull-right enabled"
                : "btn btn-secondary btn-sm pull-right enabled"
              : "btn btn-secondary btn-sm pull-right enabled"
          }
        >
          {group.N_BillboardOrigin_ID === 2
            ? "Sum of Article Level"
            : group.N_BillboardOrigin_ID === 3
            ? "Article Level"
            : "-"}
        </button>
      ) : (
        <button
          className={
            group.N_BillboardOrigin_ID === 2
              ? group.N_BB_Count > 0
                ? "btn btn-warning btn-sm pull-right enabled"
                : "btn btn-secondary btn-sm pull-right enabled"
              : group.N_BillboardOrigin_ID === 3
              ? group.N_BB_Count > 0
                ? "btn btn-warning btn-sm pull-right enabled"
                : "btn btn-secondary btn-sm pull-right enabled"
              : "btn btn-secondary btn-sm pull-right enabled"
          }
        >
          {group.N_BillboardOrigin_ID === 2
            ? "Sum of Article Level"
            : group.N_BillboardOrigin_ID === 3
            ? "Article Level"
            : "-"}
        </button>
      )
  };

  updateColumn = {
    key: "update",
    type: "text",
    content: group =>
      auth.getCurrentUser().t_role_a_name === "Admin" ? (
        <button
          onClick={() => this.props.onUpdate(group)}
          className="btn btn-info btn-sm pull-right enabled"
        >
          Update
        </button>
      ) : (
        <button className="btn btn-info btn-sm pull-right disabled clickableNon">
          Update
        </button>
      )
  };

  deleteColumn = {
    key: "delete",
    type: "text",
    content: group =>
      auth.getCurrentUser().t_role_a_name === "Admin" ? (
        <button
          onClick={() => {
            if (group.N_BB_Count < 1) {
              if (
                window.confirm(
                  `Are you sure you want to delete Group Article '${group.A_Reference}'?`
                )
              ) {
                this.props.onDelete(group);
              }
            }
          }}
          className={
            group.N_BB_Count > 0
              ? "btn btn-danger btn-sm disabled clickableNon"
              : "btn btn-danger btn-sm enabled"
          }
        >
          Delete
        </button>
      ) : (
        <button className="btn btn-danger btn-sm disabled clickableNon">
          Delete
        </button>
      )
  };

  constructor() {
    super();
    const groups = [{ ID: 1 }];

    groups.map(
      item => (
        this.columns.push({
          path: "ID",
          label: "Identifier",
          hidden: true,
          type: "text"
        }),
        this.columns.push({
          path: "B_Billboard",
          label: "Billboard",
          hidden: true,
          type: "text"
        }),
        this.columns.push({
          path: "N_BillboardOrigin_ID",
          label: "BillboardOriginIdentifier",
          hidden: true,
          type: "text"
        }),
        this.columns.push({
          path: "N_HomeLocationGroup_ID",
          label: "HomeLocationGroupIdentifier",
          hidden: true,
          type: "text"
        }),
        this.columns.push({
          path: "N_Order_No",
          label: "Order",
          type: "text"
        }),
        this.columns.push({
          path: "A_Reference",
          label: "Reference",
          type: "text"
        }),
        // this.columns.push({
        //   path: "A_Summary",
        //   label: "Summary",
        //   type: "text"
        // }),
        // this.columns.push({
        //   path: "A_Description",
        //   label: "Description",
        //   type: "text"
        // }),
        this.columns.push({ path: "I_Image", label: "Image", type: "image" }),
        this.columns.push({
          path: "N_BillboardLarge_ID",
          label: "BillboardLargeIdentifier",
          hidden: true,
          type: "text"
        }),
        this.columns.push({
          path: "D_Inserted",
          label: "Inserted",
          type: "date"
        }),
        this.columns.push({
          path: "A_Inserted_By",
          label: "Inserted_By",
          type: "text"
        }),
        this.columns.push({
          path: "D_Updated",
          label: "Updated",
          type: "date"
        }),
        this.columns.push({
          path: "A_Updated_By",
          label: "Updated_By",
          type: "text"
        })
      )
    );

    this.columns.push(this.sumOfArticleColumn);
    this.columns.push(this.updateColumn);
    this.columns.push(this.deleteColumn);

    //const user = auth.getCurrentUser();
  }

  render() {
    //console.log("render usersTable");
    const { groupsList, onSort, sortColumn } = this.props;
    return (
      <Table
        columns={this.columns}
        data={groupsList}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default GroupsTable;
