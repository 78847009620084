import React, { Component, Suspense } from "react";
import { loadHomeBillboardsMains } from "../../store/homeBillboardsMains";
import HomeBillboardChildsMain from "./homeBillboardChildsMain";
import HomeBillboardChildsMainAboutUs from "./homeBillboardChildsMainAboutUs";
import HomeBillboardChildsMainContactUs from "./homeBillboardChildsMainContactUs";
import HomeBillboardChildsMainKeypad from "./homeBillboardChildsMainKeypad";
import HomeBillboardChildsMainKeypadListRight from "./homeBillboardChildsMainKeypadListRight";
import HomeBillboardChildsMainKeypadListLeft from "./homeBillboardChildsMainKeypadListLeft";
import HomeBillboardChildsMainVerticalImages from "./homeBillboardChildsMainVerticalImages";
import HomeBillboardChildsMainImageOnStrip from "./homeBillboardChildsMainImageOnStrip";
import { NavLink, withRouter, Route } from "react-router-dom";
import CarouselSlide from "../home/carouselSlide";
import NavBarFooter from "../navBarFooter";
import { connect } from "react-redux";
import LazyLoad from "react-lazy-load";
import {
  fadeIn,
  fadeInUp,
  bounce,
  flipInX,
  flipInY,
  rubberBand,
  shake,
  rotateInUpRight,
  rotateInUpLeft,
  slideInUp
} from "react-animations";
import Radium, { StyleRoot } from "radium";
import ScrollUpButton from "react-scroll-up-button";

class HomeBillboardsMain extends Component {
  state = {
    data: {
      A_Reference: "",
      A_Summary: "",
      I_Image: ""
    }
  };

  async populateHomeBillboardsMains() {
    // window.alert(`componentDidMount (DetailsGroup) : ${menuId}`);

    await this.props.loadHomeBillboardsMains();
  }

  async componentDidMount() {
    await this.populateHomeBillboardsMains();
  }

  //   async componentWillReceiveProps(nextProps) {
  //     // window.alert("MenuForm, componentWillReceiveProps", nextProps.menuSuccess);

  //     let data = { ...this.state.data };

  //     // if (this.state.data !== nextProps.menu) {

  //     data["A_Reference"] = nextProps.menus.A_Reference;
  //     data["A_Summary"] = nextProps.menus.A_Summary;
  //     data["I_Image"] = nextProps.menus.I_Image;

  //     this.setState({
  //       data
  //     });
  //     // }
  //   }

  //   async mapToViewModel(billboardLarge) {
  //     //console.log("mapToViewModel-1", billboardLarge);
  //     return {
  //       A_Reference: billboardLarge.A_Reference,
  //       A_Summary: billboardLarge.A_Summary,
  //       I_Image: billboardLarge.I_Image
  //     };
  //   }

  renderBillboardData = ({ item }) => {
    // if (item.N_RadioGrpDirections_ID === 10) window.alert("Hello : ");

    // console.log("renderBillboardData 01 : ", item, rowCount);

    let sectionStyleTop;
    let sectionStyle, sectionStyleMD, sectionStyleSM, sectionStyleXS;
    let sectionStyleBottom;

    const styles = {
      bounce: {
        animation: "y 3s",
        animationName: Radium.keyframes(bounce, "bounce")
      },
      fadeIn: {
        animation: "y 8s",
        animationName: Radium.keyframes(fadeIn, "fadeIn")
      },
      fadeInUp01: {
        animation: "y 1s",
        animationName: Radium.keyframes(fadeInUp, "fadeInUp01")
      },
      fadeInUp02: {
        animation: "y 2s",
        animationName: Radium.keyframes(fadeInUp, "fadeInUp02")
      },
      fadeInUp03: {
        animation: "y 3s",
        animationName: Radium.keyframes(fadeInUp, "fadeInUp03")
      },
      fadeInUp04: {
        animation: "y 4s",
        animationName: Radium.keyframes(fadeInUp, "fadeInUp04")
      },
      flipInX: {
        animation: "y 3s",
        animationName: Radium.keyframes(flipInX, "flipInX")
      },
      flipInY: {
        animation: "y 3s",
        animationName: Radium.keyframes(flipInY, "flipInY")
      },
      rubberBand: {
        animation: "y 3s",
        animationName: Radium.keyframes(rubberBand, "rubberBand")
      },
      shake: {
        animation: "y 3s",
        animationName: Radium.keyframes(shake, "shake")
      },
      rotateInUpRight: {
        animation: "y 3s",
        animationName: Radium.keyframes(rotateInUpRight, "rotateInUpRight")
      },
      rotateInUpLeft: {
        animation: "y 3s",
        animationName: Radium.keyframes(rotateInUpLeft, "rotateInUpLeft")
      },
      slideInUp01: {
        animation: "y 3s",
        animationName: Radium.keyframes(slideInUp, fadeIn, "slideInUp01")
      },
      slideInUp02: {
        animation: "y 5s",
        animationName: Radium.keyframes(slideInUp, "slideInUp03")
      },
      slideInUp03: {
        animation: "y 8s",
        animationName: Radium.keyframes(slideInUp, "slideInUp03")
      }
    };

    const customizationSingleRow = this.props.customizationSingleRow;

    // document.body.style.backgroundImage = `url(${item.I_Image})`;
    // document.body.style.backgroundRepeat = "no-repeat";
    // document.body.style.backgroundSize = "cover";

    if (item.N_RadioGrpDirections_ID === 1) {
      //
      // Babak's note: Below configures if we are currently on IOS (IPhone):
      const is_ios = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);

      sectionStyle = {
        height: "100vh",
        background: !item.B_BGC_Shadow_Off
          ? `${item.A_BGC}`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), ${item.A_BGC}`,

        // background: `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), orange`,

        backgroundImage: !item.B_BGC_Shadow_Off
          ? `url(${item.I_Image})`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,

        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        backgroundPosition: "center center",
        backgroundAttachment:
          is_ios || item.B_BGC_Shadow_Off ? "scroll" : "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      sectionStyleMD = {
        height: "100vh",

        background: !item.B_BGC_Shadow_Off
          ? `${item.A_BGC}`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), ${item.A_BGC}`,

        // background: `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), orange`,

        backgroundImage: !item.B_BGC_Shadow_Off
          ? `url(${item.I_Image})`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,

        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        backgroundPosition: "center center",
        backgroundAttachment:
          is_ios || item.B_BGC_Shadow_Off ? "scroll" : "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      sectionStyleSM = {
        height: "100vh",

        background: !item.B_BGC_Shadow_Off
          ? `${item.A_BGC}`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), ${item.A_BGC}`,

        // background: `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), orange`,

        backgroundImage: !item.B_BGC_Shadow_Off
          ? `url(${item.I_Image})`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,

        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        backgroundPosition: "center center",
        backgroundAttachment:
          is_ios || item.B_BGC_Shadow_Off ? "scroll" : "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      sectionStyleXS = {
        height: "100vh",

        background: !item.B_BGC_Shadow_Off
          ? `${item.A_BGC}`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), ${item.A_BGC}`,

        // background: `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), orange`,

        backgroundImage: !item.B_BGC_Shadow_Off
          ? `url(${item.I_Image})`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,

        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        backgroundPosition: "center center",
        backgroundAttachment:
          is_ios || item.B_BGC_Shadow_Off ? "scroll" : "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      // sectionStyle = {
      //   height: "100vh",
      //   backgroundImage: `url(${item.I_Image})`,
      //   backgroundPosition: "center center",
      //   backgroundAttachment: "fixed",
      //   backgroundRepeat: "no-repeat",
      //   backgroundSize: "cover"
      // };
    } else if (item.N_RadioGrpDirections_ID === 2) {
      //
      // Babak's note: Below configures if we are currently on IOS (IPhone):
      const is_ios = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);

      // sectionStyleTop = {
      //   height: "30vh",
      //   color: "white",
      //   backgroundPosition: "center center",
      //   backgroundRepeat: "no-repeat",
      //   backgroundSize: "cover"
      // };
      sectionStyle = {
        height: "440px",

        background: !item.B_BGC_Shadow_Off
          ? `${item.A_BGC}`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), ${item.A_BGC}`,

        // background: `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), orange`,

        backgroundImage: !item.B_BGC_Shadow_Off
          ? `url(${item.I_Image})`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,

        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        backgroundPosition: "center center",
        backgroundAttachment:
          is_ios || item.B_BGC_Shadow_Off ? "scroll" : "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      sectionStyleMD = {
        height: "425px",

        background: !item.B_BGC_Shadow_Off
          ? `${item.A_BGC}`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), ${item.A_BGC}`,

        // background: `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), orange`,

        backgroundImage: !item.B_BGC_Shadow_Off
          ? `url(${item.I_Image})`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,

        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        backgroundPosition: "center center",
        backgroundAttachment:
          is_ios || item.B_BGC_Shadow_Off ? "scroll" : "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      sectionStyleSM = {
        height: "405px",

        background: !item.B_BGC_Shadow_Off
          ? `${item.A_BGC}`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), ${item.A_BGC}`,

        // background: `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), orange`,

        backgroundImage: !item.B_BGC_Shadow_Off
          ? `url(${item.I_Image})`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,

        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        backgroundPosition: "center center",
        backgroundAttachment:
          is_ios || item.B_BGC_Shadow_Off ? "scroll" : "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      sectionStyleXS = {
        height: "605px",

        background: !item.B_BGC_Shadow_Off
          ? `${item.A_BGC}`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), ${item.A_BGC}`,

        // background: `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), orange`,

        backgroundImage: !item.B_BGC_Shadow_Off
          ? `url(${item.I_Image})`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,

        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        backgroundPosition: "center center",
        backgroundAttachment:
          is_ios || item.B_BGC_Shadow_Off ? "scroll" : "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      // sectionStyleBottom = {
      //   height: "30vh",
      //   color: "white",
      //   backgroundPosition: "center center",
      //   backgroundRepeat: "no-repeat",
      //   backgroundSize: "cover"
      // };
    } else if (item.N_RadioGrpDirections_ID === 3) {
      //
      // Babak's note: Below configures if we are currently on IOS (IPhone):
      const is_ios = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);

      // sectionStyleTop = {
      //   height: "30vh",
      //   color: "white",
      //   backgroundPosition: "center center",
      //   backgroundRepeat: "no-repeat",
      //   backgroundSize: "cover"
      // };
      sectionStyle = {
        height: "1010px",

        background: !item.B_BGC_Shadow_Off
          ? `${item.A_BGC}`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), ${item.A_BGC}`,

        // background: `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), orange`,

        backgroundImage: !item.B_BGC_Shadow_Off
          ? `url(${item.I_Image})`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,

        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        backgroundPosition: "center center",
        backgroundAttachment:
          is_ios || item.B_BGC_Shadow_Off ? "scroll" : "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      sectionStyleMD = {
        height: "970px",

        background: !item.B_BGC_Shadow_Off
          ? `${item.A_BGC}`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), ${item.A_BGC}`,

        // background: `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), orange`,

        backgroundImage: !item.B_BGC_Shadow_Off
          ? `url(${item.I_Image})`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,

        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        backgroundPosition: "center center",
        backgroundAttachment:
          is_ios || item.B_BGC_Shadow_Off ? "scroll" : "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      sectionStyleSM = {
        height: "870px",

        background: !item.B_BGC_Shadow_Off
          ? `${item.A_BGC}`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), ${item.A_BGC}`,

        // background: `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), orange`,

        backgroundImage: !item.B_BGC_Shadow_Off
          ? `url(${item.I_Image})`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,

        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        backgroundPosition: "center center",
        backgroundAttachment:
          is_ios || item.B_BGC_Shadow_Off ? "scroll" : "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      sectionStyleXS = {
        height: "870px",

        background: !item.B_BGC_Shadow_Off
          ? `${item.A_BGC}`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), ${item.A_BGC}`,

        // background: `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), orange`,

        backgroundImage: !item.B_BGC_Shadow_Off
          ? `url(${item.I_Image})`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,

        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        backgroundPosition: "center center",
        backgroundAttachment:
          is_ios || item.B_BGC_Shadow_Off ? "scroll" : "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      // sectionStyleBottom = {
      //   height: "30vh",
      //   color: "white",
      //   backgroundPosition: "center center",
      //   backgroundRepeat: "no-repeat",
      //   backgroundSize: "cover"
      // };
    } else if (item.N_RadioGrpDirections_ID === 4) {
      //
      // Babak's note: Below configures if we are currently on IOS (IPhone):
      const is_ios = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);

      sectionStyle = {
        height: "760px",

        background: !item.B_BGC_Shadow_Off
          ? `${item.A_BGC}`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), ${item.A_BGC}`,

        // background: `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), orange`,

        backgroundImage: !item.B_BGC_Shadow_Off
          ? `url(${item.I_Image})`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,

        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        backgroundPosition: "center center",
        backgroundAttachment:
          is_ios || item.B_BGC_Shadow_Off ? "scroll" : "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      sectionStyleMD = {
        height: "560px",

        background: !item.B_BGC_Shadow_Off
          ? `${item.A_BGC}`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), ${item.A_BGC}`,

        // background: `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), orange`,

        backgroundImage: !item.B_BGC_Shadow_Off
          ? `url(${item.I_Image})`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,

        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        backgroundPosition: "center center",
        backgroundAttachment:
          is_ios || item.B_BGC_Shadow_Off ? "scroll" : "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      sectionStyleSM = {
        height: "565px",

        background: !item.B_BGC_Shadow_Off
          ? `${item.A_BGC}`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), ${item.A_BGC}`,

        // background: `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), orange`,

        backgroundImage: !item.B_BGC_Shadow_Off
          ? `url(${item.I_Image})`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,

        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        backgroundPosition: "center center",
        backgroundAttachment:
          is_ios || item.B_BGC_Shadow_Off ? "scroll" : "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      sectionStyleXS = {
        height: "500px",

        background: !item.B_BGC_Shadow_Off
          ? `${item.A_BGC}`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), ${item.A_BGC}`,

        // background: `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), orange`,

        backgroundImage: !item.B_BGC_Shadow_Off
          ? `url(${item.I_Image})`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,

        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        backgroundPosition: "center center",
        backgroundAttachment:
          is_ios || item.B_BGC_Shadow_Off ? "scroll" : "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
    } else if (item.N_RadioGrpDirections_ID === 5) {
      //
      // Babak's note: Below configures if we are currently on IOS (IPhone):
      const is_ios = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);

      // sectionStyleTop = {
      //   height: "30vh",
      //   color: "white",
      //   backgroundPosition: "center center",
      //   backgroundRepeat: "no-repeat",
      //   backgroundSize: "cover"
      // };
      sectionStyle = {
        height: "510px",

        background: !item.B_BGC_Shadow_Off
          ? `${item.A_BGC}`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), ${item.A_BGC}`,

        // background: `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), orange`,

        backgroundImage: !item.B_BGC_Shadow_Off
          ? `url(${item.I_Image})`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,

        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        backgroundPosition: "center center",
        backgroundAttachment:
          is_ios || item.B_BGC_Shadow_Off ? "scroll" : "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      sectionStyleMD = {
        height: "870px",

        background: !item.B_BGC_Shadow_Off
          ? `${item.A_BGC}`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), ${item.A_BGC}`,

        // background: `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), orange`,

        backgroundImage: !item.B_BGC_Shadow_Off
          ? `url(${item.I_Image})`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,

        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        backgroundPosition: "center center",
        backgroundAttachment:
          is_ios || item.B_BGC_Shadow_Off ? "scroll" : "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      sectionStyleSM = {
        height: "870px",

        background: !item.B_BGC_Shadow_Off
          ? `${item.A_BGC}`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), ${item.A_BGC}`,

        // background: `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), orange`,

        backgroundImage: !item.B_BGC_Shadow_Off
          ? `url(${item.I_Image})`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,

        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        backgroundPosition: "center center",
        backgroundAttachment:
          is_ios || item.B_BGC_Shadow_Off ? "scroll" : "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      sectionStyleXS = {
        height: "870px",
        backgroundImage: !item.B_BGC_Shadow_Off
          ? `url(${item.I_Image})`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,
        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        backgroundPosition: "center center",
        backgroundAttachment:
          is_ios || item.B_BGC_Shadow_Off ? "scroll" : "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      // sectionStyleBottom = {
      //   height: "30vh",
      //   color: "white",
      //   backgroundPosition: "center center",
      //   backgroundRepeat: "no-repeat",
      //   backgroundSize: "cover"
      // };
    } else if (item.N_RadioGrpDirections_ID === 6) {
      //
      // Babak's note: Below configures if we are currently on IOS (IPhone):
      const is_ios = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);

      // sectionStyleTop = {
      //   height: "30vh",
      //   color: "white",
      //   backgroundPosition: "center center",
      //   backgroundRepeat: "no-repeat",
      //   backgroundSize: "cover"
      // };
      sectionStyle = {
        height:
          customizationSingleRow.B_GoogleMap && item.B_GoogleMapHomeBiilboard
            ? "760px"
            : "510px",

        background: !item.B_BGC_Shadow_Off
          ? `${item.A_BGC}`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), ${item.A_BGC}`,

        // background: `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), orange`,

        backgroundImage: !item.B_BGC_Shadow_Off
          ? `url(${item.I_Image})`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,

        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        backgroundPosition: "center center",
        backgroundAttachment:
          is_ios || item.B_BGC_Shadow_Off ? "scroll" : "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      sectionStyleMD = {
        height:
          customizationSingleRow.B_GoogleMap && item.B_GoogleMapHomeBiilboard
            ? "1130px"
            : "870px",

        background: !item.B_BGC_Shadow_Off
          ? `${item.A_BGC}`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), ${item.A_BGC}`,

        // background: `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), orange`,

        backgroundImage: !item.B_BGC_Shadow_Off
          ? `url(${item.I_Image})`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,

        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        backgroundPosition: "center center",
        backgroundAttachment:
          is_ios || item.B_BGC_Shadow_Off ? "scroll" : "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      sectionStyleSM = {
        height:
          customizationSingleRow.B_GoogleMap && item.B_GoogleMapHomeBiilboard
            ? "1130px"
            : "870px",

        background: !item.B_BGC_Shadow_Off
          ? `${item.A_BGC}`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), ${item.A_BGC}`,

        // background: `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), orange`,

        backgroundImage: !item.B_BGC_Shadow_Off
          ? `url(${item.I_Image})`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,

        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        backgroundPosition: "center center",
        backgroundAttachment:
          is_ios || item.B_BGC_Shadow_Off ? "scroll" : "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      sectionStyleXS = {
        height:
          customizationSingleRow.B_GoogleMap && item.B_GoogleMapHomeBiilboard
            ? "1130px"
            : "870px",

        background: !item.B_BGC_Shadow_Off
          ? `${item.A_BGC}`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), ${item.A_BGC}`,

        // background: `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), orange`,

        backgroundImage: !item.B_BGC_Shadow_Off
          ? `url(${item.I_Image})`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,

        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        backgroundPosition: "center center",
        backgroundAttachment:
          is_ios || item.B_BGC_Shadow_Off ? "scroll" : "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      // sectionStyleBottom = {
      //   height: "30vh",
      //   color: "white",
      //   backgroundPosition: "center center",
      //   backgroundRepeat: "no-repeat",
      //   backgroundSize: "cover"
      // };
    } else if (item.N_RadioGrpDirections_ID === 7) {
      //
      // Babak's note: Below configures if we are currently on IOS (IPhone):
      const is_ios = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);

      // sectionStyleTop = {
      //   height: "30vh",
      //   color: "white",
      //   backgroundPosition: "center center",
      //   backgroundRepeat: "no-repeat",
      //   backgroundSize: "cover"
      // };
      sectionStyle = {
        height: "510px",

        background: !item.B_BGC_Shadow_Off
          ? `${item.A_BGC}`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), ${item.A_BGC}`,

        // background: `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), orange`,

        backgroundImage: !item.B_BGC_Shadow_Off
          ? `url(${item.I_Image})`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,

        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        backgroundPosition: "center center",
        backgroundAttachment:
          is_ios || item.B_BGC_Shadow_Off ? "scroll" : "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      sectionStyleMD = {
        height: "870px",

        background: !item.B_BGC_Shadow_Off
          ? `${item.A_BGC}`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), ${item.A_BGC}`,

        // background: `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), orange`,

        backgroundImage: !item.B_BGC_Shadow_Off
          ? `url(${item.I_Image})`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,

        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        backgroundPosition: "center center",
        backgroundAttachment:
          is_ios || item.B_BGC_Shadow_Off ? "scroll" : "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      sectionStyleSM = {
        height: "870px",

        background: !item.B_BGC_Shadow_Off
          ? `${item.A_BGC}`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), ${item.A_BGC}`,

        // background: `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), orange`,

        backgroundImage: !item.B_BGC_Shadow_Off
          ? `url(${item.I_Image})`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,

        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        backgroundPosition: "center center",
        backgroundAttachment:
          is_ios || item.B_BGC_Shadow_Off ? "scroll" : "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      sectionStyleXS = {
        height: "870px",

        background: !item.B_BGC_Shadow_Off
          ? `${item.A_BGC}`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), ${item.A_BGC}`,

        // background: `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), orange`,

        backgroundImage: !item.B_BGC_Shadow_Off
          ? `url(${item.I_Image})`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,

        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        backgroundPosition: "center center",
        backgroundAttachment:
          is_ios || item.B_BGC_Shadow_Off ? "scroll" : "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      // sectionStyleBottom = {
      //   height: "30vh",
      //   color: "white",
      //   backgroundPosition: "center center",
      //   backgroundRepeat: "no-repeat",
      //   backgroundSize: "cover"
      // };
    } else if (item.N_RadioGrpDirections_ID === 8) {
      //
      // Babak's note: Below configures if we are currently on IOS (IPhone):
      const is_ios = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);

      // sectionStyleTop = {
      //   height: "30vh",
      //   color: "white",
      //   backgroundPosition: "center center",
      //   backgroundRepeat: "no-repeat",
      //   backgroundSize: "cover"
      // };
      sectionStyle = {
        height: "820px",

        background: !item.B_BGC_Shadow_Off
          ? `${item.A_BGC}`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), ${item.A_BGC}`,

        // background: `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), orange`,

        backgroundImage: !item.B_BGC_Shadow_Off
          ? `url(${item.I_Image})`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,

        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        // backgroundAttachment: "fixed",
        backgroundPosition: "center center",
        backgroundAttachment:
          is_ios || item.B_BGC_Shadow_Off ? "scroll" : "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      sectionStyleMD = {
        height: "790px",

        background: !item.B_BGC_Shadow_Off
          ? `${item.A_BGC}`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), ${item.A_BGC}`,

        // background: `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), orange`,

        backgroundImage: !item.B_BGC_Shadow_Off
          ? `url(${item.I_Image})`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,

        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        backgroundPosition: "center center",
        backgroundAttachment:
          is_ios || item.B_BGC_Shadow_Off ? "scroll" : "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      sectionStyleSM = {
        height: "1260px",

        background: !item.B_BGC_Shadow_Off
          ? `${item.A_BGC}`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), ${item.A_BGC}`,

        // background: `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), orange`,

        backgroundImage: !item.B_BGC_Shadow_Off
          ? `url(${item.I_Image})`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,

        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        backgroundPosition: "center center",
        backgroundAttachment:
          is_ios || item.B_BGC_Shadow_Off ? "scroll" : "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      sectionStyleXS = {
        height: "1250px",

        background: !item.B_BGC_Shadow_Off
          ? `${item.A_BGC}`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), ${item.A_BGC}`,

        // background: `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), orange`,

        backgroundImage: !item.B_BGC_Shadow_Off
          ? `url(${item.I_Image})`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,

        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        backgroundPosition: "center center",
        backgroundAttachment:
          is_ios || item.B_BGC_Shadow_Off ? "scroll" : "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      // sectionStyleBottom = {
      //   height: "30vh",
      //   color: "white",
      //   backgroundPosition: "center center",
      //   backgroundRepeat: "no-repeat",
      //   backgroundSize: "cover"
      // };
    } else if (item.N_RadioGrpDirections_ID === 9) {
      //
      // Babak's note: Below configures if we are currently on IOS (IPhone):
      const is_ios = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);

      // sectionStyleTop = {
      //   height: "30vh",
      //   color: "white",
      //   backgroundPosition: "center center",
      //   backgroundRepeat: "no-repeat",
      //   backgroundSize: "cover"
      // };

      sectionStyle = {
        height: "820px",
        // backgroundImage: `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,

        background: !item.B_BGC_Shadow_Off
          ? `${item.A_BGC}`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), ${item.A_BGC}`,

        // background: `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), orange`,

        backgroundImage: !item.B_BGC_Shadow_Off
          ? `url(${item.I_Image})`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,

        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        // backgroundAttachment: "fixed",
        backgroundPosition: "center center",
        backgroundAttachment:
          is_ios || item.B_BGC_Shadow_Off ? "scroll" : "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      sectionStyleMD = {
        height: "790px",

        background: !item.B_BGC_Shadow_Off
          ? `${item.A_BGC}`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), ${item.A_BGC}`,

        // background: `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), orange`,

        backgroundImage: !item.B_BGC_Shadow_Off
          ? `url(${item.I_Image})`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,

        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        backgroundPosition: "center center",
        backgroundAttachment:
          is_ios || item.B_BGC_Shadow_Off ? "scroll" : "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      sectionStyleSM = {
        height: "1260px",

        background: !item.B_BGC_Shadow_Off
          ? `${item.A_BGC}`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), ${item.A_BGC}`,

        // background: `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), orange`,

        backgroundImage: !item.B_BGC_Shadow_Off
          ? `url(${item.I_Image})`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,

        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        backgroundPosition: "center center",
        backgroundAttachment:
          is_ios || item.B_BGC_Shadow_Off ? "scroll" : "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      sectionStyleXS = {
        height: "1250px",

        background: !item.B_BGC_Shadow_Off
          ? `${item.A_BGC}`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), ${item.A_BGC}`,

        // background: `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), orange`,

        backgroundImage: !item.B_BGC_Shadow_Off
          ? `url(${item.I_Image})`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,

        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        backgroundPosition: "center center",
        backgroundAttachment:
          is_ios || item.B_BGC_Shadow_Off ? "scroll" : "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      // sectionStyleBottom = {
      //   height: "30vh",
      //   color: "white",
      //   backgroundPosition: "center center",
      //   backgroundRepeat: "no-repeat",
      //   backgroundSize: "cover"
      // };
    } else if (item.N_RadioGrpDirections_ID === 10) {
      //
      // Babak's note: Below configures if we are currently on IOS (IPhone):
      const is_ios = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);

      // sectionStyleTop = {
      //   height: "30vh",
      //   color: "white",
      //   backgroundPosition: "center center",
      //   backgroundRepeat: "no-repeat",
      //   backgroundSize: "cover"
      // };

      sectionStyle = {
        height: "200px",
        // backgroundImage: `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,
        // backgroundColor: "orange",

        background: !item.B_BGC_Shadow_Off
          ? `${item.A_BGC}`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), ${item.A_BGC}`,

        // background: `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), orange`,

        backgroundImage: !item.B_BGC_Shadow_Off
          ? `url(${item.I_Image})`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,

        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",

        // backgroundAttachment: "fixed",
        backgroundPosition: "center center",
        backgroundAttachment:
          is_ios || item.B_BGC_Shadow_Off ? "scroll" : "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      sectionStyleMD = {
        height: "835px",

        background: !item.B_BGC_Shadow_Off
          ? `${item.A_BGC}`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), ${item.A_BGC}`,

        // background: `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), orange`,

        backgroundImage: !item.B_BGC_Shadow_Off
          ? `url(${item.I_Image})`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,

        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        backgroundPosition: "center center",
        backgroundAttachment:
          is_ios || item.B_BGC_Shadow_Off ? "scroll" : "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      sectionStyleSM = {
        height: "1350px",

        background: !item.B_BGC_Shadow_Off
          ? `${item.A_BGC}`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), ${item.A_BGC}`,

        // background: `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), orange`,

        backgroundImage: !item.B_BGC_Shadow_Off
          ? `url(${item.I_Image})`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,

        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        backgroundPosition: "center center",
        backgroundAttachment:
          is_ios || item.B_BGC_Shadow_Off ? "scroll" : "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      sectionStyleXS = {
        height: "1350px",

        background: !item.B_BGC_Shadow_Off
          ? `${item.A_BGC}`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), ${item.A_BGC}`,

        // background: `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), orange`,

        backgroundImage: !item.B_BGC_Shadow_Off
          ? `url(${item.I_Image})`
          : `linear-gradient(to bottom, ${item.A_BGC_Shadow}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,

        // backgroundImage: `url(${item.I_Image})`,
        // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
        backgroundPosition: "center center",
        backgroundAttachment:
          is_ios || item.B_BGC_Shadow_Off ? "scroll" : "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
      // sectionStyleBottom = {
      //   height: "30vh",
      //   color: "white",
      //   backgroundPosition: "center center",
      //   backgroundRepeat: "no-repeat",
      //   backgroundSize: "cover"
      // };
    } else {
      //
      // Babak's note: Below configures if we are currently on IOS (IPhone):
      const is_ios = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);

      sectionStyle = {
        height: "100vh",
        backgroundImage: `url(${item.I_Image})`,
        backgroundPosition: "center center",
        backgroundAttachment:
          is_ios || item.B_BGC_Shadow_Off ? "scroll" : "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
    }

    // let sectionStyleSmallerLg;
    // sectionStyleSmallerLg = {
    //   height: "50vh",
    //   backgroundImage: `url(${item.I_Image})`,
    //   backgroundPosition: "center center",
    //   backgroundRepeat: "no-repeat",
    //   backgroundSize: "cover"
    // };

    // Babak's Notes: Commented out on 20200115: START
    return (
      // <div style={sectionStyle} className="section-1 box"></div>
      <div id={item.N_OrderNo}>
        {item.N_RadioGrpDirections_ID === 1 ? (
          <div className="boxShadowOnly">
            <StyleRoot
              id={item.N_OrderNo}
              style={sectionStyle}
              // className="d-none d-lg-block HeroMain boxShadowOnly"
              className="d-none d-lg-block HeroMain boxShadowOnly homeOverflowHidden"
            >
              <div className="HeroMain-contentTopLG PageSidePadding mediumPlus-PageSidePadding">
                <div className="HeroMain-contentInnerTopLG">
                  <h1 className="HeroMain-subTitleTopLG textTitleShadowGray">
                    <NavLink
                      style={{ color: "white" }}
                      to={item.A_HoverSummary01_Link}
                    >
                      <span className="textShadow">
                        {item.A_HoverSummary01}
                      </span>
                    </NavLink>
                  </h1>
                </div>
              </div>

              <div className="HeroMain-contentMiddleReducedLG PageSidePadding mediumPlus-PageSidePadding">
                <div className="HeroMain-contentInnerMiddleReducedLG">
                  <h1 className="HeroMain-subTitleMiddleNameReducedLG textTitleShadowGray">
                    <span style={styles.fadeInUp01} className="textShadow">
                      <NavLink
                        style={{ color: "white" }}
                        to={item.A_HoverSummary02_Link}
                      >
                        {item.A_HoverSummary02},{" "}
                      </NavLink>
                    </span>
                    <span style={styles.fadeInUp01} className="textShadow">
                      <NavLink
                        style={{ color: "white" }}
                        to={item.A_HoverSummary03_Link}
                      >
                        {item.A_HoverSummary03}
                      </NavLink>
                    </span>
                  </h1>
                </div>
              </div>

              <div className="HeroMain-contentMiddleLG PageSidePadding mediumPlus-PageSidePadding">
                <div className="HeroMain-contentInnerMiddleLG">
                  <h1
                    style={styles.fadeInUp01}
                    className="HeroMain-subTitleMiddleNameLG textTitleShadowGray textShadow"
                  >
                    <NavLink
                      style={{ color: "white" }}
                      to={item.A_HoverSummary02_Link}
                    >
                      {item.A_HoverSummary02}
                    </NavLink>
                  </h1>

                  <h1
                    style={styles.fadeInUp02}
                    className="HeroMain-subTitleMiddleLG textTitleShadowGray textShadow"
                  >
                    <NavLink
                      style={{ color: "white" }}
                      to={item.A_HoverSummary03_Link}
                    >
                      {item.A_HoverSummary03}
                    </NavLink>
                  </h1>

                  <h2
                    style={styles.fadeInUp03}
                    className="HeroMain-subTitleMiddleLG textTitleShadowGray textShadow"
                  >
                    <NavLink
                      style={{ color: "white" }}
                      to={item.A_HoverSummary04_Link}
                    >
                      {item.A_HoverSummary04}
                    </NavLink>
                  </h2>

                  <h2
                    style={styles.fadeInUp03}
                    className="HeroMain-subTitleMiddleLG textTitleShadowGray textShadow"
                  >
                    <NavLink
                      style={{ color: "white" }}
                      to={item.A_HoverSummary05_Link}
                    >
                      {item.A_HoverSummary05}
                    </NavLink>
                  </h2>
                </div>
              </div>

              <div className="HeroMain-lineMiddleLG PageSidePadding mediumPlus-PageSidePadding">
                <div className="HeroMain-lineInnerMiddleLG">
                  <p className="HeroMain-lineTaglineMiddleLG"></p>
                </div>
              </div>

              <div className="HeroMain-content-plateLG">
                <div className="HeroMain-contentInner-plateLG">
                  <div
                    style={{
                      backgroundColor: `${item.A_TextFC}`,
                      height: "10rem",
                      fontWeight: "500",
                      fontSize: "20px",
                      marginLeft: "2rem"
                    }}
                    // className="HeroMain-subTitle-plateLG"
                  ></div>
                </div>
              </div>

              <div className="HeroMain-content-plate-LeftLG">
                <div className="HeroMain-contentInner-plate-LeftLG">
                  <div
                    style={{
                      backgroundColor: `${item.A_TextFC}`,
                      height: "10rem",
                      fontWeight: "500",
                      fontSize: "20px"
                    }}
                    // className="HeroMain-subTitle-plate-LeftLG"
                  ></div>
                </div>
              </div>

              <div className="HeroMain-content-plateReduced-contentLG">
                <div className="HeroMain-contentInner-plateReduced-contentLG">
                  <div className="HeroMain-subTitle-plateReduced-contentLG">
                    <h2
                      style={styles.fadeInUp01}
                      className="HeroMain-subTitle-plateReduced-contentFontLG textTitleShadowGray"
                    >
                      {item.A_HoverSummary06}
                    </h2>
                  </div>
                  <div className="HeroMain-subTitle-plateReduced-content-actionLG clickable">
                    <a>
                      {item.B_HoverSummary08_External ? (
                        <a href={item.A_HoverSummary08_Link} target={"_blank"}>
                          <span className="boxShadowLow">
                            {item.A_HoverSummary08}
                          </span>
                        </a>
                      ) : (
                        <NavLink to={item.A_HoverSummary08_Link}>
                          <span className="boxShadowLow">
                            {item.A_HoverSummary08}
                          </span>
                        </NavLink>
                      )}
                    </a>
                  </div>
                </div>
              </div>

              <div className="HeroMain-content-plate-contentLG">
                <div className="HeroMain-contentInner-plate-contentLG">
                  <div className="HeroMain-subTitle-plate-contentLG">
                    <h2 className="HeroMain-subTitle-plate-contentFontLG">
                      {item.A_HoverSummary06}
                    </h2>
                    <p className="HeroMain-subTitle-plate-contentFontLG">
                      {item.A_HoverSummary07}
                    </p>
                  </div>

                  <div className="HeroMain-subTitle-plate-content-actionLG clickable">
                    <a>
                      {item.B_HoverSummary08_External ? (
                        <a href={item.A_HoverSummary08_Link} target={"_blank"}>
                          <span className="boxShadowLow">
                            {item.A_HoverSummary08}
                          </span>
                        </a>
                      ) : (
                        <NavLink to={item.A_HoverSummary08_Link}>
                          <span className="boxShadowLow">
                            {item.A_HoverSummary08}
                          </span>
                        </NavLink>
                      )}
                    </a>
                  </div>
                </div>
              </div>
            </StyleRoot>

            <StyleRoot
              id={item.N_OrderNo}
              style={sectionStyle}
              // className="d-none d-lg-block HeroMain boxShadowOnly"
              className="d-none d-md-block d-lg-none HeroMain boxShadowOnly homeOverflowHidden"
            >
              <div className="HeroMain-contentTopMD PageSidePadding mediumPlus-PageSidePadding">
                <div className="HeroMain-contentInnerTopMD">
                  <h1 className="HeroMain-subTitleTopMD textTitleShadowGray">
                    <NavLink
                      style={{ color: "white" }}
                      to={item.A_HoverSummary01_Link}
                    >
                      <span className="textShadow">
                        {item.A_HoverSummary01}
                      </span>
                    </NavLink>
                  </h1>
                </div>
              </div>

              <div className="HeroMain-contentMiddleReducedMD PageSidePadding mediumPlus-PageSidePadding">
                <div className="HeroMain-contentInnerMiddleReducedMD">
                  <h1 className="HeroMain-subTitleMiddleNameReducedMD textTitleShadowGray">
                    <span style={styles.fadeInUp01} className="textShadow">
                      <NavLink
                        style={{ color: "white" }}
                        to={item.A_HoverSummary02_Link}
                      >
                        {item.A_HoverSummary02},{" "}
                      </NavLink>
                    </span>
                    <span style={styles.fadeInUp01} className="textShadow">
                      <NavLink
                        style={{ color: "white" }}
                        to={item.A_HoverSummary03_Link}
                      >
                        {item.A_HoverSummary03}
                      </NavLink>
                    </span>
                  </h1>
                </div>
              </div>

              <div className="HeroMain-contentMiddleMD PageSidePadding mediumPlus-PageSidePadding">
                <div className="HeroMain-contentInnerMiddleMD">
                  <h1
                    style={styles.fadeInUp01}
                    className="HeroMain-subTitleMiddleNameMD textTitleShadowGray textShadow"
                  >
                    <NavLink
                      style={{ color: "white" }}
                      to={item.A_HoverSummary02_Link}
                    >
                      {item.A_HoverSummary02}
                    </NavLink>
                  </h1>

                  <h1
                    style={styles.fadeInUp02}
                    className="HeroMain-subTitleMiddleMD textTitleShadowGray textShadow"
                  >
                    <NavLink
                      style={{ color: "white" }}
                      to={item.A_HoverSummary03_Link}
                    >
                      {item.A_HoverSummary03}
                    </NavLink>
                  </h1>

                  <h2
                    style={styles.fadeInUp03}
                    className="HeroMain-subTitleMiddleMD textTitleShadowGray textShadow"
                  >
                    <NavLink
                      style={{ color: "white" }}
                      to={item.A_HoverSummary04_Link}
                    >
                      {item.A_HoverSummary04}
                    </NavLink>
                  </h2>

                  <h2
                    style={styles.fadeInUp03}
                    className="HeroMain-subTitleMiddleMD textTitleShadowGray textShadow"
                  >
                    <NavLink
                      style={{ color: "white" }}
                      to={item.A_HoverSummary05_Link}
                    >
                      {item.A_HoverSummary05}
                    </NavLink>
                  </h2>
                </div>
              </div>

              <div className="HeroMain-lineMiddleMD PageSidePadding mediumPlus-PageSidePadding">
                <div className="HeroMain-lineInnerMiddleMD">
                  <p className="HeroMain-lineTaglineMiddleMD"></p>
                </div>
              </div>

              <div className="HeroMain-content-plateMD">
                <div className="HeroMain-contentInner-plateMD">
                  <div
                    style={{
                      backgroundColor: `${item.A_TextFC}`,
                      height: "10rem",
                      fontWeight: "500",
                      fontSize: "20px",
                      marginLeft: "2rem"
                    }}
                    // className="HeroMain-subTitle-plateMD"
                  ></div>
                </div>
              </div>

              <div className="HeroMain-content-plate-LeftMD">
                <div className="HeroMain-contentInner-plate-LeftMD">
                  <div
                    style={{
                      backgroundColor: `${item.A_TextFC}`,
                      height: "10rem",
                      fontWeight: "500",
                      fontSize: "20px"
                    }}
                    // className="HeroMain-subTitle-plate-LeftMD"
                  ></div>
                </div>
              </div>

              <div className="HeroMain-content-plateReduced-contentMD">
                <div className="HeroMain-contentInner-plateReduced-contentMD">
                  <div className="HeroMain-subTitle-plateReduced-contentMD">
                    <h2
                      style={styles.fadeInUp01}
                      className="HeroMain-subTitle-plateReduced-contentFontMD textTitleShadowGray"
                    >
                      {item.A_HoverSummary06}
                    </h2>
                  </div>
                  <div className="HeroMain-subTitle-plateReduced-content-actionMD">
                    <a>
                      {item.B_HoverSummary08_External ? (
                        <a href={item.A_HoverSummary08_Link} target={"_blank"}>
                          <span className="boxShadowLow">
                            {item.A_HoverSummary08}
                          </span>
                        </a>
                      ) : (
                        <NavLink to={item.A_HoverSummary08_Link}>
                          <span className="boxShadowLow">
                            {item.A_HoverSummary08}
                          </span>
                        </NavLink>
                      )}
                    </a>
                  </div>
                </div>
              </div>

              <div className="HeroMain-content-plate-contentMD">
                <div className="HeroMain-contentInner-plate-contentMD">
                  <div className="HeroMain-subTitle-plate-contentMD">
                    <h2 className="HeroMain-subTitle-plate-contentFontMD">
                      {item.A_HoverSummary06}
                    </h2>
                    <p className="HeroMain-subTitle-plate-contentFontMD">
                      {item.A_HoverSummary07}
                    </p>
                  </div>
                  <div className="HeroMain-subTitle-plate-content-actionMD">
                    <a>
                      {item.B_HoverSummary08_External ? (
                        <a href={item.A_HoverSummary08_Link} target={"_blank"}>
                          <span className="boxShadowLow">
                            {item.A_HoverSummary08}
                          </span>
                        </a>
                      ) : (
                        <NavLink to={item.A_HoverSummary08_Link}>
                          <span className="boxShadowLow">
                            {item.A_HoverSummary08}
                          </span>
                        </NavLink>
                      )}
                    </a>
                  </div>
                </div>
              </div>
            </StyleRoot>

            <StyleRoot
              style={sectionStyle}
              id={item.N_OrderNo}
              className="d-sm-block d-md-none d-none HeroMain homeOverflowHidden"
            >
              <div className="HeroMain-contentTopSM PageSidePadding mediumPlus-PageSidePadding">
                <div className="HeroMain-contentInnerTopSM">
                  <h1 className="HeroMain-subTitleTopSM textTitleShadowGray">
                    <NavLink
                      style={{ color: "white" }}
                      to={item.A_HoverSummary01_Link}
                    >
                      <span className="textShadow">
                        {item.A_HoverSummary01}
                      </span>
                    </NavLink>
                  </h1>
                </div>
              </div>

              <div className="HeroMain-contentMiddleReducedSM PageSidePadding mediumPlus-PageSidePadding">
                <div className="HeroMain-contentInnerMiddleReducedSM">
                  <h1 className="HeroMain-subTitleMiddleNameReducedSM textTitleShadowGray">
                    <span style={styles.fadeInUp01} className="textShadow">
                      <NavLink
                        style={{ color: "white" }}
                        to={item.A_HoverSummary02_Link}
                      >
                        {item.A_HoverSummary02},{" "}
                      </NavLink>
                    </span>
                    <span style={styles.fadeInUp01} className="textShadow">
                      <NavLink
                        style={{ color: "white" }}
                        to={item.A_HoverSummary03_Link}
                      >
                        {item.A_HoverSummary03}
                      </NavLink>
                    </span>
                  </h1>
                </div>
              </div>

              <div className="HeroMain-contentMiddleSM PageSidePadding mediumPlus-PageSidePadding">
                <div className="HeroMain-contentInnerMiddleSM">
                  <h1
                    style={styles.fadeInUp01}
                    className="HeroMain-subTitleMiddleNameSM textTitleShadowGray textShadow"
                  >
                    <NavLink
                      style={{ color: "white" }}
                      to={item.A_HoverSummary02_Link}
                    >
                      {item.A_HoverSummary02}
                    </NavLink>
                  </h1>

                  <h1
                    style={styles.fadeInUp02}
                    className="HeroMain-subTitleMiddleSM textTitleShadowGray textShadow"
                  >
                    <NavLink
                      style={{ color: "white" }}
                      to={item.A_HoverSummary03_Link}
                    >
                      {item.A_HoverSummary03}
                    </NavLink>
                  </h1>

                  <h2
                    style={styles.fadeInUp03}
                    className="HeroMain-subTitleMiddleSM textTitleShadowGray textShadow"
                  >
                    <NavLink
                      style={{ color: "white" }}
                      to={item.A_HoverSummary04_Link}
                    >
                      {item.A_HoverSummary04}
                    </NavLink>
                  </h2>

                  <h2
                    style={styles.fadeInUp03}
                    className="HeroMain-subTitleMiddleSM textTitleShadowGray textShadow"
                  >
                    <NavLink
                      style={{ color: "white" }}
                      to={item.A_HoverSummary05_Link}
                    >
                      {item.A_HoverSummary05}
                    </NavLink>
                  </h2>
                </div>
              </div>

              <div className="HeroMain-lineMiddleSM PageSidePadding mediumPlus-PageSidePadding">
                <div className="HeroMain-lineInnerMiddleSM">
                  <p className="HeroMain-lineTaglineMiddleSM"></p>
                </div>
              </div>

              {/* <div className="HeroMain-content-plateMD">
                <div className="HeroMain-contentInner-plateMD">
                  <div
                    style={{
                      backgroundColor: `${item.A_TextFC}`,
                      height: "10rem",
                      fontWeight: "500",
                      fontSize: "20px",
                      marginLeft: "2rem"
                    }}
                    // className="HeroMain-subTitle-plateMD"
                  ></div>
                </div>
              </div> */}

              <div className="HeroMain-content-plateReduced-contentSM">
                <div className="HeroMain-contentInner-plateReduced-contentSM">
                  <div className="HeroMain-subTitle-plateReduced-contentSM">
                    <h2
                      style={styles.fadeInUp01}
                      className="HeroMain-subTitle-plateReduced-contentFontSM textTitleShadowGray"
                    >
                      {item.A_HoverSummary06}
                    </h2>
                  </div>
                  <div className="HeroMain-subTitle-plateReduced-content-actionSM">
                    <a>
                      {item.B_HoverSummary08_External ? (
                        <a href={item.A_HoverSummary08_Link} target={"_blank"}>
                          <span className="boxShadowLow">
                            {item.A_HoverSummary08}
                          </span>
                        </a>
                      ) : (
                        <NavLink to={item.A_HoverSummary08_Link}>
                          <span className="boxShadowLow">
                            {item.A_HoverSummary08}
                          </span>
                        </NavLink>
                      )}
                    </a>
                  </div>
                </div>
              </div>

              <div className="HeroMain-contentBottomSM">
                <div className="HeroMain-contentInner">
                  <div className="HeroMain-subTitle-plate-contentSM">
                    <h2
                      style={styles.fadeInUp01}
                      className="HeroMain-subTitle-plate-contentFontSM textTitleShadowGray"
                    >
                      {item.A_HoverSummary06}
                    </h2>
                  </div>
                  <div className="HeroMain-subTitle-plate-content-actionSM">
                    <a>
                      {item.B_HoverSummary08_External ? (
                        <a href={item.A_HoverSummary08_Link} target={"_blank"}>
                          <span className="boxShadowLow">
                            {item.A_HoverSummary08}
                          </span>
                        </a>
                      ) : (
                        <NavLink to={item.A_HoverSummary08_Link}>
                          <span className="boxShadowLow">
                            {item.A_HoverSummary08}
                          </span>
                        </NavLink>
                      )}
                    </a>
                  </div>
                </div>
              </div>
            </StyleRoot>

            <StyleRoot
              style={sectionStyle}
              id={item.N_OrderNo}
              className="d-xs-block d-sm-none d-md-none HeroMain homeOverflowHidden"
            >
              <div className="HeroMain-contentTopXS PageSidePadding mediumPlus-PageSidePadding">
                <div className="HeroMain-contentInnerTopXS">
                  <h1 className="HeroMain-subTitleTopXS textTitleShadowGray">
                    <NavLink
                      style={{ color: "white" }}
                      to={item.A_HoverSummary01_Link}
                    >
                      <span className="textShadow">
                        {item.A_HoverSummary01}
                      </span>
                    </NavLink>
                  </h1>
                </div>
              </div>

              <div className="HeroMain-contentMiddleXS PageSidePadding mediumPlus-PageSidePadding">
                <div className="HeroMain-contentInnerMiddleXS">
                  <h1
                    style={styles.fadeInUp01}
                    className="HeroMain-subTitleNameXS textTitleShadowGray textShadow"
                  >
                    <NavLink
                      style={{ color: "white" }}
                      to={item.A_HoverSummary02_Link}
                    >
                      {item.A_HoverSummary02}
                    </NavLink>
                  </h1>

                  <h2
                    style={styles.fadeInUp02}
                    className="HeroMain-subTitleXS textTitleShadowGray textShadow"
                  >
                    <NavLink
                      style={{ color: "white" }}
                      to={item.A_HoverSummary03_Link}
                    >
                      {item.A_HoverSummary03}
                    </NavLink>
                  </h2>

                  <h2
                    style={styles.fadeInUp03}
                    className="HeroMain-subTitleXS textTitleShadowGray textShadow"
                  >
                    <NavLink
                      style={{ color: "white" }}
                      to={item.A_HoverSummary04_Link}
                    >
                      {item.A_HoverSummary04}
                    </NavLink>
                  </h2>

                  <h2
                    style={styles.fadeInUp03}
                    className="HeroMain-subTitleXS textTitleShadowGray textShadow"
                  >
                    <NavLink
                      style={{ color: "white" }}
                      to={item.A_HoverSummary05_Link}
                    >
                      {item.A_HoverSummary05}
                    </NavLink>
                  </h2>
                </div>
              </div>

              <div className="HeroMain-contentBottomXS">
                <div className="HeroMain-contentInnerBottomXS">
                  <div className="HeroMain-subTitle-plate-contentXS">
                    <h2
                      style={styles.fadeInUp01}
                      className="HeroMain-subTitle-plate-contentFontXS textTitleShadowGray"
                    >
                      {item.A_HoverSummary06}
                    </h2>
                  </div>
                  <div className="HeroMain-subTitle-plate-content-actionXS">
                    <a>
                      {item.B_HoverSummary08_External ? (
                        <a href={item.A_HoverSummary08_Link} target={"_blank"}>
                          <span className="boxShadowLow">
                            {item.A_HoverSummary08}
                          </span>
                        </a>
                      ) : (
                        <NavLink to={item.A_HoverSummary08_Link}>
                          <span className="boxShadowLow">
                            {item.A_HoverSummary08}
                          </span>
                        </NavLink>
                      )}
                    </a>
                  </div>
                </div>
              </div>
            </StyleRoot>
          </div>
        ) : item.N_RadioGrpDirections_ID === 2 ? (
          <div id={item.N_OrderNo}>
            <HomeBillboardChildsMain
              sectionStyle={sectionStyle}
              sectionStyleMD={sectionStyleMD}
              sectionStyleSM={sectionStyleSM}
              sectionStyleXS={sectionStyleXS}
              homeBillboardsID={item.ID}
              textFC={item.A_TextFC}
              reference={item.A_Reference}
              summary={item.A_Summary}
            ></HomeBillboardChildsMain>
          </div>
        ) : item.N_RadioGrpDirections_ID === 3 ? (
          <div id={item.N_OrderNo}>
            <HomeBillboardChildsMainVerticalImages
              mainView={false}
              identifier={item.N_OrderNo}
              sectionStyle={sectionStyle}
              sectionStyleMD={sectionStyleMD}
              sectionStyleSM={sectionStyleSM}
              sectionStyleXS={sectionStyleXS}
              homeBillboardsID={item.ID}
              textFC={item.A_TextFC}
              reference={item.A_Reference}
              summary={item.A_Summary}
              imageParent={item.I_Image}
              urlLinkParent={item.A_HoverSummary01_Link}
            ></HomeBillboardChildsMainVerticalImages>
          </div>
        ) : item.N_RadioGrpDirections_ID === 4 ? (
          <div id={item.N_OrderNo}>
            <CarouselSlide
              sectionStyle={sectionStyle}
              sectionStyleMD={sectionStyleMD}
              sectionStyleSM={sectionStyleSM}
              sectionStyleXS={sectionStyleXS}
              textFC={item.A_TextFC}
              reference={item.A_Reference}
              summary={item.A_Summary}
            ></CarouselSlide>
          </div>
        ) : item.N_RadioGrpDirections_ID === 5 ? (
          <div id={item.N_OrderNo}>
            <HomeBillboardChildsMainAboutUs
              mainView={false}
              identifier={item.N_OrderNo}
              sectionStyle={sectionStyle}
              sectionStyleMD={sectionStyleMD}
              sectionStyleSM={sectionStyleSM}
              sectionStyleXS={sectionStyleXS}
              homeBillboardsID={item.ID}
              textFC={item.A_TextFC}
              reference={item.A_Reference}
              summary={item.A_Summary}
              imageParent={item.I_Image}
              urlLinkParent={item.A_HoverSummary01_Link}
            ></HomeBillboardChildsMainAboutUs>
          </div>
        ) : item.N_RadioGrpDirections_ID === 6 ? (
          <div id={item.N_OrderNo}>
            <HomeBillboardChildsMainContactUs
              mainView={false}
              identifier={item.N_OrderNo}
              sectionStyle={sectionStyle}
              sectionStyleMD={sectionStyleMD}
              sectionStyleSM={sectionStyleSM}
              sectionStyleXS={sectionStyleXS}
              homeBillboardsID={item.ID}
              textFC={item.A_TextFC}
              reference={item.A_Reference}
              summary={item.A_Summary}
              imageParent={item.I_Image}
              urlLinkParent={item.A_HoverSummary01_Link}
              customizationSingleRow={this.props.customizationSingleRow}
              googleMapHomeBiilboard={item.B_GoogleMapHomeBiilboard}
            ></HomeBillboardChildsMainContactUs>
          </div>
        ) : item.N_RadioGrpDirections_ID === 7 ? (
          <div id={item.N_OrderNo}>
            <HomeBillboardChildsMainKeypad
              mainView={false}
              identifier={item.N_OrderNo}
              sectionStyle={sectionStyle}
              sectionStyleMD={sectionStyleMD}
              sectionStyleSM={sectionStyleSM}
              sectionStyleXS={sectionStyleXS}
              homeBillboardsID={item.ID}
              textFC={item.A_TextFC}
              reference={item.A_Reference}
              summary={item.A_Summary}
              imageParent={item.I_Image}
              urlLinkParent={item.A_HoverSummary01_Link}
            ></HomeBillboardChildsMainKeypad>
          </div>
        ) : item.N_RadioGrpDirections_ID === 8 ? (
          <div id={item.N_OrderNo}>
            <HomeBillboardChildsMainKeypadListRight
              identifier={item.N_OrderNo}
              sectionStyle={sectionStyle}
              sectionStyleMD={sectionStyleMD}
              sectionStyleSM={sectionStyleSM}
              sectionStyleXS={sectionStyleXS}
              homeBillboardsID={item.ID}
              textFC={item.A_TextFC}
              reference={item.A_Reference}
              summary={item.A_Summary}
              description={item.A_Description}
              imageParent={item.I_Image}
              urlLinkParent={item.A_HoverSummary01_Link}
            ></HomeBillboardChildsMainKeypadListRight>
          </div>
        ) : item.N_RadioGrpDirections_ID === 9 ? (
          <div id={item.N_OrderNo}>
            <HomeBillboardChildsMainKeypadListLeft
              identifier={item.N_OrderNo}
              sectionStyle={sectionStyle}
              sectionStyleMD={sectionStyleMD}
              sectionStyleSM={sectionStyleSM}
              sectionStyleXS={sectionStyleXS}
              homeBillboardsID={item.ID}
              textFC={item.A_TextFC}
              reference={item.A_Reference}
              summary={item.A_Summary}
              description={item.A_Description}
              imageParent={item.I_Image}
              urlLinkParent={item.A_HoverSummary01_Link}
            ></HomeBillboardChildsMainKeypadListLeft>
          </div>
        ) : item.N_RadioGrpDirections_ID === 10 ? (
          <div id={item.N_OrderNo}>
            <HomeBillboardChildsMainImageOnStrip
              identifier={item.N_OrderNo}
              sectionStyle={sectionStyle}
              sectionStyleMD={sectionStyleMD}
              sectionStyleSM={sectionStyleSM}
              sectionStyleXS={sectionStyleXS}
              homeBillboardsID={item.ID}
              textFC={item.A_TextFC}
              reference={item.A_Reference}
              summary={item.A_Summary}
              image={item.I_ImageOnImage}
              urlLink={item.A_Button_Link}
              urlLinkExternal={item.B_Button_Link_External}
              button={item.A_Button}
            ></HomeBillboardChildsMainImageOnStrip>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  render() {
    const { data } = this.state;
    // const { billboardLargesGroup } = this.props.groups;

    // let sectionStyle;

    // sectionStyle = {
    //   // height: "100vh",
    //   // backgroundAttachment: "fixed",
    //   // backgroundSize: "cover",
    //   // width: width,
    //   //opacity: 0.5,
    //   backgroundImage: `url(${data.I_Image})`,
    //   backgroundRepeat: "no-repeat",
    //   backgroundSize: "cover"
    // };

    // console.log(
    //   "DetailsGroup render, Number of billboardLargesGroup: ",
    //   this.props.match.params.id,
    //   data,
    //   this.props.groups,
    //   this.props.groups.length
    // );

    if (this.props.homeBillboardsMains.length === 0)
      return <div className="hourGlass"></div>;

    return (
      <div>
        <div>
          {this.props.homeBillboardsMains.map((item, index) => (
            <this.renderBillboardData item={item} />
          ))}
        </div>
        <NavBarFooter
          customizationSingleRow={this.props.customizationSingleRow}
          menuOnNavvar={this.props.menuOnNavvar}
          onClickSearch={this.onClickSearchHandle}
        />
        <div>
          <ScrollUpButton style={{ marginBottom: "80px" }} />
        </div>
      </div>
    );
  }
}

//
// Babak's Notes: goto tutorial directory '11-Integration with React' file '5- Connecting Components Using react-redux'
const mapStateToProps = state => ({
  //   bugs: state.entities.menus.list.filter(bug => !bug.resolved)
  homeBillboardsMains: state.entities.homeBillboardsMains.list
});

const mapDispatchToProps = dispatch => ({
  loadHomeBillboardsMains: () => dispatch(loadHomeBillboardsMains())
});

// Container Component
//   Presentation (Bugs)
//
// Babak's Notes: Below I populate 'this.props.bugs' and 'this.props.loadBugs()':-
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeBillboardsMain);
