import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import auth from "../../services/authService";
import { loadMenu, addMenu, changeMenu } from "../../store/menus";
import { loadBillboardOrigins } from "../../store/billboardOrigins";
import { loadHomeLocationGroups } from "../../store/homeLocationGroups";
import { loadBillboardMenuBGs } from "../../store/billboardMenuBGs";
import { connect } from "react-redux";
// import { getMenu, saveMenu } from "../../services/menuService";
// import { getBillboardOrigins } from "../../services/billboardOriginService";
// import { getHomeLocationGroups } from "../../services/homeLocationGroupService";

class MenuForm extends Form {
  state = {
    data: {
      B_Billboard: false,
      N_BillboardOrigin_ID: "",
      N_HomeLocationGroup_ID: "",
      N_BillboardMenuBG_ID: "",
      N_Order_No: "",
      A_Reference: "",
      A_Summary: "",
      // A_Description: "",
      I_Image: "",
      A_MenuCursorColour: "",
      // N_BillboardLarge_ID: "",
      N_ParentOrigin_ID: "",
      waitingForFileUpload: false
    },
    errors: {},
    billboardOrigins: [],
    homeLocationGroups: [],
    billboardMenuBGs: [],
    N_BillboardOrigin_ID: "",
    N_HomeLocationGroup_ID: "",
    N_ParentOrigin_ID: "",
    user: []
  };

  schemaCreate = {
    ID: Joi.number(),
    B_Billboard: Joi.boolean().label("Display Menu"),
    N_BillboardOrigin_ID: Joi.number()
      .allow("")
      .optional()
      //.required()
      // .min(1)
      // .max(100)
      .label("Billboard Origin"),
    N_HomeLocationGroup_ID: Joi.number()
      .allow("")
      .optional()
      //.required()
      // .min(1)
      // .max(100)
      .label("Home Location Group"),
    N_BillboardMenuBG_ID: Joi.number()
      .allow("")
      .optional()
      //.required()
      // .min(1)
      // .max(100)
      .label("Menu Background"),
    N_Order_No: Joi.number()
      .required()
      .min(1)
      .max(100)
      .label("Order No"),
    A_Reference: Joi.string()
      .required()
      .min(3)
      .max(30)
      .label("Reference"),
    A_Summary: Joi.string()
      //   .allow("")
      //   .optional()
      .required()
      .min(3)
      .max(200)
      .label("Summary"),
    // A_Description: Joi.string()
    //   .required()
    //   .min(3)
    //   .max(1000)
    //   .label("Description"),
    I_Image: Joi.any()
      .meta({ swaggerType: "file" })
      // .required()
      .optional()
      .label("Image"),
    N_PhotoSize01: Joi.number()
      .min(5000)
      .max(10000000)
      .optional(),
    waitingForFileUpload: Joi.boolean().optional(),
    N_ParentOrigin_ID: Joi.number()
      .allow("")
      .optional(),
    A_MenuCursorColour: Joi.string()
      .required()
      .min(5)
      .max(50)
      .label("Menu Cursor Colour")
    // N_BillboardLarge_ID: Joi.string()
    //   .min(1)
    //   .max(100)
    //   .label("BillboardIndentifier")
  };

  schemaEdit = {
    ID: Joi.number(),
    B_Billboard: Joi.boolean().label("Display Menu"),
    N_BillboardOrigin_ID: Joi.number()
      .allow("")
      .optional()
      //.required()
      .min(1)
      .max(100)
      .label("Billboard Origin"),
    N_HomeLocationGroup_ID: Joi.number()
      .allow("")
      .optional()
      //.required()
      // .min(1)
      // .max(100)
      .label("Home Location Group"),
    N_BillboardMenuBG_ID: Joi.number()
      .allow("")
      .optional()
      //.required()
      // .min(1)
      // .max(100)
      .label("Menu Background"),
    N_Order_No: Joi.number()
      .required()
      // .min(1)
      // .max(100)
      .label("Order No"),
    A_Reference: Joi.string()
      .required()
      .min(3)
      .max(30)
      .label("Reference"),
    A_Summary: Joi.string()
      //   .allow("")
      //   .optional()
      .required()
      .min(3)
      .max(200)
      .label("Summary"),
    // A_Description: Joi.string()
    //   .required()
    //   .min(3)
    //   .max(1000)
    //   .label("Description"),
    I_Image: Joi.any()
      .meta({ swaggerType: "file" })
      // .required()
      .optional()
      .label("Image"),
    N_PhotoSize01: Joi.number()
      .min(5000)
      .max(10000000)
      .optional(),
    waitingForFileUpload: Joi.boolean().optional(),
    N_ParentOrigin_ID: Joi.number()
      .allow("")
      .optional(),
    A_MenuCursorColour: Joi.string()
      .required()
      .min(5)
      .max(50)
      .label("Menu Cursor Colour")
    // N_BillboardLarge_ID: Joi.string()
    //   .min(1)
    //   .max(100)
    //   .label("BillboardIndentifier")
  };

  async populateMenus() {
    try {
      // window.alert("populateMenus-1", auth.getCurrentUser());

      let data = { ...this.state.data };
      let {
        N_BillboardOrigin_ID,
        N_HomeLocationGroup_ID,
        N_BillboardMenuBG_ID,
        N_ParentOrigin_ID
      } = this.state;

      await this.props.loadBillboardOrigins();

      await this.props.loadHomeLocationGroups();

      await this.props.loadBillboardMenuBGs();

      const menuId = this.props.match.params.id;
      if (menuId === "new") {
        data["N_BillboardOrigin_ID"] = 1;
        N_BillboardOrigin_ID = 1;
        data["N_HomeLocationGroup_ID"] = 1;
        N_HomeLocationGroup_ID = 1;
        data["N_BillboardMenuBG_ID"] = 1;
        N_BillboardMenuBG_ID = 1;
        data["N_ParentOrigin_ID"] = 1;
        N_ParentOrigin_ID = 1;

        this.setState({
          data,
          // billboardOrigin,
          // homeLocationGroup,
          N_BillboardOrigin_ID,
          N_HomeLocationGroup_ID,
          N_BillboardMenuBG_ID,
          N_ParentOrigin_ID,
          user: auth.getCurrentUser()
        });
        return;
      }

      // const { data: menu } = await getMenu(MenuId);
      await this.props.loadMenu(menuId);

      // const rawData = this.mapToViewModel(menu);

      // data["ID"] = rawData.ID;
      // N_BillboardOrigin_ID = rawData.N_BillboardOrigin_ID;
      // data["N_BillboardOrigin_ID"] = rawData.N_BillboardOrigin_ID;
      // N_HomeLocationGroup_ID = rawData.N_HomeLocationGroup_ID;
      // data["N_HomeLocationGroup_ID"] = rawData.N_HomeLocationGroup_ID;
      // data["N_Order_No"] = rawData.N_Order_No;
      // data["A_Reference"] = rawData.A_Reference;
      // data["A_Summary"] = rawData.A_Summary;
      // // data["A_Description"] = rawData.A_Description;
      // data["I_Image"] = rawData.I_Image;
      // //data["N_BillboardLarge_ID"] = rawData.N_BillboardLarge_ID;
      // N_ParentOrigin_ID = rawData.N_ParentOrigin_ID;
      // data["N_ParentOrigin_ID"] = rawData.N_ParentOrigin_ID;

      //console.log("populateMenus-1", N_BillboardOrigin_ID);

      // this.setState({
      //   data,
      //   // billboardOrigin,
      //   homeLocationGroup,
      //   N_BillboardOrigin_ID,
      //   N_HomeLocationGroup_ID,
      //   N_ParentOrigin_ID,
      //   user: auth.getCurrentUser()
      // });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async componentDidMount() {
    await this.populateMenus();
  }

  async componentWillReceiveProps(nextProps) {
    // window.alert("MenuForm, componentWillReceiveProps", nextProps.menuSuccess);

    let data = { ...this.state.data };
    let {
      N_BillboardOrigin_ID,
      N_HomeLocationGroup_ID,
      N_BillboardMenuBG_ID,
      N_ParentOrigin_ID
    } = this.state;

    if (
      this.props.match.params.id !== "new" &&
      this.state.data !== nextProps.menu
    ) {
      data["ID"] = nextProps.menu.ID;
      data["B_Billboard"] = nextProps.menu.B_Billboard;
      N_BillboardOrigin_ID = nextProps.menu.N_BillboardOrigin_ID;
      data["N_BillboardOrigin_ID"] = nextProps.menu.N_BillboardOrigin_ID;
      N_HomeLocationGroup_ID = nextProps.menu.N_HomeLocationGroup_ID;
      data["N_HomeLocationGroup_ID"] = nextProps.menu.N_HomeLocationGroup_ID;
      data["N_BillboardMenuBG_ID"] = nextProps.menu.N_BillboardMenuBG_ID;
      data["N_Order_No"] = nextProps.menu.N_Order_No;
      data["A_Reference"] = nextProps.menu.A_Reference;
      data["A_Summary"] = nextProps.menu.A_Summary;
      data["I_Image"] = nextProps.menu.I_Image;
      data["A_MenuCursorColour"] = nextProps.menu.A_MenuCursorColour;
      N_ParentOrigin_ID = nextProps.menu.N_ParentOrigin_ID;
      data["N_ParentOrigin_ID"] = nextProps.menu.N_ParentOrigin_ID;

      this.setState({
        data,
        billboardOrigins: await nextProps.billboardOrigins,
        homeLocationGroups: await nextProps.homeLocationGroups,
        billboardMenuBGs: await nextProps.billboardMenuBGs,
        user: auth.getCurrentUser()
      });
    } else {
      data["N_BillboardOrigin_ID"] = 1;
      N_BillboardOrigin_ID = 1;
      data["N_HomeLocationGroup_ID"] = 1;
      N_HomeLocationGroup_ID = 1;
      data["N_BillboardMenuBG_ID"] = 1;
      N_BillboardMenuBG_ID = 1;
      data["N_ParentOrigin_ID"] = 1;
      N_ParentOrigin_ID = 1;

      this.setState({
        data,
        billboardOrigins: await nextProps.billboardOrigins,
        homeLocationGroups: await nextProps.homeLocationGroups,
        billboardMenuBGs: await nextProps.billboardMenuBGs,
        user: auth.getCurrentUser()
      });
    }
  }

  // mapToViewModel(menu) {
  //   //console.log("mapToViewModel-1", menu);
  //   return {
  //     ID: menu.ID,
  //     N_BillboardOrigin_ID: menu.N_BillboardOrigin_ID,
  //     N_HomeLocationGroup_ID: menu.N_HomeLocationGroup_ID,
  //     N_Order_No: menu.N_Order_No,
  //     A_Reference: menu.A_Reference,
  //     A_Summary: menu.A_Summary,
  //     // A_Description: menu.A_Description,
  //     I_Image: menu.I_Image,
  //     N_ParentOrigin_ID: menu.N_ParentOrigin_ID
  //     //N_BillboardLarge_ID: menu.N_BillboardLarge_ID
  //   };
  // }

  doSubmit = async () => {
    try {
      const menuId = this.props.match.params.id;
      if (menuId === "new") {
        const currentDate = new Date();
        const month = currentDate.getMonth() + 1;
        const currentDateWithoutTime =
          month +
          "/" +
          currentDate.getDate() +
          "/" +
          currentDate.getFullYear() +
          " " +
          currentDate.getHours() +
          ":" +
          currentDate.getMinutes();

        await this.props.addMenu(
          this.state.data,
          auth.getCurrentUser().a_name,
          currentDateWithoutTime
        );
      } else {
        const currentDate = new Date();
        const month = currentDate.getMonth() + 1;
        const currentDateWithoutTime =
          month +
          "/" +
          currentDate.getDate() +
          "/" +
          currentDate.getFullYear() +
          " " +
          currentDate.getHours() +
          ":" +
          currentDate.getMinutes();

        await this.props.changeMenu(
          this.state.data,
          auth.getCurrentUser().a_name,
          currentDateWithoutTime
        );
      }
      // await saveMenu(this.state.data);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.title = ex.response.data;
        this.setState({ errors });
      }
    }

    // this.props.history.push("/menusList");
    //
    // Babak's Note: Use below rather than above:-
    await this.props.history.goBack();
  };

  clearImages = () => {
    let obj = this.state.data;
    obj["I_Image"] = "";
    //
    // Babak's Note: Unquate below line to make Image mandatory:-
    // obj["N_PhotoSize01"] = "";
    this.setState({ data: obj });
  };

  cancelForm = async () => {
    this.props.history.push("/menusList");
    //  await this.props.history.goBack();
  };

  constructor() {
    // ...
    super();

    // this.handleRadioGroupChange = this.handleRadioGroupChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    // const billboardOrigins = this.props.billboardOrigins;

    // const homeLocationGroups = this.props.homeLocationGroups;

    console.log(
      "menu render : ",
      this.state.N_BillboardOrigin_ID,
      this.state.data["N_BillboardOrigin_ID"]
    );

    // Babak's Note: *** Below 3 lines will stop rendering of child component 'TableBodyAppoints' section
    //               'console.log("Hello 04 : ");' first *** :-
    const { length: count } = this.props.billboardOrigins;
    //if (count === 0) return <p></p>;
    if (count === 0)
      return <p>There are currently no Billboard Origins in the database.</p>;

    return (
      <div
        className="container"
        // style={{ marginTop: 80, marginBottom: 40, direction: "rtl" }}
        style={{ marginTop: "160px", marginBottom: "100px" }}
      >
        <h1>Menu Form</h1>
        <br />
        <form onSubmit={this.handleSubmit}>
          {this.props.match.params.id === "new"
            ? this.renderRadioGroup(
                "N_BillboardOrigin_ID",
                "A_Origin",
                "Billboard Origin",
                this.props.billboardOrigins,
                0
              )
            : this.renderRadioGroup(
                "N_BillboardOrigin_ID",
                "A_Origin",
                "Billboard Origin",
                this.props.billboardOrigins,
                99
              )}
          <br />
          {this.renderRadioGroup(
            "N_HomeLocationGroup_ID",
            "A_Group",
            "Home Location Group",
            this.props.homeLocationGroups,
            99
          )}
          <br />
          {this.renderInput("N_Order_No", "Order Number", "number")}
          <br />
          {this.renderInput("A_Reference", "Reference")}

          <br />

          {this.renderInput("A_Summary", "Summary")}
          {/* {this.renderInput("A_Description", "Description")} */}
          <br />

          {this.renderRadioGroup(
            "N_BillboardMenuBG_ID",
            "A_Background",
            "Menu Background",
            this.props.billboardMenuBGs,
            0
          )}

          {this.renderInputColourPickerMenu(
            "A_MenuCursorColour",
            "Menu Cursor Colour"
          )}
          <br />

          {this.renderImageFile("I_Image", "Upload Image")}
          {this.state.waitingForFileUpload && (
            <div>
              <span>Uploading file...</span>
            </div>
          )}

          <button
            type="button"
            className="btn btn-warning"
            onClick={this.clearImages}
          >
            Clear Image
          </button>
          <br />
          <img style={{ maxWidth: "300px" }} src={this.state.data.I_Image} />
          {/* <img
            style={{ maxWidth: "300px" }}
            src={`data:image/jpeg;base64,${this.state.data.I_Photo01}`}
          /> */}
          <br />

          <br />
          {this.renderCheckbox("B_Billboard", "Display Menu")}
          <br />

          <div>
            <br />
          </div>

          {this.renderButton("Submit")}
          {this.renderButtonCancel("Cancel")}
          <br />
        </form>
      </div>
    );
  }
}

//
// Babak's Notes: goto tutorial directory '11-Integration with React' file '5- Connecting Components Using react-redux'
const mapStateToProps = state => ({
  //   menus: state.entities.menus.list.filter(menu => !menu.resolved)
  menu: state.entities.menus.listSingleRow,
  billboardOrigins: state.entities.billboardOrigins.list,
  homeLocationGroups: state.entities.homeLocationGroups.list,
  billboardMenuBGs: state.entities.billboardMenuBGs.list
});

const mapDispatchToProps = dispatch => ({
  loadMenu: id => dispatch(loadMenu(id)),
  loadBillboardOrigins: () => dispatch(loadBillboardOrigins()),
  loadHomeLocationGroups: () => dispatch(loadHomeLocationGroups()),
  loadBillboardMenuBGs: () => dispatch(loadBillboardMenuBGs()),
  addMenu: (menu, user, currentDate) =>
    dispatch(addMenu(menu, user, currentDate)),
  changeMenu: (menu, user, currentDate) =>
    dispatch(changeMenu(menu, user, currentDate))
});

// Container Component
//   Presentation (Menus)
//
// Babak's Notes: Below I populate 'this.props.menus' and 'this.props.loadMenus()':-
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuForm);
