import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import { toast } from "react-toastify";
import ArticlesTable from "./articlesTable";
import Pagination from "../common/pagination";
import { paginate } from "../../utils/paginate";
import {
  loadArticles,
  loadParentOriginIDMenus,
  deleteArticle
} from "../../store/articles";
import { connect } from "react-redux";
import _ from "lodash";
import SearchBox from "../searchBox";

class ArticlesList extends Component {
  state = {
    articles: [],
    articleForm: false,
    articleID: 0,
    sumOfArticleID: 0,
    currentPage: 1,
    pageSize: 8,
    searchQuery: "",
    N_BillboardLarge_ID: "",
    N_ParentOrigin_ID: "",
    sortColumn: { path: "N_Order_No", order: "asc" }
  };

  async componentDidMount() {
    console.log(
      "componentDidMount Articles 01 : ",
      this.props.match.params.sumOfArticleID
    );

    // const billboardLargeID = this.props.match.params.sumOfArticleID;

    // const { data: articles } = await getArticlesID(billboardLargeID);
    // console.log("componentDidMount Articles 02 : ", articles);

    // const { data: parentOrigin } = await getParentOriginIDMenus(
    //   billboardLargeID
    // );
    // const rawParentOriginData = this.mapToViewModel(parentOrigin);
    // const N_ParentOrigin_ID = rawParentOriginData.N_ParentOrigin_ID;
    // const N_BillboardLarge_ID = rawParentOriginData.N_BillboardLarge_ID;

    // this.setState({ articles, N_ParentOrigin_ID, N_BillboardLarge_ID });

    document.body.style.backgroundImage = `url()`;
    document.body.style.backgroundColor = "white";
    document.body.style.backgroundPosition = "center center";
    document.body.style.backgroundAttachment = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundSize = "cover";

    const billboardLargeID = this.props.match.params.sumOfArticleID;

    // window.alert("GroupsList, componentDidMount : ");

    await this.props.loadArticles(billboardLargeID);

    await this.props.loadParentOriginIDMenus(billboardLargeID);
  }

  //   mapToViewModel(parentOrigin) {
  //     //console.log("mapToViewModel-1", month);
  //     return {
  //       N_ParentOrigin_ID: parentOrigin.N_ParentOrigin_ID,
  //       N_BillboardLarge_ID: parentOrigin.N_BillboardLarge_ID
  //     };
  //   }

  handleUpdate = async article => {
    try {
      this.setState({
        articleForm: true,
        articleID: article.ID,
        sumOfArticleID: this.props.match.params.sumOfArticleID
      });
      // window.location = `/articleForm/${article.ID}/${this.props.match.params.sumOfArticleID}`;
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...ex.errors };
        this.setState({ errors });
      }
    }
  };

  // handleDelete = async article => {
  //   const originalArticles = this.state.articles;
  //   const articles = originalArticles.filter(m => m.ID !== article.ID);
  //   this.setState({ articles });

  //   try {
  //     // window.alert(`article ID : ${article.ID}`);
  //     await deleteArticle(article.ID);
  //   } catch (ex) {
  //     if (ex.response && ex.response.status === 404)
  //       toast.error("This Article has already been deleted.");

  //     this.setState({ articles: originalArticles });
  //   }
  // };

  handlePageChange = page => {
    //console.log(page);
    this.setState({ currentPage: page });
  };

  //
  // Babak's Note: We have put this 'input' field inside of the 'SearchBox' component, this component raises
  //               an event and in the we get an string. So below 'query' will contain the string the user
  //               types in the search field.
  handleSearch = query => {
    console.log("query : ", query);
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  handleSort = sortColumn => {
    console.log("handleSort : ", sortColumn);
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;

    console.log("searchQuery 2 : ", searchQuery);

    const allArticles = this.props.articles;

    let filtered = allArticles;
    if (searchQuery)
      filtered = allArticles.filter(m =>
        m.A_Reference.toLowerCase().includes(searchQuery.toLowerCase())
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const articles = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: articles };
  };

  render() {
    //console.log("render articles");

    if (this.state.articleForm === true) {
      return (
        <Redirect
          to={`/articlesList/${this.state.articleID}/${this.state.sumOfArticleID}`}
        />
      );
    }

    // if (this.props.articles.length === 0)
    //   return <div className="hourGlass"></div>;

    const { length: count } = this.state.articles;

    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery
      //   N_ParentOrigin_ID: ParentOriginID
    } = this.state;

    const parentOriginID = this.props.parentOriginIDMenus.N_ParentOrigin_ID;
    const billboardLarge_ID = this.props.parentOriginIDMenus
      .N_BillboardLarge_ID;

    //
    //Babak's Note: Below I renamed 'data' to 'articles'
    // console.log("searchQuery 1 : ", searchQuery);

    const { totalCount, data: articlesList } = this.getPagedData();

    // if (totalCount === 0) return <div className="hourGlass"></div>;

    return (
      <div className="container">
        <div className="row">
          <div
            className="col"
            style={{ marginTop: "160px", marginBottom: "100px" }}
          >
            <h1>Articles</h1>
            <br />
            <Link
              to={`/articlesList/new/${this.props.match.params.sumOfArticleID}`}
              className="btn btn-primary"
              style={{ marginBottom: 20 }}
            >
              New Article
            </Link>
            <p>Showing {totalCount} Articles in the database.</p>
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
            <ArticlesTable
              articlesList={articlesList}
              sortColumn={sortColumn}
              onDelete={article => this.props.deleteArticle(article)}
              onUpdate={this.handleUpdate}
              onSort={this.handleSort}
            />
            <div className="paginationBar">
              <Pagination
                itemsCount={totalCount}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={this.handlePageChange}
              />
            </div>
            <div className="backBar">
              <Link
                to={
                  parentOriginID === 1
                    ? "/menusList"
                    : parentOriginID === 2
                    ? `/groups/groupsList/${billboardLarge_ID}`
                    : parentOriginID === 3
                    ? `/sumOfArticles/sumOfArticlesList/${billboardLarge_ID}`
                    : "/menusList"
                }
                className="btn btn-primary"
                style={{ marginBottom: "100px" }}
              >
                {parentOriginID === 1
                  ? "Back to Menus"
                  : parentOriginID === 2
                  ? "Back to Groups"
                  : parentOriginID === 3
                  ? "Back to Sum Of Articles"
                  : "--"}
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// Babak's Notes: goto tutorial directory '11-Integration with React' file '5- Connecting Components Using react-redux'
const mapStateToProps = state => ({
  //   bugs: state.entities.menus.list.filter(bug => !bug.resolved)
  articles: state.entities.articles.list,
  parentOriginIDMenus: state.entities.articles.listParentOriginIDMenus
});

const mapDispatchToProps = dispatch => ({
  loadArticles: billboardLargeID => dispatch(loadArticles(billboardLargeID)),
  loadParentOriginIDMenus: billboardLargeID =>
    dispatch(loadParentOriginIDMenus(billboardLargeID)),
  deleteArticle: article => dispatch(deleteArticle(article))
});

// Container Component
//   Presentation (Bugs)
//
// Babak's Notes: Below I populate 'this.props.bugs' and 'this.props.loadBugs()':-
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticlesList);
