import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
import moment from "moment";

const slice = createSlice({
  name: "homeBillboards",
  initialState: {
    list: [],
    listSingleRow: [],
    listOnNavvar: [],
    loading: false,
    lastFetch: null
  },
  reducers: {
    //
    // Babak's Note: By adding 'homeBillboardsRequested' or 'homeBillboardsReceived' below, a action is created for us:-
    // actions => action handlers. The 'homeBillboards' below represents the 'state':-
    homeBillboardsRequested: (homeBillboards, action) => {
      console.log("homeBillboardsRequested : ", action.payload);
      homeBillboards.loading = true;
    },
    homeBillboardsReceived: (homeBillboards, action) => {
      console.log("homeBillboardsReceived : ", action.payload);
      homeBillboards.list = action.payload;
      homeBillboards.loading = false;
      homeBillboards.lastFetch = Date.now();
    },
    homeBillboardsRequestedFailed: (homeBillboards, action) => {
      homeBillboards.loading = false;
      //   window.alert(`No HomeBillboards found.`);
    },

    homeBillboardsOnNavbarRequested: (homeBillboards, action) => {
      console.log("homeBillboardsOnNavbarRequested : ", action.payload);
      homeBillboards.loading = true;
    },
    homeBillboardsOnNavbarReceived: (homeBillboards, action) => {
      console.log("homeBillboardsOnNavbarReceived : ", action.payload);
      homeBillboards.listOnNavvar = action.payload;
      homeBillboards.loading = false;
      homeBillboards.lastFetch = Date.now();
    },
    homeBillboardsOnNavbarRequestedFailed: (homeBillboards, action) => {
      homeBillboards.loading = false;
      //   window.alert(`No HomeBillboards found.`);
    },

    homeBillboardRequested: (homeBillboard, action) => {
      console.log("homeBillboardRequested : ", action.payload);
      homeBillboard.loading = true;
    },
    homeBillboardReceived: (homeBillboard, action) => {
      console.log("homeBillboardReceived : ", action.payload);
      homeBillboard.listSingleRow = action.payload;
      homeBillboard.loading = false;
      homeBillboard.lastFetch = Date.now();
    },
    homeBillboardRequestedFailed: (homeBillboard, action) => {
      homeBillboard.loading = false;
      //   window.alert(`No HomeBillboard found.`);
    },
    homeBillboardChangeRequested: (homeBillboard, action) => {
      console.log("homeBillboardChangeRequested : ", action.payload);
      homeBillboard.loading = true;
    },
    homeBillboardChangeFailed: (homeBillboard, action) => {
      homeBillboard.loading = false;

      // //
      // // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete homeBillboard api at the back end.
      // const { ID: homeBillboardId, A_Name: homeBillboardName } = action.payload;

      // window.alert(`HomeBillboard '${homeBillboardName}' no longer exists.`);

      // const index = homeBillboard.list.findIndex(homeBillboard => homeBillboard.ID === homeBillboardId);
      // //console.log("homeBillboardDeleted ha ", index);
      // homeBillboard.list.splice(index, 1);
    },
    homeBillboardChanged: (homeBillboard, action) => {
      homeBillboard.loading = false;
    },
    homeBillboardDeleteRequested: (homeBillboard, action) => {
      console.log("homeBillboardDeleteRequested : ", action.payload);
      homeBillboard.loading = true;
    },
    homeBillboardDeleteFailed: (homeBillboards, action) => {
      homeBillboards.loading = false;

      //
      // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete homeBillboard api at the back end.
      const { ID: homeBillboardId, A_Name: homeBillboardName } = action.payload;

      window.alert(
        `An issue has occured with homeBillboard '${homeBillboardName}'. ${action.payload}`
      );

      const index = homeBillboards.list.findIndex(
        homeBillboard => homeBillboard.ID === homeBillboardId
      );
      //console.log("homeBillboardDeleted ha ", index);
      homeBillboards.list.splice(index, 1);
    },
    homeBillboardDeleted: (homeBillboards, action) => {
      //
      // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete homeBillboard api at the back end.
      const { ID: homeBillboardID } = action.payload;
      // console.log("homeBillboardDeleted ha ", action.payload, homeBillboardID, homeBillboards);
      const index = homeBillboards.list.findIndex(
        homeBillboard => homeBillboard.ID === homeBillboardID
      );
      //console.log("homeBillboardDeleted, ha! ha! ", index);
      homeBillboards.list.splice(index, 1);
    },
    homeBillboardAddRequested: (homeBillboard, action) => {
      console.log("homeBillboardAddRequested : ", action.payload);
      homeBillboard.loading = true;
    },
    homeBillboardAddFailed: (homeBillboard, action) => {
      homeBillboard.loading = false;
    },
    //
    // Babak's Note: The 'homeBillboards' below represents the 'state':-
    homeBillboardAdded: (homeBillboards, action) => {
      console.log("homeBillboardAdded : ", action.payload);
      homeBillboards.list.push(action.payload);
    }
    // homeBillboardAdded: (homeBillboards, action) => {
    //   homeBillboards.list.push({
    //     id: ++lastId,
    //     description: action.payload.description,
    //     resolved: false
    //   });
    // },
    // homeBillboardDeleted: (homeBillboards, action) => {
    //   console.log("homeBillboardDeleted ha ", action.payload.ID);
    //   homeBillboards.list.filter(homeBillboard => homeBillboard.ID !== action.payload.ID);
    // },
  }
});
const {
  homeBillboardAddRequested,
  homeBillboardAdded,
  homeBillboardAddFailed,
  homeBillboardChangeRequested,
  homeBillboardChanged,
  homeBillboardChangeFailed,
  homeBillboardDeleteRequested,
  homeBillboardDeleted,
  homeBillboardDeleteFailed,
  homeBillboardsReceived,
  homeBillboardsRequested,
  homeBillboardsRequestedFailed,
  homeBillboardsOnNavbarReceived,
  homeBillboardsOnNavbarRequested,
  homeBillboardsOnNavbarRequestedFailed,
  homeBillboardReceived,
  homeBillboardRequested,
  homeBillboardRequestedFailed
} = slice.actions;
export default slice.reducer;

//
// Action Creators
const url = "/homeBillboards";

export const loadHomeBillboards = () => (dispatch, getState) => {
  // const { lastFetch } = getState().entities.homeBillboards;

  // console.log("loadHomeBillboards lastFetch : ", lastFetch);

  // //
  // // Babak's Note: Below we check with 'moment' that catch that if this request was already fetched less than 10 minutes ago then do not fetch again:-
  // const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  // if (diffInMinutes < 10) return;

  dispatch(
    apiCallBegan({
      url: url + "/main",
      onStart: homeBillboardsRequested.type,
      method: "get",
      onSuccess: homeBillboardsReceived.type,
      onError: homeBillboardsRequestedFailed.type
    })
  );
};

export const loadHomeBillboard = homeBillboardID => (dispatch, getState) => {
  // const { lastFetch } = getState().entities.homeBillboards;

  //console.log("loadHomeBillboard : ", homeBillboardID);

  // //
  // // Babak's Note: Below we check with 'moment' that catch that if this request was already fetched less than 10 minutes ago then do not fetch again:-
  // const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  // if (diffInMinutes < 10) return;

  dispatch(
    apiCallBegan({
      url: url + "/" + homeBillboardID,
      onStart: homeBillboardRequested.type,
      method: "get",
      onSuccess: homeBillboardReceived.type,
      onError: homeBillboardRequestedFailed.type
    })
  );
};

//
// Babak's Note: Below function 'apiCallBegan' is returned back by which function (in this case from within 'index.js') calls 'addHomeBillboard':-
export const addHomeBillboard = (homeBillboard, user, currentDate) =>
  apiCallBegan({
    url,
    method: "post",
    onStart: homeBillboardAddRequested.type,
    //data: homeBillboard,
    data: {
      N_OrderNo: homeBillboard.N_OrderNo,
      N_RadioGrpDirections_ID: homeBillboard.N_RadioGrpDirections_ID,
      N_HomeBillboards_ID: homeBillboard.N_HomeBillboards_ID,
      A_Reference: homeBillboard.A_Reference,
      A_Summary: homeBillboard.A_Summary,
      A_Description: homeBillboard.A_Description,
      A_HoverSummary01: homeBillboard.A_HoverSummary01,
      A_HoverSummary01_Link: homeBillboard.A_HoverSummary01_Link,
      A_HoverSummary02: homeBillboard.A_HoverSummary02,
      A_HoverSummary02_Link: homeBillboard.A_HoverSummary02_Link,
      A_HoverSummary03: homeBillboard.A_HoverSummary03,
      A_HoverSummary03_Link: homeBillboard.A_HoverSummary03_Link,
      A_HoverSummary04: homeBillboard.A_HoverSummary04,
      A_HoverSummary04_Link: homeBillboard.A_HoverSummary04_Link,
      A_HoverSummary05: homeBillboard.A_HoverSummary05,
      A_HoverSummary05_Link: homeBillboard.A_HoverSummary05_Link,
      A_HoverSummary06: homeBillboard.A_HoverSummary06,
      A_HoverSummary07: homeBillboard.A_HoverSummary07,
      A_HoverSummary08: homeBillboard.A_HoverSummary08,
      A_HoverSummary08_Link: homeBillboard.A_HoverSummary08_Link,
      B_HoverSummary08_External: homeBillboard.B_HoverSummary08_External,
      A_BGC: homeBillboard.A_BGC,
      A_BGC_Shadow: homeBillboard.A_BGC_Shadow,
      B_BGC_Shadow_Off: homeBillboard.B_BGC_Shadow_Off,
      A_TextFC: homeBillboard.A_TextFC,
      I_Image: homeBillboard.I_Image,
      I_ImageOnImage: homeBillboard.I_ImageOnImage,
      A_Button_Link: homeBillboard.A_Button_Link,
      B_Button_Link_External: homeBillboard.B_Button_Link_External,
      A_Button: homeBillboard.A_Button,
      B_GoogleMapHomeBiilboard: homeBillboard.B_GoogleMapHomeBiilboard,
      D_Inserted: currentDate,
      A_Inserted_By: user
    },
    onSuccess: homeBillboardAdded.type,
    onError: homeBillboardAddFailed.type
  });

export const getHomeBillboards = createSelector(
  state => state.entities.homeBillboards,
  //
  // Babak's Note: In the below line, by installing above 'reselect' (at the top of this file) if the list of (homeBillboards, projects) has not changed then do not requery again:-
  // (homeBillboards) => homeBillboards.list.filter(homeBillboard => !homeBillboard.B_Active)
  homeBillboards => homeBillboards.list
);

export const changeHomeBillboard = (homeBillboard, user, currentDate) =>
  apiCallBegan({
    url: url + "/" + homeBillboard.ID,
    method: "put",
    onStart: homeBillboardChangeRequested.type,
    data: {
      N_OrderNo: homeBillboard.N_OrderNo,
      N_RadioGrpDirections_ID: homeBillboard.N_RadioGrpDirections_ID,
      N_HomeBillboards_ID: homeBillboard.N_HomeBillboards_ID,
      A_Reference: homeBillboard.A_Reference,
      A_Summary: homeBillboard.A_Summary,
      A_Description: homeBillboard.A_Description,
      A_HoverSummary01: homeBillboard.A_HoverSummary01,
      A_HoverSummary01_Link: homeBillboard.A_HoverSummary01_Link,
      A_HoverSummary02: homeBillboard.A_HoverSummary02,
      A_HoverSummary02_Link: homeBillboard.A_HoverSummary02_Link,
      A_HoverSummary03: homeBillboard.A_HoverSummary03,
      A_HoverSummary03_Link: homeBillboard.A_HoverSummary03_Link,
      A_HoverSummary04: homeBillboard.A_HoverSummary04,
      A_HoverSummary04_Link: homeBillboard.A_HoverSummary04_Link,
      A_HoverSummary05: homeBillboard.A_HoverSummary05,
      A_HoverSummary05_Link: homeBillboard.A_HoverSummary05_Link,
      A_HoverSummary06: homeBillboard.A_HoverSummary06,
      A_HoverSummary07: homeBillboard.A_HoverSummary07,
      A_HoverSummary08: homeBillboard.A_HoverSummary08,
      A_HoverSummary08_Link: homeBillboard.A_HoverSummary08_Link,
      B_HoverSummary08_External: homeBillboard.B_HoverSummary08_External,
      A_BGC: homeBillboard.A_BGC,
      A_BGC_Shadow: homeBillboard.A_BGC_Shadow,
      B_BGC_Shadow_Off: homeBillboard.B_BGC_Shadow_Off,
      A_TextFC: homeBillboard.A_TextFC,
      I_Image: homeBillboard.I_Image,
      I_ImageOnImage: homeBillboard.I_ImageOnImage,
      A_Button_Link: homeBillboard.A_Button_Link,
      B_Button_Link_External: homeBillboard.B_Button_Link_External,
      A_Button: homeBillboard.A_Button,
      B_GoogleMapHomeBiilboard: homeBillboard.B_GoogleMapHomeBiilboard,
      D_Updated: currentDate,
      A_Updated_By: user
    },
    onSuccess: homeBillboardChanged.type,
    onError: homeBillboardChangeFailed.type
  });

export const deleteHomeBillboard = homeBillboard =>
  apiCallBegan({
    url: url + "/" + homeBillboard.ID,
    method: "delete",
    onStart: homeBillboardDeleteRequested.type,
    data: { ID: homeBillboard.ID, A_Reference: homeBillboard.A_Reference },
    onSuccess: homeBillboardDeleted.type,
    onError: homeBillboardDeleteFailed.type
  });
