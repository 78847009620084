import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import MenusTable from "./menusTable";
import Pagination from "../common/pagination";
import { paginate } from "../../utils/paginate";
import { loadMenus, deleteMenu } from "../../store/menus";
import { connect } from "react-redux";
import _ from "lodash";
import SearchBox from "../searchBox";
import { Redirect } from "react-router";

class MenusList extends Component {
  state = {
    menus: [],
    menuForm: false,
    groups: false,
    sumOfArticles: false,
    articles: false,
    menuID: 0,
    currentPage: 1,
    pageSize: 8,
    searchQuery: "",
    sortColumn: { path: "N_Order_No", order: "asc" }
  };

  async componentDidMount() {
    // window.alert("componentDidMount Menus 01 : ");

    document.body.style.backgroundImage = `url()`;
    document.body.style.backgroundColor = "white";
    document.body.style.backgroundPosition = "center center";
    document.body.style.backgroundAttachment = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundSize = "cover";

    const billLocationGroupID = 1;

    // window.alert("MenusList, componentDidMount : ");

    await this.props.loadMenus(billLocationGroupID);
  }

  handleGroup = async menu => {
    try {
      if (menu.N_BillboardOrigin_ID === 1) {
        this.setState({ groups: true, menuID: menu.ID });
        //window.location = `/groups/groups/${menu.ID}`;
      } else if (menu.N_BillboardOrigin_ID === 2) {
        this.setState({ sumOfArticles: true, menuID: menu.ID });
        //window.location = `/sumOfArticles/sumOfArticles/${menu.ID}`;
      } else if (menu.N_BillboardOrigin_ID === 3) {
        this.setState({ articles: true, menuID: menu.ID });
        //window.location = `/articles/articles/${menu.ID}`;
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...ex.errors };
        this.setState({ errors });
      }
    }
  };

  handleUpdate = async menu => {
    try {
      // window.alert(`menuList, handleUpdate : ${menu.ID}`);
      this.setState({ menuForm: true, menuID: menu.ID });
      // window.location = `/menusList/${menu.ID}`;
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...ex.errors };
        this.setState({ errors });
      }
    }
  };

  // handleDelete = async menu => {
  //   const originalMenus = this.state.menus;
  //   const menus = originalMenus.filter(m => m.ID !== menu.ID);
  //   this.setState({ menus });

  //   try {
  //     // await deleteMenu(menu.ID);
  //   } catch (ex) {
  //     if (ex.response && ex.response.status === 404)
  //       toast.error("This menu has already been deleted.");

  //     this.setState({ menus: originalMenus });
  //   }
  // };

  handlePageChange = page => {
    //console.log(page);
    this.setState({ currentPage: page });
  };

  //
  // Babak's Note: We have put this 'input' field inside of the 'SearchBox' component, this component raises
  //               an event and in the we get an string. So below 'query' will contain the string the user
  //               types in the search field.
  handleSearch = query => {
    console.log("query : ", query);
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  handleSort = sortColumn => {
    console.log("handleSort : ", sortColumn);
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;

    console.log("searchQuery 2 : ", searchQuery);

    const allMenus = this.props.menus;

    let filtered = allMenus;
    if (searchQuery)
      filtered = allMenus.filter(m =>
        m.A_Reference.toLowerCase().includes(searchQuery.toLowerCase())
      );

    //
    // Babak's Note: lodash used:-
    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const menus = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: menus };
  };

  render() {
    // window.alert("render menus 01 : ", this.state.groups, this.state.menuID);

    if (this.state.groups === true) {
      return <Redirect push to={`/groups/groupsList/${this.state.menuID}`} />;
    }
    if (this.state.sumOfArticles === true) {
      return (
        <Redirect
          push
          to={`/sumOfArticles/sumOfArticlesList/${this.state.menuID}`}
        />
      );
    }
    if (this.state.articles === true) {
      return (
        <Redirect push to={`/articles/articlesList/${this.state.menuID}`} />
      );
    }
    if (this.state.menuForm === true) {
      // window.alert(`menuList, render() : ${this.state.menuID}`);
      return <Redirect push to={`/menusList/${this.state.menuID}`} />;
    }

    if (this.props.menus.length === 0) return <div className="hourGlass"></div>;

    // const { length: count } = this.state.menus;

    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;

    //
    //Babak's Note: Below I renamed 'data' to 'menus'
    // console.log("searchQuery 1 : ", searchQuery);

    const { totalCount, data: menusList } = this.getPagedData();

    console.log("render menus 02 : ", menusList);

    return (
      <div className="container">
        <div className="row">
          <div
            className="col"
            style={{ marginTop: "160px", marginBottom: "100px" }}
          >
            <h1>Menus</h1>
            <br />

            {totalCount <= 7 ? (
              <Link
                to="/menusList/new"
                className="btn btn-primary"
                style={{ marginBottom: 20 }}
              >
                New Menu
              </Link>
            ) : (
              <button
                title="Maximum number of Menus reached!"
                disabled
                className="btn btn-danger disabled clickableNon"
                style={{ marginBottom: 20 }}
              >
                New Menu
              </button>
            )}

            <p>Showing {totalCount} menus in the database.</p>
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
            <MenusTable
              menusList={menusList}
              sortColumn={sortColumn}
              onGroup={this.handleGroup}
              onUpdate={this.handleUpdate}
              onDelete={menu => this.props.deleteMenu(menu)}
              onSort={this.handleSort}
            />
            <div className="paginationBar">
              <Pagination
                itemsCount={totalCount}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={this.handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

//
// Babak's Notes: goto tutorial directory '11-Integration with React' file '5- Connecting Components Using react-redux'
const mapStateToProps = state => ({
  //   bugs: state.entities.menus.list.filter(bug => !bug.resolved)
  menus: state.entities.menus.list
});

const mapDispatchToProps = dispatch => ({
  loadMenus: billLocationGroupID => dispatch(loadMenus(billLocationGroupID)),
  deleteMenu: menu => dispatch(deleteMenu(menu))
});

// Container Component
//   Presentation (Bugs)
//
// Babak's Notes: Below I populate 'this.props.bugs' and 'this.props.loadBugs()':-
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenusList);
