import http from "./httpService";

// import { apiUrl } from "../config.json";
// const apiEndpoint = apiUrl + "/homeBillboards";
// const apiEndpointHomeBillboardsMain = apiUrl + "/homeBillboards/main";

const apiEndpointHomeBillboardChildsMains = "/homeBillboardChildsMains";

function HomeBillboardChildsMainsUrl(id) {
  return `${apiEndpointHomeBillboardChildsMains}/main/${id}`;
}

export function getHomeBillboardChildsMains(homeBillboardID) {
  //console.log("getHomeBillboardChildsMains : ", HomeBillboardChildsMainsUrl(homeBillboardID));

  return http.get(HomeBillboardChildsMainsUrl(homeBillboardID));
}
