import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
import moment from "moment";

const slice = createSlice({
  name: "billboardOrigins",
  initialState: {
    list: [],
    loading: false,
    lastFetch: null
  },
  reducers: {
    //
    // Babak's Note: By adding 'billboardOriginsRequested' or 'billboardOriginsReceived' below, a action is created for us:-
    // actions => action handlers. The 'billboardOrigins' below represents the 'state':-
    billboardOriginsRequested: (billboardOrigins, action) => {
      console.log("billboardOriginsRequested : ", action.payload);
      billboardOrigins.loading = true;
    },
    billboardOriginsReceived: (billboardOrigins, action) => {
      console.log("billboardOriginsReceived : ", action.payload);
      billboardOrigins.list = action.payload;
      billboardOrigins.loading = false;
      billboardOrigins.lastFetch = Date.now();
    },
    billboardOriginsRequestedFailed: (billboardOrigins, action) => {
      billboardOrigins.loading = false;
      // window.alert(`No BillboardOrigins found.`);
    },
    billboardOriginRequested: (billboardOrigin, action) => {
      console.log("billboardOriginRequested : ", action.payload);
      billboardOrigin.loading = true;
    },
    billboardOriginReceived: (billboardOrigin, action) => {
      console.log("billboardOriginReceived : ", action.payload);
      billboardOrigin.list = action.payload;
      billboardOrigin.loading = false;
      billboardOrigin.lastFetch = Date.now();
    },
    billboardOriginRequestedFailed: (billboardOrigin, action) => {
      billboardOrigin.loading = false;
      // window.alert(`No BillboardOrigin found.`);
    },
    billboardOriginChangeRequested: (billboardOrigin, action) => {
      console.log("billboardOriginChangeRequested : ", action.payload);
      billboardOrigin.loading = true;
    },
    billboardOriginChangeFailed: (billboardOrigin, action) => {
      billboardOrigin.loading = false;

      // //
      // // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete billboardOrigin api at the back end.
      // const { ID: billboardOriginId, A_Origin: billboardOriginName } = action.payload;

      // window.alert(`BillboardOrigin '${billboardOriginName}' no longer exists.`);

      // const index = billboardOrigin.list.findIndex(billboardOrigin => billboardOrigin.ID === billboardOriginId);
      // //console.log("billboardOriginDeleted ha ", index);
      // billboardOrigin.list.splice(index, 1);
    },
    billboardOriginChanged: (billboardOrigin, action) => {
      billboardOrigin.loading = false;
    },
    billboardOriginDeleteRequested: (billboardOrigin, action) => {
      console.log("billboardOriginDeleteRequested : ", action.payload);
      billboardOrigin.loading = true;
    },
    billboardOriginDeleteFailed: (billboardOrigins, action) => {
      billboardOrigins.loading = false;

      //
      // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete billboardOrigin api at the back end.
      const {
        ID: billboardOriginId,
        A_Origin: billboardOriginName
      } = action.payload;

      window.alert(
        `An issue has occured with billboardOrigin '${billboardOriginName}'. ${action.payload}`
      );

      const index = billboardOrigins.list.findIndex(
        billboardOrigin => billboardOrigin.ID === billboardOriginId
      );
      //console.log("billboardOriginDeleted ha ", index);
      billboardOrigins.list.splice(index, 1);
    },
    billboardOriginDeleted: (billboardOrigins, action) => {
      //
      // Babak's Notes: The 'action.payload' contains the returned data from database. Note: look what I am returning from delete billboardOrigin api at the back end.
      const { ID: billboardOriginID } = action.payload;
      // console.log("billboardOriginDeleted ha ", action.payload, billboardOriginID, billboardOrigins);
      const index = billboardOrigins.list.findIndex(
        billboardOrigin => billboardOrigin.ID === billboardOriginID
      );
      //console.log("billboardOriginDeleted, ha! ha! ", index);
      billboardOrigins.list.splice(index, 1);
    },
    billboardOriginAddRequested: (billboardOrigin, action) => {
      console.log("billboardOriginAddRequested : ", action.payload);
      billboardOrigin.loading = true;
    },
    billboardOriginAddFailed: (billboardOrigin, action) => {
      billboardOrigin.loading = false;
    },
    //
    // Babak's Note: The 'billboardOrigins' below represents the 'state':-
    billboardOriginAdded: (billboardOrigins, action) => {
      console.log("billboardOriginAdded : ", action.payload);
      billboardOrigins.list.push(action.payload);
    }
    // billboardOriginAdded: (billboardOrigins, action) => {
    //   billboardOrigins.list.push({
    //     id: ++lastId,
    //     description: action.payload.description,
    //     resolved: false
    //   });
    // },
    // billboardOriginDeleted: (billboardOrigins, action) => {
    //   console.log("billboardOriginDeleted ha ", action.payload.ID);
    //   billboardOrigins.list.filter(billboardOrigin => billboardOrigin.ID !== action.payload.ID);
    // },
  }
});
const {
  billboardOriginAddRequested,
  billboardOriginAdded,
  billboardOriginAddFailed,
  billboardOriginChangeRequested,
  billboardOriginChanged,
  billboardOriginChangeFailed,
  billboardOriginDeleteRequested,
  billboardOriginDeleted,
  billboardOriginDeleteFailed,
  billboardOriginsReceived,
  billboardOriginsRequested,
  billboardOriginsRequestedFailed,
  billboardOriginReceived,
  billboardOriginRequested,
  billboardOriginRequestedFailed
} = slice.actions;
export default slice.reducer;

//
// Action Creators
const url = "/billboardOrigins";

export const loadBillboardOrigins = () => (dispatch, getState) => {
  const { lastFetch } = getState().entities.billboardOrigins;

  console.log("loadBillboardOrigins lastFetch : ", lastFetch);

  // //
  // // Babak's Note: Below we check with 'moment' that catch that if this request was already fetched less than 10 minutes ago then do not fetch again:-
  // const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  // if (diffInMinutes < 10) return;

  dispatch(
    apiCallBegan({
      url: url,
      onStart: billboardOriginsRequested.type,
      method: "get",
      onSuccess: billboardOriginsReceived.type,
      onError: billboardOriginsRequestedFailed.type
    })
  );
};

export const loadBillboardOrigin = billboardOriginID => (
  dispatch,
  getState
) => {
  // const { lastFetch } = getState().entities.billboardOrigins;

  //console.log("loadBillboardOrigin : ", billboardOriginID);

  // //
  // // Babak's Note: Below we check with 'moment' that catch that if this request was already fetched less than 10 minutes ago then do not fetch again:-
  // const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  // if (diffInMinutes < 10) return;

  dispatch(
    apiCallBegan({
      url: url + "/" + billboardOriginID,
      onStart: billboardOriginRequested.type,
      method: "get",
      onSuccess: billboardOriginReceived.type,
      onError: billboardOriginRequestedFailed.type
    })
  );
};

//
// Babak's Note: Below function 'apiCallBegan' is returned back by which function (in this case from within 'index.js') calls 'addBillboardOrigin':-
export const addBillboardOrigin = billboardOrigin =>
  apiCallBegan({
    url,
    method: "post",
    onStart: billboardOriginAddRequested.type,
    data: billboardOrigin,
    onSuccess: billboardOriginAdded.type,
    onError: billboardOriginAddFailed.type
  });

export const getBillboardOrigins = createSelector(
  state => state.entities.billboardOrigins,
  //
  // Babak's Note: In the below line, by installing above 'reselect' (at the top of this file) if the list of (billboardOrigins, projects) has not changed then do not requery again:-
  // (billboardOrigins) => billboardOrigins.list.filter(billboardOrigin => !billboardOrigin.B_Active)
  billboardOrigins => billboardOrigins.list
);

export const changeBillboardOrigin = billboardOrigin =>
  apiCallBegan({
    url: url + "/" + billboardOrigin.ID,
    method: "put",
    onStart: billboardOriginChangeRequested.type,
    data: {
      A_Origin: billboardOrigin.A_Origin
    },
    onSuccess: billboardOriginChanged.type,
    onError: billboardOriginChangeFailed.type
  });

export const deleteBillboardOrigin = billboardOrigin =>
  apiCallBegan({
    url: url + "/" + billboardOrigin.ID,
    method: "delete",
    onStart: billboardOriginDeleteRequested.type,
    data: { ID: billboardOrigin.ID, A_Origin: billboardOrigin.A_Origin },
    onSuccess: billboardOriginDeleted.type,
    onError: billboardOriginDeleteFailed.type
  });
