import React, { Component } from "react";
import Flash from "react-reveal/Flash";
import HeadShake from "react-reveal/HeadShake";
import Jello from "react-reveal/Jello";
import Jump from "react-reveal/Jump";
import RubberBand from "react-reveal/RubberBand";
import Swing from "react-reveal/Swing";
import Tada from "react-reveal/Tada";
import Wobble from "react-reveal/Wobble";
import Zoom from "react-reveal/Zoom";
import Radium, { StyleRoot } from "radium";
import LazyLoad from "react-lazy-load";
import { Redirect } from "react-router";
import { Link, NavLink, withRouter, Route } from "react-router-dom";
// import backgroundImage from "../../images/logo.jpeg";

import { bottom } from "@popperjs/core";

class RenderHomeBillboardChildsMainKeypadListLeft extends Component {
  state = {
    navMaster: false,
    navDetail: false,
    link: ""
  };

  // async componentWillReceiveProps(nextProps) {
  //   const { homeBillboardChildsMains } = nextProps;

  //   if (
  //     this.state.homeBillboardChildsMains !== nextProps.homeBillboardChildsMains
  //   ) {
  //     for (let i = 1; i < this.props.homeBillboardChildsMains.length; i++) {
  //       console.log(
  //         "HomeBillboardChildsMain, componentWillReceivePropsssssssssssssssssssssssssssss  :  ",
  //         homeBillboardChildsMains[i],
  //         this.props.homeBillboardChildsMains.length
  //       );
  //     }
  //     this.setState({ homeBillboardChildsMains });
  //   }

  //   // console.log(
  //   //   `HomeBillboardChildsMain, componentWillReceivePropsssssssssssssssssssssssssssss  :  ${nextProps.homeBillboardChildsMains}`
  //   // );
  //   // const { homeBillboardChildsMains } = nextProps.homeBillboardChildsMains;
  //   // this.setState({
  //   //   homeBillboardChildsMains
  //   // });
  // }

  RenderParentImage = ({
    id,
    image,
    imageOnImage,
    parentImageLink,
    reference,
    parentReferenceLink,
    summary,
    parentSummaryLink,
    urlLink,
    urlLinked,
    urlLinkedExternal
  }) => {
    const sectionStyle = {
      // height: "310px",
      // backgroundImage: `linear-gradient(to bottom, ${item.A_BGC}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,
      backgroundImage: `url(${image})`,
      // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
      backgroundPosition: "center center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover"
    };

    return (
      <LazyLoad>
        {urlLinked ? (
          urlLinkedExternal ? (
            <a style={{ color: "white" }} href={urlLink} target={"_blank"}>
              <div
                key={id}
                style={sectionStyle}
                // onClick={() => {
                //   // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
                //   //               rows where N_HomeLocationGroup_ID === 1:-
                //   if (item.N_BillboardOrigin_ID === 2) {
                //     window.location = `../detailsMaster/${item.ID}`;
                //   } else if (item.N_BillboardOrigin_ID === 3) {
                //     window.location = `../detailsDetail/${item.ID}`;
                //   }
                // }}
                // src={image}
                className={`${parentImageLink} boxShadowLight`}
              >
                <div
                  key={id}
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px"
                  }}
                >
                  {imageOnImage ? (
                    <img
                      style={{
                        paddingTop: "10px",
                        float: "right",
                        maxWidth: "160px",
                        minWidth: "160px",
                        maxHeight: "120px",
                        minHeight: "120px"
                      }}
                      src={imageOnImage}
                    ></img>
                  ) : (
                    ""
                  )}
                </div>

                <div
                  style={{
                    backgroundColor: "white",
                    opacity: "0.8",
                    paddingBottom: "5px",
                    paddingLeft: "5px",
                    paddingRight: "5px"
                  }}
                >
                  <h2
                    style={{
                      maxWidth: "50vw",
                      minWidth: "50vw"
                    }}
                    className={parentReferenceLink}
                  >
                    {reference}
                  </h2>
                  <h2 className={parentSummaryLink}>{summary}</h2>
                </div>
              </div>
            </a>
          ) : (
            <NavLink style={{ color: "white" }} to={urlLink}>
              <div
                key={id}
                style={sectionStyle}
                // onClick={() => {
                //   // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
                //   //               rows where N_HomeLocationGroup_ID === 1:-
                //   if (item.N_BillboardOrigin_ID === 2) {
                //     window.location = `../detailsMaster/${item.ID}`;
                //   } else if (item.N_BillboardOrigin_ID === 3) {
                //     window.location = `../detailsDetail/${item.ID}`;
                //   }
                // }}
                // src={image}
                className={parentImageLink}
              >
                <div
                  key={id}
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px"
                  }}
                >
                  {imageOnImage ? (
                    <img
                      style={{
                        paddingTop: "10px",
                        float: "right",
                        maxWidth: "160px",
                        minWidth: "160px",
                        maxHeight: "120px",
                        minHeight: "120px"
                      }}
                      src={imageOnImage}
                    ></img>
                  ) : (
                    ""
                  )}
                </div>

                <div
                  style={{
                    backgroundColor: "white",
                    opacity: "0.8",
                    paddingBottom: "5px",
                    paddingLeft: "5px",
                    paddingRight: "5px"
                  }}
                >
                  <h2
                    style={{
                      maxWidth: "50vw",
                      minWidth: "50vw"
                    }}
                    className={`${parentImageLink} boxShadowLight`}
                  >
                    {reference}
                  </h2>
                  <h2 className={parentSummaryLink}>{summary}</h2>
                </div>
              </div>
            </NavLink>
          )
        ) : (
          <div style={{ color: "white" }}>
            <div
              key={id}
              style={sectionStyle}
              // onClick={() => {
              //   // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
              //   //               rows where N_HomeLocationGroup_ID === 1:-
              //   if (item.N_BillboardOrigin_ID === 2) {
              //     window.location = `../detailsMaster/${item.ID}`;
              //   } else if (item.N_BillboardOrigin_ID === 3) {
              //     window.location = `../detailsDetail/${item.ID}`;
              //   }
              // }}
              // src={image}
              className={parentImageLink}
            >
              <div
                key={id}
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px"
                }}
              >
                {imageOnImage ? (
                  <img
                    style={{
                      paddingTop: "10px",
                      float: "right",
                      maxWidth: "160px",
                      minWidth: "160px",
                      maxHeight: "120px",
                      minHeight: "120px"
                    }}
                    src={imageOnImage}
                  ></img>
                ) : (
                  ""
                )}
              </div>

              <div
                style={{
                  backgroundColor: "white",
                  opacity: "0.8",
                  paddingBottom: "5px",
                  paddingLeft: "5px",
                  paddingRight: "5px"
                }}
              >
                <h2
                  style={{
                    maxWidth: "50vw",
                    minWidth: "50vw"
                  }}
                  className={parentReferenceLink}
                >
                  {reference}
                </h2>
                <h2 className={parentSummaryLink}>{summary}</h2>
              </div>
            </div>
          </div>
        )}
      </LazyLoad>
    );
  };

  RenderChildImage = ({
    id,
    image,
    imageOnImage,
    childImageLink,
    reference,
    childReferenceLink,
    summary,
    childSummaryLink,
    urlLink,
    urlLinked,
    urlLinkedExternal
  }) => {
    const sectionStyle = {
      // height: "510px",
      // backgroundImage: `linear-gradient(to bottom, ${item.A_BGC}, 35%, rgba(0, 0, 0, 0)), url(${item.I_Image})`,
      backgroundImage: `url(${image})`,
      // boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9)",
      backgroundPosition: "center center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover"
    };

    //  console.log(`Number of rows xxxxxxxxxxxxxxxxxxxxxxxxxx : ${reference}`);

    return (
      <LazyLoad>
        {urlLinked ? (
          urlLinkedExternal ? (
            <a style={{ color: "white" }} href={urlLink} target={"_blank"}>
              <div
                key={id}
                style={sectionStyle}
                // style={{ padding: "10px" }}
                // onClick={() => {
                //   // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
                //   //               rows where N_HomeLocationGroup_ID === 1:-
                //   if (item.N_BillboardOrigin_ID === 2) {
                //     window.location = `../detailsMaster/${item.ID}`;
                //   } else if (item.N_BillboardOrigin_ID === 3) {
                //     window.location = `../detailsDetail/${item.ID}`;
                //   }
                // }}
                // src={image}
                className={`${childImageLink} boxShadowLight`}
              >
                <div
                  key={id}
                  style={{ paddingLeft: "10px", paddingRight: "10px" }}
                >
                  {imageOnImage ? (
                    <img
                      style={{
                        paddingTop: "10px",
                        float: "right",
                        maxWidth: "90px",
                        minWidth: "90px",
                        maxHeight: "70px",
                        minHeight: "70px"
                      }}
                      src={imageOnImage}
                    ></img>
                  ) : (
                    ""
                  )}
                </div>

                <div
                  style={{
                    backgroundColor: "white",
                    opacity: "0.8",
                    paddingBottom: "5px",
                    paddingLeft: "5px",
                    paddingRight: "5px"
                  }}
                >
                  <h2 className={childReferenceLink}>{reference}</h2>
                  <h2 className={childSummaryLink}>{summary}</h2>
                </div>
              </div>
            </a>
          ) : (
            <NavLink style={{ color: "white" }} to={urlLink}>
              <div
                key={id}
                style={sectionStyle}
                // style={{ padding: "10px" }}
                // onClick={() => {
                //   // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
                //   //               rows where N_HomeLocationGroup_ID === 1:-
                //   if (item.N_BillboardOrigin_ID === 2) {
                //     window.location = `../detailsMaster/${item.ID}`;
                //   } else if (item.N_BillboardOrigin_ID === 3) {
                //     window.location = `../detailsDetail/${item.ID}`;
                //   }
                // }}
                // src={image}
                className={`${childImageLink} boxShadowLight`}
              >
                <div
                  key={id}
                  style={{ paddingLeft: "10px", paddingRight: "10px" }}
                >
                  {imageOnImage ? (
                    <img
                      style={{
                        paddingTop: "10px",
                        float: "right",
                        maxWidth: "90px",
                        minWidth: "90px",
                        maxHeight: "70px",
                        minHeight: "70px"
                      }}
                      src={imageOnImage}
                    ></img>
                  ) : (
                    ""
                  )}
                </div>

                <div
                  style={{
                    backgroundColor: "white",
                    opacity: "0.8",
                    paddingBottom: "5px",
                    paddingLeft: "5px",
                    paddingRight: "5px"
                  }}
                >
                  <h2 className={childReferenceLink}>{reference}</h2>
                  <h2 className={childSummaryLink}>{summary}</h2>
                </div>
              </div>
            </NavLink>
          )
        ) : (
          <div style={{ color: "white" }}>
            <div
              key={id}
              style={sectionStyle}
              // style={{ padding: "10px" }}
              // onClick={() => {
              //   // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
              //   //               rows where N_HomeLocationGroup_ID === 1:-
              //   if (item.N_BillboardOrigin_ID === 2) {
              //     window.location = `../detailsMaster/${item.ID}`;
              //   } else if (item.N_BillboardOrigin_ID === 3) {
              //     window.location = `../detailsDetail/${item.ID}`;
              //   }
              // }}
              // src={image}
              className={childImageLink}
            >
              <div
                key={id}
                style={{ paddingLeft: "10px", paddingRight: "10px" }}
              >
                {imageOnImage ? (
                  <img
                    style={{
                      paddingTop: "10px",
                      float: "right",
                      maxWidth: "90px",
                      minWidth: "90px",
                      maxHeight: "70px",
                      minHeight: "70px"
                    }}
                    src={imageOnImage}
                  ></img>
                ) : (
                  ""
                )}
              </div>

              <div
                style={{
                  backgroundColor: "white",
                  opacity: "0.8",
                  paddingBottom: "5px",
                  paddingLeft: "5px",
                  paddingRight: "5px"
                }}
              >
                <h2 className={childReferenceLink}>{reference}</h2>
                <h2 className={childSummaryLink}>{summary}</h2>
              </div>
            </div>
          </div>
        )}
      </LazyLoad>
    );
  };

  RenderVericalMenu = ({ id, reference, billboardOriginID, billboardID }) => {
    return billboardOriginID === 2 ? (
      <NavLink
        key={id}
        style={{ color: "white" }}
        to={`../detailsMaster/${billboardID}`}
      >
        <h2
          className="KeypadListMainChildReferenceColourLG"
          style={{ fontSize: "16px" }}
          //   style={{
          //     maxWidth: "50vw",
          //     minWidth: "50vw"
          //   }}
          //   className={parentReferenceLink}
        >
          {reference}
        </h2>
      </NavLink>
    ) : billboardOriginID === 3 ? (
      <NavLink
        key={id}
        style={{ color: "white" }}
        to={`../detailsDetail/${billboardID}`}
      >
        <h2
          className="KeypadListMainChildReferenceColourLG"
          style={{ fontSize: "16px" }}
          //   style={{
          //     maxWidth: "50vw",
          //     minWidth: "50vw"
          //   }}
          //   className={parentReferenceLink}
        >
          {reference}
        </h2>
      </NavLink>
    ) : (
      ""
    );
  };

  closeNav = item => {
    // console.log(
    //   "NavBar 1 : ",
    //   this.state.navExpanded,
    //   window.location.pathname
    // );
    // if (window.location.pathname != "/homeBillboardsMain") {
    //   window.location = `/appointments`;
    // }

    // e.stopPropagation();
    // console.log(e.target.className); // This gets the className of the target

    if (item.N_BillboardOrigin_ID === 2) {
      this.setState({ navMaster: true, link: `../detailsMaster/${item.ID}` });
    } else if (item.N_BillboardOrigin_ID === 3) {
      this.setState({ navDetail: true, link: `../detailsDetail/${item.ID}` });
    }
  };

  RenderReference = ({ reference, referenceCSS, urlLink }) => {
    return (
      <NavLink style={{ color: "white" }} to={urlLink}>
        <div
          className={referenceCSS}
          // style={{ textAlign: "right" }}
          // onClick={() => {
          //   // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
          //   //               rows where N_HomeLocationGroup_ID === 1:-
          //   if (item.N_BillboardOrigin_ID === 2) {
          //     window.location = `../detailsMaster/${item.ID}`;
          //   } else if (item.N_BillboardOrigin_ID === 3) {
          //     window.location = `../detailsDetail/${item.ID}`;
          //   }
          // }}
        >
          <div className="HomeTitleLink">{reference}</div>

          {/* <div
                  className="HomeTitleLinkBlack"
                  style={{ marginTop: "5px" }}
                  // style={{ textAlign: "right" }}
                >
                  {item.A_Summary}
                </div> */}
          {/* <div
                // style={{ textAlign: "right" }}
                className="HomeRedLink"
                onClick={() => {
                  // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
                  //               rows where N_HomeLocationGroup_ID === 1:-
                  if (item.N_BillboardOrigin_ID === 2) {
                    window.location = `../detailsMaster/${item.ID}`;
                  } else if (item.N_BillboardOrigin_ID === 3) {
                    window.location = `../detailsDetail/${item.ID}`;
                  }
                }}
              >
                More...
              </div> */}
        </div>
      </NavLink>
    );
  };

  render() {
    const {
      homeBillboardChildsMainKeypadList,
      homeBillboardLargesSlides,
      nameOfClassParentColumn,
      nameOfClassChildColumn,
      mediaAttribute,
      parentImageLink,
      parentReferenceLink,
      parentSummaryLink,
      childImageLink,
      childReferenceLink,
      childSummaryLink,
      textFC,
      description
    } = this.props;

    if (this.state.navMaster === true) {
      // window.alert(`${this.state.link}`);
      window.location = `../detailsMaster/${this.state.link}`;
    } else if (this.state.navDetail === true) {
      // window.alert(`${this.state.link}`);
      window.location = `../detailsDetail/${this.state.link}`;
    }

    // console.log(
    //   `Number of rows xxxxxxxxxxxxxxxxxxxxxxxxxx : ${homeBillboardChildsMainKeypadList.length}`
    // );

    return (
      <div key={1}>
        {/* <div className="col-6">Hello</div>
        <div className="col-6">
          <div className="col-6" style={{ float: "left" }}>
            Bye1
          </div>
          <div className="col-6" style={{ float: "left" }}>
            Bye2
          </div>
          <div className="col-6" style={{ float: "left" }}>
            Bye3
          </div>
          <div className="col-6" style={{ float: "left" }}>
            Bye4
          </div>
        </div> */}
        {/* *** Babak's Notes: Below is the make up of above *** */}

        <div
          key={2}
          className={nameOfClassParentColumn}
          style={{
            float: "left",
            // border: "1px solid white",
            paddingRight:
              mediaAttribute === "LG"
                ? "15px"
                : mediaAttribute === "MD"
                ? "15px"
                : mediaAttribute === "SM"
                ? "0px"
                : mediaAttribute === "XS"
                ? "0px"
                : "",
            paddingLeft:
              mediaAttribute === "LG"
                ? "0px"
                : mediaAttribute === "MD"
                ? "0px"
                : mediaAttribute === "SM"
                ? "0px"
                : mediaAttribute === "XS"
                ? "0px"
                : "",
            paddingBottom:
              mediaAttribute === "LG"
                ? "0px"
                : mediaAttribute === "MD"
                ? "0px"
                : mediaAttribute === "SM"
                ? "0px"
                : mediaAttribute === "XS"
                ? "0px"
                : ""
          }}
        >
          <h3
            className="borderInner"
            style={{
              color: `${textFC}`,
              border: `1px solid ${textFC}`,
              marginBottom: "5px"
            }}
            className="KeypadListMainChildTitleReferenceLG"
          >
            {description}
          </h3>
          <ul
            className="KeypadListLeftMainChildReferenceLG leaf"

            //   aria-label="Vehicle Models Available"
          >
            {homeBillboardLargesSlides.map((item, index) => (
              <li
                key={item.ID}
                className="star large-list"
                onClick={() => {
                  this.closeNav(item);
                  // Babak's Note: HomeLocationGroup ID will always be "1" as I query for all "t_billboardLarge"
                  //               rows where N_HomeLocationGroup_ID === 1:-
                }}
                // style={{ backgroundImage: `url(${item.I_Image})` }}
              >
                <this.RenderVericalMenu
                  reference={item.A_Reference}
                  billboardOriginID={item.N_BillboardOrigin_ID}
                  billboardID={item.ID}
                />
                {/* {item.A_Reference} */}
              </li>
            ))}
          </ul>
        </div>

        {mediaAttribute === "LG" ? (
          <div
            key={3}
            className={nameOfClassParentColumn}
            style={{
              float: "left",
              // border: "1px solid white",
              paddingRight: "0px",
              paddingLeft: "0px"
            }}
          >
            {homeBillboardChildsMainKeypadList.map((item, index) =>
              index === 0 ? (
                <div
                  key={item.ID}
                  className="col-md-12"
                  style={{
                    float: "left",
                    // border: "1px solid white",
                    paddingLeft: "5px",
                    paddingRight: "0px",
                    paddingBottom: "5px"
                  }}
                >
                  <this.RenderParentImage
                    id={item.ID}
                    image={item.I_Image}
                    imageOnImage={item.I_ImageOnImage}
                    parentImageLink={parentImageLink}
                    reference={item.A_Reference}
                    parentReferenceLink={parentReferenceLink}
                    summary={item.A_Summary}
                    parentSummaryLink={parentSummaryLink}
                    urlLink={item.A_HoverSummary01_Link}
                    urlLinked={item.B_HoverSummary01_Linked}
                    urlLinkedExternal={item.B_HoverSummary01_Link_External}
                  />
                </div>
              ) : index > 0 ? (
                <div
                  key={item.ID}
                  className={nameOfClassChildColumn}
                  style={{
                    float: "left",
                    // border: "1px solid white",
                    paddingBottom:
                      index === 1
                        ? "5px"
                        : index === 2
                        ? "5px"
                        : index === 3
                        ? "0px"
                        : index === 4
                        ? "0px"
                        : "0px",

                    paddingTop:
                      index === 1
                        ? "5px"
                        : index === 2
                        ? "5px"
                        : index === 3
                        ? "5px"
                        : index === 4
                        ? "5px"
                        : "0px",

                    paddingLeft:
                      index === 1
                        ? "5px"
                        : index === 2
                        ? "5px"
                        : index === 3
                        ? "5px"
                        : index === 4
                        ? "5px"
                        : "0px",

                    paddingRight:
                      index === 1
                        ? "5px"
                        : index === 2
                        ? "0px"
                        : index === 3
                        ? "5px"
                        : index === 4
                        ? "0px"
                        : "0px"
                  }}
                >
                  <this.RenderChildImage
                    id={item.ID}
                    image={item.I_Image}
                    imageOnImage={item.I_ImageOnImage}
                    childImageLink={childImageLink}
                    reference={item.A_Reference}
                    childReferenceLink={childReferenceLink}
                    summary={item.A_Summary}
                    childSummaryLink={childSummaryLink}
                    urlLink={item.A_HoverSummary01_Link}
                    urlLinked={item.B_HoverSummary01_Linked}
                    urlLinkedExternal={item.B_HoverSummary01_Link_External}
                  />
                </div>
              ) : (
                ""
              )
            )}
          </div>
        ) : mediaAttribute === "MD" ? (
          <div
            key={4}
            className={nameOfClassParentColumn}
            style={{
              float: "left",
              // border: "1px solid white",
              paddingRight: "5px",
              paddingLeft: "0px"
            }}
          >
            {homeBillboardChildsMainKeypadList.map((item, index) =>
              index === 0 ? (
                <div
                  key={item.ID}
                  className="col-md-12"
                  style={{
                    float: "left",
                    // border: "1px solid white",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    paddingBottom: "5px"
                  }}
                >
                  <this.RenderParentImage
                    id={item.ID}
                    image={item.I_Image}
                    imageOnImage={item.I_ImageOnImage}
                    parentImageLink={parentImageLink}
                    reference={item.A_Reference}
                    parentReferenceLink={parentReferenceLink}
                    summary={item.A_Summary}
                    parentSummaryLink={parentSummaryLink}
                    urlLink={item.A_HoverSummary01_Link}
                    urlLinked={item.B_HoverSummary01_Linked}
                    urlLinkedExternal={item.B_HoverSummary01_Link_External}
                  />
                </div>
              ) : index > 0 ? (
                <div
                  key={item.ID}
                  className={nameOfClassChildColumn}
                  style={{
                    float: "left",
                    // border: "1px solid white",
                    paddingBottom:
                      index === 1
                        ? "5px"
                        : index === 2
                        ? "5px"
                        : index === 3
                        ? "0px"
                        : index === 4
                        ? "0px"
                        : "0px",

                    paddingTop:
                      index === 1
                        ? "5px"
                        : index === 2
                        ? "5px"
                        : index === 3
                        ? "5px"
                        : index === 4
                        ? "5px"
                        : "0px",

                    paddingLeft:
                      index === 1
                        ? "0px"
                        : index === 2
                        ? "5px"
                        : index === 3
                        ? "0px"
                        : index === 4
                        ? "5px"
                        : "0px",

                    paddingRight:
                      index === 1
                        ? "5px"
                        : index === 2
                        ? "0px"
                        : index === 3
                        ? "5px"
                        : index === 4
                        ? "0px"
                        : "0px"
                  }}
                >
                  <this.RenderChildImage
                    id={item.ID}
                    image={item.I_Image}
                    imageOnImage={item.I_ImageOnImage}
                    childImageLink={childImageLink}
                    reference={item.A_Reference}
                    childReferenceLink={childReferenceLink}
                    summary={item.A_Summary}
                    childSummaryLink={childSummaryLink}
                    urlLink={item.A_HoverSummary01_Link}
                    urlLinked={item.B_HoverSummary01_Linked}
                    urlLinkedExternal={item.B_HoverSummary01_Link_External}
                  />
                </div>
              ) : (
                ""
              )
            )}
          </div>
        ) : mediaAttribute === "SM" ? (
          <div
            key={5}
            className={nameOfClassParentColumn}
            style={{
              float: "left",
              // border: "1px solid white",
              paddingRight: "0px",
              paddingLeft: "0px"
            }}
          >
            {homeBillboardChildsMainKeypadList.map((item, index) =>
              index === 0 ? (
                <div
                  key={item.ID}
                  className="col-md-12"
                  style={{
                    float: "left",
                    // border: "1px solid white",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    paddingBottom: "5px"
                  }}
                >
                  <this.RenderParentImage
                    id={item.ID}
                    image={item.I_Image}
                    imageOnImage={item.I_ImageOnImage}
                    parentImageLink={parentImageLink}
                    reference={item.A_Reference}
                    parentReferenceLink={parentReferenceLink}
                    summary={item.A_Summary}
                    parentSummaryLink={parentSummaryLink}
                    urlLink={item.A_HoverSummary01_Link}
                    urlLinked={item.B_HoverSummary01_Linked}
                    urlLinkedExternal={item.B_HoverSummary01_Link_External}
                  />
                </div>
              ) : index > 0 ? (
                <div
                  key={item.ID}
                  className={nameOfClassChildColumn}
                  style={{
                    float: "left",
                    // border: "1px solid white",
                    paddingBottom:
                      index === 1
                        ? "5px"
                        : index === 2
                        ? "5px"
                        : index === 3
                        ? "15px"
                        : index === 4
                        ? "15px"
                        : "0px",

                    paddingTop:
                      index === 1
                        ? "5px"
                        : index === 2
                        ? "5px"
                        : index === 3
                        ? "5px"
                        : index === 4
                        ? "5px"
                        : "0px",

                    paddingLeft:
                      index === 1
                        ? "0px"
                        : index === 2
                        ? "5px"
                        : index === 3
                        ? "0px"
                        : index === 4
                        ? "5px"
                        : "0px",

                    paddingRight:
                      index === 1
                        ? "5px"
                        : index === 2
                        ? "0px"
                        : index === 3
                        ? "5px"
                        : index === 4
                        ? "0px"
                        : "0px"
                  }}
                >
                  <this.RenderChildImage
                    id={item.ID}
                    image={item.I_Image}
                    imageOnImage={item.I_ImageOnImage}
                    childImageLink={childImageLink}
                    reference={item.A_Reference}
                    childReferenceLink={childReferenceLink}
                    summary={item.A_Summary}
                    childSummaryLink={childSummaryLink}
                    urlLink={item.A_HoverSummary01_Link}
                    urlLinked={item.B_HoverSummary01_Linked}
                    urlLinkedExternal={item.B_HoverSummary01_Link_External}
                  />
                </div>
              ) : (
                ""
              )
            )}
          </div>
        ) : mediaAttribute === "XS" ? (
          <div
            key={6}
            className={nameOfClassParentColumn}
            style={{
              float: "left",
              // border: "1px solid white",
              paddingRight: "0px",
              paddingLeft: "0px"
            }}
          >
            {homeBillboardChildsMainKeypadList.map((item, index) =>
              index === 0 ? (
                <div
                  key={item.ID}
                  className="col-md-12"
                  style={{
                    float: "left",
                    // border: "1px solid white",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    paddingBottom: "5px"
                  }}
                >
                  <this.RenderParentImage
                    id={item.ID}
                    image={item.I_Image}
                    imageOnImage={item.I_ImageOnImage}
                    parentImageLink={parentImageLink}
                    reference={item.A_Reference}
                    parentReferenceLink={parentReferenceLink}
                    summary={item.A_Summary}
                    parentSummaryLink={parentSummaryLink}
                    urlLink={item.A_HoverSummary01_Link}
                    urlLinked={item.B_HoverSummary01_Linked}
                    urlLinkedExternal={item.B_HoverSummary01_Link_External}
                  />
                </div>
              ) : index > 0 ? (
                <div
                  key={item.ID}
                  className={nameOfClassChildColumn}
                  style={{
                    float: "left",
                    // border: "1px solid white",
                    paddingBottom:
                      index === 1
                        ? "5px"
                        : index === 2
                        ? "5px"
                        : index === 3
                        ? "15px"
                        : index === 4
                        ? "15px"
                        : "0px",

                    paddingTop:
                      index === 1
                        ? "5px"
                        : index === 2
                        ? "5px"
                        : index === 3
                        ? "5px"
                        : index === 4
                        ? "5px"
                        : "0px",

                    paddingLeft:
                      index === 1
                        ? "0px"
                        : index === 2
                        ? "5px"
                        : index === 3
                        ? "0px"
                        : index === 4
                        ? "5px"
                        : "0px",

                    paddingRight:
                      index === 1
                        ? "5px"
                        : index === 2
                        ? "0px"
                        : index === 3
                        ? "5px"
                        : index === 4
                        ? "0px"
                        : "0px"
                  }}
                >
                  <this.RenderChildImage
                    id={item.ID}
                    image={item.I_Image}
                    imageOnImage={item.I_ImageOnImage}
                    childImageLink={childImageLink}
                    reference={item.A_Reference}
                    childReferenceLink={childReferenceLink}
                    summary={item.A_Summary}
                    childSummaryLink={childSummaryLink}
                    urlLink={item.A_HoverSummary01_Link}
                    urlLinked={item.B_HoverSummary01_Linked}
                    urlLinkedExternal={item.B_HoverSummary01_Link_External}
                  />
                </div>
              ) : (
                ""
              )
            )}
          </div>
        ) : (
          ""
        )}
      </div>

      // <this.RenderReference
      //   reference={item.A_Reference}
      //   urlLink={item.A_HoverSummary01_Link}
      //   referenceCSS={referenceCSS}
      // />
    );
  }
}

export default RenderHomeBillboardChildsMainKeypadListLeft;
