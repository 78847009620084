import React, { Component } from "react";
import Radium, { StyleRoot } from "radium";
import { NavLink, withRouter, Route } from "react-router-dom";
// import RenderomeBillboardChildsMainImageOnStrip from "./renderomeBillboardChildsMainImageOnStrip";
import { getHomeBillboardChildsMains } from "../../services/homeBillboardChildsMainKeypadList";
import { getBillboardLargesWindows } from "../../services/billboardLargeService";

class HomeBillboardChildsMainImageOnStrip extends Component {
  state = {
    homeBillboardChildsMainKeypadList: [],
    homeBillboardLargesWindows: []
  };

  async componentDidMount() {
    const homeBillboardsID = this.props.homeBillboardsID;

    // window.alert(`renderBillboardBulletPinterChilds 01 : ${homeBillboardsID}`);

    // console.log(
    //   `HomeBillboardBulletPointChilds, componentDidMount 01 : ${homeBillboardID}`
    // );

    const {
      data: homeBillboardChildsMainKeypadList
    } = await getHomeBillboardChildsMains(homeBillboardsID);

    const {
      data: homeBillboardLargesWindows
    } = await getBillboardLargesWindows();

    // console.log(
    //   "componentDidMount BulletPointChild 02 : ",
    //   homeBillboardChilds
    // );

    this.setState({
      homeBillboardChildsMainKeypadList,
      homeBillboardLargesWindows
    });
  }

  render() {
    const identifier = this.props.identifier;
    const sectionStyle = this.props.sectionStyle;
    const sectionStyleMD = this.props.sectionStyleMD;
    const sectionStyleSM = this.props.sectionStyleSM;
    const sectionStyleXS = this.props.sectionStyleXS;
    const homeBillboardsID = this.props.homeBillboardsID;
    const textFC = this.props.textFC;
    const reference = this.props.reference;
    const summary = this.props.summary;
    const image = this.props.image;
    const urlLink = this.props.urlLink;
    const urlLinkExternal = this.props.urlLinkExternal;
    const button = this.props.button;

    // if (homeBillboardsID === 10) window.alert("Hello : ");

    // const {
    //   homeBillboardChildsMainKeypadList,
    //   homeBillboardLargesWindows
    // } = this.state;

    // window.alert("Hello : ");

    // const { length: count } = this.state.homeBillboardChildsMainKeypadList;
    // window.alert(`Number of rows : ${count}`);
    // if (count === 0) return <div className="hourGlass"></div>;

    // window.alert(`Number of rows : ${reference}`);

    return (
      <div>
        <StyleRoot
          style={sectionStyle}
          className="d-none d-lg-block HeroMain homeOverflowHidden"
        >
          <div className="ImageOnStripChildHeroMain-contentTopLG colorlib-about">
            <div className="ImageOnStripChildHeroMain-contentInnerTopLG">
              <div className="ImageOnStripChildHeroMain-subTitleTopLG">
                <div className="row">
                  <div className="col-6">
                    <div
                      className="heading-meta"
                      style={{ marginBottom: "12px", marginTop: "15px" }}
                    >
                      <span
                        className="borderInner"
                        style={{
                          color: `${textFC}`,
                          border: `1px solid ${textFC}`
                        }}
                      >
                        {reference}
                      </span>
                    </div>

                    <div
                      className="heading-meta"
                      style={{ marginBottom: "10px" }}
                    >
                      <span
                        style={{
                          color: `${textFC}`,
                          border: `1px solid ${textFC}`
                        }}
                        className="colorlib-heading borderInner"
                      >
                        {summary}
                      </span>
                    </div>

                    {urlLinkExternal ? (
                      <a
                        // className="navbar-brand text-warning ml-sm-2"
                        //
                        // Babak's Note: "mb-1" from bottom up 1. "ml-sm-2" from left right 2. "https://getbootstrap.com/docs/4.0/utilities/spacing/",
                        //                                                                     "https://getbootstrap.com/docs/4.0/utilities/display/" :-
                        className="navbar-brand mb-1 btn btn-warning"
                        href={urlLink}
                        target={"_blank"}
                      >
                        {button}
                      </a>
                    ) : (
                      <NavLink
                        // className="navbar-brand text-warning ml-sm-2"
                        //
                        // Babak's Note: "mb-1" from bottom up 1. "ml-sm-2" from left right 2. "https://getbootstrap.com/docs/4.0/utilities/spacing/",
                        //                                                                     "https://getbootstrap.com/docs/4.0/utilities/display/" :-
                        className="navbar-brand mb-1 btn btn-warning"
                        to={`${urlLink}`}
                      >
                        {button}
                      </NavLink>
                    )}

                    {/* <button className="btn btn-warning">{button}</button> */}
                  </div>

                  <div className="col-6">
                    <div className="ImageOnStripChildHeroMain-subTitleMiddleLG">
                      <div style={{ float: "right" }}>
                        {urlLinkExternal ? (
                          <a href={urlLink} target={"_blank"}>
                            <img
                              className="boxShadowLow"
                              src={image}
                              style={{
                                float: "left",
                                minWidth: "200px",
                                maxWidth: "180px",
                                maxHeight: "135px",
                                minHeight: "135px"
                              }}
                            ></img>
                          </a>
                        ) : (
                          <NavLink to={`${urlLink}`}>
                            <img
                              className="boxShadowLow"
                              src={image}
                              style={{
                                float: "left",
                                minWidth: "200px",
                                maxWidth: "180px",
                                maxHeight: "135px",
                                minHeight: "135px"
                              }}
                            ></img>
                          </NavLink>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </StyleRoot>

        <StyleRoot
          style={sectionStyle}
          className="d-none d-md-block d-lg-none HeroMain homeOverflowHidden"
        >
          <div className="ImageOnStripChildHeroMain-contentTopMD colorlib-about">
            <div className="ImageOnStripChildHeroMain-contentInnerTopMD">
              <div className="ImageOnStripChildHeroMain-subTitleTopMD">
                <div className="row">
                  <div className="col-6">
                    <div
                      className="heading-meta"
                      style={{ marginBottom: "12px", marginTop: "15px" }}
                    >
                      <span
                        className="borderInner"
                        style={{
                          color: `${textFC}`,
                          border: `1px solid ${textFC}`
                        }}
                      >
                        {reference}
                      </span>
                    </div>

                    <div
                      className="heading-meta"
                      style={{ marginBottom: "10px" }}
                    >
                      <span
                        style={{
                          color: `${textFC}`,
                          border: `1px solid ${textFC}`
                        }}
                        className="colorlib-heading borderInner"
                      >
                        {summary}
                      </span>
                    </div>

                    {urlLinkExternal ? (
                      <a
                        // className="navbar-brand text-warning ml-sm-2"
                        //
                        // Babak's Note: "mb-1" from bottom up 1. "ml-sm-2" from left right 2. "https://getbootstrap.com/docs/4.0/utilities/spacing/",
                        //                                                                     "https://getbootstrap.com/docs/4.0/utilities/display/" :-
                        className="navbar-brand mb-1 btn btn-warning"
                        href={urlLink}
                        target={"_blank"}
                      >
                        {button}
                      </a>
                    ) : (
                      <NavLink
                        // className="navbar-brand text-warning ml-sm-2"
                        //
                        // Babak's Note: "mb-1" from bottom up 1. "ml-sm-2" from left right 2. "https://getbootstrap.com/docs/4.0/utilities/spacing/",
                        //                                                                     "https://getbootstrap.com/docs/4.0/utilities/display/" :-
                        className="navbar-brand mb-1 btn btn-warning"
                        to={`${urlLink}`}
                      >
                        {button}
                      </NavLink>
                    )}

                    {/* <button className="btn btn-warning">{button}</button> */}
                  </div>

                  <div className="col-6">
                    <div className="ImageOnStripChildHeroMain-subTitleMiddleMD">
                      <div style={{ float: "right" }}>
                        {urlLinkExternal ? (
                          <a href={urlLink} target={"_blank"}>
                            <img
                              className="boxShadowLow"
                              src={image}
                              style={{
                                float: "left",
                                minWidth: "200px",
                                maxWidth: "180px",
                                maxHeight: "135px",
                                minHeight: "135px"
                              }}
                            ></img>
                          </a>
                        ) : (
                          <NavLink to={`${urlLink}`}>
                            <img
                              className="boxShadowLow"
                              src={image}
                              style={{
                                float: "left",
                                minWidth: "200px",
                                maxWidth: "180px",
                                maxHeight: "135px",
                                minHeight: "135px"
                              }}
                            ></img>
                          </NavLink>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </StyleRoot>

        <StyleRoot
          style={sectionStyle}
          className="d-sm-block d-md-none d-none HeroMain homeOverflowHidden"
        >
          <div className="ImageOnStripChildHeroMain-contentTopSM colorlib-about">
            <div className="ImageOnStripChildHeroMain-contentInnerTopSM">
              <div
                style={{
                  marginTop: "-50px"
                }}
                className="ImageOnStripChildHeroMain-subTitleTopSM"
              >
                <div className="row">
                  <div className="col-6">
                    <div
                      className="heading-meta"
                      style={{ marginBottom: "12px", marginTop: "15px" }}
                    >
                      <span
                        className="borderInner"
                        style={{
                          color: `${textFC}`,
                          border: `1px solid ${textFC}`
                        }}
                      >
                        {reference}
                      </span>
                    </div>

                    <div
                      className="heading-meta"
                      style={{ marginBottom: "10px" }}
                    >
                      <span
                        style={{
                          color: `${textFC}`,
                          border: `1px solid ${textFC}`
                        }}
                        className="colorlib-heading borderInner"
                      >
                        {summary}
                      </span>
                    </div>

                    {urlLinkExternal ? (
                      <a
                        // className="navbar-brand text-warning ml-sm-2"
                        //
                        // Babak's Note: "mb-1" from bottom up 1. "ml-sm-2" from left right 2. "https://getbootstrap.com/docs/4.0/utilities/spacing/",
                        //                                                                     "https://getbootstrap.com/docs/4.0/utilities/display/" :-
                        className="navbar-brand mb-1 btn btn-warning"
                        href={urlLink}
                        target={"_blank"}
                      >
                        {button}
                      </a>
                    ) : (
                      <NavLink
                        // className="navbar-brand text-warning ml-sm-2"
                        //
                        // Babak's Note: "mb-1" from bottom up 1. "ml-sm-2" from left right 2. "https://getbootstrap.com/docs/4.0/utilities/spacing/",
                        //                                                                     "https://getbootstrap.com/docs/4.0/utilities/display/" :-
                        className="navbar-brand mb-1 btn btn-warning"
                        to={`${urlLink}`}
                      >
                        {button}
                      </NavLink>
                    )}

                    {/* <button className="btn btn-warning">{button}</button> */}
                  </div>

                  <div className="col-6">
                    <div className="ImageOnStripChildHeroMain-subTitleMiddleSM">
                      <div style={{ float: "right" }}>
                        {urlLinkExternal ? (
                          <a href={urlLink} target={"_blank"}>
                            <img
                              className="boxShadowLow"
                              src={image}
                              style={{
                                float: "left",
                                minWidth: "200px",
                                maxWidth: "180px",
                                maxHeight: "135px",
                                minHeight: "135px"
                              }}
                            ></img>
                          </a>
                        ) : (
                          <NavLink to={`${urlLink}`}>
                            <img
                              className="boxShadowLow"
                              src={image}
                              style={{
                                float: "left",
                                minWidth: "200px",
                                maxWidth: "180px",
                                maxHeight: "135px",
                                minHeight: "135px"
                              }}
                            ></img>
                          </NavLink>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </StyleRoot>

        <StyleRoot
          style={sectionStyle}
          className="d-xs-block d-sm-none d-md-none HeroMain homeOverflowHidden"
        >
          <div className="ImageOnStripChildHeroMain-contentTopXS colorlib-about">
            <div className="ImageOnStripChildHeroMain-contentInnerTopXS">
              <div
                style={{
                  marginTop: "-50px"
                }}
                className="ImageOnStripChildHeroMain-subTitleTopXS"
              >
                <div className="row">
                  <div className="col-6">
                    <div
                      className="heading-meta"
                      style={{ marginBottom: "12px", marginTop: "15px" }}
                    >
                      <span
                        className="borderInner"
                        style={{
                          color: `${textFC}`,
                          border: `1px solid ${textFC}`,
                          fontSize: `10px`
                        }}
                      >
                        {reference}
                      </span>
                    </div>

                    <div
                      className="heading-meta"
                      style={{ marginBottom: "10px" }}
                    >
                      <span
                        style={{
                          color: `${textFC}`,
                          border: `1px solid ${textFC}`,
                          fontSize: `10px`
                        }}
                        className="borderInner"
                      >
                        {summary}
                      </span>
                    </div>

                    {urlLinkExternal ? (
                      <a
                        // className="navbar-brand text-warning ml-sm-2"
                        //
                        // Babak's Note: "mb-1" from bottom up 1. "ml-sm-2" from left right 2. "https://getbootstrap.com/docs/4.0/utilities/spacing/",
                        //                                                                     "https://getbootstrap.com/docs/4.0/utilities/display/" :-
                        className="navbar-brand mb-1 btn btn-warning"
                        href={urlLink}
                        target={"_blank"}
                      >
                        {button}
                      </a>
                    ) : (
                      <NavLink
                        // className="navbar-brand text-warning ml-sm-2"
                        //
                        // Babak's Note: "mb-1" from bottom up 1. "ml-sm-2" from left right 2. "https://getbootstrap.com/docs/4.0/utilities/spacing/",
                        //                                                                     "https://getbootstrap.com/docs/4.0/utilities/display/" :-
                        className="navbar-brand mb-1 btn btn-warning"
                        to={`${urlLink}`}
                      >
                        {button}
                      </NavLink>
                    )}

                    {/* <button className="btn btn-warning">{button}</button> */}
                  </div>

                  <div className="col-6">
                    <div className="ImageOnStripChildHeroMain-subTitleMiddleXS">
                      <div style={{ float: "right" }}>
                        {urlLinkExternal ? (
                          <a href={urlLink} target={"_blank"}>
                            <img
                              className="boxShadowLow"
                              src={image}
                              style={{
                                float: "left",
                                minWidth: "160px",
                                maxWidth: "160px",
                                maxHeight: "135px",
                                minHeight: "135px"
                              }}
                            ></img>
                          </a>
                        ) : (
                          <NavLink to={`${urlLink}`}>
                            <img
                              className="boxShadowLow"
                              src={image}
                              style={{
                                float: "left",
                                minWidth: "160px",
                                maxWidth: "160px",
                                maxHeight: "135px",
                                minHeight: "135px"
                              }}
                            ></img>
                          </NavLink>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </StyleRoot>
      </div>
    );
  }
}

export default HomeBillboardChildsMainImageOnStrip;
