// import React from 'react';

// const Input = (props) => {
//     return <div className="form-group">
//     <label htmlFor={props.name}>Username</label>
//     <input
//       autoFocus
//       value={account.username}
//       onChange={this.handleChange}
//       id={props.name}
//       name={props.name}
//       type="text"
//       className="form-control"
//     />
//   </div>;
// }

// export default Input;

//
// Babak's Note: I have destructed above '(props)' to below '({name, label, value, onChange})'
import React from "react";

// //
// // Babak's Note: Old version
// const Input = ({ type, name, label, value, error, onChange }) => {
//   return (
//     <div className="form-group">
//       <label htmlFor={name}>{label}</label>
//       <input
//         value={value}
//         onChange={onChange}
//         type={type}
//         name={name}
//         id={name}
//         className="form-control"
//       />
//       {error && <div className="alert alert-danger">{error}</div>}
//     </div>
//   );
// };
//
// Babak's Note: New version included of 'Rest operator'
const InputRememberMe = ({ name, label, error, ...rest }) => {
  return (
    <div className="row">
      <label style={{ marginRight: "6px" }} htmlFor={name}>
        {label}
      </label>
      <input
        style={{ marginTop: "7px", marginRight: "15px" }}
        {...rest}
        name={name}
        id={name}
      />
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default InputRememberMe;
